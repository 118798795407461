import { Box, Grid, Typography } from '@mui/material';

import { TEASER_PAGE } from '@/constants';
import { TeaserSectionTitle } from '@/view/components/Teaser/TeaserSectionTitle';
import { STYLES, TEASER_STYLES } from '@/view/styling/theme';

const report = TEASER_PAGE.REPORT;

export function TeaserReport({ customSubtitle }: { customSubtitle?: string }) {
  return (
    <Box>
      <Grid container sx={STYLES.topPage.report.grid}>
        <Grid item>
          <TeaserSectionTitle
            title={'Report'}
            subtitle={customSubtitle || report.TITLE}
          />
        </Grid>
        <Grid item container direction="column" sx={{ marginTop: '24px' }}>
          <Grid container item>
            <Grid item width="100%">
              <img
                style={{
                  width: '90%',
                }}
                src={report.BODY_IMAGE_1}
              />
            </Grid>
            <Grid item sx={{ marginTop: '12px', textAlign: 'left' }}>
              <Typography sx={TEASER_STYLES.reportContent}>
                {report.CAPTION_1}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container sx={{ marginBottom: '20px', marginTop: '32px' }}>
            <Grid item width="100%">
              <img width="50%" src={report.BODY_IMAGE_3} />
            </Grid>
            <Grid item sx={{ marginTop: '12px', textAlign: 'left' }}>
              <Typography sx={TEASER_STYLES.reportContent}>
                {report.CAPTION_3}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
