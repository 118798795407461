import * as _ from 'lodash';

import { ALL_SURVEY } from '@/constants';
import { onSelectAnswer } from '@/domain/middlewares/user';
import { Store } from '@/domain/store';
import { createLogger } from '@/domain/utils/logger';
import { Answer, Question } from '@/types';
import { NormalizedQuestion } from '@/types/network';

const logger = createLogger('@domain/utils/survey');

/**
 * removeQuestion
 *
 * @remarks
 * - remove a question with the supplied key
 *
 * @param store - js-atom instance
 * @param currentState - the current state
 * @param removeKey - a string of the key you want to remove
 */
export function removeQuestion(
  questions: Question[],
  surveyAnswers: Answer[],
  removeKey: string
): { answers: Answer[]; questions: Question[] } {
  const answers = _.filter(surveyAnswers, (o) => {
    return o.key !== removeKey;
  });
  logger.debug('question removed', removeKey);
  return {
    answers,
    questions: _.filter([...questions], (o) => {
      return o.id !== removeKey;
    }),
  };
}

/**
 * addQuestion
 *
 * @remarks
 * - insert a question after a key
 *
 * @param store - js-atom instance
 * @param currentState - the current state
 * @param question - a question that will be inserted
 * @param keyBefore - a string of the previous key to insert the question after
 */
export function addQuestion(
  questions: Question[],
  question: Question,
  keyBefore: string
): { questions: Question[] } {
  const hasQuestion =
    _.findIndex(questions, (o) => {
      return o.id === question.id;
    }) !== -1;

  if (!hasQuestion) {
    const nextQuestions = [...questions];
    const parentIndex = _.findIndex(questions, (o) => {
      return o.id === keyBefore;
    });
    if (parentIndex >= 0) {
      nextQuestions.splice(parentIndex + 1, 0, question);
      logger.debug('question added', question?.id);
      return { questions: nextQuestions };
    }
  }
  logger.debug('no question added', question?.id);
  return {
    questions,
  };
}

/**
 * getUnAnsweredQuestions
 *
 * @remarks
 * - get a list of unanswered questions
 *
 * @param surveyAnswers - a list of answers
 * @param questions - a list of questions
 */
export function getUnAnsweredQuestions(
  surveyAnswers: Answer[],
  questions: Question[]
): Question[] {
  const completeAnswers = _.filter(
    surveyAnswers,
    (a) => !isIncompleteAnswerIdOrValue(`${a.value}`)
  );
  logger.debug('completeAnswers', completeAnswers);

  const currentAnswers = completeAnswers.map((a) => a.key);
  logger.debug('current answer keys', currentAnswers);
  return _.filter(
    questions,
    (q) => !currentAnswers.includes(q.id) && q.children.length > 0
  );
}

/**
 * restoreQuestionsInputSelection
 *
 * @remarks
 * - loop through the current answers
 * - compare those keys to the current questions
 * - similar selecting the answer again to reuse conditional logic
 *
 * @param store - js-atom instance
 * @param answers - a list of survey answers
 * @param questions - a list of survey questions
 */
export function restoreQuestionsInputSelection(
  store: Store,
  answers: Answer[],
  questions: Question[]
): void {
  const keyList = questions.map((q) => q.id);
  answers.forEach(async (k) => {
    if (keyList.includes(k.key)) {
      await onSelectAnswer(store, false, k);
    }
  });
}

/**
 * isIncompleteAnswerIdOrValue
 *
 * @remarks
 * - check if the answerId or an answer value is null or incomplete
 *
 * @param boolean - return true if its an incomplete answerId
 */
export function isIncompleteAnswerIdOrValue(value: string | null) {
  const isIncomplete = `${value}`.slice(-1) === ',' || `${value}`[0] === ',';
  return isIncomplete || _.isNull(value) || _.isEmpty(value);
}

/**
 * prepareSurveyAnswersForRestoration
 *
 * @remarks
 * - get the answers from the selectedAssessment
 * - convert them to an Answer shape
 *
 * @param NormalizedQuestion - A list of survey questions from a Normalized Assessment
 */
export function prepareSurveyAnswersForRestoration(
  questions: NormalizedQuestion[]
): Answer[] {
  return _.filter(questions, (question) => {
    return !isIncompleteAnswerIdOrValue(_.get(question, 'answerId', null));
  }).map((question) => {
    return {
      key: question.questionId,
      value: _.get(question, 'answerId', null),
    };
  });
}

/**
 * calculateSurveyPageIndexByCompletion
 *
 * @remarks
 * - get a list of question keys
 * - cross reference those keys against the question pages
 * - find the correct page to redirect the user.
 *
 * @param answersList - A list of answers
 * @param allQuestions - A list of all questions split into per page array
 */
export function calculateSurveyPageIndexByCompletion(
  answersList: Answer[],
  allQuestions: Question[][]
): string {
  // for each page of the questionary
  const expandedQuestions = allQuestions.map((pageQuestions) => {
    // loop through all answers on the page to check for
    // conditional fields
    const pageQuestionsWithChildren = pageQuestions.filter(
      (q) => q.children.length > 0
    );
    const allQuestionPlusConditionalOnes = answersList.map((answer) => {
      return _.get(
        calculateNextQuestionsFromCurrentAnswers(
          pageQuestionsWithChildren,
          answersList,
          `${answer.key}_${answer.value}`
        ),
        'questions',
        pageQuestionsWithChildren
      ) as Question[];
    });
    //return all unique questions
    return _.uniqBy(_.flatten(allQuestionPlusConditionalOnes), 'id');
  });

  // generate a list of key based on the expanded questions
  const allQuestionsPerKey = expandedQuestions.map((listOfQuestions) =>
    getKeyStringList(listOfQuestions, 'id')
  );
  const answersListKeys = getKeyStringList(answersList, 'key');

  // Start at one because of survey pages start at 1
  let lastIndex = 1;
  allQuestionsPerKey.forEach((pageQuestion, questionListIndex) => {
    const nextPage = questionListIndex + 1;
    // For each page, check if the key is answered
    const listOfKeysThatAreAnswered = pageQuestion.filter((questionKey) => {
      return answersListKeys.includes(questionKey);
    });
    // if listOfKeysThatAreAnswered they haven't answered any question on that page
    if (listOfKeysThatAreAnswered.length !== 0) {
      // if its not, they should be AT LEAST on that page
      lastIndex = nextPage;
      if (listOfKeysThatAreAnswered.length === pageQuestion.length) {
        // if answers exist for all the keys on that page, they should go to the next page
        lastIndex = nextPage + 1;
      }
    }
  });
  if (lastIndex > allQuestions.length) {
    return `${allQuestions.length}`;
  }
  return `${lastIndex}`;
}

/**
 * calculateQuestionNumberOffset
 *
 * @remarks
 * - get a list of question keys
 * - cross reference those keys against the question pages
 * - find which questions are expanded and gets the correct question number
 *
 * @param answersList - A list of answers
 * @param excludedQuestions - a list of answer id that are excluded from the count normally the current page
 * @param allQuestions - A list of all questions split into per page array
 */
export function calculateQuestionNumberOffset(
  answersList: Answer[],
  excludedQuestions: Question[],
  allQuestions: Question[]
): number {
  const excludedKeys = getKeyStringList(excludedQuestions, 'id');
  const answersListKeys = getKeyStringList(answersList, 'key').filter(
    (key) => !excludedKeys.includes(key)
  );
  const parentQuestionsAnswered: string[] = [];
  const allAnswersListQuestionWithNumbers = allQuestions.filter(
    (q: Question) => {
      const hasAnswer = answersListKeys.includes(q.id);
      if (q.hideNumber && hasAnswer && q.parentId) {
        parentQuestionsAnswered.push(q.parentId);
        return false;
      }
      return answersListKeys.includes(q.id);
    }
  );

  const result =
    allAnswersListQuestionWithNumbers.length +
    _.uniq(parentQuestionsAnswered).length;
  return result;
}

/**
 * calculateNextQuestionsFromCurrentAnswers
 *
 * @remarks
 * - it returns what the next list of answers and/or questions should be
 * - based on the key passed in
 *
 * @param questions - A list of all questions split into per page array
 * @param currentAnswers - A list of answers
 * @param key - an id and value pair as a string
 */
export function calculateNextQuestionsFromCurrentAnswers(
  questions: Question[],
  currentAnswers: Answer[],
  key: string
): { answers?: Answer[]; questions?: Question[] } {
  switch (key) {
    case 'HOW_OFTEN_ALCOHOL_1':
    case 'HOW_OFTEN_ALCOHOL_2':
      return removeQuestion(
        questions,
        currentAnswers,
        _.get(ALL_SURVEY[3], 'id')
      );
    case 'HOW_OFTEN_ALCOHOL_3':
    case 'HOW_OFTEN_ALCOHOL_4':
    case 'HOW_OFTEN_ALCOHOL_5':
    case 'HOW_OFTEN_ALCOHOL_6':
    case 'HOW_OFTEN_ALCOHOL_7':
      return addQuestion(questions, ALL_SURVEY[3], 'HOW_OFTEN_ALCOHOL');
  }
  return {};
}

/**
 * getSurveyWithoutExcludedQuestions
 *
 * @remarks
 * - remove conditional question for each page so the page structure is kep
 *
 * @param questionPerPage - A list of all questions split into per page array
 * @param excludedQuestions - a list of question that should be filtered out of the pages
 */
export function getSurveyWithoutExcludedQuestions(
  questionPerPage: Question[][],
  excludedQuestions: Question[]
): Question[][] {
  const justKeys = excludedQuestions.map((q) => q.id);
  return questionPerPage.map((page: Question[]) => {
    return page.filter((question: Question) => {
      return question && !justKeys.includes(question.id);
    });
  });
}

export function getKeyStringList<T>(list: T[], prop: 'id' | 'key'): string[] {
  return _.map(list, prop);
}
