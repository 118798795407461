/* eslint-disable sort-keys-fix/sort-keys-fix */

export const products = [
  {
    id: 1,
    name: 'ビフィドバクテリウムタイプ',
    imageUrl: '/images/products/product1.png',
    designation: 'ココア飲料',
    contents: '125ml×24本',
    ingredients:
      '砂糖（国内製造）、ラフィノース、フラクトオリゴ糖、脱脂粉乳、ココアパウダー、全粉乳、油脂パウダー、食塩／安定剤（セルロース）、乳化剤、香料',
    bestBefore: '発送日より90日以上',
    storageMethod: '直射日光や高温を避けて保存してください。',
    energy: '78kcal',
    protein: '1.8g',
    fat: '1.5g',
    carbohydrates: '18.8g',
    sugar: '17.8g',
    dietaryFiber: '0.6～1.3g',
    saltEquivalent: '0.14g',
    additionalInfoFirstLabel: 'フラクトオリゴ糖',
    additionalInfoFirstValue: '3000mg',
    additionalInfoSecondLabel: 'ラフィノース',
    additionalInfoSecondValue: '5000mg',
    allergens: '乳成分',
  },
  {
    id: 2,
    name: 'バクテロイデスタイプ',
    imageUrl: '/images/products/product2.png',
    designation: 'ココア飲料',
    contents: '125ml×24本',
    ingredients:
      '砂糖（国内製造）、フラクトオリゴ糖、脱脂粉乳、ココアパウダー、全粉乳、油脂パウダー、食塩／シクロデキストリン、安定剤（セルロース）、乳化剤、香料',
    bestBefore: '発送日より90日以上',
    storageMethod: '直射日光や高温を避けて保存してください。',
    energy: '72kcal',
    protein: '1.7g',
    fat: '1.5g',
    carbohydrates: '15.4g',
    sugar: '14.4g',
    dietaryFiber: '0.6～1.3g',
    saltEquivalent: '0.13g',
    additionalInfoFirstLabel: 'フラクトオリゴ糖',
    additionalInfoFirstValue: '3000mg',
    additionalInfoSecondLabel: 'シクロデキストリン',
    additionalInfoSecondValue: '750mg',
    allergens: '乳成分',
  },
  {
    id: 3,
    name: 'フィーカリバクテリウムタイプ',
    imageUrl: '/images/products/product3.png',
    designation: 'ココア飲料',
    contents: '125ml×24本',
    ingredients:
      'ガラクトオリゴ糖（国内製造）、砂糖、フラクトオリゴ糖、脱脂粉乳、ココアパウダー、全粉乳、油脂パウダー、食塩／安定剤（セルロース）、乳化剤、香料',
    bestBefore: '発送日より90日以上',
    storageMethod: '直射日光や高温を避けて保存してください。',
    energy: '91kcal',
    protein: '1.8g',
    fat: '1.5g',
    carbohydrates: '22.1g',
    sugar: '21.1g',
    dietaryFiber: '0.6～1.3g',
    saltEquivalent: '0.13g',
    additionalInfoFirstLabel: 'フラクトオリゴ糖',
    additionalInfoFirstValue: '3000mg',
    additionalInfoSecondLabel: 'ガラクトオリゴ糖',
    additionalInfoSecondValue: '5000mg',
    allergens: '乳成分',
  },
  {
    id: 4,
    name: 'プレボテラタイプ',
    imageUrl: '/images/products/product4.png',
    designation: 'ココア飲料',
    contents: '125ml×24本',
    ingredients:
      '砂糖（国内製造）、フラクトオリゴ糖、脱脂粉乳、キシロオリゴ糖、ココアパウダー、全粉乳、油脂パウダー、食塩／安定剤（セルロース）、乳化剤、香料',
    bestBefore: '発送日より90日以上',
    storageMethod: '直射日光や高温を避けて保存してください。',
    energy: '77cal',
    protein: '1.7g',
    fat: '1.5g',
    carbohydrates: '16.7g',
    sugar: '15.7g',
    dietaryFiber: '0.6～1.3g',
    saltEquivalent: '0.13g',
    additionalInfoFirstLabel: 'フラクトオリゴ糖',
    additionalInfoFirstValue: '3000mg',
    additionalInfoSecondLabel: 'キシロオリゴ糖',
    additionalInfoSecondValue: '1200mg',
    allergens: '乳成分',
  },
  {
    id: 5,
    name: 'ルミノコッカスタイプ',
    imageUrl: '/images/products/product5.png',
    designation: 'ココア飲料',
    contents: '125ml×24本',
    ingredients:
      '砂糖（国内製造）、食物繊維（イヌリン）、フラクトオリゴ糖、脱脂粉乳、ココアパウダー、全粉乳、油脂パウダー、食塩／安定剤（セルロース）、乳化剤、香料',
    bestBefore: '発送日より90日以上',
    storageMethod: '直射日光や高温を避けて保存してください。',
    energy: '86cal',
    protein: '1.8g',
    fat: '1.5g',
    carbohydrates: '21.7g',
    sugar: '15.7g',
    dietaryFiber: '6.0g',
    saltEquivalent: '0.13g',
    additionalInfoFirstLabel: 'フラクトオリゴ糖',
    additionalInfoFirstValue: '5000mg',
    additionalInfoSecondLabel: 'イヌリン',
    additionalInfoSecondValue: '5000mg',
    allergens: '乳成分',
  },
];
