import { Typography, Grid, Box } from '@mui/material';
import HTMLParser from 'react-html-parser';

import { STYLES } from '@/view/styling/theme';

type Props = {
  description: string;
  image: string;
  title?: string;
};

export function GreyBoxInstructionCard({ title, image, description }: Props) {
  return (
    <Grid container gap={2}>
      {title && (
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <Typography sx={STYLES.greyBoxInstructionCard.title}>
            {title}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box>
          <img src={image} style={{ borderRadius: '12px', width: '100%' }} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: 2 }}>
        <Typography sx={STYLES.greyBoxInstructionCard.description}>
          {HTMLParser(description)}
        </Typography>
      </Grid>
    </Grid>
  );
}
