import * as _ from 'lodash';
import page from 'page';
import z from 'zod';

import {
  ERRORS,
  SURVEY_PAGE_QUESTIONS,
  ONGOING_ASSESSMENT_STATUSES,
} from '@/constants';
import { refreshAccessTokenAndSetUserState } from '@/domain/middlewares/auth';
import {
  getFirstSetPurchaseStatus,
  getLatestBacteriaType,
  getPurchases,
} from '@/domain/middlewares/router/helpers';
import { getState, store } from '@/domain/store';
import * as reducers from '@/domain/store/reducers';
import { pageView } from '@/domain/utils/analytics';
import { createLogger } from '@/domain/utils/logger';
import * as network from '@/domain/utils/network';
import * as normalizers from '@/domain/utils/normalizers';
import {
  prepareSurveyAnswersForRestoration,
  calculateSurveyPageIndexByCompletion,
} from '@/domain/utils/survey';
import { AppRoute } from '@/types';
import { Assessment, AssessmentStatus } from '@/types/network';

const logger = createLogger(
  '@domain/middlewares/router/middlewares/authMiddleware'
);

/**
 * authMiddleware
 *
 * @remarks
 * A route middleware to check user authentication
 * - Refresh access token and get meijiId of the session user
 * - Update user state with access token and meijiId
 * - When any of requests to IDPF get failed, prevent transiting to the target page and redirect to login page
 *
 * @param ctx - PageJS context
 * @param next - A callback function to call the next middleware function
 */
export const authMiddleware: PageJS.Callback = async (ctx, next) => {
  logger.debug('authMiddleware');
  try {
    await refreshAccessTokenAndSetUserState();
    next();
  } catch (error) {
    logger.debug('authMiddleware: Refreshing token is failed', error);
  }
};

/**
 * analyticsMiddleware
 * A route middleware to send path information to Analytics
 *
 * @param ctx - PageJS context
 * @param next - A callback function to call the next middleware function
 */
export const analyticsMiddleware: PageJS.Callback = async (ctx, next) => {
  logger.debug('analyticsMiddleware', ctx.canonicalPath);
  pageView(ctx.canonicalPath);
  next();
};

/**
 * scrollTopMiddleware
 * A route middleware to rest scrolling position
 *
 * @param ctx - PageJS context
 * @param next - A callback function to call the next middleware function
 */
export const scrollTopMiddleware: PageJS.Callback = async (ctx, next) => {
  logger.debug('scrollTopMiddleware');
  window.history.scrollRestoration = 'manual';
  window.scrollTo(0, 0);
  next();
};

/**
 * scrollTopMiddleware
 * A route middleware to rest scrolling position
 *
 * @param ctx - PageJS context
 * @param next - A callback function to call the next middleware function
 */

export const scrollToTopOnNavigationMiddleware: PageJS.Callback = async (
  ctx,
  next
) => {
  logger.debug('scrollTopMiddleware');
  const excludedPaths = [
    '/products/first-set',
    '/products/check-kit',
    '/products/drink',
  ];
  if (excludedPaths.includes(ctx.path)) {
    next();
  } else {
    window.history.scrollRestoration = 'manual';
    window.scrollTo(0, 0);
    next();
  }
};

/**
 * termsAgreementMiddleware
 * A route middleware to confirm the user's terms agreement
 *
 * @param ctx - PageJS context
 * @param next - A callback function to call the next middleware function
 */
export const termsAgreementMiddleware: PageJS.Callback = async (ctx, next) => {
  logger.debug('termsAgreementMiddleware');
  const currentState = getState();
  if (!currentState.agreedTerms) {
    return page('/agreement');
  }
  next();
};

/**
 * Middleware to get a users ongoingAssessment and set it to state.ongoingAssessment
 * Will set to null if user does not have any ongoingAssessments
 * @param ctx
 * @param next
 * @returns
 */

export const getOngoingAssessmentMiddleware: PageJS.Callback = async (
  ctx,
  next
) => {
  try {
    const currentState = getState();

    if (!currentState.user.meijiId || !currentState.user.token) {
      return next();
    }

    const oneAssessmentResponse = await network.getAssessments(
      {
        limit: '1',
        meijiId: currentState.user.meijiId,
        sort: { field: 'createdAt', order: 'desc' },
      },
      currentState.user.token
    );

    const mostRecentAssessment = oneAssessmentResponse.data[0];

    if (ONGOING_ASSESSMENT_STATUSES.includes(mostRecentAssessment?.status)) {
      reducers.setOngoingAssessment(
        store,
        // TODO actually need to get an order to get updated, but do we care?
        normalizers.normalizeAssessment(
          mostRecentAssessment,
          currentState.user.meijiId,
          ''
        )
      );
    } else {
      reducers.setOngoingAssessment(store, null);
    }

    return next();
  } catch (err) {
    logger.error(err);
    reducers.updateSnackbar(store, {
      message: ERRORS.GET_ASSESSMENTS_ERROR,
      type: 'error',
      visible: true,
    });
    page('/');
  }
};

/**
 * Middleware to set a users first-set kit purchase status to state.isFirstSetPurchase
 *
 * @param ctx
 * @param next
 * @returns
 */

export const getFirstSetPurchaseStatusMiddleware: PageJS.Callback = async (
  ctx,
  next
) => {
  try {
    const currentState = getState();

    if (!currentState.user.meijiId || !currentState.user.token) {
      return next();
    }

    await getFirstSetPurchaseStatus(
      currentState.user.meijiId,
      currentState.user.token
    );

    return next();
  } catch (err) {
    logger.error(err);
    reducers.updateSnackbar(store, {
      message: ERRORS.GET_ASSESSMENTS_ERROR,
      type: 'error',
      visible: true,
    });
    page('/');
  }
};

/**
 * getAssessmentsMiddleware
 *
 * A route middleware to get assessments and store them into state
 */
export const getAssessmentsMiddleware: PageJS.Callback = async (ctx, next) => {
  logger.debug('getAssessmentsMiddleware');

  const currentState = getState();
  try {
    const assessmentResponse = await network.getAssessments(
      { meijiId: currentState.user.meijiId, status: AssessmentStatus.COMPLETE },
      currentState.user.token
    );
    const normalizedAssessments = assessmentResponse.data
      .map((assessment: Assessment) => {
        return normalizers.normalizeAssessment(
          assessment,
          currentState.user.meijiId,
          ''
        );
      })
      .flatMap((assessment) => (assessment ? assessment : []));
    reducers.setUserAssessments(store, normalizedAssessments);

    // FIXME: abstract to util
    const oneAssessmentResponse = await network.getAssessments(
      {
        limit: '1',
        meijiId: currentState.user.meijiId,
        sort: { field: 'createdAt', order: 'desc' },
      },
      currentState.user.token
    );

    const mostRecentAssessment = oneAssessmentResponse.data[0];

    if (ONGOING_ASSESSMENT_STATUSES.includes(mostRecentAssessment?.status)) {
      reducers.setOngoingAssessment(
        store,
        // TODO actually need to get an order to get updated, but do we care?
        normalizers.normalizeAssessment(
          mostRecentAssessment,
          currentState.user.meijiId,
          ''
        )
      );
    } else {
      reducers.setOngoingAssessment(store, null);
    }

    return next();
  } catch (err) {
    logger.error(err);
    reducers.updateSnackbar(store, {
      message: ERRORS.GET_ASSESSMENTS_ERROR,
      type: 'error',
      visible: true,
    });
    page('/');
  }
};

/**
 * assessmentStatusMiddleware
 * A route middleware to confirm the user's assessment status and export status
 *
 * @param requiredStatuses - The required assessment status to pass to the handler
 * @return PageJS.Callback
 */
export const assessmentStatusMiddleware: (
  requiredStatuses: (AssessmentStatus | undefined)[]
) => PageJS.Callback = (requiredStatuses) => async (ctx, next) => {
  logger.debug('assessmentStatusMiddleware');

  const { ongoingAssessment } = getState();

  const hasRequiredStatus = requiredStatuses.includes(
    ongoingAssessment?.status
  );
  if (hasRequiredStatus) {
    logger.debug(`Matched to the required statuses: ${requiredStatuses}`);
    return next();
  }
  const myPagePath = '/my-page';
  logger.debug(
    `Redirect to ${myPagePath} since the current status:${ongoingAssessment?.status} is not matched to the required statuses: ${requiredStatuses}`
  );
  if (myPagePath === location.pathname) {
    logger.debug(`Cancel to redirect since it was already on ${myPagePath}`);
    return next();
  }
  page.redirect(myPagePath);
};

/**
 * selectAssessmentMiddleware
 * A route middleware to set Selected Assessment using `assessmentId` route params.
 *
 * @param conditions - requirements for assessments
 * @return PageJS.Callback
 */
export const selectAssessmentMiddleware: (
  conditions?: Partial<{
    assessmentStatuses: AssessmentStatus[];
  }>
) => PageJS.Callback = (conditions) => async (context, next) => {
  logger.debug('selectedAssessmentMiddleware');

  const currentState = getState();
  const assessments = currentState.assessments;

  try {
    const paramsSchema = z.object({
      assessmentId: z.string(),
    });
    const params = paramsSchema.parse(context.params);
    const assessment = assessments.find(
      (assessment) => assessment._id === params.assessmentId
    );

    if (
      !assessment ||
      (conditions?.assessmentStatuses &&
        !conditions?.assessmentStatuses.includes(assessment.status))
    ) {
      reducers.setSelectedAssessment(store, null);
      page('/my-page');
      return;
    }
    reducers.setSelectedAssessment(store, assessment);
    return next();
  } catch (err) {
    logger.error(err);
    reducers.updateSnackbar(store, {
      message: ERRORS.INTERNAL_ERROR_PARAM_NOT_FOUND,
      type: 'error',
      visible: true,
    });
    page('/');
  }
};

/**
 * surveyRestoreAnswersMiddleware
 * store the answers to the state before loading the survey page
 *
 * @param context - PageJS context
 * @param next - A callback function to call the next middleware function
 */
export const surveyRestoreAnswersMiddleware: PageJS.Callback = async (
  context,
  next
) => {
  const previousAnswers = prepareSurveyAnswersForRestoration(
    _.get(getState(), 'ongoingAssessment.survey', [])
  );
  try {
    reducers.updateSurveyAnswers(store, previousAnswers);
  } catch (prepareSurveyAnswersForRestorationError) {
    logger.error(prepareSurveyAnswersForRestorationError);
    reducers.updateSnackbar(store, {
      message: ERRORS.RESTORING_ANSWERS,
      type: 'error',
      visible: true,
    });
  }
  next();
};

/**
 * surveyRestorePageMiddleware
 * calculate the correct page that should be set based on the answers
 *
 * @param context - PageJS context
 * @param next - A callback function to call the next middleware function
 */
export const surveyRestorePageMiddleware: PageJS.Callback = async (
  context,
  next
) => {
  const surveyRouter = _.get(context, 'params.page', '1');
  const pageFromAnswers = calculateSurveyPageIndexByCompletion(
    getState().surveyAnswers,
    SURVEY_PAGE_QUESTIONS
  );
  const isFirstLoad = !getState().currentRoute;
  if (pageFromAnswers !== surveyRouter && isFirstLoad) {
    return page(`/survey/${pageFromAnswers}`);
  }

  const isFromMyPage = getState().currentRoute === AppRoute.MY_PAGE;
  if (pageFromAnswers !== surveyRouter && isFromMyPage) {
    return page(`/survey/${pageFromAnswers}`);
  }

  if (
    surveyRouter !== '1' &&
    _.get(getState(), 'surveyAnswers', []).length === 0
  ) {
    return page('/survey/1');
  }
  next();
};

/**
 * Middleware to set the latest bacteria type to state.latestBacteriaType
 *
 * @param ctx
 * @param next
 * @returns
 */

export const getLatestBacteriaTypeMiddleware: PageJS.Callback = async (
  ctx,
  next
) => {
  try {
    const currentState = getState();

    if (!currentState.user.meijiId || !currentState.user.token) {
      return next();
    }

    await getLatestBacteriaType(
      currentState.user.meijiId,
      currentState.user.token
    );

    return next();
  } catch (err) {
    logger.error(err);
    reducers.updateSnackbar(store, {
      message: ERRORS.GET_ASSESSMENTS_ERROR,
      type: 'error',
      visible: true,
    });
    page('/');
  }
};
