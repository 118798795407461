import { Container, Button, Grid, Typography } from '@mui/material';
import _ from 'lodash';

import {
  COLLECTION_PHASE_STEPPER,
  TEST_KIT_PREPARE_COLLECTION_PAGE,
} from '@/constants';
import * as user from '@/domain/middlewares/user';
import { store } from '@/domain/store';
import { State } from '@/types';
import { OutlinedCard } from '@/view/components/OutlinedCard';
import { FormStepper } from '@/view/components/Stepper';
import { STYLES } from '@/view/styling/theme';

export function AssessmentSampleCollection({ state }: { state: State }) {
  const onClickMyPage = _.partial(user.onClickMyPage, store);
  const onClickOpenSurvey = _.partial(user.onClickOpenSurvey, store);
  return (
    <div>
      <FormStepper activeStep={1} steps={COLLECTION_PHASE_STEPPER} />
      <Container>
        <Typography mb={4} sx={STYLES.mainHeading}>
          {state.currentRouteMeta?.title}
        </Typography>
        <Grid container direction="column" rowGap={3} sx={{ mb: 4 }}>
          <Grid item>
            <OutlinedCard
              type="info"
              color="info.light"
              padding={3}
              borderRadius={3}
              coloredTitle={
                <strong>
                  {TEST_KIT_PREPARE_COLLECTION_PAGE.INSTRUCTION.TITLE}
                </strong>
              }
              content={
                <>
                  {_.map(
                    TEST_KIT_PREPARE_COLLECTION_PAGE.INSTRUCTION.CONTENT,
                    (item) => (
                      <div
                        key={item}
                        style={{ lineHeight: '28.8px', marginBottom: '16px' }}
                      >
                        <div>
                          <strong>{item}</strong>
                        </div>
                      </div>
                    )
                  )}
                </>
              }
            />
          </Grid>
        </Grid>
        <Grid container direction="column" rowGap={3}>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              fullWidth
              onClick={onClickOpenSurvey}
              data-e2e="test-kit-prepare-collection__start-survey-btn"
            >
              {TEST_KIT_PREPARE_COLLECTION_PAGE.SUBMIT_BUTTON}
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              sx={{
                ...STYLES.secondaryButtonBottomPage,
                borderColor: 'grey.100',
                color: 'common.black',
              }}
              variant="outlined"
              fullWidth
              onClick={onClickMyPage}
            >
              {TEST_KIT_PREPARE_COLLECTION_PAGE.CANCEL_BUTTON}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
