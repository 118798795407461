import { Grid } from '@mui/material';

import { NormalizedReport } from '@/types/network';
import { LatestReportCard } from '@/view/components/LatestReportCard';
import { ColumnCarousel } from '@/view/components/MyPage/ColumnCarousel';

type Props = {
  onClick: (assessmentId: string) => void;
  report?: NormalizedReport & { assessmentId: string; reportDate: string };
};

export function NoOngoing({ onClick, report }: Props) {
  return (
    <Grid
      container
      direction="column"
      gap={2}
      data-e2e="my-page__no-ongoing-content"
    >
      <Grid item>
        <Grid container justifyContent="space-between">
          <LatestReportCard
            report={report}
            hasOngoing={false}
            hideIfEmpty={false}
            onClickGoToAssessmentReport={() => {
              if (report) onClick(report.assessmentId);
            }}
          />

          <Grid item alignContent="center">
            <ColumnCarousel />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
