import { createAtom, Store as StoreType } from '@basaldev/js-atom';
import atomDevTools from '@basaldev/js-atom-devtools';

import { initialState } from '@/domain/store/state';
import { State } from '@/types';

export type Store = StoreType<State>;

export const store = createAtom(initialState);
store.addWatch('atom-devtools', atomDevTools);

export function getState(): State {
  return store.deref();
}
