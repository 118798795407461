import { Box } from '@mui/material';

import { TEASER_PAGE } from '@/constants';
import { AutoplayImageCarousel } from '@/view/components/Carousel';
import { TeaserHeroTitle } from '@/view/components/Teaser/TeaserHeroTitle';
import { TEASER_STYLES } from '@/view/styling/theme';

export function TeaserHero() {
  return (
    <>
      <Box sx={TEASER_STYLES.teaserHero}>
        <Box
          sx={(theme) => ({
            backgroundColor: '#F8F6F4',
            pb: 4,
            [theme.breakpoints.up('lg')]: { pb: 6 },
            [theme.breakpoints.up('md')]: { pb: 4 },
          })}
        >
          <TeaserHeroTitle />
        </Box>

        <Box
          sx={(theme) => ({
            backgroundColor: '#F8F6F4',
            display: 'flex',
            height: '45%',
            maxWidth: '100vw',
            [theme.breakpoints.up('xl')]: { height: '55%' },
            [theme.breakpoints.up('md')]: { height: '50%' },
          })}
        >
          <AutoplayImageCarousel images={[...TEASER_PAGE.HERO.IMAGE_LIST]} />
        </Box>
      </Box>
      <Box>
        <img
          src={TEASER_PAGE.HERO.DISCOUNT_COUPON_IMAGE}
          style={TEASER_STYLES.discountCouponImage}
        />
      </Box>
    </>
  );
}
