import { BacteriaType } from '@/types';
import {
  Assessment,
  NewAssessment,
  UpdateAssessment,
  NetworkSessionUser,
  NetworkRefreshToken,
  NetworkTerms,
  AssessmentStatus,
  ProductType,
  NetworkPurchase,
} from '@/types/network';

const MEIJI_BACKEND_ENDPOINT = import.meta.env.MEIJI_BACKEND_ENDPOINT;
const IDPF_ENDPOINT = import.meta.env.MEIJI_IDPF_ENDPOINT;
const FRONTEND_SYSTEM_ID = Number(import.meta.env.MEIJI_FRONTEND_SYSTEM_ID);

/**
 * Get session user data from IDPF
 *
 * @remarks
 * This request requires the access token
 *
 * @param token - the access token
 *
 * @returns NetworkSessionUser
 */
export async function getSessionUser(
  token: string
): Promise<NetworkSessionUser> {
  const url = `${IDPF_ENDPOINT}/user`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
  if (response.ok) {
    return response.json();
  }
  throw new Error("Failed to get session user's data");
}

/**
 * Request refresh access token to IDPF
 *
 * @remarks
 * This request requires the session cookie given when the user login
 *
 * @returns NetworkRefreshToken
 */
export async function refreshAccessTokenAndSetUserState(): Promise<NetworkRefreshToken> {
  const url = `${IDPF_ENDPOINT}/refresh/subdomain`;
  const payload = { frontsystemId: FRONTEND_SYSTEM_ID };
  const response = await fetch(url, {
    body: JSON.stringify(payload),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
  if (response.ok) {
    return response.json();
  }
  throw new Error('Failed to refresh access token');
}

/**
 * Request logout to IDPF
 *
 * @param token - the access token
 *
 * @remarks
 * Remove the refresh token in cookie
 */
export async function logout(token: string): Promise<void> {
  const url = `${IDPF_ENDPOINT}/logout/subdomain`;
  const payload = { frontsystemId: FRONTEND_SYSTEM_ID };
  const response = await fetch(url, {
    body: JSON.stringify(payload),
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
  if (!response.ok) {
    throw new Error('Failed to logout');
  }
}

/**
 * Request terms from IDPF
 *
 * @param token - the access token
 *
 * @remarks
 * get the terms url
 */
export async function getTerms(token: string): Promise<NetworkTerms> {
  const url = `${IDPF_ENDPOINT}/terms?frontsystemId=${FRONTEND_SYSTEM_ID}`;
  const response = await fetch(url, {
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
  if (response.ok) {
    return response.json();
  }
  throw new Error('Failed to get terms');
}

//FIXME - where is reportRequired used?
export type GetAssessmentQuery = {
  id?: string;
  limit?: string;
  meijiId: string;
  offset?: string;
  reportRequired?: boolean;
  sort?: {
    field?: string;
    order?: string;
  };
  status?: AssessmentStatus;
};

export type GetProductQuery = GetAssessmentQuery & {
  productType?: ProductType;
};

export function buildQuery(
  query: GetAssessmentQuery | GetProductQuery
): string {
  const queryParams: string[] = [`meijiId=${query.meijiId}`];

  if (query.limit) {
    queryParams.push(`limit=${query.limit}`);
  }

  if (query.id) {
    queryParams.push(`id=${query.id}`);
  }

  if (query.status) {
    queryParams.push(`status=${query.status}`);
  }

  if (query.offset) {
    queryParams.push(`offset=${query.offset}`);
  }

  if ('productType' in query) {
    queryParams.push(`offset=${query.productType}`);
  }

  if (query.sort) {
    if (query.sort.order) {
      queryParams.push(`sort[order]=${query.sort.order}`);
    }

    if (query.sort.field) {
      queryParams.push(`sort[field]=${query.sort.field}`);
    }
  }

  // Combine all the query parameters into a single string separated by '&'
  const queryString = queryParams.join('&');

  // Construct the final URL query string
  return queryString ? `?${queryString}` : '';
}

export async function getAssessments(
  query: GetAssessmentQuery,
  token: string
): Promise<{ data: Assessment[]; total: number }> {
  const url = new URL(
    `${MEIJI_BACKEND_ENDPOINT}/assessments${buildQuery(query)}`
  );
  const response = await fetch(url, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      token,
    },
    method: 'GET',
  });
  if (response.ok) {
    return response.json();
  }
  throw new Error('Failed to get user data');
}

export async function createAssessment(
  payload: NewAssessment,
  token: string
): Promise<Assessment> {
  const url = `${MEIJI_BACKEND_ENDPOINT}/assessments`;
  const response = await fetch(url, {
    body: JSON.stringify(payload),
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      token,
    },
    method: 'POST',
  });
  if (response.ok) {
    return response.json();
  }
  throw new Error('Failed to create test kit');
}

export async function updateAssessment(
  id: string,
  payload: UpdateAssessment,
  token: string
): Promise<Assessment> {
  const url = `${MEIJI_BACKEND_ENDPOINT}/assessments/${id}`;
  const response = await fetch(url, {
    body: JSON.stringify(payload),
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      token,
    },
    method: 'PUT',
  });
  if (response.ok) {
    return response.json();
  }
  throw new Error('Failed to create test kit');
}

export function buildPurchaseQuery(sellingPlanId?: string): string {
  return sellingPlanId ? `?sellingPlanId=${sellingPlanId}` : '';
}

/**
 * Get a shopify unique checkout url for the specified product
 * @param token
 * @param productVariantId
 * @param sellingPlanId optional selling plan Id for subscription purchase
 * @returns checkoutUrl
 */
export async function getCheckoutUrl(
  token: string,
  productVariantId: string,
  sellingPlanId?: string
): Promise<{ checkoutUrl: string }> {
  const url = `${MEIJI_BACKEND_ENDPOINT}/assessments/checkout-url/${productVariantId}${buildPurchaseQuery(
    sellingPlanId
  )}`;
  const response = await fetch(url, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      token,
    },
    method: 'GET',
  });
  if (response.ok) {
    return response.json();
  }
  throw new Error('Failed to get checkout url');
}

/**
 * Get a shopify unique subscription url for the specified purchase
 * @param token
 * @param shopifyOrderId
 * @returns subscriptionUrl
 */
export async function getSubscriptionDetailsUrl(
  token: string,
  shopifyOrderId: string
): Promise<{ subscriptionUrl: string }> {
  const url = `${MEIJI_BACKEND_ENDPOINT}/purchases/${shopifyOrderId}/subscription-url`;
  const response = await fetch(url, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      token,
    },
    method: 'GET',
  });
  if (response.ok) {
    return response.json();
  }
  throw new Error('Failed to get subscription url');
}

/**
 * Get the first valid Assessment (no Abort Status) for a given meijiId
 *
 * @param token
 * @param query - GetAssessmentQuery
 *
 * @returns Assessment
 */
export async function getFirstValidAssessment(
  query: GetAssessmentQuery,
  token: string
): Promise<Assessment> {
  const url = new URL(
    `${MEIJI_BACKEND_ENDPOINT}/assessments/first-valid${buildQuery(query)}`
  );
  const response = await fetch(url, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      token,
    },
    method: 'GET',
  });

  if (response.ok) {
    return response.json();
  }
  throw new Error('Failed to get user assessment data');
}

/**
 * Get the latest Bacteria Type from user assessments
 *
 * @param token
 * @param query - GetAssessmentQuery
 *
 * @returns bacteriaType
 */
export async function getLatestBacteriaType(
  query: GetAssessmentQuery,
  token: string
): Promise<{ bacteriaType: BacteriaType | 'UNMEASURABLE' }> {
  const url = new URL(
    `${MEIJI_BACKEND_ENDPOINT}/assessments/latestBacteriaType${buildQuery(
      query
    )}`
  );
  const response = await fetch(url, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      token,
    },
    method: 'GET',
  });

  if (response.ok) {
    return response.json();
  }
  throw new Error('Failed to get latestBacteriaType from user assessment data');
}

/**
 * Get the users purchase history
 *
 * @param token
 * @param query - GetProductQuery
 *
 * @returns NetworkPurchase[]
 */
export async function getPurchases(
  query: GetProductQuery,
  token: string
): Promise<{ data: NetworkPurchase[] }> {
  const url = new URL(
    `${MEIJI_BACKEND_ENDPOINT}/purchases${buildQuery(query)}`
  );
  const response = await fetch(url, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      token,
    },
    method: 'GET',
  });

  if (response.ok) {
    return response.json();
  }
  throw new Error('Failed to get products from user order data');
}
