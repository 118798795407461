import { Grid } from '@mui/material';

import { PRODUCT_LIST_PAGE } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { State } from '@/types';
import { PageTitle } from '@/view/components/PageTitle';
import { ProductListItem } from '@/view/components/ProductListItem';

export function ProductList({ state }: { state: State }) {
  return (
    <Grid container sx={{ marginBottom: 2 }}>
      <Grid item xs={12} sx={{ marginBottom: 1 }}>
        <PageTitle text={PRODUCT_LIST_PAGE.PAGE_TITLE} variant="borderless" />
      </Grid>
      {PRODUCT_LIST_PAGE.BODY.map((product, index) => (
        <Grid item xs={12} key={index} sx={{ marginBottom: 5, marginX: 2 }}>
          <ProductListItem
            subTitle={product.SUBTITLE}
            image={product.IMAGE_PATH}
            price={product.PRICE}
            title={product.TITLE}
            nextButton={{
              label: PRODUCT_LIST_PAGE.BUTTON_TEXT,
              onClick: () =>
                user.navigateTo(`/products/${product.DETAIL_PAGE}`),
            }}
            taxText={PRODUCT_LIST_PAGE.PRICE_TAX_TEXT}
          />
        </Grid>
      ))}
    </Grid>
  );
}
