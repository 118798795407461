import { Grid, Typography, Card, CardContent } from '@mui/material';
import { ReactElement } from 'react';

import { LINE_HEIGHTS } from '@/view/styling/theme';

type Props = {
  borderRadius?: number;
  color?: string;
  coloredTitle: ReactElement | string;
  content: ReactElement | string;
  padding?: number;
  type: 'primary' | 'error' | 'info';
};

export function OutlinedCard({
  type,
  coloredTitle,
  content,
  color,
  padding = 2,
  borderRadius = 1.5,
}: Props) {
  const themeColor = color || `${type}.main`;
  return (
    <Card
      variant="outlined"
      sx={{ borderColor: themeColor, borderRadius }}
      className={`styled-card ${type}`}
    >
      <CardContent sx={{ p: padding }}>
        <Grid container direction="column">
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography
              sx={{
                color: themeColor,
                lineHeight: LINE_HEIGHTS.xl,
              }}
            >
              {coloredTitle}
            </Typography>
          </Grid>
          <Grid item className="text-wrap">
            {content}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
