import { Grid, Divider } from '@mui/material';

import { ALLERGY_INGREDIENT_PRODUCTS } from '@/constants';
import { State } from '@/types';
import { PageTitle } from '@/view/components/PageTitle';
import { ProductAccordion } from '@/view/components/ProductCard/ProductAccordion';
import { products } from '@/view/components/ProductCard/productData';
import { STYLES } from '@/view/styling/theme';

export function AllergyIngredientProducts({ state }: { state: State }) {
  return (
    <Grid container padding={2}>
      <Grid item xs={12}>
        <PageTitle
          text={ALLERGY_INGREDIENT_PRODUCTS.PAGE_TITLE}
          variant="border-bottom"
          styles={STYLES.pageTitleForProducts}
        />
      </Grid>

      <Grid item xs={12} mb={5}>
        {products.map((product) => (
          <ProductAccordion key={product.id} product={product} />
        ))}
        <Divider />
      </Grid>
    </Grid>
  );
}
