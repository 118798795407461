import { Grid } from '@mui/material';

type Props = {
  list: string[];
  showBorderBottom: boolean;
};

export function GraphLegend({ list = [], showBorderBottom }: Props) {
  return (
    <Grid
      container
      borderBottom={showBorderBottom ? 1 : 0}
      borderColor="grey.100"
    >
      {list.map((l, i) => {
        return (
          <Grid
            key={i}
            xs={12 / list.length}
            item
            className="legend-block"
            sx={{ display: 'flex', flexWrap: 'wrap' }}
          >
            {/* 33.4ug/min以上 → 33.4ug/min\n以上 → ['33.4ug/min', '以上']*/}
            {l
              .split('以')
              .join('\n以')
              .split('\n')
              .map((fragment, i) => (
                <span key={i}>{fragment}</span>
              ))}
          </Grid>
        );
      })}
    </Grid>
  );
}
