import { Box } from '@mui/material';

import { TOP_PAGE } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { Carousel } from '@/view/components/Carousel';
import { VoicesItemCard } from '@/view/components/TopPage/VoicesItemCard';
import { TopPageItem } from '@/view/components/TopPageItem';

export function Voices() {
  return (
    <TopPageItem
      title={TOP_PAGE.SECTION_BODY_7.BODY_TITLE_1}
      subtitle={TOP_PAGE.SECTION_BODY_7.BODY_TITLE_2}
      marginTop={3}
      buttonTitle={TOP_PAGE.SECTION_BODY_7.BUTTON_TITLE}
      onClick={() => user.navigateTo('/user-feedback')}
    >
      <Box sx={{ marginBottom: 3, marginTop: 4 }}>
        <Carousel>
          {TOP_PAGE.SECTION_BODY_7.BODY.map((item, index) => (
            <VoicesItemCard
              key={index}
              body1={item.BODY_1}
              body2={item.BODY_2}
              body3={item.BODY_3}
              imagePerson={item.IMAGE_PATH}
              readmore={TOP_PAGE.SECTION_BODY_7.READMORE}
              readless={TOP_PAGE.SECTION_BODY_7.READLESS}
              title={item.TITLE}
            />
          ))}
        </Carousel>
      </Box>
    </TopPageItem>
  );
}
