import {
  Grid,
  Container,
  Typography,
  Box,
  Divider,
  ListItem,
  List,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import { FunctionComponent, ReactNode, Fragment } from 'react';
import HTMLParser from 'react-html-parser';

import {
  MY_PAGE_REPORT,
  NOT_FOUND_PAGE,
  REPORT_BACTERIA_DESCRIPTION,
  BACTERIA_NAME_JP,
  BACTERIA_IMAGE,
  RECOMMENDED_MATERIALS,
  RECOMMENDED_PRODUCTS,
  RECOMMENDED_PRODUCTS_CAUTION,
} from '@/constants';
import * as user from '@/domain/middlewares/user';
import { BacteriaType, State } from '@/types';
import { HabitAndAdvice, Report } from '@/types/network';
import { AdviceItem } from '@/view/components/AdviceItem';
import { ErrorCard } from '@/view/components/ErrorCard';
import { PageTitleWithBackButton } from '@/view/components/PageTitleWithBackButton';
import { GraphColoringLabels } from '@/view/components/Report/GraphColoringLabels';
import { ReportTabs, ReportTabId } from '@/view/components/Report/ReportTabs';
import { ReportGraphCard } from '@/view/components/ReportGraphCard';
import { BORDER_RADIUS, FONT_SIZES } from '@/view/styling/theme';

import { SpiderGraph } from '../components/Report/ReportSpiderGraph';
import { ReportGardenScoreFold } from '../components/ReportGardenScoreFold';

const greyCardWrapper = {
  backgroundColor: 'grey.50',
  m: 0,
  marginBottom: 1,
  padding: 1,
};

export function MyPageReports({ state }: { state: State }) {
  if ((state.selectedAssessment?.report?.majorVersion ?? 1) !== 1) {
    return (
      <Container maxWidth="md" fixed>
        <ErrorCard
          code={'500'}
          detailDescription={
            MY_PAGE_REPORT.UNSUPPORTED_REPORT_FORMAT_DESCRIPTION
          }
          detailTitle={MY_PAGE_REPORT.UNSUPPORTED_REPORT_FORMAT_TITLE}
          description={''}
          title={MY_PAGE_REPORT.UNSUPPORTED_REPORT_FORMAT}
          onClick={() => user.onClickErrorButton('/my-page')}
          linkLabel={NOT_FOUND_PAGE.LINK_LABEL_1}
        />
      </Container>
    );
  }

  const report = _.get(
    state,
    'selectedAssessment.report.reportSummary',
    {}
  ) as Report['reportSummary'] & { gardenScore: number | null };

  /// TODO: Could not confirm habits.
  const habits = _.get(
    state,
    'selectedAssessment.report.habits',
    []
  ) as HabitAndAdvice[];

  const advices = state.selectedAssessment?.report?.advices ?? [];

  const testCompletedAt = dayjs(
    state.selectedAssessment?.testCompletedAt
  ).format('YYYY年M月D日');

  /// TODO: Fill this!
  const gardenScoreMedian = 63.2;

  const percentileText =
    state.selectedAssessment?.report?.result?.find(
      (result) => result.key === 'GARDEN_SCORE'
    )?.outcomePercentageText ?? null;

  const bacteriaType: BacteriaType =
    report.primaryBacteria === 'UNMEASURABLE' ? 'None' : report.primaryBacteria;
  const tScore = state.selectedAssessment?.report?.reportSummary.tScore;

  return (
    <div>
      <PageTitleWithBackButton
        text={testCompletedAt}
        onClickBack={() => user.navigateTo('/my-page/reports')}
      />
      <ReportTabs
        current={ReportTabId.REPORTS}
        onChange={user.onClickMyPageChallenges}
      />
      <Container sx={{ marginTop: 4 }}>
        <Grid container direction="column" rowGap={3}>
          <Grid item paddingBottom={1}>
            <Typography
              sx={{ fontSize: '17px', fontWeight: 300, lineHeight: '27.2px' }}
            >
              {MY_PAGE_REPORT.BODY_LINE_1}
            </Typography>
          </Grid>
          <Grid item>
            <GardenScore
              median={gardenScoreMedian}
              score={report.gardenScore}
              percentileMessage={percentileText}
            />
          </Grid>
          <Grid item>
            <ReportGardenScoreFold />
          </Grid>
          <Grid item marginTop={4}>
            <Typography
              variant="h3"
              textAlign="center"
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '19.6px',
                marginBottom: 1,
              }}
            >
              {MY_PAGE_REPORT.YOUR_TYPE}
            </Typography>
            <Typography
              variant="h2"
              textAlign="center"
              sx={{
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '25.2px',
                marginBottom: 2,
              }}
            >
              {bacteriaType !== 'None' ? (
                <>
                  {BACTERIA_NAME_JP[bacteriaType]}
                  {MY_PAGE_REPORT.TYPE}
                </>
              ) : (
                MY_PAGE_REPORT.BACTERIA_NO_DATA
              )}
            </Typography>
            <img width="125" height="125" src={BACTERIA_IMAGE[bacteriaType]} />
            {REPORT_BACTERIA_DESCRIPTION[bacteriaType][0].map((text, i) => (
              <Typography
                key={i}
                component="p"
                sx={{
                  fontSize: '16px',
                  fontWeight: 300,
                  lineHeight: '28.8px',
                  py: 2,
                }}
              >
                {text}
              </Typography>
            ))}
            {REPORT_BACTERIA_DESCRIPTION[bacteriaType][1] && (
              <Typography
                component="p"
                sx={{
                  bgcolor: 'grey.50',
                  borderRadius: '9px',
                  p: '16px',
                }}
                fontSize="14px"
              >
                参考文献：
                <br />
                {!!REPORT_BACTERIA_DESCRIPTION[bacteriaType][1] &&
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  REPORT_BACTERIA_DESCRIPTION[bacteriaType][1]!.map(
                    (text, i) => (
                      <Fragment key={i}>
                        {text}
                        <br />
                      </Fragment>
                    )
                  )}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
      <Divider sx={{ color: 'grey.100', mb: 2, mt: 4 }} />
      <Container sx={{ marginTop: 4 }}>
        <Grid container direction="column">
          <Grid item sx={{ minHeight: 'min(100vw, 960px)' }}>
            <Typography
              component="h2"
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '22px',
                mb: 2,
                textAlign: 'center',
              }}
            >
              菌の偏差値
            </Typography>
            <SpiderGraph tScore={tScore ?? undefined} />
          </Grid>
        </Grid>
      </Container>
      <Divider sx={{ color: 'grey.100', mb: 5, mt: 4 }} />
      <Container sx={{ marginTop: 4 }}>
        <Grid container direction="column" rowGap={4}>
          <Grid item>
            <Box
              sx={{
                bgcolor: 'rgba(255, 84, 0, 0.06)',
                borderRadius: '9px',
                px: 4,
                py: 5,
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  color: '#FF8718',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '22.4px',
                  mb: 3,
                  textAlign: 'center',
                }}
              >
                あなたにおすすめの素材
              </Typography>
              {RECOMMENDED_MATERIALS[bacteriaType].map(
                ({ title, description }, i) => (
                  <Box key={i}>
                    <Typography
                      variant="h2"
                      sx={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '25.6px',
                        mb: 1,
                        mt: 3.5,
                      }}
                    >
                      {title.map((elm, i) => (
                        <Fragment key={i}>
                          {elm}
                          <br />
                        </Fragment>
                      ))}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 300,
                        lineHeight: '22.4px',
                      }}
                    >
                      {description.map((elm, i) => (
                        <Fragment key={i}>
                          {elm}
                          <br />
                        </Fragment>
                      ))}
                    </Typography>
                  </Box>
                )
              )}
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                bgcolor: 'rgba(255, 84, 0, 0.06)',
                borderRadius: '9px',
                px: 4,
                py: 5,
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  color: '#FF8718',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '22.4px',
                  textAlign: 'center',
                }}
              >
                あなたにおすすめの素材が入った商品
              </Typography>
              <Box
                sx={{
                  bgcolor: 'white',
                  borderRadius: '9px',
                  height: '240px',
                  margin: 'auto',
                  my: 3,
                  overflow: 'hidden',
                  position: 'relative',
                  py: '40px',
                  width: '240px',
                }}
              >
                <img
                  src={RECOMMENDED_PRODUCTS[bacteriaType].imageUrl}
                  width="100px"
                />
                {bacteriaType === 'None' && (
                  <Box
                    sx={{
                      backgroundColor: '#8890A0',
                      bottom: 0,
                      left: 0,
                      opacity: 0.4,
                      position: 'absolute',
                      right: 0,
                      top: 0,
                    }}
                  />
                )}
              </Box>
              <Typography
                variant="h2"
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '25.6px',
                  mb: 2,
                }}
              >
                {RECOMMENDED_PRODUCTS[bacteriaType].title.map((elm, i) => (
                  <Fragment key={i}>
                    {elm}
                    <br />
                  </Fragment>
                ))}
              </Typography>
              <Typography
                sx={{ fontSize: '14px', fontWeight: 300, lineHeight: '22.4px' }}
              >
                {RECOMMENDED_PRODUCTS[bacteriaType].description.map(
                  (elm, i) => (
                    <Fragment key={i}>
                      {HTMLParser(elm)}
                      <br />
                    </Fragment>
                  )
                )}
              </Typography>
              <List sx={{ ml: 3, mt: 2 }}>
                {RECOMMENDED_PRODUCTS_CAUTION.map((elm, i) => (
                  <ListItem
                    disableGutters={true}
                    disablePadding={true}
                    key={i}
                    sx={{ display: 'list-item', listStyleType: 'disc', pl: 1 }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 300,
                        lineHeight: '22.4px',
                      }}
                    >
                      {elm}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={greyCardWrapper} borderRadius={BORDER_RADIUS.xl}>
              <Typography
                textAlign="center"
                variant="h2"
                sx={{
                  fontSize: FONT_SIZES.sm,
                  fontWeight: 600,
                  lineHeight: '19.6px',
                  marginBottom: 2.4,
                  marginTop: 1.4,
                }}
              >
                {MY_PAGE_REPORT.HABIT_SECTION_TITLE}
              </Typography>
              <Box marginBottom={2}>
                <GraphColoringLabels
                  list={[
                    {
                      color: 'primary.main',
                      label: MY_PAGE_REPORT.GRAPH_LABEL_3,
                    },
                    {
                      color: 'grey.500',
                      label: MY_PAGE_REPORT.GRAPH_LABEL_4,
                    },
                  ]}
                />
              </Box>
              <Grid container gap={1} direction="column">
                {habits.map((h) => {
                  const advice = advices.find((a) => a.key === h.key);
                  return (
                    <Grid item key={h.key}>
                      <ReportGraphCard
                        firstBar={{
                          color: 'primary.main',
                          end: h.value,
                          start: h.value - 1,
                        }}
                        secondBar={{
                          color: 'grey.500',
                          end: h.target,
                          start: h.target - 1,
                        }}
                        legend={h.legend}
                        isAboveTarget={h.value >= h.target}
                        showChildren={MY_PAGE_REPORT.ADVICES_DISPLAYED_BY_DEFAULT.includes(
                          h.key
                        )}
                        title={h.title}
                      >
                        {advice && (
                          <AdviceItem
                            rowKey={h.key}
                            header={MY_PAGE_REPORT.ADVICE_SECTION_TITLE}
                            title={advice.title}
                            description={advice.description}
                          />
                        )}
                      </ReportGraphCard>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const GardenScore: FunctionComponent<{
  median: number;
  percentileMessage: string | null;
  score: number | null;
}> = ({ score, median, percentileMessage }) => {
  return (
    <>
      <CardOuter label={MY_PAGE_REPORT.GRAPH_SECTION_TITLE}>
        <CardInner>
          {score && (
            <Box
              sx={{
                bgcolor: 'rgba(255, 84, 0, 0.06)',
                borderRadius: '20px',
                color: '#ff8718',
                display: 'inline-block',
                fontSize: '14px',
                fontWeight: 600,
                left: 'calc(50%)',
                lineHeight: '14px',
                mb: '8px',
                mt: '12px',
                mx: 'auto',
                p: '8px',
                position: 'relative',
                transform: 'translateX(-50%)',
              }}
            >
              {percentileMessage}
            </Box>
          )}
          <Box
            sx={{
              margin: '0 50px',
              paddingBottom: '20px',
              paddingTop: '10px',
            }}
          >
            <Box
              sx={{
                left: `${score ?? 50}%`,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  border: 'solid 1px',
                  borderRadius: '4px',
                  color: '#ff8718',
                  display: 'inline-block',
                  fontSize: '20px',
                  fontWeight: 600,
                  padding: '6px',
                  position: 'relative',
                  transform: 'translateX(-50%)',
                }}
              >
                <Box
                  sx={{
                    heigh: '20px',
                    lineHeight: '20px',
                    textAlign: 'center',
                    width: '57px',
                  }}
                >
                  <>
                    {score ? score.toFixed(1) : '-'}
                    {!score && (
                      <Box
                        sx={{
                          color: 'text.primary',
                          fontSize: '11px',
                          heigh: '11px',
                          lineHeight: '11px',
                          textAlign: 'center',
                          width: '55px',
                        }}
                      >
                        (計測不可)
                      </Box>
                    )}
                  </>
                </Box>
                <Box
                  sx={{
                    height: '0px',
                    left: 'calc(50%)',
                    position: 'relative',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: 'solid 0px',
                      borderLeft: 'solid 7px rgba(0,0,0,0)',
                      borderRight: 'solid 7px rgba(0,0,0,0)',
                      borderTop: 'solid 5px',
                      transform: 'translateX(-50%) translateY(7px)',
                      width: '14px',
                    }}
                  />
                </Box>
              </Box>
            </Box>

            {/* Scale */}
            <Box
              sx={{
                borderBottom: 'solid 1px',
                color: 'grey.600',
                height: '5px',
                mb: '25px',
                mt: '10px',
                position: 'relative',
              }}
            >
              {/* Vertical */}
              {[...Array(11)].map((_, i) => (
                <Box
                  key={i}
                  sx={{
                    borderLeft: 'solid 1px',
                    height: '5px',
                    left: `${i * 10}%`,
                    position: 'absolute',
                  }}
                />
              ))}
              {/* 0, 50, 100 */}
              {[0, 50, 100].map((label, i, a) => (
                <Box
                  key={i}
                  sx={{
                    color: 'text.primary',
                    fontSize: '11px',
                    left: `${i * (100 / (a.length - 1))}%`,
                    position: 'absolute',
                    transform: 'translateX(-50%) translateY(5px)',
                  }}
                >
                  {label}
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                left: `${median}%`,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  border: 'solid 1px',
                  borderRadius: '4px',
                  color: 'grey.600',
                  display: 'inline-block',
                  padding: '6px',
                  position: 'relative',
                  transform: 'translateX(-50%)',
                }}
              >
                <Box
                  sx={{
                    height: '0px',
                    left: 'calc(50%)',
                    position: 'absolute',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: 'solid 5px',
                      borderLeft: 'solid 7px rgba(0,0,0,0)',
                      borderRight: 'solid 7px rgba(0,0,0,0)',
                      borderTop: 'solid 0px',
                      transform: 'translateX(-50%) translateY(-12px)',
                      width: '14px',
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    color: 'text.primary',
                    fontSize: '11px',
                    heigh: '11px',
                    lineHeight: '11px',
                    textAlign: 'center',
                    width: '55px',
                  }}
                >
                  (中央値)
                </Box>
                <Box
                  sx={{
                    color: 'text.primary',
                    fontSize: '14px',
                    fontWeight: 600,
                    heigh: '14px',
                    lineHeight: '14px',
                    marginTop: '3px',
                    textAlign: 'center',
                    width: '55px',
                  }}
                >
                  {median}
                </Box>
              </Box>
            </Box>
          </Box>
        </CardInner>
      </CardOuter>
    </>
  );
};

const CardOuter: FunctionComponent<{
  children?: ReactNode;
  label: string;
}> = ({ label, children }) => {
  return (
    <Box sx={greyCardWrapper} borderRadius={BORDER_RADIUS.xl}>
      <Typography
        variant="h2"
        sx={{
          fontSize: FONT_SIZES.md,
          fontWeight: 600,
          lineHeight: '25.6px',
          marginY: 1,
          textAlign: 'center',
        }}
      >
        {label}
      </Typography>
      {children}
    </Box>
  );
};

const CardInner: FunctionComponent<{
  children?: ReactNode;
}> = ({ children }) => {
  return (
    <Box
      bgcolor="common.white"
      borderRadius={BORDER_RADIUS.lg}
      mt={1.5}
      sx={{ overflow: 'hidden' }}
    >
      {children}
    </Box>
  );
};
