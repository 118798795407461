import * as _ from 'lodash';

import { ALL_SURVEY } from '@/constants';
import { isIncompleteAnswerIdOrValue } from '@/domain/utils/survey';
import { Answer, Question, Option } from '@/types';
import {
  Assessment,
  ChallengeItem,
  HabitAndAdvice,
  NormalizedAssessment,
  NormalizedQuestion,
  NormalizedReport,
  NormalizedSurvey,
  Report,
  ReportAdvice,
  ReportHabit,
  ReportMission,
  ReportResult,
  ReportResults,
  SurveyQuestion,
  TScore,
} from '@/types/network';

/**
 * normalizeReport
 *
 * @remarks
 * normalize the network assessment report object so its easier to display in the UI
 * - limit value and ave to min and max
 * - replace null values with negative values
 * @param ReportResults - result number values from the test kit
 */
export function normalizeResult(
  result: ReportResults | null
): ReportResults | null {
  if (!_.isNull(result)) {
    const nextResult = _.cloneDeep(result);
    if (!_.isNumber(_.get(nextResult, 'max'))) {
      nextResult.max = -1;
    }
    if (!_.isNumber(_.get(nextResult, 'min'))) {
      nextResult.min = -1;
    }

    if (!_.isNumber(_.get(nextResult, 'value'))) {
      nextResult.value = -1;
    }

    if (!_.isNumber(_.get(nextResult, 'ave'))) {
      nextResult.ave = -1;
    }
    return nextResult;
  }
  return null;
}

/**
 * normalizeAssessment
 *
 * @remarks
 * normalize the network assessment so its easier to display in the UI
 * - merge advice and habit
 * - normalize result values
 * @param assessment - assessment
 */
export function normalizeAssessment(
  assessment: Assessment | null,
  meijiId: string,
  updatedAt: string
): NormalizedAssessment | null {
  if (!assessment) {
    return null;
  }

  const normalizedAssessment = {
    _id: assessment._id,
    createdAt: assessment.createdAt,
    meijiId,
    // PaymentDetails
    report: assessment.report
      ? normalizeReport(assessment.report, assessment.test.testKitId ?? '')
      : null,
    status: assessment.status,
    survey: normalizeSurveyFromApi(assessment.survey),
    testCompletedAt: assessment.test.testCompletedAt ?? '',
    testKitId: assessment.test.testKitId ?? null,
    updatedAt,
  };
  return normalizedAssessment;
}

export function normalizeReport(
  report: Report,
  testKitId: string
): NormalizedReport {
  const gardenScore =
    report.result.find((result) => result.key === 'GARDEN_SCORE')?.value ||
    null;

  let tScore: TScore | undefined;

  if (report.reportSummary.primaryBacteria !== 'UNMEASURABLE') {
    tScore = {
      BACTEROIDES: 0,
      BIFIDOBACTERIUM: 0,
      FAECALIBACTERIUM: 0,
      PREVOTELLA: 0,
      RUMINOCOCCUS: 0,
    };

    report.result.forEach((result) => {
      if (tScore && result.key !== 'GARDEN_SCORE' && result.value !== null) {
        tScore[result.key as keyof TScore] = result.value;
      }
    });
  }
  return {
    ...report,
    reportSummary: {
      ...report.reportSummary,
      gardenScore,
      ...(tScore && { tScore }),
    },
    testKitId,
  };
}

// TODO are we using these anywhere?
export function normalizeHabits(
  habits: ReportHabit[],
  advices: ReportAdvice[]
): HabitAndAdvice[] {
  return [];
}

export function normalizeMissions(missions: ReportMission[]): ChallengeItem[] {
  const challengeItems = missions.map((mission) => {
    const challengeItem: ChallengeItem = {
      category: mission.category,
      description: mission.description,
      image: mission.image,
      title: mission.title,
    };
    return challengeItem;
  });
  return challengeItems;
}

export function normalizeReportResult(result: ReportResult): ReportResults {
  const reportResult: ReportResults = {
    ave: result.average,
    // TODO remove this
    labels: {
      rankA: '',
      rankB: '',
      rankC: '',
      rankD: '',
    },
    legend: [],
    max: result.max,
    min: result.min,
    rank: result.outcomePercentageText,
    value: result.value ?? 0,
  };
  return reportResult;
}

/**
 * trimQuestionTitleForNetwork
 *
 * @remarks
 * @param questionInfo - a question from the survey
 */
export function trimQuestionTitleForNetwork(questionInfo: Question) {
  return _.get(questionInfo, 'title', '').replace(/\n|\s/g, '');
}

/**
 * getAnswerLabels
 *
 * @remarks
 * map through the survey in order so its exported in order
 * normalize the network survey so it matches the export
 * @param value - a split of the answer
 * @param children - the array of options from a question
 * @param childrenSecondary - an often undefined, array of options from a question secondary option list
 */
export function getAnswerLabels(
  value: string[],
  children: Option[],
  childrenSecondary: Option[]
) {
  return {
    first: _.get(
      _.find(children, {
        value: _.get(value, '[0]'),
      }),
      'label'
    ),
    second: _.get(
      _.find(childrenSecondary, { value: _.get(value, '[1]') }),
      'label'
    ),
  };
}

export function normalizeSurveyFromApi(
  survey: Assessment['survey']
): NormalizedSurvey {
  return survey.questions.map((question: SurveyQuestion) => {
    const normalizedQuestion: NormalizedQuestion = {
      answer: question.answer ?? null,
      answerId: question.answerId ?? null,
      question: question.question,
      questionId: question.questionId,
    };
    return normalizedQuestion;
  });
}

export function normalizedSurveyToSurvey(
  normalizedSurvey: NormalizedSurvey
): Assessment['survey'] {
  const survey: Assessment['survey'] = {
    questions: normalizedSurvey.map(
      (normalizedQuestion: NormalizedQuestion) => {
        const question: SurveyQuestion = {
          question: normalizedQuestion.question,
          questionId: normalizedQuestion.questionId,
        };
        if (normalizedQuestion.answer) {
          question.answer = normalizedQuestion.answer;
        }
        if (normalizedQuestion.answerId) {
          question.answerId = normalizedQuestion.answerId;
        }
        return question;
      }
    ),
  };
  return survey;
}

/**
 * normalizeSurvey
 *
 * @remarks
 * map through the survey in order so its exported in order
 * normalize the network survey so it matches the export
 * @param allSurvey - a list of all survey questions
 * @param surveyAnswers - a key value pair for every answered question.
 */
export function normalizeSurvey(
  allSurvey: typeof ALL_SURVEY,
  surveyAnswers: Answer[]
): NormalizedSurvey {
  return allSurvey.map((questionInfo) => {
    const includedAnswer = _.find(surveyAnswers, {
      key: questionInfo.id,
    }) as Answer;
    const trimmedQuestionTitle = trimQuestionTitleForNetwork(questionInfo);
    if (
      !includedAnswer ||
      isIncompleteAnswerIdOrValue(`${_.get(includedAnswer, 'value')}`)
    ) {
      return {
        answer: null,
        answerId: null,
        question: trimmedQuestionTitle,
        questionId: questionInfo.id,
      };
    }
    const answerLabels = getAnswerLabels(
      `${includedAnswer.value}`.split(','),
      _.get(questionInfo, 'children', []),
      _.get(questionInfo, 'childrenSecondary', [])
    );
    return {
      answer: `${answerLabels?.first}${
        answerLabels?.second ? ',' + answerLabels?.second : ''
      }`,
      answerId: `${includedAnswer.value}`,
      question: trimmedQuestionTitle,
      questionId: includedAnswer.key,
    };
  }) as NormalizedSurvey;
}

export function normalizeBirthday(date: string) {
  return date.replace(/^(\d{4})年(\d{2})月(\d{2})日$/, '$1-$2-$3');
}
