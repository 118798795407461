import { Container } from '@mui/material';

import { INTERNAL_SERVER_ERROR_PAGE } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { ErrorCard } from '@/view/components/ErrorCard';

export function InternalServerError() {
  return (
    <div>
      <Container>
        <ErrorCard
          code={INTERNAL_SERVER_ERROR_PAGE.ERROR_CODE}
          title={INTERNAL_SERVER_ERROR_PAGE.PAGE_TITLE}
          description={INTERNAL_SERVER_ERROR_PAGE.BODY_LINE_1}
          detailTitle={INTERNAL_SERVER_ERROR_PAGE.BODY_LINE_2}
          detailDescription={INTERNAL_SERVER_ERROR_PAGE.BODY_LINE_3}
          onClick={() => user.onClickErrorButton('/')}
          linkLabel={INTERNAL_SERVER_ERROR_PAGE.LINK_LABEL_1}
        />
      </Container>
    </div>
  );
}
