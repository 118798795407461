import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Box, Typography } from '@mui/material';

import { STYLES } from '@/view/styling/theme';

type Props = {
  amount: string;
  marginLeft?: string;
};

export function ShippingLabel({ amount, marginLeft = '0px' }: Props) {
  return (
    <Box sx={{ ...STYLES.shippingTag.shippingTagWrapper, marginLeft }}>
      <Box sx={STYLES.shippingTag.shippingIconWrapper}>
        <LocalShippingIcon sx={STYLES.shippingTag.shippingIcon} />
        <Typography sx={STYLES.shippingTag.shippingIconCurrency}>¥</Typography>
        <Typography sx={STYLES.shippingTag.shippingIconAmount}>
          {amount}
        </Typography>
      </Box>
      {amount === '0' && (
        <Typography variant="body2" sx={STYLES.shippingTag.freeShippingText}>
          送料無料
        </Typography>
      )}
    </Box>
  );
}
