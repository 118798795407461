import { Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { STYLES } from '@/view/styling/theme';

type Props = {
  rightSection?: ReactNode;
  styles?: object;
  text: string;
  variant?: 'borderless' | 'border-bottom';
};

export function PageTitle({
  text,
  variant = 'border-bottom',
  rightSection,
  styles = undefined,
}: Props) {
  return (
    <Grid
      container
      className={variant}
      maxWidth="100%"
      justifyContent="space-between"
      alignItems="center"
      sx={{ background: '#fff' }}
    >
      <Grid item>
        <Typography
          marginLeft={2}
          alignContent="center"
          sx={{ ...STYLES.pageTitle, ...styles }}
        >
          {text}
        </Typography>
      </Grid>
      {rightSection && (
        <Grid item marginRight={2} alignContent="center">
          {rightSection}
        </Grid>
      )}
    </Grid>
  );
}
