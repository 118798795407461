import { Step, StepLabel, Stepper } from '@mui/material';

type Props = {
  activeStep: number;
  steps: string[];
};

export function FormStepper({ activeStep, steps }: Props) {
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      sx={{
        paddingY: 4,
      }}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            sx={{
              '& .MuiStepIcon-text': {
                fill: 'white',
              },
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
