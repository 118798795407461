import { Box, Grid, Button } from '@mui/material';
import * as _ from 'lodash';
import { useEffect } from 'react';

import { TOP_PAGE } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { store } from '@/domain/store';
import { State } from '@/types';
import { AssessmentStatus } from '@/types/network';
import { FloatingAction } from '@/view/components/FloatingAction';
import { Caution } from '@/view/components/TopPage/Caution';
import { Column } from '@/view/components/TopPage/Column';
import { Fact } from '@/view/components/TopPage/Fact';
import { Hero } from '@/view/components/TopPage/Hero';
import { Information } from '@/view/components/TopPage/Information';
import { Partner } from '@/view/components/TopPage/Partner';
import { Product } from '@/view/components/TopPage/Product';
import { Report } from '@/view/components/TopPage/Report';
import { Service } from '@/view/components/TopPage/Service';
import { Steps } from '@/view/components/TopPage/Steps';
import { Voices } from '@/view/components/TopPage/Voices';

export function Top({ state }: { state: State }) {
  const hasOngoingAssessments = !_.isNull(state.ongoingAssessment);

  const shouldShowFloatingAction =
    !hasOngoingAssessments ||
    state.ongoingAssessment?.status === AssessmentStatus.START_APPLICATION;

  const onClickRegister = _.partial(user.onClickStartApplying, store);

  useEffect(() => {
    const action = shouldShowFloatingAction ? 'add' : 'remove';
    document.body.classList[action]('has-floating-action');
    return () => document.body.classList.remove('has-floating-action');
  }, [shouldShowFloatingAction]);

  return (
    <Box>
      <Grid container>
        {/* Hero */}
        <Grid item xs={12}>
          <Hero />
        </Grid>
        {/* Information */}
        <Grid item xs={12}>
          <Information />
        </Grid>
        {/* service */}
        <Grid item xs={12}>
          <Service serviceData={TOP_PAGE.SERVICES} />
        </Grid>
        <Grid item xs={12}>
          <Report />
        </Grid>
        <Grid item xs={12}>
          <Fact />
        </Grid>
        <Grid item xs={12}>
          <Product
            firstVisit={state.isFirstSetPurchase}
            isAuthenticated={state.user?.meijiId ? true : false}
          />
        </Grid>
        {/* step  */}
        <Grid item xs={12}>
          <Steps data={TOP_PAGE.STEPS_DATA} />
        </Grid>
        {/* caution */}
        <Grid item xs={12}>
          <Caution />
        </Grid>
        {/* voice */}
        <Grid item xs={12}>
          <Voices />
        </Grid>
        {/* partner */}
        <Grid item xs={12}>
          <Partner />
        </Grid>
        {/* column */}
        <Grid item xs={12}>
          <Column />
        </Grid>
      </Grid>
      {shouldShowFloatingAction && (
        <FloatingAction>
          <Button
            size="large"
            variant="contained"
            fullWidth
            sx={{ backgroundColor: '#F7871A' }}
            onClick={onClickRegister}
            data-gtm="click_application_button"
          >
            {TOP_PAGE.NEXT_BUTTON}
          </Button>
        </FloatingAction>
      )}
    </Box>
  );
}
