import {
  ONGOING_ASSESSMENT_STATUSES,
  UNDER_ASSESSMENT_STATUSES,
} from '@/constants';
import { refreshAccessTokenAndSetUserState } from '@/domain/middlewares/auth';
import { getState, store } from '@/domain/store';
import * as reducers from '@/domain/store/reducers';
import { createLogger } from '@/domain/utils/logger';
import * as network from '@/domain/utils/network';
import { normalizeAssessment } from '@/domain/utils/normalizers';
import { AssessmentStatus } from '@/types/network';

const logger = createLogger('@domain/middlewares/router/helpers');

export async function getLoggedInUserState() {
  const currentState = getState();
  try {
    const userInfo = currentState.user.token
      ? await refreshAccessTokenAndSetUserState(false, true)
      : await refreshAccessTokenAndSetUserState(true, true);

    if (!userInfo.token) {
      throw new Error('Failed to the user authentication');
    }

    await getFirstSetPurchaseStatus(userInfo.userId, userInfo.token);

    // FIXME: abstract to util
    const oneAssessmentResponse = await network.getAssessments(
      {
        limit: '1',
        meijiId: userInfo.userId,
        sort: { field: 'createdAt', order: 'desc' },
      },
      userInfo.token
    );

    const mostRecentAssessment = oneAssessmentResponse.data[0];

    if (ONGOING_ASSESSMENT_STATUSES.includes(mostRecentAssessment?.status)) {
      reducers.setOngoingAssessment(
        store,
        // TODO updatedAt is on the order, not the assessment
        normalizeAssessment(mostRecentAssessment, userInfo.userId, '')
      );
    } else {
      reducers.setOngoingAssessment(store, null);
    }
  } catch (err) {
    logger.error(err);
  }
}

/**
 * Get a single complete assessment by id
 * @param assessmentId
 * @param meijiId
 * @param token
 * @returns Assessment
 */
export async function getOneCompleteAssessmentById(
  assessmentId: string,
  meijiId: string,
  token: string
) {
  const getAssessmentByIdResponse = await network.getAssessments(
    {
      id: assessmentId,
      limit: '1',
      meijiId,
      status: AssessmentStatus.COMPLETE,
    },
    token
  );

  return getAssessmentByIdResponse.data[0];
}

/**
 * Get first valid assessment (no AbortStatus) by meijiId
 * Will set isFirstSetPurchase status to true for new users.
 *
 * @param meijiId
 * @param token
 * @returns
 */
export async function getFirstSetPurchaseStatus(
  meijiId: string,
  token: string
) {
  const firstAssessmentResponse = await network.getFirstValidAssessment(
    {
      meijiId,
    },
    token
  );

  // can purchase first set - only if an assessment status is NOT under assessment or complete.
  const canPurchaseFirstSet = ![
    ...UNDER_ASSESSMENT_STATUSES,
    AssessmentStatus.COMPLETE,
  ].includes(firstAssessmentResponse?.status);

  reducers.updateIsFirstSetPurchase(store, canPurchaseFirstSet);
}

/**
 * Get the latest Bacteria Type from user assessments
 * Will set the latest bacteria type to state.
 *
 */
export async function getLatestBacteriaType(meijiId: string, token: string) {
  const latestBacteriaTypeResponse = await network.getLatestBacteriaType(
    {
      meijiId,
    },
    token
  );

  const latestBacteriaType = latestBacteriaTypeResponse.bacteriaType;

  if (latestBacteriaType === 'UNMEASURABLE') {
    reducers.setLatestBacteriaType(store, 'BACTEROIDES');
  } else {
    reducers.setLatestBacteriaType(store, latestBacteriaType);
  }
}

/**
 * Get purchased products from user orders
 *
 */
export async function getPurchases(meijiId: string, token: string) {
  const purchaseResponse = await network.getPurchases(
    {
      meijiId,
    },
    token
  );

  reducers.setPurchases(store, purchaseResponse.data);
}
