import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Grid, Button, Typography } from '@mui/material';

import { STYLES } from '@/view/styling/theme';

type Props = {
  backgroundColor?: string;
  buttonTitle?: string;
  children: React.ReactNode;
  gap?: number;
  marginBottom?: number;
  marginTop?: number;
  onClick?: () => void;
  subtitle?: string;
  title: string;
};

export function TopPageItem({
  backgroundColor = 'inherit',
  buttonTitle,
  children,
  gap = 0,
  marginTop = 0,
  marginBottom = 0,
  subtitle,
  title,
  onClick,
}: Props) {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      gap={gap}
      sx={{
        backgroundColor,
        marginBottom,
        padding: 2,
      }}
    >
      <Grid item sx={{ marginTop }}>
        <Typography component="h4" sx={STYLES.topPage.sectionTitle1}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography component="h3" sx={STYLES.topPage.sectionTitle2}>
          {subtitle}
        </Typography>
      </Grid>
      <Grid item>{children}</Grid>
      <Grid item>
        {buttonTitle && (
          <Button
            size="small"
            sx={STYLES.topPage.secondaryButtonRounded}
            variant="outlined"
            onClick={onClick}
            endIcon={
              <ChevronRightIcon
                sx={{
                  color: 'grey.600',
                  ml: '-6px',
                }}
              />
            }
          >
            <Typography sx={STYLES.topPage.buttonTextRounded}>
              {buttonTitle}
            </Typography>
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
