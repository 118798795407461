import { Box, Typography } from '@mui/material';

import { TEASER_PAGE } from '@/constants';
import { TEASER_STYLES } from '@/view/styling/theme';

export function TeaserHeroTitle() {
  return (
    <Box sx={{ width: 1 }}>
      <Box width={'100%'} sx={{ pt: 2, textAlign: 'center' }}>
        <Typography sx={TEASER_STYLES.preHeroTitle}>
          {TEASER_PAGE.HERO.TITLE}
        </Typography>
      </Box>
      <Box sx={{ pt: 1 }}>
        <img src="/images/top-page/hero_line.svg" />
      </Box>
      <Box width={'100%'} sx={{ pt: 2, textAlign: 'center' }}>
        <Typography sx={TEASER_STYLES.heroTitle}>
          あなたの
          <span style={{ color: '#F7871A' }}>腸内タイプ</span>に
        </Typography>
        <br />
        <Typography sx={TEASER_STYLES.heroTitle}>適した商品をお届け</Typography>
      </Box>

      <Box width={'100%'} sx={{ pt: 2, textAlign: 'center' }}>
        <Box
          sx={{
            background: ' #FFF1E3',
            borderRadius: '10px',
            margin: '0px 20px',
            padding: 2,
            textAlign: 'center',
          }}
        >
          <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
            <span style={{ color: '#F7871A' }}>発売開始は12月12日</span>
            です。
          </Typography>
          <br />
          <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
            現在はクーポンのみ先行で獲得できます。
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
