import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';

/**
 * startAnalytics
 *
 * @remarks
 * Initialize ReactGA
 *
 * @param id - GA tracking id
 * @param options - Option values for ReactGA
 */
export function startAnalytics(
  id: string,
  options?: Parameters<typeof ReactGA.initialize>[1]
) {
  ReactGA.initialize(id, options);
}

/**
 * pageView
 *
 * @remarks
 * Send page view tracking data to GA
 *
 * @param path - A path to track
 */
export function pageView(path: string) {
  ReactGA.send({ hitType: 'pageview', page: path });
}

/**
 * customEvent
 *
 * @remarks
 * Send custom event data to GA
 *
 * @param params - A option value for the event
 */
export function customEvent(
  name: string,
  params: Omit<UaEventOptions, 'action'>
) {
  ReactGA.event({
    ...params,
    action: name,
  });
}
