import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid, List, ListItem, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FunctionComponent } from 'react';

import { CategoryToColumnsMap, ColumnCategoryJA } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { State } from '@/types';

export const Column: FunctionComponent<{ state: State }> = ({ state }) => {
  if (!state.column) return <></>;

  const column =
    CategoryToColumnsMap[state.column.category][state.column.num - 1];

  return (
    <Box sx={{ background: '#fff', pb: 5 }}>
      <Grid
        container
        className="border-bottom"
        maxWidth="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item ml={2}>
          <ArrowBackIcon
            sx={{
              color: '#828A98',
              fontSize: '24px',
            }}
            onClick={() =>
              user.navigateTo(
                state.column
                  ? `/columns?category=${state.column?.category}`
                  : '/columns'
              )
            }
          />
        </Grid>
        <Grid item>
          <Typography
            align="center"
            mt={1}
            noWrap
            sx={(theme) => ({
              display: 'block',
              fontSize: 15,
              fontWeight: '600',
              [theme.breakpoints.down('sm')]: { width: '80vw' },
            })}
          >
            {ColumnCategoryJA[column.category]}
          </Typography>
          <Typography
            align="center"
            sx={{
              display: 'block',
              fontSize: 11,
              fontWeight: '600',
            }}
            mb={1}
          >
            No. {state.column.num}
          </Typography>
        </Grid>
        <Grid item sx={{ width: 24 }} />
      </Grid>
      <img src={`/images/column/${column.headerImage}.png`} width="100%" />
      <Box mx={2}>
        <Typography
          sx={{
            display: 'block',
            mb: '6px',
            mt: '32px',
          }}
          fontSize={20}
          fontWeight="bold"
        >
          {column.title}
        </Typography>
        <Typography
          sx={{
            color: 'grey.300',
            display: 'block',
          }}
          fontSize={14}
        >
          {column.lastUpdated
            ? dayjs(column.lastUpdated).format('YYYY年MM月DD日')
            : ''}
        </Typography>
        {column.sections.map((section, i) => (
          <section key={i}>
            <Typography
              fontWeight="bold"
              variant="h4"
              lineHeight={1.6}
              sx={{ whiteSpace: 'pre-wrap' }}
              fontSize={16}
              mt={5}
              mb={2}
            >
              {section.title}
            </Typography>
            <Box
              sx={{
                overflow: 'hidden',
              }}
              my={2}
            >
              <img src={`/images/column/${section.image}.png`} width="100%" />
              {section.imageLegend && (
                <List sx={{ listStyleType: 'disc', mb: 1, pl: 6 }}>
                  {section.imageLegend.map((text, i) => (
                    <ListItem
                      key={i}
                      sx={{
                        display: 'list-item',
                        mt: '-12px',
                      }}
                    >
                      <span
                        style={{
                          left: '-16px',
                          position: 'relative',
                        }}
                      >
                        {text}
                      </span>
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
            {section.text.map((frag, i) => (
              <Typography
                key={i}
                sx={{ whiteSpace: 'pre-wrap' }}
                lineHeight="1.8rem"
                paragraph
              >
                {frag}
              </Typography>
            ))}
            {section.reference && (
              <Box
                sx={{
                  bgcolor: 'grey.50',
                  borderRadius: '9px',
                  padding: '16px',
                  wordBreak: 'break-word',
                }}
                my={2}
              >
                {section.reference.map((frag, i) => (
                  <Typography sx={{ whiteSpace: 'pre-wrap' }} key={i}>
                    {frag}
                  </Typography>
                ))}
              </Box>
            )}
          </section>
        ))}
      </Box>
    </Box>
  );
};
