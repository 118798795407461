import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import _ from 'lodash';

import {
  PRODUCT_DETAIL_DRINK_PAGE,
  PRODUCT_DRINK,
  PRODUCT_LIST_PAGE,
  ProductDrinkConfig,
} from '@/constants';
import * as user from '@/domain/middlewares/user';
import { store } from '@/domain/store';
import { State, BacteriaType } from '@/types';
import { DrinkList } from '@/view/components/DrinkList';
import { ProductInfoRow } from '@/view/components/ProductCard/ProductAccordion';
import { ProductDetailItem } from '@/view/components/ProductDetailItem';
import { ProductListItem } from '@/view/components/ProductListItem';
import { PurchaseProductCard } from '@/view/components/PurchaseProductCard';
import { STYLES } from '@/view/styling/theme';

function getProductDetails(latestType: BacteriaType): ProductDrinkConfig {
  return PRODUCT_DRINK[latestType];
}

export function ProductDetailDrink({ state }: { state: State }) {
  const latestType = state.latestBacteriaType;
  const productListItemProps = getProductDetails(latestType);

  const drinkDescription = productListItemProps.description;
  const drinkPrice = [...productListItemProps.price]; // converting to mutable array
  const productVariant = productListItemProps.productVariant;
  const sellingPlan = productListItemProps.sellingPlan;

  const bottomDividerMargin = latestType === 'None' ? 7 : 0;
  const speechBubbleDisplayComponent = (
    <Box textAlign={'center'}>
      <Typography>最新の検査結果は</Typography>
      <br />
      <a
        href="/my-page/reports"
        style={{ color: '#2F2F2F', textDecoration: 'underline' }}
      >
        こちらから
      </a>
      <Typography>ご確認ください！</Typography>
    </Box>
  );

  const onClickGoToSubscriptionPayment = _.partial(
    user.handleSubscriptionProductPurchase,
    store
  );

  const onClickGoToOneTimePayment = _.partial(
    user.handleOneTimeProductPurchase,
    store
  );

  const suggestedProducts = PRODUCT_LIST_PAGE.BODY.filter(
    (product) =>
      product.DETAIL_PAGE !== 'drink' && product.DETAIL_PAGE !== 'first-set'
  );

  const onClickHandler = _.partial(user.navigateTo);

  return (
    <Grid container direction={'column'} rowSpacing={2}>
      <Grid item xs={12} marginBottom={4}>
        <ProductListItem
          {...productListItemProps}
          price={drinkPrice}
          marginX={2}
        />
      </Grid>
      <Grid item marginX={2}>
        <ProductInfoRow
          label={PRODUCT_DETAIL_DRINK_PAGE.PRODUCT_INFO.LABEL}
          value={PRODUCT_DETAIL_DRINK_PAGE.PRODUCT_INFO.VALUE}
          firstColumnWidth={50}
        />
      </Grid>
      {latestType !== 'None' && (
        <>
          <Grid item marginX={2}>
            <Typography>{drinkDescription}</Typography>
          </Grid>

          <Grid item xs={12} marginX={2}>
            <PurchaseProductCard
              title={PRODUCT_DETAIL_DRINK_PAGE.BUTTON.TYPE.subscription.title}
              subtitle={
                PRODUCT_DETAIL_DRINK_PAGE.BUTTON.TYPE.subscription.subtitle
              }
              data-e2e="product-detail__subscription-buy-btn"
              buttonText={PRODUCT_DETAIL_DRINK_PAGE.BUTTON.TEXT}
              icon={PRODUCT_DETAIL_DRINK_PAGE.BUTTON.TYPE.subscription.icon}
              onClick={() =>
                onClickGoToSubscriptionPayment(productVariant, sellingPlan)
              }
            />
          </Grid>
          <Grid item xs={12} marginX={2}>
            <PurchaseProductCard
              title={
                PRODUCT_DETAIL_DRINK_PAGE.BUTTON.TYPE.oneTimePurchase.title
              }
              subtitle={
                PRODUCT_DETAIL_DRINK_PAGE.BUTTON.TYPE.oneTimePurchase.subtitle
              }
              data-e2e="product-detail__buy-btn"
              buttonText={PRODUCT_DETAIL_DRINK_PAGE.BUTTON.TEXT}
              icon={PRODUCT_DETAIL_DRINK_PAGE.BUTTON.TYPE.oneTimePurchase.icon}
              onClick={() => onClickGoToOneTimePayment(productVariant)}
            />
          </Grid>
        </>
      )}

      {latestType === 'None' && (
        <Grid item xs={12} marginX={2}>
          <Button size="large" variant="contained" fullWidth disabled={true}>
            {PRODUCT_DETAIL_DRINK_PAGE.BUTTON.TEXT_DISABLED}
          </Button>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography
          align="center"
          component="h5"
          sx={STYLES.productListItem.sellerText}
        >
          {PRODUCT_LIST_PAGE.SELLER_TEXT}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ marginX: 2, marginY: 3 }}>
        <Divider />
      </Grid>

      <Grid item>
        <DrinkList
          speechBubbleDisplayComponent={speechBubbleDisplayComponent}
        />
        <Divider
          sx={{
            marginBottom: bottomDividerMargin,
            marginTop: 5,
            marginX: 2,
          }}
        />
      </Grid>
      {latestType !== 'None' && (
        <Grid item xs={12} sx={{ justifyContent: 'center', textAlign: 'left' }}>
          <ProductDetailItem
            sectionTitle={PRODUCT_DETAIL_DRINK_PAGE.PRODUCT_DETAIL_ITEM.TITLE}
            products={suggestedProducts}
            taxText={PRODUCT_DETAIL_DRINK_PAGE.PRODUCT_DETAIL_ITEM.TEXT}
            onClick={onClickHandler}
          />
        </Grid>
      )}
    </Grid>
  );
}
