import { Grid, Typography, Box } from '@mui/material';
import { useState } from 'react';

import ANIMATION_CLIP from '@/assets/qr-code-animation.json';
import { TEST_KIT_ADD_BARCODE_PAGE } from '@/constants';
import { Animation } from '@/view/components/Animation';
import { BarcodeScanner } from '@/view/components/BarcodeScanner';
import { Modal } from '@/view/components/Modal';

type Props = {
  onReadComplete: (base64Image: string, qrCodeValue?: string) => void;
  triggerButton: JSX.Element;
};

export function QRModal({ triggerButton, onReadComplete }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <span onClick={() => setIsModalOpen(true)}>{triggerButton}</span>
      <Modal
        title=""
        open={isModalOpen}
        hasStickyTitle={true}
        onClose={() => {
          setIsModalOpen(false);
        }}
        actions={
          <BarcodeScanner
            disabled={false}
            onReadComplete={onReadComplete}
            afterReadComplete={() => setIsModalOpen(false)}
            label="コードの撮影へ"
            variant="primary"
          />
        }
      >
        <Grid
          container
          direction="column"
          rowSpacing={3}
          wrap="nowrap"
          sx={{ mb: 5 }}
        >
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h6" fontSize={20}>
                {TEST_KIT_ADD_BARCODE_PAGE.MODAL_TITLE}
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Box>
              <Animation animation={ANIMATION_CLIP} />
            </Box>
          </Grid>

          <Grid item>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <Typography variant="body1" fontSize={14} color="grey.300">
                {TEST_KIT_ADD_BARCODE_PAGE.MODAL_CAPTION1}
              </Typography>

              <Typography variant="body1" fontSize={14} color="grey.300">
                {TEST_KIT_ADD_BARCODE_PAGE.MODAL_CAPTION2}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}
