import { Box, Grid, Typography } from '@mui/material';

import * as user from '@/domain/middlewares/user';
import { categoryData } from '@/view/components/Report/categoryData';
import { FONT_SIZES } from '@/view/styling/theme';

type Props = {
  rowKey?: string;
};

export function RecommendColumn({ rowKey }: Props) {
  const columnDataList = rowKey
    ? categoryData.filter((data) => data.key === rowKey)
    : [];
  return (
    <>
      {columnDataList.length >= 1 && (
        <Box
          py={2}
          px={1}
          sx={{
            cursor: 'pointer',
          }}
        >
          <Box>
            <Typography sx={{ fontSize: FONT_SIZES.sm }}>
              おすすめの腸に関するコラム
            </Typography>
          </Box>
          {columnDataList.map((columnData) => (
            <Grid
              key={columnData.link}
              onClick={() => {
                user.onClickToColumn(columnData.link);
              }}
              container
              spacing={1}
            >
              <Grid
                item
                xs={5}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <img
                  src={`/images/reports/${columnData.image}`}
                  width="100%"
                  alt="Recommended article Image"
                />
              </Grid>
              <Grid item xs={7}>
                <Box
                  sx={{
                    lineHeight: '18px',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'grey.300',
                      fontSize: FONT_SIZES.xxs,
                    }}
                  >
                    {columnData.subtitle}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{ fontSize: FONT_SIZES.md, fontWeight: 'bold' }}
                  >
                    {columnData.title}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Box>
      )}
    </>
  );
}
