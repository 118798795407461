import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';

import { PURCHASE_HISTORY_PAGE, REPORTS_LIST } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { store } from '@/domain/store';
import { State } from '@/types';
import { PageTitle } from '@/view/components/PageTitle';
import { STYLES } from '@/view/styling/theme';

export function PurchaseHistory({ state }: { state: State }) {
  const purchases = state.purchases;

  const onClickGoToSubscription = _.partial(
    user.onClickGoToSubscriptionDetails,
    store
  );

  return (
    <Grid container sx={{ mb: 5 }}>
      <Grid item xs={12} sx={{ marginBottom: 5 }}>
        <PageTitle text={PURCHASE_HISTORY_PAGE.PAGE_TITLE} />
      </Grid>

      {purchases?.map(({ shopifyData, productType, createdAt, _id }) => (
        <Grid
          item
          xs={12}
          key={_id}
          sx={{
            background: 'white',
            borderBottom: '0.5px solid #DBDFE5',
            paddingX: 2,
            paddingY: 1.5,
          }}
        >
          <Grid
            container
            sx={{ display: 'flex' }}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid
              item
              xs={4}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box sx={STYLES.orderList.listItemImageWrapper}>
                <img
                  style={{ maxHeight: '100px', maxWidth: '110px' }}
                  src={
                    shopifyData.productVariantName
                      ? PURCHASE_HISTORY_PAGE.PRODUCTS[
                          shopifyData.productVariantName
                        ].IMAGE_PATH
                      : productType === 'DRINK'
                      ? PURCHASE_HISTORY_PAGE.PRODUCTS.DEFAULT_DRINK.IMAGE_PATH
                      : PURCHASE_HISTORY_PAGE.PRODUCTS.DEFAULT_SET.IMAGE_PATH
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={STYLES.orderList.listItemWrapper}
                gap={0.5}
              >
                {productType === 'DRINK' &&
                  shopifyData.orderType === 'SUBSCRIPTION' && (
                    <Grid item xs={12}>
                      {shopifyData.subscriptionCancelledDate ? (
                        <Chip
                          label={PURCHASE_HISTORY_PAGE.TAG_CANCELLED}
                          size="small"
                          variant="outlined"
                          sx={{
                            ...STYLES.orderList.tag,
                            color: '#828A9B',
                            width: 110,
                          }}
                        />
                      ) : (
                        <Chip
                          label={PURCHASE_HISTORY_PAGE.TAG_TITLE}
                          size="small"
                          variant="outlined"
                          sx={STYLES.orderList.tag}
                        />
                      )}
                    </Grid>
                  )}
                <Grid item xs={12}>
                  <Typography
                    component="h3"
                    sx={STYLES.orderList.listItemTitle}
                  >
                    {shopifyData.title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      component="h4"
                      sx={STYLES.orderList.listItemDescription}
                    >
                      {shopifyData.subscriptionCancelledDate ? (
                        <>
                          <span style={{ color: 'red' }}>
                            {dayjs(
                              shopifyData.subscriptionCancelledDate
                            ).format('YYYY年M月D日')}
                            {PURCHASE_HISTORY_PAGE.CANCELLED}
                          </span>
                        </>
                      ) : (
                        <>
                          {PURCHASE_HISTORY_PAGE.DATE_LABEL}
                          {dayjs(createdAt).format('YYYY年M月D日')}
                        </>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                {productType === 'DRINK' &&
                  shopifyData.orderType === 'SUBSCRIPTION' && (
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                      <Button
                        variant="text"
                        size="small"
                        endIcon={
                          <ChevronRightIcon
                            sx={{
                              color: 'grey.600',
                              height: 24,
                              ml: '-6px',
                              width: 24,
                            }}
                          />
                        }
                        sx={{
                          color: 'text.primary',
                          fontWeight: 300,
                          height: 24,
                          lineHeight: '21px',
                          textSize: '14px',
                        }}
                        onClick={() =>
                          onClickGoToSubscription(shopifyData.orderId)
                        }
                      >
                        {PURCHASE_HISTORY_PAGE.SUB_PAGE_BUTTON_LABEL}
                      </Button>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}

      {purchases && purchases.length < 1 && (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: 329,
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <img
              style={{ margin: 32, width: '300px' }}
              src={REPORTS_LIST.NO_REPORTS.IMAGE_PATH}
            />
            <Typography
              fontSize={'16px'}
              fontWeight={300}
              lineHeight={'12px'}
              sx={{
                color: 'grey.300',
              }}
            >
              {PURCHASE_HISTORY_PAGE.EMPTY}
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
