import { Grid, Typography, Button, Divider } from '@mui/material';
import _ from 'lodash';

import {
  PRODUCT_DETAIL_CHECK_KIT_PAGE,
  PRODUCT_LIST_PAGE,
  UNDER_ASSESSMENT_STATUSES,
} from '@/constants';
import * as user from '@/domain/middlewares/user';
import { store } from '@/domain/store';
import { State } from '@/types';
import { AssessmentStatus } from '@/types/network';
import { ProductInfoRow } from '@/view/components/ProductCard/ProductAccordion';
import { ProductDetailItem } from '@/view/components/ProductDetailItem';
import { defaultThemeColor, FONT_SIZES, STYLES } from '@/view/styling/theme';

export function ProductDetailCheckKit({ state }: { state: State }) {
  const onClickHandler = _.partial(user.navigateTo);
  const isSecondPurchase = !state.isFirstSetPurchase;
  const hasTestKitId = state.ongoingAssessment?.testKitId;
  const ongoingAssessmentStatus = state.ongoingAssessment
    ?.status as AssessmentStatus;

  let cannotPurchase = true;

  if (isSecondPurchase && (ongoingAssessmentStatus || !hasTestKitId)) {
    const isUnderAssessment = UNDER_ASSESSMENT_STATUSES.includes(
      ongoingAssessmentStatus
    );
    cannotPurchase = isUnderAssessment || !state.user.meijiId;
  }

  const onClickGoToPayment = _.debounce(
    _.partial(
      user.onClickGoToPayment,
      store,
      import.meta.env.MEIJI_SHOPIFY_PRODUCT_VARIANT_DEFAULT
    ),
    500
  );

  const suggestedProducts = PRODUCT_LIST_PAGE.BODY.filter(
    (product) =>
      product.DETAIL_PAGE !== 'check-kit' && product.DETAIL_PAGE !== 'first-set'
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <img
          src={PRODUCT_DETAIL_CHECK_KIT_PAGE.SECTION_BODY.HEADER_IMAGE}
          style={{ height: 'auto', width: '100%' }}
          alt="腸内フローラ検査"
        />
      </Grid>
      <Grid item container xs={12} sx={{ p: 2 }}>
        <Grid item xs={12} sx={{ mb: '16px' }}>
          <Typography
            sx={{ fontSize: FONT_SIZES.sm, fontWeight: 600, lineHeight: 2 }}
          >
            {PRODUCT_DETAIL_CHECK_KIT_PAGE.DESCRIPTION}
          </Typography>
          <br />
          <Typography
            sx={{ fontSize: FONT_SIZES.lg, fontWeight: 600, lineHeight: 2 }}
          >
            {PRODUCT_DETAIL_CHECK_KIT_PAGE.PAGE_TITLE}
          </Typography>
          <br />
          <Typography
            sx={{ fontSize: FONT_SIZES.xl, fontWeight: 600, lineHeight: 2.5 }}
          >
            {PRODUCT_DETAIL_CHECK_KIT_PAGE.PRICE}
          </Typography>
          <Typography
            sx={{ fontSize: FONT_SIZES.sm, fontWeight: 400, lineHeight: 2 }}
          >
            {PRODUCT_DETAIL_CHECK_KIT_PAGE.TAX_TEXT}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ProductInfoRow
            label={PRODUCT_DETAIL_CHECK_KIT_PAGE.SECTION_BODY.CONTENT}
            value={PRODUCT_DETAIL_CHECK_KIT_PAGE.SECTION_BODY.DESCRIPTION}
            subValue={PRODUCT_DETAIL_CHECK_KIT_PAGE.SECTION_BODY.WARNING}
            firstColumnWidth={50}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={onClickGoToPayment}
            size="large"
            variant="contained"
            fullWidth
            disabled={cannotPurchase}
            data-e2e="product-detail__buy-btn"
            sx={{ backgroundColor: defaultThemeColor, marginTop: 4 }}
          >
            {cannotPurchase
              ? PRODUCT_DETAIL_CHECK_KIT_PAGE.BUTTON_LABEL.DISABLED
              : PRODUCT_DETAIL_CHECK_KIT_PAGE.BUTTON_LABEL.ENABLED}
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            alignContent: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: 1,
            marginX: 2,
          }}
        >
          <Typography component="h5" sx={STYLES.productListItem.sellerText}>
            {PRODUCT_DETAIL_CHECK_KIT_PAGE.SELLER_TEXT}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 5, marginX: 2, width: '100%' }}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ProductDetailItem
          sectionTitle={
            PRODUCT_DETAIL_CHECK_KIT_PAGE.PRODUCT_DETAIL.SECTION_TITLE
          }
          products={suggestedProducts}
          taxText={PRODUCT_DETAIL_CHECK_KIT_PAGE.PRODUCT_DETAIL.TAX_TEXT}
          onClick={onClickHandler}
        />
      </Grid>
    </Grid>
  );
}
