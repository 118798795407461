import { Typography, Grid, Button } from '@mui/material';
import _ from 'lodash';
import { useMemo } from 'react';

import { STYLES } from '@/view/styling/theme';

type Props = {
  buttonText: string;
  icon: string;
  onClick: () => void;
  subtitle: string;
  title: string;
};

export function PurchaseProductCard({
  buttonText,
  icon,
  onClick,
  subtitle,
  title,
}: Props) {
  const onButtonClick = useMemo(() => _.debounce(onClick, 500), [onClick]);

  return (
    <Grid container sx={STYLES.purchaseProductCard.purchaseCardWrapper} gap={2}>
      <Grid item xs={12} sx={{ marginTop: 2 }}>
        <Grid container>
          <Grid item xs={4}>
            <img src={icon} alt="Product Shipment" />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h3" sx={STYLES.purchaseProductCard.title}>
              {title}
            </Typography>
            <Typography variant="h5" sx={STYLES.purchaseProductCard.subtitle}>
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          size="large"
          variant="contained"
          fullWidth
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      </Grid>
    </Grid>
  );
}
