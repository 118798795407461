import { Button, styled } from '@mui/material';
import { BrowserQRCodeReader } from '@zxing/browser';
import * as _ from 'lodash';
import { useRef } from 'react';

import { isMeijiTestBarcode } from '@/domain/utils/forms';
import { createLogger } from '@/domain/utils/logger';
import { STYLES } from '@/view/styling/theme';

const logger = createLogger('@view/components/barcode-scanner');

type Props = {
  afterReadComplete?: () => void;
  disabled: boolean;
  label: string;
  onReadComplete: (base64Image: string, qrCodeValue?: string) => void;
  variant: 'primary' | 'secondary';
};

const VisuallyHiddenInput = styled('input')({
  bottom: 0,
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  left: 0,
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: 1,
});

function getBase64(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(reader.error);
  });
}

export function BarcodeScanner({
  onReadComplete,
  label,
  variant,
  disabled = false,
  afterReadComplete,
}: Props) {
  const imgRef = useRef<HTMLImageElement>(null);
  return (
    <>
      <Button
        component="label"
        disabled={disabled}
        size="large"
        variant={variant === 'primary' ? 'contained' : 'outlined'}
        sx={variant === 'secondary' ? STYLES.secondaryButtonBottomPage : {}}
        role={undefined}
        tabIndex={-1}
        fullWidth
      >
        {label}
        <VisuallyHiddenInput
          type="file"
          accept="image/*"
          capture="environment"
          onChange={async (e) => {
            const file = e?.target?.files?.[0];

            if (file && !_.isNull(imgRef) && !_.isNull(imgRef.current)) {
              const base64Image = await getBase64(file);

              imgRef.current.onload = async (event) => {
                try {
                  const codeReader = new BrowserQRCodeReader();
                  const result = await codeReader.decodeFromImageElement(
                    event.target as HTMLImageElement
                  );
                  const code = result?.getText();
                  if (!isMeijiTestBarcode(code))
                    throw new Error('no valid code found');
                  onReadComplete(base64Image, code);
                } catch (error) {
                  logger.error(error);
                  onReadComplete(base64Image);
                } finally {
                  afterReadComplete && afterReadComplete();
                }
              };
              imgRef.current.src = base64Image;
            }
            e.target.files = null;
          }}
        />
      </Button>
      <img ref={imgRef} style={{ height: 0, width: 0 }} />
    </>
  );
}
