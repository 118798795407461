import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Typography, Grid } from '@mui/material';

import { TOP_PAGE, ColumnCategoryJA, ColumnCategory } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { Carousel } from '@/view/components/Carousel';

export function ColumnCarousel() {
  return (
    <Grid container direction="column" sx={{ mt: 5 }} gap={1}>
      <Grid item>
        <Grid container justifyContent={'space-between'} alignItems="center">
          <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
            腸に関するコラム
          </Typography>
          <Typography
            onClick={() => user.navigateTo('/columns')}
            sx={{
              color: '#5A5D65',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '19px',
            }}
          >
            すべて見る{' '}
            <ChevronRightIcon
              sx={{ fontSize: '18px', position: 'relative', top: '3px' }}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid item sx={{ marginBottom: 3, marginTop: 1 }}>
        <Carousel>
          {TOP_PAGE.SECTION_BODY_8.BODY.map((column, i) => (
            <Box
              key={i}
              sx={{
                bgcolor: 'common.white',
                borderRadius: '12px',
                overflow: 'hidden',
              }}
            >
              <img src={column.IMAGE_PATH} width="100%" />
              <Box
                p={2}
                onClick={() =>
                  user.onClickGoToColumn(
                    column.CATEGORY as ColumnCategory,
                    column.NUMBER
                  )
                }
              >
                <Typography
                  sx={{
                    color: 'grey.300',
                    display: 'block',
                    mb: '4px',
                  }}
                  fontSize={12}
                  fontWeight="bold"
                >
                  {ColumnCategoryJA[column.CATEGORY as ColumnCategory]}No.
                  {column.NUMBER}
                </Typography>
                <Typography
                  sx={{
                    display: 'block',
                    mb: '8px',
                  }}
                  fontSize={16}
                  fontWeight="bold"
                >
                  {column.TITLE}
                </Typography>
                <Typography
                  sx={{
                    display: 'block',
                  }}
                  fontSize={14}
                >
                  {column.SECTION_TITLE.length > 18
                    ? column.SECTION_TITLE.substring(0, 18) + '・・・'
                    : column.SECTION_TITLE}
                </Typography>
              </Box>
            </Box>
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
}
