import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import React from 'react';

import { FaqAccordion } from '@/view/components/Accordion/FaqAccordion';
import { STYLES } from '@/view/styling/theme';

interface AccordionItem {
  bulletList?: boolean;
  content: string[];
  id: number;
  section: string;
  subContent?: {
    applyNumber: number;
    content: string[];
  }[];
  title: string;
}

interface AccordionData {
  categoryTitle: string;
  children: AccordionItem[];
  id: number;
}

interface NestedAccordionProps {
  data: AccordionData[];
}

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ChevronRightIcon sx={{ color: 'grey.600' }} />}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}));

const parentIdFromHash = Number(location.hash?.split('-')[1]);
const childIdFromHash = Number(location.hash?.split('-')[2]);

export function NestedAccordion({ data }: NestedAccordionProps) {
  const defaultExpanded =
    location.hash && location.hash.includes('detail')
      ? new Set().add(parentIdFromHash)
      : new Set();
  const [expandedIndices, setExpandedIndices] = React.useState(defaultExpanded);

  const handleChange = (index: number) => {
    setExpandedIndices((prevExpandedIndices) => {
      const newExpandedIndices = new Set(prevExpandedIndices);
      newExpandedIndices.has(index)
        ? newExpandedIndices.delete(index)
        : newExpandedIndices.add(index);
      return newExpandedIndices;
    });
  };

  return (
    <div>
      <div>
        {data.map((item) => (
          <Accordion
            key={item.id}
            expanded={expandedIndices.has(item.id)}
            className="customAccordion"
            onChange={() => handleChange(item.id)}
            style={{
              borderColor: '#DBDFE5',
              borderWidth: '0.5px 0px 0px',
              boxShadow: 'none',
            }}
          >
            <AccordionSummary
              aria-controls={`panel${item.id}a-content`}
              id={`panel${item.id}a-header`}
              style={{
                boxShadow: '0px 1px #DBDFE5',
              }}
            >
              <Typography
                sx={
                  expandedIndices.has(item.id)
                    ? STYLES.accordionTitleExpand
                    : STYLES.accordionTitle
                }
              >
                {item.categoryTitle}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 2 }}>
              {item.children.map((subItem) => (
                <FaqAccordion
                  defaultExpanded={
                    item.id === parentIdFromHash &&
                    subItem.id === childIdFromHash
                  }
                  key={subItem.section}
                  section={subItem.section}
                  title={subItem.title}
                  content={subItem.content}
                  subContent={subItem.subContent}
                  bulletList={subItem.bulletList}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}
