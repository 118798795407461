import {
  Container,
  Button,
  useMediaQuery,
  Grid,
  Typography,
  Theme,
  Box,
} from '@mui/material';
import _ from 'lodash';

import * as user from '@/domain/middlewares/user';
import { store } from '@/domain/store';
import { State } from '@/types';
import { QRModal } from '@/view/components/QRModal';
import {
  BORDER_RADIUS,
  FONT_SIZES,
  LINE_HEIGHTS,
  STYLES,
} from '@/view/styling/theme';

export function BarcodeScanAttempt({ state }: { state: State }) {
  const onSubmitAddAssessmentForm = _.debounce(
    _.partial(user.onSubmitAddAssessmentForm, store),
    500
  );
  const onScanOfBarcodeAssessment = _.partial(
    user.onScanOfBarcodeAssessment,
    store
  );
  const onCancelBarcodeScan = _.partial(user.onCancelBarcodeScan, store);
  const isInputInvalid = !state.addAssessmentInput.isValid;
  const isInputValid = state.addAssessmentInput.isValid;
  const isInputEmpty = state.addAssessmentInput.value === '';

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <div>
      <Container>
        <Grid container direction="column" rowGap={2}>
          <Grid item>
            <Typography
              sx={{
                display: 'block',
                fontSize: FONT_SIZES.lg,
                fontWeight: 600,
                lineHeight: LINE_HEIGHTS.xl,
                my: 4,
                textAlign: 'center',
                width: '100%',
              }}
            >
              {state.currentRouteMeta?.title}
            </Typography>
          </Grid>

          <Grid item>
            <Box
              sx={{
                backgroundColor: 'grey.50',
                borderRadius: BORDER_RADIUS.lg,
                display: 'flex',
                flexDirection: 'column',
                minHeight: '52px',
                padding: 2,
                rowGap: 1,
                textAlign: 'center',
              }}
            >
              {isInputInvalid ? (
                <>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: 'error.main', fontWeight: 800 }}
                  >
                    検査受付番号の読み込みに<br></br>失敗しました
                  </Typography>
                  <Typography>
                    もう一度お試しいただくか、QRコードの下に記載されている検査受付番号を直接ご入力ください。
                  </Typography>
                </>
              ) : (
                <Typography variant="body1">
                  {state.addAssessmentInput.value}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                backgroundImage: `url(${state.barcodeScanPhoto})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                borderRadius: '8px',
                height: '30vh',
                width: '100%',
              }}
            ></Box>
          </Grid>
          <Grid item>
            {isInputValid ? (
              <Button
                onClick={onSubmitAddAssessmentForm}
                disabled={isInputInvalid || isInputEmpty}
                size="large"
                variant="contained"
                fullWidth
              >
                この検査受付番号で進める
              </Button>
            ) : null}
          </Grid>
          <Grid item>
            <QRModal
              onReadComplete={onScanOfBarcodeAssessment}
              triggerButton={
                <Button
                  component="label"
                  size="large"
                  variant="contained"
                  fullWidth
                  tabIndex={-1}
                  sx={{
                    lineHeight: '21.44px',
                    textAlign: 'center',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {isMobile ? (
                    <span>もう一度撮影する</span>
                  ) : (
                    'QRコードを撮影して検査受付番号を読み込む'
                  )}
                </Button>
              }
            />
          </Grid>
          <Grid item>
            <Button
              onClick={() => onCancelBarcodeScan()}
              sx={STYLES.secondaryButtonBottomPage}
              size="large"
              variant="outlined"
              fullWidth
            >
              検査受付番号の登録画面に戻る
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
