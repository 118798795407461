import DoneIcon from '@mui/icons-material/Done';
import { Button, ButtonGroup, Typography, Grid } from '@mui/material';

import { MY_PAGE_REPORT } from '@/constants';

export enum ReportTabId {
  CHALLENGES = 'CHALLENGES',
  REPORTS = 'REPORTS',
}

export type Props = {
  current: ReportTabId;
  onChange: (tabId: ReportTabId) => void;
};

export function ReportTabs({ current, onChange }: Props) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <ButtonGroup fullWidth variant="outlined" orientation="horizontal">
          <Button
            sx={{
              borderBottomLeftRadius: '48px',
              borderTopLeftRadius: '48px',
              color: 'black',
              fontWeight: 400,
            }}
            className={`report-tab ${
              current === ReportTabId.REPORTS && 'active'
            }`}
            onClick={() => {
              onChange(ReportTabId.REPORTS);
            }}
          >
            {current === ReportTabId.REPORTS && <DoneIcon />}
            <Typography
              variant="body1"
              sx={{ fontSize: '16px', fontWeight: 300, lineHeight: '20px' }}
            >
              {MY_PAGE_REPORT.TAB_1}
            </Typography>
          </Button>
          <Button
            sx={{
              borderBottomRightRadius: '48px',
              borderTopRightRadius: '48px',
              color: 'black',
              fontWeight: 400,
            }}
            className={`report-challenges-tab ${
              current === ReportTabId.CHALLENGES && 'active'
            }`}
            onClick={() => {
              onChange(ReportTabId.CHALLENGES);
            }}
          >
            {current === ReportTabId.CHALLENGES && <DoneIcon />}
            <Typography
              variant="body1"
              sx={{ fontSize: '16px', fontWeight: 300, lineHeight: '20px' }}
            >
              {MY_PAGE_REPORT.TAB_2}
            </Typography>
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}
