import { Box, Typography, Card } from '@mui/material';
import { FunctionComponent, useCallback, useState } from 'react';

import { MY_PAGE_REPORT } from '@/constants';

export const ReportGardenScoreFold: FunctionComponent = () => {
  // I don't definitely think this state should lifted to global redux store.
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => {
    setIsOpen((x) => !x);
  }, []);

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 3,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          padding: 2,
        }}
      >
        <Typography
          sx={{ fontSize: '16px', fontWeight: 300, lineHeight: '24px' }}
        >
          {MY_PAGE_REPORT.GARDEN_SCORE_TITLE}
        </Typography>
        <Box
          sx={{
            border: 'solid 1px',
            borderColor: 'grey.100',
            borderRadius: '3px',
            height: '29px',
            lineHeight: '29px',
            textAlign: 'center',
            userSelect: 'none',
            width: '29px',
          }}
          onClick={toggle}
        >
          {isOpen ? 'ー' : '＋'}
        </Box>
      </Box>
      {isOpen && (
        <Box
          sx={{
            backgroundColor: 'grey.50',
            padding: '24px 17px',
          }}
        >
          <Typography
            component="p"
            sx={{ fontSize: '16px', fontWeight: 300, lineHeight: '24px' }}
          >
            {MY_PAGE_REPORT.GARDEN_SCORE_DESCRIPTION}
          </Typography>
        </Box>
      )}
    </Card>
  );
};
