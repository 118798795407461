import { Theme } from '@mui/material';

export function CSSThemeVariables({ theme }: { theme: Theme }) {
  const palette = theme.palette;
  return (
    <style>
      {`
      :root {
        --mui-palette-common-white: ${palette.common.white};
        --mui-palette-grey-100: ${palette.grey[100]};
        --mui-palette-grey-300: ${palette.grey[300]};
        --mui-palette-primary-main: ${palette.primary.main};
        --mui-palette-error-main: ${palette.error.main};
        --mui-palette-info-main: ${palette.info.main};
        --unit: 8;
        --border-radius: 4
      }
      `}
    </style>
  );
}
