import { Grid, Link, Typography } from '@mui/material';

import FooterLogo from '@/assets/meiji.png';
import { FOOTER } from '@/constants';
import { FONT_SIZES, LINE_HEIGHTS, STYLES } from '@/view/styling/theme';

export function MeijiFooter({
  type,
  onClickLogout,
  showLogout,
  links,
}: {
  links: {
    name: string;
    url: string;
  }[];
  onClickLogout: () => void;
  showLogout: boolean;
  type: 'small' | 'large';
}) {
  if (type === 'small') {
    return (
      <Grid
        className="footer"
        container
        direction="column"
        textAlign="left"
        p={2}
        pt={7}
      >
        <Grid item>
          <Typography
            variant="caption"
            sx={{ color: 'grey.300', fontSize: FONT_SIZES.xs }}
          >
            {FOOTER.COPYRIGHT}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <>
      <Grid
        className="footer"
        container
        direction="column"
        sx={STYLES.footer}
        textAlign="left"
      >
        <Grid item width="100%">
          <Grid container direction="column" width="100%">
            {links.map((item) => {
              return (
                <Grid item marginBottom={LINE_HEIGHTS.md} key={item.name}>
                  <Link
                    target="_blank"
                    href={item.url}
                    sx={{
                      color: 'grey.300',
                      fontSize: FONT_SIZES.sm,
                      textDecoration: 'none',
                    }}
                  >
                    {item.name}
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {showLogout && (
          <Grid
            item
            marginBottom={LINE_HEIGHTS.lg}
            marginTop="2px"
            fontSize={FONT_SIZES.sm}
          >
            <Link onClick={onClickLogout} sx={{ cursor: 'pointer' }}>
              {FOOTER.LOGOUT}
            </Link>
          </Grid>
        )}
        <Grid item marginTop={LINE_HEIGHTS.md}>
          <img src={FooterLogo} height="40px" className="inline-block" />
        </Grid>
        <Grid item>
          <Typography
            variant="caption"
            sx={{ color: 'grey.300', fontSize: FONT_SIZES.xs }}
          >
            {FOOTER.COPYRIGHT}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
