import { Grid, Typography } from '@mui/material';

import { FONT_SIZES, STYLES, TEASER_STYLES } from '@/view/styling/theme';

interface SectionTitleProps {
  subtitle: string;
  title: string;
}

export function TeaserSectionTitle({ title, subtitle }: SectionTitleProps) {
  return (
    <>
      <Grid item xs={12} width={'100%'} sx={STYLES.topPage.serviceTitle}>
        <Typography sx={TEASER_STYLES.sectionTitle}>{title}</Typography>
        <Typography sx={{ fontSize: FONT_SIZES.xxl, fontWeight: 800, pt: 1 }}>
          {subtitle}
        </Typography>
      </Grid>
    </>
  );
}
