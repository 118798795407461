import { Box, Container } from '@mui/material';
import { ReactNode } from 'react';

import { STYLES } from '@/view/styling/theme';

type Props = {
  /**
   * `children` are the elements to be displayed within the carousel.
   * This prop can include a single React element or multiple React elements.
   */
  children: ReactNode;
};

/**
 * Carousel is a reusable UI component that displays its children
 * in a horizontal scrollable format. It utilizes the MUI Container and Box components
 * to lay out the children appropriately.
 *
 * @param {Props} props The properties passed to the component, expecting `children`.
 * @returns {JSX.Element} The rendered JSX element of the Carousel.
 */
export function Carousel({ children }: Props): JSX.Element {
  return (
    <Container
      maxWidth="md"
      fixed
      disableGutters
      sx={(theme) => ({
        width: 'calc(100vw - 32px)',
        [theme.breakpoints.up(600)]: { width: 550 },
        [theme.breakpoints.up('md')]: { width: 850 },
      })}
    >
      <Box
        sx={{
          '& > *': {
            flexShrink: 0,
            maxWidth: 375,
            scrollSnapAlign: 'center',
            width: '80%',
          },
          '::-webkit-scrollbar': { display: 'none' },
          display: 'flex',
          flexWrap: 'nowrap',
          gap: 2,
          overflowX: 'auto',
          scrollSnapType: 'x mandatory',
          width: '100%',
        }}
      >
        {children}
      </Box>
    </Container>
  );
}

export function AutoplayImageCarousel({
  images,
}: {
  images: string[];
}): JSX.Element {
  return (
    <Container sx={STYLES.carousel.container} className="carousel-container">
      <Box sx={STYLES.carousel.track} className="carousel-track">
        {images.map((image, index) => (
          <Box key={`1_${index}`} sx={STYLES.carousel.item}>
            <img
              src={image}
              alt={`drink`}
              style={{
                height: '100%',
                width: 'auto',
              }}
            />
          </Box>
        ))}
      </Box>
      <Box sx={STYLES.carousel.track} aria-hidden className="carousel-track">
        {images.map((image, index) => (
          <Box key={`1_${index}`} sx={STYLES.carousel.item}>
            <img
              src={image}
              alt={`drink`}
              style={{
                height: '100%',
                width: 'auto',
              }}
            />
          </Box>
        ))}
      </Box>
    </Container>
  );
}
