import { Box, Typography } from '@mui/material';

import { FONT_SIZES, LINE_HEIGHTS } from '@/view/styling/theme';

interface StepProps {
  image?: string;
  imageTitle?: string;
  imageWidth?: string;
  text: string;
  type?: string;
}

export function StepDetail(props: StepProps) {
  switch (props.type) {
    case 'TextOnly':
      return TextOnly(props);
    case 'imageWithText':
      return ImageWithText(props);
    default:
      return TextOnly(props);
  }
}

function TextOnly({ text }: StepProps) {
  return (
    <Typography
      sx={{
        fontSize: FONT_SIZES.md,
        lineHeight: LINE_HEIGHTS.xl,
        p: 1,
        whiteSpace: 'pre-wrap',
      }}
    >
      {text}
    </Typography>
  );
}

function ImageWithText(props: StepProps) {
  // width 70 % is default
  const imageWidth = props.imageWidth ? props.imageWidth : '70%';
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography sx={{ fontWeight: '800', lineHeight: '80px' }}>
          {props.imageTitle}
        </Typography>
      </Box>

      <Box sx={{ pb: 3 }}>
        <img width={imageWidth} src={props.image} alt="" />
      </Box>

      <Typography
        sx={{
          fontSize: FONT_SIZES.md,
          lineHeight: LINE_HEIGHTS.xxl,
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
        }}
      >
        {props.text}
      </Typography>
    </Box>
  );
}
