import Typography from '@mui/material/Typography';

interface TextWithLinksProps {
  text: string;
}

export function TextWithLinks({ text }: TextWithLinksProps) {
  // Regex to identify URLs
  const urlRegex = /(\bhttps?:\/\/[^\s]+\b)/g;

  // Split text into parts by URLs
  const parts = text.split(urlRegex);

  return (
    <Typography sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
      {parts.map((part, index) =>
        urlRegex.test(part) ? (
          <a
            key={index}
            href={part}
            target="_blank"
            style={{ color: 'black', textDecoration: 'underline' }}
          >
            {part}
          </a>
        ) : (
          part
        )
      )}
    </Typography>
  );
}
