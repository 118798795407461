export type NormalizedQuestion = {
  answer: string | null;
  answerId: string | null;
  question: string;
  questionId: string;
};

export type AdviceTitle = {
  text: string;
  type: 'title';
};

export type AdviceParagraph = {
  text: string;
  type: 'paragraph';
};

export type AdviceImage = {
  alt: string;
  src: string;
  type: 'image';
};

export type AdviceLink = {
  href: string;
  text: string;
  type: 'link';
};

export type AdviceElement =
  | AdviceTitle
  | AdviceParagraph
  | AdviceImage
  | AdviceLink;

export type Habit = {
  key: string;
  legend: string[];
  target: number; // the bar in grey
  title: string;
  value: number; // the bar in red
};

export type HabitAndAdvice = {
  advice?: AdviceItem;
  key: string;
  legend: string[];
  target: number;
  title: string;
  value: number;
};

export type ChallengeItem = {
  category?: string;
  description: string;
  image: string;
  title: string;
};

export type AdviceItem = {
  description: string | AdviceElement[];
  key: string;
  title: string;
};

export const AverageIgAKeys = [
  '20sFemale',
  '20sMale',
  '30sFemale',
  '30sMale',
  '40sFemale',
  '40sMale',
  '50sFemale',
  '50sMale',
  '60sAndAboveFemale',
  '60sAndAboveMale',
] as const;

export type AverageIgAKey = (typeof AverageIgAKeys)[number];

export const BacteriaTypes = [
  'PREVOTELLA',
  'BACTEROIDES',
  'FAECALIBACTERIUM',
  'BIFIDOBACTERIUM',
  'RUMINOCOCCUS',
] as const;

export type BacteriaType = (typeof BacteriaTypes)[number];

export const HabitCategories = [
  'FERMENTED_FOOD',
  'FOOD_FOR_FLORA',
  'SUPPLEMENTS',
  'REGULAR_LIFE',
  'STRESS',
  'EXERCISE',
  'YOGA',
  'GUT_HEALTHY_DIET',
] as const;

export type HabitCategory = (typeof HabitCategories)[number];

export type ReportResults = {
  ave: number;

  // At Version 1, this property MIGHT NOT be present.
  // The pull (/pbio-group/pbio-backend-apps/pull/35)
  // will add this property while keeping majorVersion = 1.
  group?: Record<AverageIgAKey, number>;
  labels: {
    rankA: string;
    rankB: string;
    rankC: string;
    rankD: string;
  };
  legend: string[];
  max: number;
  min: number;
  rank: string;
  value: number;
};

export type Report = {
  advices: ReportAdvice[];
  habits: ReportHabit[];
  majorVersion: number;
  missions: ReportMission[];
  recommends: ReportRecommendation[];
  reportSummary: ReportSummary;
  result: ReportResult[];
};

export type ReportResult =
  | {
      average: number;
      key: BacteriaType;
      max: number;
      min: number;
      outcomePercentageText: string;
      title: string;
      value: number | null;
    }
  | {
      average: number;
      key: 'GARDEN_SCORE';
      max: number;
      min: number;
      outcomePercentageText: string;
      title: string;
      value: number | null;
    };

export type ReportSummary = {
  description: string; // TODO is this necessary?
  primaryBacteria: BacteriaType | 'UNMEASURABLE';
  title: string;
};

export type TScore = {
  BACTEROIDES: number;
  BIFIDOBACTERIUM: number;
  FAECALIBACTERIUM: number;
  PREVOTELLA: number;
  RUMINOCOCCUS: number;
};

export type ReportHabit = {
  key: HabitCategory;
  legend: string[];
  target: number;
  title: string;
  value: number;
};

export type ReportAdvice = {
  description: string;
  key: HabitCategory;
  title: string;
};

export type ReportMission = {
  category: string;
  description: string;
  image: string;
  title: string;
};

export type ReportRecommendation = {
  description: string;
  title: string;
};

export type NormalizedReport = {
  advices: AdviceItem[];
  habits: HabitAndAdvice[];
  majorVersion: number;
  missions: ChallengeItem[];
  reportSummary: Report['reportSummary'] & {
    gardenScore: number | null;
    tScore?: TScore;
  };
  // TODO this needs to become an array!
  result: ReportResult[];
  testKitId: string;
};

export type NormalizedSurvey = NormalizedQuestion[];

type CustomerInfo = {
  email: string;
  firstName: string;
  lastName: string;
};

type PaymentDetails = {
  customerAdminId: string;
  customerInfo: CustomerInfo;
  numberOfOrders: number;
  orderId: string;
  paymentCompletedAt: string;
  title: string;
  totalPrice: string;
};

export type SurveyQuestion = {
  answer?: string;
  answerId?: string;
  question: string;
  questionId: string;
};

export type TestResult = {
  bacteroides: number;
  bifidobacterium: number;
  faecalibacterium: number;
  prevotella: number;
  ruminococcus: number;
};

export const TestStatus = {
  ARRIVED: '100',
  COULD_NOT_COMPLETE: '303',
  DONE: '300',
} as const;
export type TestStatusCode = (typeof TestStatus)[keyof typeof TestStatus];
export type TestStatusName = keyof typeof TestStatus;

export type Assessment = {
  _id: string;
  createdAt: string;
  readyForPostAt?: string;
  report?: Report;
  reportedAt?: string; // TEST_COMPLETE status update
  status: AssessmentStatus;
  survey: {
    questions: SurveyQuestion[];
    surveyCompletedAt?: string; //PREPARING_POST status update
  };
  test: {
    registeredAt?: string; // user sends the TestKitId
    testCompletedAt?: string; // PREPARING_POST status update
    testKitId?: string;
    testResult?: TestResult;
    testStatus?: TestStatusCode;
  };
};

export type NormalizedAssessment = {
  _id: string;
  createdAt: string;
  meijiId: string;
  paymentDetails?: PaymentDetails;
  report: NormalizedReport | null;
  status: AssessmentStatus;
  survey: NormalizedSurvey | null;
  testCompletedAt: string;
  testKitId: string | null;
  updatedAt: string;
};

export type NewAssessment = { meijiId: string };

export type NetworkPurchase = {
  _id: string;
  createdAt: string;
  productType: ProductType;
  shopifyData: NetworkShopifyData;
  updatedAt: string;
};

export type NetworkShopifyData = {
  customerAdminId: string;
  customerInfo: CustomerInfo;
  numberOfOrders: number;
  orderId: string;
  orderType: 'SUBSCRIPTION' | 'ONE_TIME';
  paymentCompletedAt: string;
  productType: ProductType;
  productVariantId: string;
  productVariantName: string;
  subscriptionCancelledDate: Date | null;
  title: string;
  totalPrice: string;
};

export type UpdateAssessment = {
  status?: Assessment['status'];
  survey?: Assessment['survey'];
  testKitId?: Assessment['test']['testKitId'];
};

export enum AssessmentStatus {
  'ABORT_EXCHANGE' = 'ABORT_EXCHANGE',
  'ABORT_REPAYMENT' = 'ABORT_REPAYMENT',
  'ABORT_WITHDRAWAL' = 'ABORT_WITHDRAWAL',
  'COMPLETE' = 'COMPLETE',
  'DELIVERY_REQUEST' = 'DELIVERY_REQUEST',
  'LAB_RECEIVED' = 'LAB_RECEIVED',
  'PAYMENT_COMPLETE' = 'PAYMENT_COMPLETE',
  'PREPARING_POST' = 'PREPARING_POST',
  'PREPARING_SURVEY' = 'PREPARING_SURVEY',
  'READY_FOR_POST' = 'READY_FOR_POST',
  'REGISTER_TESTKIT_ID' = 'REGISTER_TESTKIT_ID',
  'START_APPLICATION' = 'START_APPLICATION',
  'TESTING' = 'TESTING',
}

export enum ProductType {
  'DRINK' = 'DRINK',
  'TEST_KIT' = 'TEST-KIT',
}

export type NetworkSessionUser = {
  user: {
    buildingName: string;
    city: string;
    dayOfBirth: number;
    email: string;
    firstName: string;
    firstNameKana: string;
    houseNumber: string;
    lastName: string;
    lastNameKana: string;
    monthOfBirth: number;
    phoneNumber: string;
    postalCode: string;
    prefecture: string;
    sex: string;
    sexJA: string;
    userId: number;
    yearOfBirth: number;
  };
};

export type NetworkRefreshToken = {
  accessToken: string;
  disagreeFrontsystemIds: number[];
  isFirstLogin: boolean;
};

export type NetworkTerms = {
  isUserLatestTermAgreed: boolean;
  term: {
    frontsystemId: number;
    frontsystemName: string;
    note: string;
    revisionDate: string;
    termsUrl: string;
    version: number;
  };
};
