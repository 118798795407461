import page from 'page';

import * as routeHandlers from '@/domain/middlewares/router/handlers';
import {
  authMiddleware,
  analyticsMiddleware,
  scrollTopMiddleware,
  assessmentStatusMiddleware,
  surveyRestoreAnswersMiddleware,
  surveyRestorePageMiddleware,
  getOngoingAssessmentMiddleware,
  getFirstSetPurchaseStatusMiddleware,
  scrollToTopOnNavigationMiddleware,
  getLatestBacteriaTypeMiddleware,
  selectAssessmentMiddleware,
  getAssessmentsMiddleware,
} from '@/domain/middlewares/router/middlewares';
import { AssessmentStatus } from '@/types/network';

page('*', analyticsMiddleware);
page('*', scrollToTopOnNavigationMiddleware);

page(
  '/campaign/2024',
  scrollToTopOnNavigationMiddleware,
  routeHandlers.teaserRouteHandler
);

page(
  '/test-kit/barcode-scan',
  authMiddleware,
  getOngoingAssessmentMiddleware,
  assessmentStatusMiddleware([
    AssessmentStatus.START_APPLICATION,
    AssessmentStatus.PAYMENT_COMPLETE,
  ]),
  routeHandlers.assessmentAddByScanHandler
);

page(
  '/test-kit/barcode-scan-attempt',
  authMiddleware,
  getOngoingAssessmentMiddleware,
  assessmentStatusMiddleware([
    AssessmentStatus.START_APPLICATION,
    AssessmentStatus.PAYMENT_COMPLETE,
  ]),
  routeHandlers.assessmentBarcodeScanAttemptHandler
);

page(
  '/test-kit/before-sample-collection',
  authMiddleware,
  getOngoingAssessmentMiddleware,
  assessmentStatusMiddleware([AssessmentStatus.PAYMENT_COMPLETE]),
  routeHandlers.assessmentPrepareCollectionHandler
);
page(
  '/test-kit/collection-instructions',
  authMiddleware,
  getOngoingAssessmentMiddleware,
  assessmentStatusMiddleware([
    AssessmentStatus.TESTING,
    AssessmentStatus.PREPARING_POST,
  ]),
  routeHandlers.assessmentCollectionInstructionsHandler
);

page(
  '/test-kit/postage-instructions',
  authMiddleware,
  getOngoingAssessmentMiddleware,
  assessmentStatusMiddleware([AssessmentStatus.PREPARING_POST]),
  routeHandlers.assessmentPostageInstructionsHandler
);
page(
  '/my-page',
  authMiddleware,
  getOngoingAssessmentMiddleware,
  assessmentStatusMiddleware(Object.values(AssessmentStatus)),
  routeHandlers.myPageHandler
);
page(
  '/my-page/reports',
  authMiddleware,
  getAssessmentsMiddleware,
  routeHandlers.myPageReportsListHandler
);
page(
  '/my-page/reports/:assessmentId',
  authMiddleware,
  getAssessmentsMiddleware,
  selectAssessmentMiddleware(),
  routeHandlers.myPageReportsHandler
);

page(
  '/my-page/reports/:assessmentId/challenges',
  authMiddleware,
  routeHandlers.myPageReportChallengesHandler
);

page(
  '/survey',
  authMiddleware,
  getOngoingAssessmentMiddleware,
  assessmentStatusMiddleware([AssessmentStatus.PREPARING_SURVEY]),
  surveyRestoreAnswersMiddleware,
  surveyRestorePageMiddleware,
  routeHandlers.surveyPageHandler
);
page(
  '/survey/:page',
  authMiddleware,
  getOngoingAssessmentMiddleware,
  assessmentStatusMiddleware([AssessmentStatus.PREPARING_SURVEY]),
  surveyRestoreAnswersMiddleware,
  surveyRestorePageMiddleware,
  routeHandlers.surveyPageHandler
);

page('/columns', routeHandlers.columnListPageHandler);
page('/columns/:column', routeHandlers.columnPageHandler);

page('/faq', routeHandlers.faqRouteHandler);

page('/user-feedback', routeHandlers.customerFeedbackPageHandler);

page(
  '/purchase-history',
  authMiddleware,
  routeHandlers.purchaseHistoryPageHandler
);

page('/products', routeHandlers.productsListPageHandler);
page(
  '/products/allergy-ingredient',
  routeHandlers.allergyIngredientProductsHandler
);
page(
  '/products/drink',
  getLatestBacteriaTypeMiddleware,
  scrollTopMiddleware,
  routeHandlers.productDrinkPageHandler
);
page(
  '/products/:product',
  getOngoingAssessmentMiddleware,
  getFirstSetPurchaseStatusMiddleware,
  scrollTopMiddleware,
  routeHandlers.productTestKitPageHandler
);
page('/concept', routeHandlers.conceptRouteHandler);

page('/campaign/2024', routeHandlers.teaserRouteHandler);

page('/auth/callback', routeHandlers.authCallbackRouteHandler);
page('/login', routeHandlers.loginRouteHandler);
page('/', routeHandlers.topRouteHandler);
page('*', routeHandlers.notFoundRouteHandler);

export function startRouters(): void {
  page.start();
}
