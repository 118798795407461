import { Grid, Container, Button, Typography, Link } from '@mui/material';
import _ from 'lodash';

import { COLLECTION_PHASE_STEPPER, SURVEY_PAGE, ERRORS } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { store } from '@/domain/store';
import { State, Question, Answer, OnQuestionAnswerChanged } from '@/types';
import { OutlinedCard } from '@/view/components/OutlinedCard';
import { FormStepper } from '@/view/components/Stepper';
import { SurveyRadioItem } from '@/view/components/SurveyRadioItem';
import { SurveySelectInlineItem } from '@/view/components/SurveySelectInline';
import { SurveySelectItem } from '@/view/components/SurveySelectItem';
import { FONT_SIZES, STYLES } from '@/view/styling/theme';

function getAnswer(answers: Answer[], item: Question) {
  const nextValue = _.find(answers, { key: item.id });
  return nextValue?.value || item.selectedValue;
}

function SurveyRouter(
  answers: Answer[],
  item: Question,
  onSelectItem: OnQuestionAnswerChanged,
  questionNumber: number,
  isAutoSaving: boolean
) {
  switch (item.component) {
    case 'SELECT':
      return (
        <SurveySelectItem
          {...item}
          questionNumber={questionNumber}
          isAutoSaving={isAutoSaving}
          onChange={onSelectItem}
          selectedValue={getAnswer(answers, item)}
          key={item.id + '_parent'}
        />
      );
    case 'SELECT_INLINE':
      return (
        <SurveySelectInlineItem
          {...item}
          isAutoSaving={isAutoSaving}
          questionNumber={questionNumber}
          onChange={onSelectItem}
          selectedValue={getAnswer(answers, item)}
          key={item.id + '_parent'}
        />
      );
    default:
    case 'RADIO':
      return (
        <SurveyRadioItem
          {...item}
          isAutoSaving={isAutoSaving}
          showBorderTop={item.hideNumber}
          questionNumber={questionNumber}
          onChange={onSelectItem}
          selectedValue={getAnswer(answers, item)}
          key={item.id + '_parent'}
        />
      );
  }
}

export function Survey({ state }: { state: State }) {
  const onSelectItem = _.partial(user.onSelectAnswer, store, true);
  const onClickOpenBeforeSampleCollections = _.partial(
    user.onClickOpenBeforeSampleCollections,
    store
  );
  const onNextPage = _.partial(user.onNextSurveyPage, store);
  const onPreviousPage = _.partial(user.onPreviousSurveyPage, store);
  const onSubmitSurvey = _.partial(user.onSubmitSurvey, store);
  const isLastPage = state.surveyPage.index === SURVEY_PAGE.LAST_PAGE_OF_SURVEY;
  const isFirstPage = state.surveyPage.index <= 1;

  const questionCountOffset = state.surveyPage.questionOffset;
  let questionCount = 0;
  return (
    <div>
      <FormStepper activeStep={2} steps={COLLECTION_PHASE_STEPPER} />
      <Container>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignContent="center"
          sx={{ pt: 2 }}
        >
          <Grid item>
            <Typography
              align="center"
              variant="h3"
              sx={{ fontSize: FONT_SIZES.lg, mb: 1 }}
            >
              {state.currentRouteMeta?.title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="caption"
              sx={{
                color: 'grey.300',
                display: 'block',
                mb: 4,
                textAlign: 'center',
              }}
            >
              {state.surveyPage.index}/{SURVEY_PAGE.LAST_PAGE_OF_SURVEY}
              {SURVEY_PAGE.BODY_LINE_1}
            </Typography>
          </Grid>
          {isFirstPage && (
            <Grid item>
              <Typography
                variant="h6"
                sx={{
                  align: 'center',
                  fontWeight: '400',
                  mb: 6,
                }}
              >
                {SURVEY_PAGE.BODY_LINE_2}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container direction="column" spacing={4} sx={{ mb: 7 }}>
          {state.surveyPage.questions.map((item) => {
            if (!item.hideNumber) {
              questionCount++;
            }
            const questionNumber = item.hideNumber
              ? 0
              : questionCount + questionCountOffset;
            return (
              <Grid item key={item.id}>
                {SurveyRouter(
                  state.surveyAnswers,
                  item,
                  onSelectItem,
                  questionNumber,
                  state.loading.surveyAutoSaveIsLoading
                )}
              </Grid>
            );
          })}
        </Grid>
        <Grid container direction="column" spacing={4}>
          {state.surveyPage.questionErrors.length > 0 && (
            <Grid item>
              <OutlinedCard
                type="error"
                coloredTitle={ERRORS.NOT_ANSWERED}
                content={
                  <Typography sx={{ color: 'error.main', fontStyle: 'normal' }}>
                    <ul>
                      {state.surveyPage.questionErrors.map((q) => {
                        return (
                          <li key={q.title}>
                            <Link
                              sx={{
                                color: 'error.main',
                                display: 'block',
                                paddingY: 1,
                              }}
                              onClick={() => {
                                document.getElementById(q.id)?.scrollIntoView({
                                  behavior: 'smooth',
                                  block: 'start',
                                  inline: 'start',
                                });
                              }}
                            >
                              {q.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </Typography>
                }
              />
            </Grid>
          )}
        </Grid>
        <Grid container direction="column">
          <Grid item>
            <Typography
              sx={{
                color: 'grey.300',
                display: 'block',
                paddingY: 3,
                textAlign: 'center',
              }}
            >
              {state.surveyPage.index}/{SURVEY_PAGE.LAST_PAGE_OF_SURVEY}
            </Typography>
            {isLastPage ? (
              <>
                <Button
                  size="large"
                  variant="contained"
                  fullWidth
                  data-testid="survey__complete-btn"
                  sx={{ marginBottom: 3 }}
                  onClick={onSubmitSurvey}
                  data-gtm-link="complete_survey"
                >
                  {SURVEY_PAGE.SUBMIT_BUTTON}
                </Button>
                <Button
                  size="large"
                  variant="outlined"
                  fullWidth
                  sx={STYLES.secondaryButtonBottomPage}
                  onClick={onPreviousPage}
                >
                  {SURVEY_PAGE.CANCEL_BUTTON}
                </Button>
              </>
            ) : (
              <>
                <Button
                  data-testid="survey__next-btn"
                  size="large"
                  variant="contained"
                  fullWidth
                  sx={{ marginBottom: 3 }}
                  onClick={onNextPage}
                  disabled={state.loading.surveyAutoSaveIsLoading}
                >
                  {SURVEY_PAGE.NEXT_BUTTON}
                </Button>
                <Button
                  data-testid="BACK_SURVEY_PAGE"
                  size="large"
                  sx={STYLES.secondaryButtonBottomPage}
                  variant="outlined"
                  fullWidth
                  onClick={
                    isFirstPage
                      ? onClickOpenBeforeSampleCollections
                      : onPreviousPage
                  }
                >
                  {SURVEY_PAGE.CANCEL_BUTTON}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
