import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ScienceIcon from '@mui/icons-material/Science';
import { Grid, Typography, Button, Box, Paper, Divider } from '@mui/material';
import page from 'page';

import { NormalizedReport } from '@/types/network';
import { ExplainingFloraCheck } from '@/view/components/ExplainingFloraCheck';
import { FONT_SIZES, LINE_HEIGHTS } from '@/view/styling/theme';

import { AssessmentReportCard } from './AssessmentReportCard';

type Props = {
  hasOngoing: boolean;
  hideIfEmpty?: boolean;
  onClickGoToAssessmentReport: (assessmentId: string) => void;
  report?: NormalizedReport & { assessmentId: string; reportDate: string };
};

export function LatestReportCard({
  report,
  hasOngoing = true,
  hideIfEmpty,
  onClickGoToAssessmentReport,
}: Props) {
  if (!report && hideIfEmpty) {
    return null;
  }

  return (
    <Grid container direction="column" gap={2}>
      <Grid item sx={{ mt: '24px' }}>
        <Grid container justifyContent="space-between">
          <Grid item alignContent="center">
            <Typography
              sx={{
                display: 'block',
                fontSize: FONT_SIZES.lg,
                fontWeight: 600,
                whiteSpace: 'pre-wrap',
                width: '100%',
              }}
            >
              最新の検査結果
            </Typography>
          </Grid>
          {hasOngoing === false && (
            <Grid item alignContent="center">
              <a href="/products">
                <Button
                  size="medium"
                  variant="contained"
                  fullWidth
                  startIcon={<ScienceIcon />}
                >
                  検査の申し込み
                </Button>
              </a>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Paper elevation={0} sx={{ borderRadius: '12px', padding: '24px' }}>
          {report ? (
            <Grid
              container
              direction="column"
              onClick={() => onClickGoToAssessmentReport(report.assessmentId)}
              marginBottom={1}
              sx={{ cursor: 'pointer' }}
            >
              <Grid item xs={12}>
                <AssessmentReportCard
                  report={report}
                  testCompletedAt={report.reportDate}
                  displayGardenScore={false}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ margin: '16px 0' }} />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  component="h5"
                  sx={{
                    color: 'text.primary',
                    fontSize: FONT_SIZES.sm,
                    fontWeight: 300,
                    lineHeight: LINE_HEIGHTS.md,
                  }}
                >
                  {report.reportSummary.description}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column" gap={4}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',

                    justifyContent: 'space-between',
                    textAlign: 'center',
                  }}
                >
                  <img
                    width="248.46px"
                    src="/images/top-page/bacteria_characters.png"
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: 'grey.300',
                    display: 'block',
                    fontSize: FONT_SIZES.md,
                    fontWeight: 600,
                    lineHeight: LINE_HEIGHTS.lg,
                    textAlign: 'center',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                  }}
                >
                  現在お申し込み中の検査はありません
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          )}
          <ExplainingFloraCheck
            triggerButton={
              <Button
                size="small"
                variant="contained"
                fullWidth
                sx={{
                  '&:hover': {
                    backgroundColor: 'common.white',
                    boxShadow: 'none',
                  },
                  alignItems: 'end',
                  backgroundColor: 'common.white',
                  color: 'grey.300',
                  fontSize: FONT_SIZES.sm,
                  fontWeight: 300,
                  height: '37px',
                  justifyContent: 'flex-start',
                  paddingLeft: 0,
                  textTransform: 'none',
                }}
                startIcon={
                  <HelpOutlineIcon
                    sx={{
                      color: 'primary.main',
                    }}
                  />
                }
              >
                腸内フローラ検査について
              </Button>
            }
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
