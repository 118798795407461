import ImportContactsTwoToneIcon from '@mui/icons-material/ImportContactsTwoTone';
import { Grid, Divider, Button, Typography } from '@mui/material';
import _ from 'lodash';

import { PRODUCT_LIST_PAGE, PRODUCT_DETAIL_FIRST_SET_PAGE } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { store } from '@/domain/store';
import { State } from '@/types';
import { DrinkList } from '@/view/components/DrinkList';
import { ExplainingCocoa } from '@/view/components/ExplainingCocoa';
import { ProductInfoRow } from '@/view/components/ProductCard/ProductAccordion';
import { ProductDetailItem } from '@/view/components/ProductDetailItem';
import { ProductListItem } from '@/view/components/ProductListItem';
import { STYLES } from '@/view/styling/theme';

export function ProductDetailFirstSet({ state }: { state: State }) {
  const onClickHandler = _.partial(user.navigateTo);
  const suggestedProducts = PRODUCT_LIST_PAGE.BODY.filter(
    (product) => product.DETAIL_PAGE !== 'first-set'
  );

  const isLoggedIn = !_.isEmpty(`${state.user.token}`);

  const onClickGoToPayment = _.debounce(
    _.partial(
      user.onClickGoToPayment,
      store,
      import.meta.env.MEIJI_SHOPIFY_PRODUCT_VARIANT_FIRST_SET
    ),
    500
  );
  return (
    <Grid container sx={{ marginBottom: 2 }}>
      <Grid item xs={12} sx={{ marginBottom: 4 }}>
        <ProductListItem
          subTitle={PRODUCT_LIST_PAGE.BODY[0].SUBTITLE}
          image={PRODUCT_LIST_PAGE.BODY[0].IMAGE_PATH}
          price={PRODUCT_LIST_PAGE.BODY[0].PRICE}
          title={PRODUCT_LIST_PAGE.BODY[0].TITLE}
          taxText={PRODUCT_LIST_PAGE.PRICE_TAX_TEXT}
          marginX={2}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          marginBottom: '4px',
          marginX: 2,
        }}
      >
        <Typography
          component="h3"
          sx={STYLES.productDetailFirstSet.productInfoRowTitle}
        >
          {PRODUCT_DETAIL_FIRST_SET_PAGE.SECTION_BODY_1.TEST_KIT_TEXT.TITLE}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginX: 2 }}>
        <ProductInfoRow
          label={PRODUCT_DETAIL_FIRST_SET_PAGE.SECTION_BODY_1.CONTENT}
          value={
            PRODUCT_DETAIL_FIRST_SET_PAGE.SECTION_BODY_1.TEST_KIT_TEXT
              .DESCRIPTION
          }
          subValue={
            PRODUCT_DETAIL_FIRST_SET_PAGE.SECTION_BODY_1.TEST_KIT_TEXT.WARNING
          }
          firstColumnWidth={50}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          marginBottom: '4px',
          marginTop: 2,
          marginX: 2,
        }}
      >
        <Typography
          component="h3"
          sx={STYLES.productDetailFirstSet.productInfoRowTitle}
        >
          {PRODUCT_DETAIL_FIRST_SET_PAGE.SECTION_BODY_1.PRODUCT_TEXT.TITLE}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: 1, marginX: 2 }}>
        <ProductInfoRow
          label={PRODUCT_DETAIL_FIRST_SET_PAGE.SECTION_BODY_1.CONTENT}
          value={
            PRODUCT_DETAIL_FIRST_SET_PAGE.SECTION_BODY_1.PRODUCT_TEXT
              .DESCRIPTION
          }
          firstColumnWidth={50}
        />
      </Grid>
      <Grid item xs={12} sx={{ marginX: 2, marginY: 2 }}>
        <ExplainingCocoa
          triggerButton={
            <Button
              size="large"
              variant="outlined"
              fullWidth
              sx={{
                ...STYLES.secondaryButtonBottomPage,
                ...STYLES.productDetailFirstSet.explainingCocoaButton,
              }}
              startIcon={
                <ImportContactsTwoToneIcon
                  sx={{
                    color: 'primary.main',
                  }}
                />
              }
            >
              {PRODUCT_DETAIL_FIRST_SET_PAGE.BUTTON_LABEL.MORE_INFORMATION}
            </Button>
          }
        />
      </Grid>
      <Grid item xs={12} sx={{ marginX: 2, marginY: 1 }}>
        <Button
          size="large"
          variant="contained"
          fullWidth
          disabled={isLoggedIn && state.isFirstSetPurchase === false}
          onClick={onClickGoToPayment}
          data-e2e="product-detail__buy-btn"
        >
          {isLoggedIn && state.isFirstSetPurchase === false
            ? PRODUCT_DETAIL_FIRST_SET_PAGE.BUTTON_LABEL.DISABLED
            : PRODUCT_DETAIL_FIRST_SET_PAGE.BUTTON_LABEL.ENABLED}
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          alignContent: 'center',
          display: 'flex',
          justifyContent: 'center',
          marginTop: 1,
          marginX: 2,
        }}
      >
        <Typography component="h5" sx={STYLES.productListItem.sellerText}>
          {PRODUCT_LIST_PAGE.SELLER_TEXT}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 5, marginX: 2, width: '100%' }}>
        <Divider />
      </Grid>
      {isLoggedIn && state.isFirstSetPurchase === false ? (
        <>
          <Grid item xs={12}>
            <ProductDetailItem
              sectionTitle={PRODUCT_DETAIL_FIRST_SET_PAGE.TITLE_3}
              taxText={PRODUCT_LIST_PAGE.PRICE_TAX_TEXT}
              products={suggestedProducts}
              onClick={onClickHandler}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ marginBottom: 4, marginX: 2, width: '100%' }}
          >
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: 5 }}>
            <DrinkList />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sx={{ marginBottom: 1, marginTop: 5, marginX: 2 }}>
            <DrinkList />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 4, marginX: 2, width: '100%' }}>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: 2 }}>
            <ProductDetailItem
              sectionTitle={PRODUCT_DETAIL_FIRST_SET_PAGE.TITLE_3}
              taxText={PRODUCT_LIST_PAGE.PRICE_TAX_TEXT}
              products={suggestedProducts}
              onClick={onClickHandler}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
