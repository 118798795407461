import { Grid, Typography, Box } from '@mui/material';

import { AdviceElement } from '@/types/network';
import { AdviceItemTemplates } from '@/view/components/AdviceItemTemplate';
import { RecommendColumn } from '@/view/components/Report/RecommendColumn';
import { FONT_SIZES, LINE_HEIGHTS } from '@/view/styling/theme';

type Props = {
  description?: string | AdviceElement[];
  header: string;
  rowKey?: string;
  title?: string;
};

export function AdviceItem({ header, title, description, rowKey }: Props) {
  return (
    <>
      <Box pt={3} px={2}>
        <Grid direction="row" container marginBottom={1} gap={1}>
          <Grid item>
            <img
              width={25}
              src="/images/reports/emoji_objects.svg"
              alt="生活習慣アドバイス"
            />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: 'grey.300',
                fontSize: FONT_SIZES.xs,
                fontWeight: 600,
              }}
            >
              {header}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="h2"
          sx={{ lineHeight: LINE_HEIGHTS.xl, marginBottom: 2 }}
        >
          {title}
        </Typography>
        <AdviceItemTemplates description={description} />
      </Box>
      <Box sx={{ borderTop: 'solid 1px #DBDFE5' }} mt={2}>
        <RecommendColumn rowKey={rowKey} />
      </Box>
    </>
  );
}
