import { Grid, Typography } from '@mui/material';

import { TEASER_PAGE } from '@/constants';

export function Explanation() {
  const explanations = TEASER_PAGE.EXPLANATION.CONTENT;

  return (
    <>
      <Grid container sx={{ pb: '20px', pt: '40px' }}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '18px',
          }}
        >
          <Typography
            sx={{
              color: '#2F2F2F',
              fontSize: '18px',
              fontWeight: '600',
              lineHeight: '28.08px',
              textAlign: 'center',
            }}
          >
            {TEASER_PAGE.EXPLANATION.TITLE}
          </Typography>
        </Grid>

        {explanations.map((explanation) => {
          return (
            <Grid
              item
              key={explanation.id}
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '20px',
              }}
            >
              <Typography
                sx={{
                  color: '#2F2F2F',
                  fontSize: '16px',
                  fontWeight: '300',
                  lineHeight: '25.6px',
                  textAlign: 'center',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {explanation.text}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
