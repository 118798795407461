import { Box, SxProps, Theme, Typography } from '@mui/material';
import { CSSProperties } from 'react';

import { STYLES } from '@/view/styling/theme';

type Props = {
  commonTextStyle?: SxProps<Theme>;
  highlightTextStyle?: CSSProperties;
  normalTextStyle?: CSSProperties;
  text: string;
};

type TextFragment = {
  text: string;
  type: 'normal' | 'highlight';
};

const highlightOpen = '<hl>';
const highlightClose = '</hl>';
// eslint-disable-next-line security-node/non-literal-reg-expr
const regex = new RegExp(`(?=${highlightOpen})(.*?)(?:${highlightClose})`);

/*
Component for applying different styles to a string. Adds in a new <hl> tag to specify
which regions should be highlighted. Allows for line breaks if the string contains newlines

Example input string:
"first line\nsecond <hl>this</hl> is <hl>highlighted</hl> line\nthird line\nfourth<hl>this too</hl>"

output:
first line
second THIS is HIGHLIGHTED line
third line
fourth THIS TOO

*/

// FIXME: with HTMLParser from 'react-html-parser'

export function TextHighlight({
  text,
  normalTextStyle,
  highlightTextStyle,
  commonTextStyle,
}: Props) {
  // style overrides
  const normalStyle =
    normalTextStyle != null
      ? ({
          ...STYLES.textHighlight.normalTextStyle,
          ...normalTextStyle,
        } as CSSProperties)
      : (STYLES.textHighlight.normalTextStyle as CSSProperties);

  const highlightStyle =
    highlightTextStyle != null
      ? ({
          ...STYLES.textHighlight.highlightTextStyle,
          ...highlightTextStyle,
        } as CSSProperties)
      : (STYLES.textHighlight.highlightTextStyle as CSSProperties);

  const commonStyle =
    commonTextStyle != null
      ? ({
          ...STYLES.textHighlight.commonStyle,
          ...commonTextStyle,
        } as SxProps<Theme>)
      : (STYLES.textHighlight.commonStyle as SxProps<Theme>);

  // split text into fragments that know if they are normal or highlighted text
  const processedFragments: TextFragment[] = [];
  const unprocessedFragments = text.split(regex);

  if (unprocessedFragments.length >= 1) {
    unprocessedFragments.forEach((fragment) => {
      //fragment has tags, it's a highlighted section
      if (fragment.includes('<hl>') || fragment.includes('</hl>')) {
        // remove the tags
        const highlightedText = fragment
          .replace('<hl>', '')
          .replace('</hl>', '');
        if (highlightedText !== '') {
          processedFragments.push({ text: highlightedText, type: 'highlight' });
        }
      }

      // fragment has no tags, it's normal text
      else {
        processedFragments.push({ text: fragment, type: 'normal' });
      }
    });

    // regex returned only one result, that means there are no <hl> tags in the text
  } else {
    processedFragments.push({ text, type: 'normal' });
  }

  return (
    <Box>
      {processedFragments.map((fragment, index) => (
        <Typography sx={commonStyle} key={index}>
          {fragment.type === 'normal' && (
            <span key={index} style={normalStyle}>
              {fragment.text}
            </span>
          )}
          {fragment.type === 'highlight' && (
            <span key={index} style={highlightStyle}>
              {fragment.text}
            </span>
          )}
        </Typography>
      ))}
    </Box>
  );
}
