import { Grid, Typography } from '@mui/material';

import { defaultThemeColor, FONT_SIZES, STYLES } from '@/view/styling/theme';

interface SectionTitleProps {
  subtitle: string;
  title: string;
}

export function SectionTitle({ title, subtitle }: SectionTitleProps) {
  return (
    <>
      <Grid item xs={12} width={'100%'} sx={STYLES.topPage.serviceTitle}>
        <Typography sx={{ color: defaultThemeColor, fontWeight: 800 }}>
          {title}
        </Typography>
        <Typography sx={{ fontSize: FONT_SIZES.xxl, fontWeight: 800, pt: 1 }}>
          {subtitle}
        </Typography>
      </Grid>
    </>
  );
}
