import { Container, Typography } from '@mui/material';

import { State } from '@/types';
import { PageTitle } from '@/view/components/PageTitle';
import { STYLES } from '@/view/styling/theme';

export function Register({ state }: { state: State }) {
  return (
    <div>
      <PageTitle text={'Home'} />
      <Container>
        <Typography mb={3} sx={{ ...STYLES.mainHeading, textAlign: 'left' }}>
          Content...
        </Typography>
      </Container>
    </div>
  );
}
