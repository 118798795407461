import { Box, Typography } from '@mui/material';

import { STYLES } from '@/view/styling/theme';

type Props = {
  amount: string;
  description?: string;
  marginLeft?: string | number;
};

export function DiscountLabel({
  amount,
  description,
  marginLeft = '0px',
}: Props) {
  return (
    <Box
      sx={{
        ...STYLES.discountTag.discountTagWrapper,
        marginLeft,
      }}
    >
      <Typography variant="body2" sx={STYLES.discountTag.discountAmount}>
        {amount}%OFF
      </Typography>
      <Typography variant="caption" sx={STYLES.discountTag.description}>
        {description}
      </Typography>
    </Box>
  );
}
