import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Typography, IconButton } from '@mui/material';

import { STYLES } from '@/view/styling/theme';

type Props = {
  onClickBack: () => void;
  text: string;
  variant?: 'borderless' | 'border-bottom';
};

export function PageTitleWithBackButton({ text, onClickBack }: Props) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        borderBottom: 1,
        borderColor: 'grey.100',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 3,
        position: 'relative',
        width: '100%',
      }}
    >
      <IconButton onClick={onClickBack} sx={{ left: 8, position: 'absolute' }}>
        <ArrowBackIcon />
      </IconButton>
      <Typography
        variant="h6"
        sx={{ ...STYLES.pageTitleWithBackButton, textAlign: 'center' }}
      >
        {text}
      </Typography>
    </Box>
  );
}
