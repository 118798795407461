import { Box, Button, Grid, Typography } from '@mui/material';
import { useRef, useEffect } from 'react';

import { CONTACT_FORM_ELEMENT_ID, FAQ_PAGE, TOP_PAGE } from '@/constants';
import { State } from '@/types';
import { data } from '@/view/components/Accordion/faqData';
import { NestedAccordion } from '@/view/components/Accordion/NestedAccordion';
import { PageTitle } from '@/view/components/PageTitle';
import { SpeechBubble } from '@/view/components/SpeechBubble';
import { TextHighlight } from '@/view/components/TextHighlight';
import { STYLES, LINE_HEIGHTS, FONT_SIZES } from '@/view/styling/theme';

const CONSTANTS = TOP_PAGE.INFORMATION;

export function Faq({ state }: { state: State }) {
  const contactFormRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (location.hash && location.hash.includes('contact')) {
      contactFormRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);

  return (
    <div>
      <PageTitle text={FAQ_PAGE.PAGE_TITLE} variant="border-bottom" />
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ margin: '16px' }}>
            <SpeechBubble
              style={{
                backgroundColor: '#F3F5F7',
                borderRadius: '10px',
                padding: '16px',
                width: '100%',
              }}
            >
              <TextHighlight
                commonTextStyle={{
                  fontSize: FONT_SIZES.sm,
                  fontWeight: 400,
                  lineHeight: LINE_HEIGHTS.md,
                }}
                text={FAQ_PAGE.DESCRIPTION_1}
              />
            </SpeechBubble>
            <img
              style={{
                height: 'auto',
                marginTop: '-20px',
                maxWidth: '290px',
              }}
              src={CONSTANTS.BODY_CAPTION_1_FOOTER.BODY_IMAGE_1}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ pb: 3 }}>
          <NestedAccordion data={data} />
        </Grid>

        <Grid item xs={12} ref={contactFormRef} id={CONTACT_FORM_ELEMENT_ID}>
          <PageTitle text={FAQ_PAGE.CONTACT_TITLE} variant="borderless" />
          <Grid item xs={12}>
            <Box sx={{ margin: '16px' }}>
              <SpeechBubble
                style={{
                  backgroundColor: '#F3F5F7',
                  borderRadius: '10px',
                  padding: '16px',
                  width: '100%',
                }}
              >
                <TextHighlight
                  commonTextStyle={{
                    fontSize: FONT_SIZES.sm,
                    fontWeight: 400,
                    lineHeight: LINE_HEIGHTS.md,
                  }}
                  text={FAQ_PAGE.DESCRIPTION_2}
                />
              </SpeechBubble>
              <img
                style={{
                  height: 'auto',
                  marginTop: '-20px',
                  maxWidth: '290px',
                }}
                src={CONSTANTS.BODY_CAPTION_1_FOOTER.BODY_IMAGE_1}
              />
            </Box>
          </Grid>
          <Grid container sx={{ p: 2 }}>
            <Button
              size="large"
              variant="outlined"
              fullWidth
              sx={{
                ...STYLES.topPage.secondaryButton,
                marginBottom: 2,
                marginTop: 0,
              }}
              href={'https://id.meiji.co.jp/contact/input'}
            >
              <Typography
                sx={{
                  color: 'text.primary',
                  fontSize: FONT_SIZES.md,
                  fontWeight: 300,
                  lineHeight: LINE_HEIGHTS.md,
                }}
              >
                {FAQ_PAGE.CONTACT_BUTTON}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
