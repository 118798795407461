import { Typography, Link, Box } from '@mui/material';
import _ from 'lodash';

import { AdviceElement } from '@/types/network';
import { FONT_SIZES, LINE_HEIGHTS } from '@/view/styling/theme';

type Props = {
  description?: string | AdviceElement[];
};
export function AdviceItemTemplates({ description }: Props) {
  if (!_.isArray(description)) {
    return (
      <Typography
        sx={{
          fontSize: FONT_SIZES.md,
          lineHeight: LINE_HEIGHTS.xl,
          whiteSpace: 'pre-wrap',
        }}
      >
        {description}
      </Typography>
    );
  }
  return (
    <>
      {description.map((item, index) => {
        switch (item.type) {
          case 'image':
            return (
              <Box
                key={index}
                sx={{
                  borderRadius: 3,
                  marginBottom: 2,
                  overflow: 'hidden',
                }}
              >
                <img src={item.src} alt={item.alt} width="100%" />
              </Box>
            );
          case 'title':
            return (
              <Typography
                key={index}
                variant="h2"
                sx={{
                  lineHeight: LINE_HEIGHTS.xl,
                  marginBottom: 2,
                }}
              >
                {item.text}
              </Typography>
            );
          case 'link':
            return (
              <Link
                key={index}
                sx={{
                  borderColor: 'grey.100',
                  borderRadius: 1.5,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  display: 'block',
                  fontSize: FONT_SIZES.md,
                  marginY: 2,
                  padding: 1,
                  textAlign: 'center',
                  textDecoration: 'none',
                }}
                href={item.href}
                target="_blank"
              >
                {item.text}
              </Link>
            );
          case 'paragraph':
          default:
            return (
              <Typography
                key={index}
                sx={{
                  display: 'block',
                  fontSize: FONT_SIZES.md,
                  lineHeight: LINE_HEIGHTS.xl,
                  marginBottom: 2,
                  whiteSpace: 'pre-wrap',
                }}
              >
                {item.text}
              </Typography>
            );
        }
      })}
    </>
  );
}
