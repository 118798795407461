import { Grid, List, Typography, ListItem } from '@mui/material';

import { TEASER_PAGE } from '@/constants';
import { TeaserSectionTitle } from '@/view/components/Teaser/TeaserSectionTitle';
import { theme } from '@/view/styling/theme';

const cautions = TEASER_PAGE.CAUTION;
interface ContentProps {
  contents: {
    link?: string;
    subContent?: { link?: string; text: string }[];
    text: string;
  }[];
  parentKey: string;
}
interface SubContentProps {
  contents: { link?: string; text: string }[];
  parentKey: string;
}
function TextOrHyperLink({ text, link }: { link?: string; text: string }) {
  return link ? (
    <a
      href={link}
      style={{
        color: theme.palette.text.primary,
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '28.8px',
        textDecoration: 'underline',
        textDecorationColor: theme.palette.text.primary,
      }}
      target="_blank"
      rel="noopener "
    >
      {text}
    </a>
  ) : (
    <span
      style={{
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '28.8px',
      }}
    >
      {text}
    </span>
  );
}

function SubContent({ contents, parentKey }: SubContentProps) {
  return (
    <Typography sx={{ display: 'block', pl: 2 }}>
      {contents.map((content, index) => (
        <TextOrHyperLink
          key={`${parentKey}-subContent-${index}`}
          link={content.link}
          text={content.text}
        />
      ))}
    </Typography>
  );
}
function Content({ contents, parentKey }: ContentProps) {
  return (
    <Typography key={`${parentKey}-content-wrapper`}>
      {contents.map((content, contentIndex) => (
        <span key={`${parentKey}-content-${contentIndex}`}>
          <TextOrHyperLink link={content.link} text={content.text} />
          {content.subContent && (
            <SubContent parentKey={parentKey} contents={content.subContent} />
          )}
        </span>
      ))}
    </Typography>
  );
}
export function TeaserCaution() {
  return (
    <Grid
      container
      sx={{
        background: '#F8F6F4',
        justifyContent: 'center',
        px: 4,
        py: 5,
        textAlign: 'center',
      }}
    >
      <TeaserSectionTitle title={'Caution'} subtitle={'注意事項'} />
      <Grid item xs={12}>
        <List>
          {cautions.map((caution, index) => (
            <ListItem disableGutters disablePadding key={`list-item-${index}`}>
              <Content
                parentKey={`list-item-${index}`}
                contents={caution.content}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
