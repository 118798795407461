import { Button, Divider, Grid, Typography } from '@mui/material';

import { DISCOUNTED_PRODUCT_LIST_PAGE } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { ShippingLabel } from '@/view/components/ShippingLabel';
import { SpeechBubble } from '@/view/components/SpeechBubble';
import { TeaserSectionTitle } from '@/view/components/Teaser/TeaserSectionTitle';
import { STYLES } from '@/view/styling/theme';
const { MEIJI_SHOPIFY_CART_PRODUCTION_URL } = import.meta.env;

export function DiscountedProduct() {
  const product = DISCOUNTED_PRODUCT_LIST_PAGE;

  return (
    <Grid container sx={{ background: 'white', marginTop: '24px' }}>
      <TeaserSectionTitle title={'Product'} subtitle={`初回お試しセット`} />

      <Grid container direction="column" marginX={2}>
        <Grid item sx={{ marginTop: '16px' }}>
          <Grid item xs={12}>
            <img
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              src={product.IMAGE_PATH}
              alt={`${product.TITLE} 画像`}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginX: '0px', mt: 2 }}>
            <Typography component="h3" sx={STYLES.productListItem.title}>
              {product.TITLE}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginX: '0px', mt: 2 }}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sx={STYLES.productListItem.priceSectionWrapper}
              >
                <Typography
                  variant="body1"
                  sx={{
                    ...STYLES.productListItem.priceItemWrapper,
                    fontFamily: 'Roboto',
                  }}
                >
                  <span style={{ textDecoration: 'line-through' }}>
                    {product.PRICE}
                  </span>
                  <span
                    style={{
                      ...STYLES.productListItem.purchaseTaxText,
                      textDecoration: 'none',
                    }}
                  >
                    ({product.PRICE_TAX_TEXT})
                  </span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  marginBottom: '-16px',
                  marginLeft: '-6px',
                  marginTop: '-12px',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '24px',
                    fontWeight: 900,
                    lineHeight: '43.2px',
                  }}
                >
                  ↓
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sx={STYLES.productListItem.priceSectionWrapper}
              >
                <Typography
                  variant="body1"
                  sx={{
                    ...STYLES.productListItem.priceItemWrapper,
                    fontFamily: 'Roboto',
                  }}
                >
                  <span
                    style={{
                      color: '#FF8718',
                    }}
                  >
                    {product.DISCOUNTED_PRICE}
                  </span>
                  <span
                    style={{
                      ...STYLES.productListItem.purchaseTaxText,
                      color: '#2F2F2F',
                    }}
                  >
                    ({product.PRICE_TAX_TEXT})
                  </span>
                </Typography>
                <ShippingLabel
                  amount={product.SHIPPING_AMOUNT}
                  marginLeft={'16px'}
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: '8px', pb: 2 }}>
                <img
                  src={'/images/teaser/discount_coupon.svg'}
                  style={{
                    backgroundColor: 'rgba(255, 84, 0, 0.06)',
                    borderRadius: '6px',
                    width: '100%',
                  }}
                />
              </Grid>

              <Grid item xs={12} sx={{ marginX: '0px' }}>
                <Button
                  size="large"
                  variant="contained"
                  sx={{
                    ...STYLES.secondaryButtonBottomPage,
                    color: 'white',
                    height: '52px',
                  }}
                  fullWidth
                  onClick={() =>
                    user.navigateToExternal(MEIJI_SHOPIFY_CART_PRODUCTION_URL)
                  }
                >
                  <Typography
                    sx={{
                      ...STYLES.productListItem.nextButtonLabel,
                      color: 'white',
                    }}
                  >
                    {product.BUTTON_TEXT}
                  </Typography>
                </Button>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  alignContent: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  marginX: 2,
                  mt: 4,
                }}
              >
                <Typography
                  component="h5"
                  sx={STYLES.productListItem.sellerText}
                >
                  {product.SELLER_TEXT}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: 4, width: '100%' }} />
              </Grid>

              <Grid item xs={12} sx={{ mb: 4 }}>
                <SpeechBubble
                  text={product.SPEECH_BUBBLE_TEXT}
                  textStyle={{
                    color: 'text.primary',
                    display: 'flex',
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '26.56px',
                    textAlign: 'center',
                  }}
                  style={{
                    backgroundColor: '#FFF1E3',
                    borderRadius: '12px',
                    width: '100%',
                  }}
                  tipSize="12px"
                />
                <img
                  style={{ width: '80%' }}
                  src={product.SPEECH_BUBBLE_IMAGE_PATH}
                />
              </Grid>

              <BacterialTypeProducts bacterialTypes={product.BACTERIAL_TYPES} />

              <Grid item xs={12} sx={{ mb: '40px' }}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 300,
                    lineHeight: '19.6px',
                  }}
                >
                  商品詳細は、
                  <a
                    href="/products/allergy-ingredient"
                    style={{ color: '#0066C0', textDecoration: 'underline' }}
                  >
                    こちら
                  </a>
                  をご確認ください。
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function BacterialTypeProducts({
  bacterialTypes,
}: {
  bacterialTypes: { IMAGE_PATH: string; TEXT: string; TITLE: string }[];
}) {
  return (
    <>
      {bacterialTypes.map((bacterialType) => (
        <Grid
          container
          item
          xs={12}
          sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}
          key={bacterialType.TITLE}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '124px',
            }}
          >
            <img
              src={bacterialType.IMAGE_PATH}
              style={{ height: '100px', width: '100px' }}
              alt={bacterialType.TITLE}
            />
          </Grid>
          <Grid
            container
            item
            direction="column"
            sx={{ width: 'calc(100% - 124px)' }}
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '22.4px',
                }}
              >
                {bacterialType.TITLE}
              </Typography>
            </Grid>
            <Grid item sx={{ lineHeight: '22.4px' }}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                }}
              >
                {`タイプ`}
              </Typography>
            </Grid>
            <Grid item sx={{ lineHeight: '19.6px', mt: 1 }}>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 300,
                }}
              >
                {bacterialType.TEXT}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
