import {
  Dialog,
  DialogTitle,
  Box,
  DialogContentText,
  Backdrop,
  CircularProgress,
  DialogActions,
} from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import { ReactNode } from 'react';

import { FONT_SIZES, STYLES } from '@/view/styling/theme';

export function Modal(
  props: Partial<DialogProps> & {
    actions?: ReactNode;
    backgroundColor?: string;
    children?: ReactNode;
    hasStickyTitle?: boolean;
    isLoading?: boolean;
    textContent?: string | ReactNode;
    titleAlignment?: 'center' | 'left' | 'right';
  }
) {
  const {
    textContent,
    children,
    backgroundColor = 'common.white',
    isLoading,
    hasStickyTitle,
    actions,
    titleAlignment = 'center', // FIXME: We don't have time but this should be DialogTitleSxProps
    ...dialogProps
  } = props;
  const stickyStyles = hasStickyTitle ? { position: 'sticky', top: 0 } : {};
  const actionStyles = actions ? { marginBottom: 0, overflow: 'scroll' } : {};
  return (
    <Dialog
      open={!!props.open}
      {...dialogProps}
      PaperProps={{
        sx: {
          ...STYLES.customModal,
          backgroundColor,
        },
      }}
    >
      <DialogTitle
        sx={{
          ...stickyStyles,
          fontSize: FONT_SIZES.md,
          fontWeight: 600,
          lineHeight: '25px',
          paddingTop: 3,
          textAlign: titleAlignment,
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
        }}
      >
        {props.title}
      </DialogTitle>
      <Box
        sx={{
          marginBottom: 3,
          marginX: 3,
          ...actionStyles,
        }}
      >
        <Backdrop sx={STYLES.whiteBackdrop} open={isLoading || false}>
          <CircularProgress />
        </Backdrop>
        {textContent ? (
          <DialogContentText
            component="div"
            sx={{
              marginBottom: 3,
              marginTop: -2,
              textAlign: 'center',
            }}
          >
            <>{textContent}</>
          </DialogContentText>
        ) : null}
        {children ? <>{children}</> : null}
      </Box>
      {actions ? (
        <DialogActions
          sx={{
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            paddingBottom: 4,
            paddingTop: 2,
            paddingX: 2,
          }}
        >
          {actions}
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
