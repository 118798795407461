import { Grid, Container, Box, Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';

import {
  MY_PAGE_REPORT,
  MY_PAGE_REPORT_CHALLENGES,
  TOP_PAGE,
} from '@/constants';
import * as user from '@/domain/middlewares/user';
import { State } from '@/types';
import { ChallengeAccordion } from '@/view/components/Accordion/ChallengeAccordion';
import { PageTitleWithBackButton } from '@/view/components/PageTitleWithBackButton';
import { ReportTabs, ReportTabId } from '@/view/components/Report/ReportTabs';
import { SpeechBubble } from '@/view/components/SpeechBubble';
import { TextHighlight } from '@/view/components/TextHighlight';
import { FONT_SIZES, LINE_HEIGHTS } from '@/view/styling/theme';

const CONSTANTS = TOP_PAGE.INFORMATION;

export function MyPageReportChallenges({ state }: { state: State }) {
  const testCompletedAt = dayjs(
    state.selectedAssessment?.testCompletedAt
  ).format('YYYY年M月D日');

  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  const filteredChallenges = activeCategory
    ? MY_PAGE_REPORT_CHALLENGES.filter(
        (challenge) => challenge.categoryTitle === activeCategory
      )
    : MY_PAGE_REPORT_CHALLENGES;

  useEffect(() => {
    if (activeCategory) {
      const activeElement = document.getElementById(
        `filter-button-${activeCategory}`
      );
      activeElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    } else {
      const defaultElement = document.getElementById(`filter-button-all`);
      defaultElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [activeCategory]);

  return (
    <div>
      <PageTitleWithBackButton
        text={testCompletedAt}
        onClickBack={() => user.navigateTo('/my-page/reports')}
      />
      <ReportTabs
        current={ReportTabId.CHALLENGES}
        onChange={user.onClickMyPageReports}
      />
      <Container sx={{ backgroundColor: 'grey.50', mt: '28px' }}>
        <Box
          sx={{
            height: '60px',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              overflow: 'auto',
              position: 'absolute',
              py: 2,
            }}
          >
            <Grid container flexWrap="nowrap" gap={'8px'}>
              <Grid item flex="0 0 auto" id="filter-button-all">
                <FilterButton
                  isActive={activeCategory === null}
                  onClick={() => setActiveCategory(null)}
                >
                  すべて
                </FilterButton>
              </Grid>
              {MY_PAGE_REPORT_CHALLENGES.map((challenge, index) => (
                <Grid
                  item
                  flex="0 0 auto"
                  key={index}
                  id={`filter-button-${challenge.categoryTitle}`}
                >
                  <FilterButton
                    isActive={activeCategory === challenge.categoryTitle}
                    onClick={() => setActiveCategory(challenge.categoryTitle)}
                  >
                    {challenge.categoryTitle}
                  </FilterButton>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        <Grid item xs={12}>
          <Typography fontSize={FONT_SIZES.lg} variant="h2">
            {activeCategory
              ? activeCategory
              : MY_PAGE_REPORT.CHALLENGE_SECTION_TITLE}
          </Typography>
        </Grid>
        <Box>
          <SpeechBubble
            style={{
              backgroundColor: 'white',
              borderRadius: '10px',
              p: '16px',
              width: '100%',
            }}
          >
            <TextHighlight
              commonTextStyle={{
                fontSize: FONT_SIZES.sm,
                fontWeight: 400,
                lineHeight: LINE_HEIGHTS.md,
              }}
              text={MY_PAGE_REPORT.MISSION_BUBBLE_TEXT}
            />
          </SpeechBubble>
          <img
            style={{
              height: 'auto',
              marginBottom: '32px',
              marginTop: '-20px',
              maxWidth: '290px',
            }}
            src={CONSTANTS.BODY_CAPTION_1_FOOTER.BODY_IMAGE_1}
          />
        </Box>
        <Box sx={{ pb: '16px' }}>
          {filteredChallenges.map((challengeCategory, index) => (
            <ChallengeAccordion
              key={index}
              challenges={challengeCategory.children}
              categoryTitle={challengeCategory.categoryTitle}
            />
          ))}
        </Box>
      </Container>
    </div>
  );
}

interface FilterButtonProps {
  children: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
}

function FilterButton({ isActive, onClick, children }: FilterButtonProps) {
  const backgroundColor = isActive ? 'orange' : 'white';
  const color = isActive ? 'white' : 'black';
  const borderColor = isActive ? 'orange' : 'grey.100';

  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        '&:hover': {
          backgroundColor,
          color,
        },
        backgroundColor,
        borderColor,
        borderRadius: '99999px',
        color,
        fontSize: FONT_SIZES.sm,
        fontWeight: 'normal',
      }}
    >
      {children}
    </Button>
  );
}
