import { LogLevel } from 'consola';
import consola from 'consola/src/browser';

/**
 * Create logger
 *
 * @param name - scope name
 * @returns - consola instance
 */
export function createLogger(name: string) {
  const log = consola.create({
    level: import.meta.env.PROD ? LogLevel.Silent : LogLevel.Debug,
  });
  return log.withTag(name);
}
