import { Box, Grid, Typography } from '@mui/material';

import { TOP_PAGE } from '@/constants';
import { SpeechBubble } from '@/view/components/SpeechBubble';
import { TextHighlight } from '@/view/components/TextHighlight';
import { STYLES, FONT_SIZES, LINE_HEIGHTS } from '@/view/styling/theme';

const CONSTANTS = TOP_PAGE.INFORMATION;

export function Information() {
  return (
    <Grid container rowSpacing={4} sx={STYLES.topPage.information.grid}>
      <Grid item marginTop={5}>
        <TextHighlight
          text={CONSTANTS.BODY_CAPTION_1}
          commonTextStyle={STYLES.topPage.information.header}
        />
      </Grid>
      <Grid item>
        <Box>
          <SpeechBubble>
            <TextHighlight
              commonTextStyle={{
                fontSize: FONT_SIZES.sm,
                lineHeight: LINE_HEIGHTS.md,
              }}
              text={CONSTANTS.BODY_CAPTION_1_FOOTER.BODY_LINE_1}
            />
          </SpeechBubble>
          <img
            style={{ width: '85%' }}
            src={CONSTANTS.BODY_CAPTION_1_FOOTER.BODY_IMAGE_1}
          />
        </Box>
      </Grid>
      <Grid item container direction={'column'} rowSpacing={2}>
        <Grid item width="100%">
          <img style={{ width: '100%' }} src={CONSTANTS.BODY_IMAGE_1} />
        </Grid>
        <Grid item>
          <Typography sx={STYLES.topPage.information.description}>
            {CONSTANTS.BODY_LINE_2}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction={'column'} rowSpacing={2}>
        <Grid item width="100%">
          <img style={{ width: '100%' }} src={CONSTANTS.BODY_IMAGE_2} />
        </Grid>
        <Grid item>
          <Typography sx={STYLES.topPage.information.description}>
            {CONSTANTS.BODY_LINE_1}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction={'column'} rowSpacing={2}>
        <Grid item width="100%">
          <img style={{ width: '100%' }} src={CONSTANTS.BODY_IMAGE_3} />
        </Grid>
        <Grid item>
          <Typography sx={STYLES.topPage.information.description}>
            {CONSTANTS.BODY_LINE_3}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        marginBottom={5}
        rowSpacing={3}
        sx={{ justifyContent: 'center' }}
      >
        <Grid item>
          <TextHighlight
            text={CONSTANTS.BODY_CAPTION_2}
            commonTextStyle={STYLES.topPage.information.header}
          />
        </Grid>
        <Grid item width="100%">
          <SpeechBubble text={CONSTANTS.BODY_LINE_4} />
          <img style={{ width: '80%' }} src={CONSTANTS.BODY_IMAGE_4} />
          <Box sx={{ pt: 3 }}>
            <Typography sx={{ fontSize: FONT_SIZES.xs }}>
              {CONSTANTS.REMARK}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
