import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  CardMedia,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import React from 'react';

import { FONT_SIZES, STYLES } from '@/view/styling/theme';

type Product = {
  additionalInfoFirstLabel: string;
  additionalInfoFirstValue: string;
  additionalInfoSecondLabel: string;
  additionalInfoSecondValue: string;
  allergens: string;
  bestBefore: string;
  carbohydrates: string;
  contents: string;
  designation: string;
  dietaryFiber: string;
  energy: string;
  fat: string;
  id: number;
  imageUrl: string;
  ingredients: string;
  name: string;
  protein: string;
  saltEquivalent: string;
  storageMethod: string;
  sugar: string;
};

/**
 * AddIcon component renders a square image encasing the "+" symbol.
 *
 * @returns {JSX.Element} The AddIcon component.
 */
function AddIcon(): JSX.Element {
  return <img src="/images/products/plus-icon.png" alt="add" />;
}

/**
 * RemoveIcon component renders a square image encasing the "-" symbol.
 *
 * @returns {JSX.Element} The AddIcon component.
 */
function RemoveIcon(): JSX.Element {
  return <img src="/images/products/minus-icon.png" alt="remove" />;
}

/**
 * ProductInfoRow component renders a row with a label and value, with configurable styles.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.label - The label text.
 * @param {string} props.value - The value text.
 * @param {number} [props.firstColumnWidth=100] - The width of the first column in pixels. Defaults to 100.
 * @param {string | undefined} [props.backgroundColor] - The background color of the columns. If undefined, a default background color value is used.
 * @param {string | undefined} [props.padding="8px"] - The padding inside the columns. If undefined, defaults to 8px.
 *
 * @returns {JSX.Element} The ProductInfoRow component.
 */
export function ProductInfoRow({
  label,
  value,
  subValue,
  firstColumnWidth = 100,
  backgroundColor = undefined,
  padding = '8px',
}: {
  backgroundColor?: string;
  firstColumnWidth?: number;
  label: string;
  padding?: string;
  subValue?: string;
  value: string;
}): JSX.Element {
  return (
    <Grid container sx={STYLES.productInfoRowGridContainer}>
      <Grid item sx={{ flexShrink: 0, width: `${firstColumnWidth}px` }}>
        <Box
          sx={{
            ...STYLES.productInfoRowBox,
            backgroundColor: backgroundColor || '#EDEFF2',
            padding,
          }}
        >
          <Typography sx={STYLES.tableSectionTitle}>{label}</Typography>
        </Box>
      </Grid>
      <Grid item sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            ...STYLES.productInfoRowBox,
            backgroundColor: backgroundColor || '#F8F8F8',
            display: 'flex-column',
            padding,
          }}
        >
          <Typography
            sx={STYLES.tableSectionTitle}
            style={{ whiteSpace: 'pre-line' }}
          >
            {value}
          </Typography>
          {subValue && (
            <Typography
              sx={{
                display: 'block',
                fontSize: FONT_SIZES.xxs,
                marginTop: '5px',
              }}
              style={{ whiteSpace: 'pre-line' }}
            >
              {subValue}
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

/**
 * ProductAccordion component renders an accordion for a product with various details.
 * Pressing the "+" icon (AddIcon) expands the accordion, showing detailed product information.
 * Pressing the "-" icon (RemoveIcon) collapses the accordion, hiding the detailed product information.
 *
 * @param {Object} props - The properties object.
 * @param {Product} props.product - The product object containing various details.
 *
 * @returns {JSX.Element} The ProductAccordion component.
 */
export function ProductAccordion({
  product,
}: {
  product: Product;
}): JSX.Element {
  const [isExpanded, setIsExpanded] = React.useState(false);

  function handleExpandClick() {
    setIsExpanded(!isExpanded);
  }

  const isFirstProduct = product.id === 1;

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleExpandClick}
      disableGutters
      sx={{
        ...STYLES.productAccordion,
        // Fixes issue where the top border to a product row does not display when isExpanded
        borderTop:
          isExpanded && !isFirstProduct
            ? '1px solid rgba(0, 0, 0, 0.12)'
            : 'none',
      }}
    >
      <AccordionSummary
        expandIcon={
          <IconButton aria-label="expand" sx={{ padding: 0 }}>
            {isExpanded ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ margin: 0, padding: 0 }}
      >
        <Box sx={STYLES.fullWidthCenterAlignedFlexbox}>
          <CardMedia
            component="img"
            image={product.imageUrl}
            alt={product.name}
            sx={STYLES.productAccordionImage}
          />
          <Box sx={STYLES.productNameBox}>
            <Typography sx={STYLES.productNameTypography}>
              {product.name}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={STYLES.productAccordionDetails}>
        <Grid container item xs={12}>
          {/* ＜Product Overview＞ */}
          <Grid item xs={12} sx={{ pb: 1 }}>
            <Typography sx={STYLES.tableSectionTitle}>＜商品概要＞</Typography>
          </Grid>
          <Grid item container xs={12}>
            {/* Designation */}
            <ProductInfoRow label="名称" value={product.designation} />
            {/* Contents */}
            <ProductInfoRow label="内容量" value={product.contents} />
            {/* Ingredients */}
            <ProductInfoRow label="原材料名" value={product.ingredients} />
            {/* Best before */}
            <ProductInfoRow label="賞味期限" value={product.bestBefore} />
            {/* Storage method */}
            <ProductInfoRow label="保存方法" value={product.storageMethod} />
          </Grid>

          {/* ＜Nutritional information per bottle (125ml)＞ */}
          <Grid item xs={12} sx={{ pb: 1, pt: 2 }}>
            <Typography sx={STYLES.tableSectionTitle}>
              ＜栄養成分表示１本（125ml）当たり＞
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            {/* Energy */}
            <ProductInfoRow label="エネルギー" value={product.energy} />
            {/* Protein */}
            <ProductInfoRow label="たんぱく質" value={product.protein} />
            {/* Fat */}
            <ProductInfoRow label="脂質" value={product.fat} />
            {/* Carbohydrates */}
            <ProductInfoRow label="炭水化物" value={product.carbohydrates} />
            {/* －Sugar */}
            <ProductInfoRow label="－糖質" value={product.sugar} />
            {/* －Dietary fiber */}
            <ProductInfoRow label="－食物繊維" value={product.dietaryFiber} />
            {/* Salt equivalent */}
            <ProductInfoRow label="食塩相当量" value={product.saltEquivalent} />
          </Grid>

          {/* ＜Additional nutritional information */}
          <Grid item container xs={12} sx={{ pb: 2 }}>
            {/* First info line */}
            <ProductInfoRow
              label={product.additionalInfoFirstLabel}
              value={product.additionalInfoFirstValue}
              firstColumnWidth={130}
              backgroundColor="white"
              padding="2px"
            />
            {/* Second info line */}
            <ProductInfoRow
              label={product.additionalInfoSecondLabel}
              value={product.additionalInfoSecondValue}
              firstColumnWidth={130}
              backgroundColor="white"
              padding="2px"
            />
          </Grid>

          {/* Allergen info */}
          <Grid item container xs={12}>
            {/* Allergens (specific ingredients, etc.) */}
            <ProductInfoRow
              label="アレルギー物質 (特定原材料等）"
              value={product.allergens}
              firstColumnWidth={130}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
