import { Container, Grid } from '@mui/material';
import _ from 'lodash';

import { FOOTER, TOP_BAR, GREY_PAGES } from '@/constants';
import * as USER from '@/domain/middlewares/user';
import { getState } from '@/domain/store';
import { AppRoute } from '@/types';
import { MeijiFooter } from '@/view/components/Footer/Footer';
import { Loading } from '@/view/components/Loading';
import { TopBar } from '@/view/components/TopBar';
import { AllergyIngredientProducts } from '@/view/pages/AllergyIngredientProducts';
import { AssessmentAddByScan } from '@/view/pages/AssessmentAddByScan';
import { BarcodeScanAttempt } from '@/view/pages/AssessmentBarcodeScanAttempt';
import { AssessmentCollectionInstructions } from '@/view/pages/AssessmentCollectionInstructions';
import { AssessmentPostageInstruction } from '@/view/pages/AssessmentPostageInstructions';
import { AssessmentSampleCollection } from '@/view/pages/AssessmentSampleCollection';
import { Column } from '@/view/pages/Column';
import { ColumnList } from '@/view/pages/ColumnList';
import { Concept } from '@/view/pages/Concept';
import { CustomerFeedback } from '@/view/pages/CustomerFeedback';
import { Faq } from '@/view/pages/Faq';
import { Login } from '@/view/pages/Login';
import { MyPage } from '@/view/pages/MyPage';
import { MyPageReportChallenges } from '@/view/pages/MyPageReportChallenges';
import { MyPageReportList } from '@/view/pages/MyPageReportList';
import { MyPageReports } from '@/view/pages/MyPageReports';
import { NotFound } from '@/view/pages/NotFound';
import { ProductDetailCheckKit } from '@/view/pages/ProductDetailCheckKit';
import { ProductDetailDrink } from '@/view/pages/ProductDetailDrink';
import { ProductDetailFirstSet } from '@/view/pages/ProductDetailFirstSet';
import { ProductList } from '@/view/pages/ProductList';
import { PurchaseHistory } from '@/view/pages/PurchaseHistory';
import { Register } from '@/view/pages/Register';
import { RegisterComplete } from '@/view/pages/RegisterComplete';
import { Survey } from '@/view/pages/Survey';
import { Teaser } from '@/view/pages/Teaser';
import { Top } from '@/view/pages/Top';
import { STYLES } from '@/view/styling/theme';

import { isTeaserRoute } from './domain/utils';

function App() {
  const state = getState();
  const router = (route: AppRoute | null) => {
    switch (route) {
      case AppRoute.NOT_FOUND:
        return <NotFound />;
      case AppRoute.REGISTER_COMPLETE:
        return <RegisterComplete state={state} />;
      case AppRoute.REGISTER:
        return <Register state={state} />;
      case AppRoute.BARCODE_SCAN:
        return <AssessmentAddByScan state={state} />;
      case AppRoute.BARCODE_SCAN_ATTEMPT:
        return <BarcodeScanAttempt state={state} />;
      case AppRoute.TEST_KIT_PREPARE_COLLECTION:
        return <AssessmentSampleCollection state={state} />;
      case AppRoute.TEST_KIT_COLLECTION_INSTRUCTIONS:
        return <AssessmentCollectionInstructions state={state} />;
      case AppRoute.TEST_KIT_POSTAGE_INSTRUCTIONS:
        return <AssessmentPostageInstruction state={state} />;
      case AppRoute.MY_PAGE:
        return <MyPage state={state} />;
      case AppRoute.FAQ:
        return <Faq state={state} />;
      case AppRoute.MY_PAGE_REPORTS:
        return <MyPageReports state={state} />;
      case AppRoute.MY_PAGE_REPORT_CHALLENGES:
        return <MyPageReportChallenges state={state} />;
      case AppRoute.MY_PAGE_REPORTS_LIST:
        return <MyPageReportList state={state} />;
      case AppRoute.DRINK:
        return <ProductDetailDrink state={state} />;
      case AppRoute.SURVEY_PAGE:
        return <Survey state={state} />;
      case AppRoute.TEASER:
        return <Teaser state={state} />;
      case AppRoute.TOP:
        return <Top state={state} />;
      case AppRoute.LOGIN:
        return <Login state={state} />;
      case AppRoute.COLUMN:
        return <Column state={state} />;
      case AppRoute.COLUMN_LIST:
        return <ColumnList state={state} />;
      case AppRoute.CUSTOMER_FEEDBACK:
        return <CustomerFeedback state={state} />;
      case AppRoute.PURCHASE_HISTORY:
        return <PurchaseHistory state={state} />;
      case AppRoute.PRODUCTS:
        return <ProductList state={state} />;
      case AppRoute.FIRST_SET:
        return <ProductDetailFirstSet state={state} />;
      case AppRoute.CHECK_KIT:
        return <ProductDetailCheckKit state={state} />;
      case AppRoute.ALLERGY_INGREDIENT_PRODUCTS:
        return <AllergyIngredientProducts state={state} />;
      case AppRoute.CONCEPT:
        return <Concept state={state} />;

      default:
        // TODO: should show skeleton
        return <></>;
    }
  };
  const SHOW_MENU_ROUTES = isTeaserRoute(window.location.pathname)
    ? []
    : TOP_BAR.SHOW_MENU;
  return (
    <Container maxWidth="md" disableGutters fixed>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        sx={{
          bgcolor: GREY_PAGES.includes(state.currentRoute)
            ? 'grey.50'
            : 'common.white',
          minHeight: '100vh',
        }}
      >
        <Grid item>
          <TopBar
            state={state}
            img={'/images/top-page/logo.svg'}
            showMenu={SHOW_MENU_ROUTES.includes(
              `${_.get(state, 'currentRoute')}`
            )}
            isLoggedIn={state.user.token !== ''}
          />
        </Grid>
        <Grid item>{router(state.currentRoute)}</Grid>
        <Grid item sx={STYLES.bottomAlignFooter}>
          <MeijiFooter
            type={
              FOOTER.LARGE_FOOTER_ROUTE.includes(
                `${_.get(state, 'currentRoute')}`
              )
                ? 'large'
                : 'small'
            }
            showLogout={state.user.token !== ''}
            links={[
              {
                name: FOOTER.TERMS_LINK_TEXT,
                url: _.get(state, 'termsUrl') || FOOTER.TERMS_LINK_URL,
              },
              ...FOOTER.LINKS,
            ]}
            onClickLogout={USER.onClickLogout}
          />
        </Grid>
      </Grid>
      <Loading isLoading={state.loading.applicationIsLoading} />
    </Container>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
