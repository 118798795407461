import { Box, Grid, SxProps, Theme, Typography } from '@mui/material';
import { CSSProperties } from 'react';

import { FONT_SIZES, LINE_HEIGHTS, STYLES, theme } from '@/view/styling/theme';

type Props = {
  children?: JSX.Element;
  style?: SxProps<Theme>;
  subText?: string;
  text?: string;
  textStyle?: SxProps<Theme>;
  tipSize?: string;
};

const defaultTextStyle = {
  fontSize: FONT_SIZES.md,
  fontWeight: 800,
  lineHeight: LINE_HEIGHTS.lg,
};

const tip: CSSProperties = {
  background: 'transparent',
  borderBottomColor: 'transparent',
  borderLeftColor: 'transparent',
  borderRightColor: 'transparent',
};

export function SpeechBubble({
  text,
  textStyle,
  children,
  style,
  subText,
  tipSize = '20px',
}: Props) {
  const componentStyle = { ...STYLES.speechBubble, ...style };
  const textSx = { ...defaultTextStyle, ...textStyle };
  const backgroundColor =
    (componentStyle?.backgroundColor as string) ?? theme.palette.common.white;

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mb: 1,
        }}
      >
        <Grid
          sx={componentStyle as SxProps<Theme>}
          container
          justifyContent={'center'}
          alignItems={'center'}
          direction="column"
        >
          {children && <Grid item>{children}</Grid>}
          {text && (
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 1.5,
                textAlign: 'center',
              }}
            >
              <Typography sx={textSx as SxProps<Theme>}>{text}</Typography>
              {subText && (
                <Grid item>
                  <Typography sx={{ fontSize: '0.8em', mt: 0 }}>
                    {subText}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <span
          style={{ border: `${tipSize} solid ${backgroundColor}`, ...tip }}
        ></span>
      </Box>
    </>
  );
}
