import { ColumnCategory } from '@/constants';
import {
  NormalizedAssessment,
  NormalizedReport,
  NetworkPurchase,
} from '@/types/network';

export enum AppRoute {
  ALLERGY_INGREDIENT_PRODUCTS = 'ALLERGY_INGREDIENT_PRODUCTS',
  BARCODE_SCAN = 'BARCODE_SCAN',
  BARCODE_SCAN_ATTEMPT = 'BARCODE_SCAN_ATTEMPT',
  CHECK_KIT = 'CHECK_KIT',
  COLUMN = 'COLUMN',
  COLUMN_LIST = 'COLUMN_LIST',
  CONCEPT = 'CONCEPT',
  CUSTOMER_FEEDBACK = 'CUSTOMER_FEEDBACK',
  DRINK = 'DRINK',
  FAQ = 'FAQ',
  FIRST_SET = 'FIRST_SET',
  LOGIN = 'LOGIN',
  MY_PAGE = 'MY_PAGE',
  MY_PAGE_REPORTS = 'MY_PAGE_REPORTS',
  MY_PAGE_REPORTS_LIST = 'MY_PAGE_REPORTS_LIST',
  MY_PAGE_REPORT_CHALLENGES = 'MY_PAGE_REPORT_CHALLENGES',
  NOT_FOUND = 'NOT_FOUND',
  PRODUCTS = 'PRODUCTS',
  PURCHASE_HISTORY = 'PURCHASE_HISTORY',
  REGISTER = 'REGISTER',
  REGISTER_COMPLETE = 'REGISTER_COMPLETE',
  REGISTER_CONFIRMATION = 'REGISTER_CONFIRMATION',
  SURVEY_PAGE = 'SURVEY_PAGE',
  TEASER = 'TEASER',
  TEST_KIT_COLLECTION_INSTRUCTIONS = 'TEST_KIT_COLLECTION_INSTRUCTIONS',
  TEST_KIT_POSTAGE_INSTRUCTIONS = 'TEST_KIT_POSTAGE_INSTRUCTIONS',
  TEST_KIT_PREPARE_COLLECTION = 'TEST_KIT_PREPARE_COLLECTION',
  TOP = 'TOP',
}

export type Route = {
  id: AppRoute;
  isPrivate?: boolean;
  title: string;
};

export type RouterState = {
  currentRoute: AppRoute;
  currentRouteMeta: Route | null;
};

export type State = {
  addAssessmentInput: {
    errorMessage: string;
    isValid: boolean;
    value: string;
  };
  agreedTerms: boolean;
  assessments: NormalizedAssessment[];
  barcodeScanPhoto: string;
  column?: {
    category: ColumnCategory;
    num: number;
  };
  columnListCategory?: ColumnCategory | null;
  currentRoute: AppRoute | null;
  currentRouteMeta: Route | null;
  isFirstSetPurchase: boolean;
  latestBacteriaType: BacteriaType;
  latestReport?: NormalizedReport & {
    assessmentId: string;
    reportDate: string;
  };
  loading: {
    applicationIsLoading: boolean;
    assessmentIsLoading: boolean;
    scanningIsLoading: boolean;
    surveyAutoSaveIsLoading: boolean;
  };
  ongoingAssessment: NormalizedAssessment | null;
  purchases?: NetworkPurchase[];
  registerForm: RegisterForm;
  registerFormErrors: {
    addressLine1: { isValid: boolean; message: string };
    addressLine2: { isValid: boolean; message: string };
    addressLine3: { isValid: boolean; message: string };
    addressLine4: { isValid: boolean; message: string };
    addressLine5: { isValid: boolean; message: string };
    phone: { isValid: boolean; message: string };
    postcode: { isValid: boolean; message: string };
  };
  selectedAssessment: NormalizedAssessment | null;
  snackbar: {
    message: string;
    type: 'error' | 'info' | 'success' | 'warning';
    visible: boolean;
  };
  surveyAnswers: Answer[];
  surveyPage: SurveyPage;
  termsUrl: string;
  user: {
    buildingName: string;
    city: string;
    dayOfBirth: string;
    email: string;
    firstName: string;
    houseNumber: string;
    isNew: boolean;
    lastName: string;
    meijiId: string;
    monthOfBirth: string;
    phoneNumber: string;
    postalCode: string;
    prefecture: string;
    sex: string;
    sexJA: string;
    token: string;
    yearOfBirth: string;
  };
};

export type SurveyPage = {
  index: number;
  //TODO I called it errors but it really unanswered :think:
  questionErrors: Question[];
  questionOffset: number;
  questions: Question[];
};

export type Answer = {
  key: string;
  value: string | number | null;
};
export type Option = { label: string; value: string };

export enum SurveyComponentType {
  'RADIO' = 'RADIO',
  'SELECT' = 'SELECT',
  'SELECT_INLINE' = 'SELECT_INLINE',
}
export type Question = {
  children: Option[];
  childrenSecondary?: Option[];
  component: SurveyComponentType;
  description: string;
  hideNumber?: boolean;
  id: string;
  parentId?: string;
  placeholder?: string;
  selectedValue: string | number;
  suffix: string | Array<string>;
  title: string;
};

export type Questions = {
  [key: string]: Question;
};

export type RegisterForm = {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  addressLine5: string;
  birthday: string;
  name: string;
  phone: string;
  postcode1: string;
  postcode2: string;
  sex: string;
};

export type OnQuestionAnswerChanged = ({
  key,
  value,
}: {
  key: string;
  value: string | number;
}) => Promise<void>;

export type EmojiColor = 'blue' | 'grey' | 'red' | 'yellow';

export type BacteriaType =
  | 'BACTEROIDES'
  | 'BIFIDOBACTERIUM'
  | 'FAECALIBACTERIUM'
  | 'PREVOTELLA'
  | 'RUMINOCOCCUS'
  | 'None';

export type EmojiLevelItem = {
  color: EmojiColor;
  header: string;
  key: string;
  text: string;
};

export type Challenge = {
  category?: string;
  description: string;
  image: string;
  title: string;
};

export type GraphBar = {
  color: string;
  end: number;
  start: number;
};
