import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, Typography, Paper, Button, Box } from '@mui/material';

import { MY_PAGE_READY_FOR_POST } from '@/constants';
import { ExplainingFloraCheck } from '@/view/components/ExplainingFloraCheck';
import { FONT_SIZES, LINE_HEIGHTS, STYLES } from '@/view/styling/theme';

export function ReadyForPost() {
  return (
    <Grid container direction="column" gap={2}>
      <Grid item>
        <Typography sx={STYLES.myPageHeading}>申込み中の検査</Typography>
      </Grid>
      <Grid item>
        <Paper elevation={0} sx={{ borderRadius: '12px', padding: '24px' }}>
          <Grid container direction="column" gap={2}>
            <Grid item sx={{ marginTop: '12px' }}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                }}
              >
                <img
                  width="248.46px"
                  src="/images/top-page/ready_for_post.png"
                />
              </Box>
            </Grid>
            <Grid item>
              <Typography mb={2} sx={STYLES.mainHeading}>
                {MY_PAGE_READY_FOR_POST.BODY_LINE_1}
              </Typography>
              <Typography
                sx={{
                  color: 'grey.300',
                  display: 'block',
                  fontSize: FONT_SIZES.sm,
                  lineHeight: LINE_HEIGHTS.lg,
                  textAlign: 'center',
                  whiteSpace: 'pre-wrap',
                  width: '100%',
                }}
              >
                {MY_PAGE_READY_FOR_POST.BODY_CAPTION_1}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                backgroundColor: '#F3F5F7',
                borderRadius: '8px',
                gap: '8px',
                padding: '16px 0',
              }}
            >
              <Typography
                sx={{
                  display: 'block',
                  fontSize: FONT_SIZES.sm,
                  fontWeight: 300,
                  lineHeight: LINE_HEIGHTS.mmd,
                  marginBottom: 1,
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                {MY_PAGE_READY_FOR_POST.BODY_LINE_2}
              </Typography>
              <Typography
                sx={{
                  color: 'grey.300',
                  display: 'block',
                  fontSize: FONT_SIZES.sm,
                  fontWeight: 300,
                  lineHeight: LINE_HEIGHTS.mmd,
                  textAlign: 'center',
                  whiteSpace: 'pre',
                  width: '100%',
                }}
              >
                {MY_PAGE_READY_FOR_POST.BODY_CAPTION_2}
              </Typography>
            </Grid>
            <Grid item>
              <ExplainingFloraCheck
                triggerButton={
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{
                      '&:hover': {
                        backgroundColor: 'common.white',
                        boxShadow: 'none',
                      },
                      alignItems: 'end',
                      backgroundColor: 'common.white',
                      color: 'grey.300',
                      fontSize: FONT_SIZES.sm,
                      fontWeight: 300,
                      height: '37px',
                      justifyContent: 'flex-start',
                      paddingLeft: 0,
                      textTransform: 'none',
                    }}
                    startIcon={
                      <HelpOutlineIcon
                        sx={{
                          color: 'primary.main',
                        }}
                      />
                    }
                  >
                    腸内フローラ検査について
                  </Button>
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
