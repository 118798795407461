import { Container, Typography, Grid, Button, Box } from '@mui/material';
import _ from 'lodash';

import {
  COLLECTION_PHASE_STEPPER,
  TEST_KIT_COLLECTION_INSTRUCTIONS_PAGE,
} from '@/constants';
import * as user from '@/domain/middlewares/user';
import { store } from '@/domain/store';
import { State } from '@/types';
import { GreyBoxInstructionCard } from '@/view/components/GreyBoxInstructionCard';
import { SpeechBubble } from '@/view/components/SpeechBubble';
import { FormStepper } from '@/view/components/Stepper';
import { FONT_SIZES, LINE_HEIGHTS, STYLES } from '@/view/styling/theme';

export function AssessmentCollectionInstructions({ state }: { state: State }) {
  const onClickOpenPostageInstructions = _.partial(
    user.onClickOpenPostageInstructions,
    store
  );

  return (
    <div>
      <FormStepper activeStep={3} steps={COLLECTION_PHASE_STEPPER} />
      <Container>
        <Grid container direction="column" gap={2}>
          <Grid item>
            <Box>
              <SpeechBubble
                style={{
                  ...STYLES.instruction.speechBubble,
                  padding: '20px 20px 10px 20px',
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      ...STYLES.instruction.topWarningTitle,
                      marginBottom: '-12px',
                    }}
                  >
                    {TEST_KIT_COLLECTION_INSTRUCTIONS_PAGE.TOP_WARNING.TITLE}
                  </Typography>
                  <ul
                    style={{
                      ...STYLES.instruction.bulletList,
                      paddingLeft: '30px',
                    }}
                  >
                    <li
                      style={{
                        marginBottom: '2px',
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={STYLES.instruction.topWarningDescription}
                      >
                        {
                          TEST_KIT_COLLECTION_INSTRUCTIONS_PAGE.TOP_WARNING
                            .DESCRIPTION_1
                        }
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body1"
                        sx={{
                          color: 'error.main',
                          fontSize: FONT_SIZES.md,
                          lineHeight: LINE_HEIGHTS.md,
                        }}
                      >
                        {
                          TEST_KIT_COLLECTION_INSTRUCTIONS_PAGE.TOP_WARNING
                            .DESCRIPTION_2
                        }
                      </Typography>
                    </li>
                  </ul>
                </Box>
              </SpeechBubble>
              <Box sx={{ marginBottom: '40px', marginTop: '-20px' }}>
                <img
                  src={TEST_KIT_COLLECTION_INSTRUCTIONS_PAGE.IMAGE_PATH}
                  style={{ height: 'auto', maxWidth: '300px' }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Typography sx={STYLES.instruction.pageTitle}>
              {TEST_KIT_COLLECTION_INSTRUCTIONS_PAGE.PAGE_TITLE}
            </Typography>
          </Grid>
          {TEST_KIT_COLLECTION_INSTRUCTIONS_PAGE.INSTRUCTIONS.map(
            (item, index) => {
              return (
                <Grid item key={index}>
                  <GreyBoxInstructionCard
                    title={item.TITLE}
                    description={item.DESCRIPTION}
                    image={item.IMAGE_PATH}
                  />
                </Grid>
              );
            }
          )}
          <Grid item>
            <Button
              size="large"
              sx={{ marginBottom: 3 }}
              variant="contained"
              fullWidth
              onClick={onClickOpenPostageInstructions}
              data-e2e="collection-instructions__submit-btn"
              data-gtm-link="complete_testing"
            >
              {TEST_KIT_COLLECTION_INSTRUCTIONS_PAGE.NEXT_BUTTON}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
