import { Box, Stack, Typography } from '@mui/material';
import { FunctionComponent, useMemo } from 'react';

import { BACTERIA_IMAGE } from '@/constants';
import { BacteriaType } from '@/types';

function calcLine(
  from: [number, number],
  to: [number, number]
): {
  angleRad: number;
  from: [number, number];
  length: number;
} {
  const length = Math.sqrt((from[0] - to[0]) ** 2 + (from[1] - to[1]) ** 2);
  const angleRad = Math.atan2(to[1] - from[1], to[0] - from[0]);
  return { angleRad, from, length };
}

type PureBacteriaType = Exclude<BacteriaType, 'None'>;
const OrderedBacteria: PureBacteriaType[] = [
  'BIFIDOBACTERIUM',
  'BACTEROIDES',
  'FAECALIBACTERIUM',
  'PREVOTELLA',
  'RUMINOCOCCUS',
];
const BacteriaNameWithNewLinePosition: { [key in PureBacteriaType]: string[] } =
  {
    BACTEROIDES: ['バクテ', 'ロイデス'],
    BIFIDOBACTERIUM: ['ビフィドバクテリウム'],
    FAECALIBACTERIUM: ['フィーカリ', 'バクテリウム'],
    PREVOTELLA: ['プレボテラ'],
    RUMINOCOCCUS: ['ルミノ', 'コッカス'],
  };

export const SpiderGraph: FunctionComponent<{
  previousTScore?: {
    [key in PureBacteriaType]: number;
  };
  tScore?: {
    [key in PureBacteriaType]: number;
  };
}> = ({ tScore, previousTScore }) => {
  // In this components, coordination and axis are like:
  //   center = [0, 0]
  //   outer rect = [-1, -1] - [-1, 1] - [1, 1] - [1, -1]

  const elementLength = OrderedBacteria.length;

  const bacteriaDisplay: {
    imageUrl: string;
    label: string[];
    position: [number, number];
  }[] = useMemo(() => {
    return OrderedBacteria.map((bacteria, i) => {
      const rad = -(Math.PI * 2 * i) / elementLength + Math.PI;
      const n = BacteriaNameWithNewLinePosition[bacteria];
      return {
        imageUrl: BACTERIA_IMAGE[bacteria],
        label: [...n],
        position: [Math.sin(rad) * 1.2, Math.cos(rad) * 1.2],
      };
    });
  }, []);

  const valueDisplay: {
    label: string;
    position: [number, number];
  }[] = useMemo(() => {
    if (!tScore) return [];

    return OrderedBacteria.map((bacteria, i) => {
      const rad = -(Math.PI * 2 * i) / elementLength + Math.PI;
      const l = tScore[bacteria];
      return {
        label: `${l}`,
        position: [
          (Math.sin(rad) * (l + 10)) / 100,
          (Math.cos(rad) * (l + 10)) / 100,
        ],
      };
    });
  }, []);

  // when tScore is not provided.
  const fillRectVertices: [number, number][] = useMemo(() => {
    const vertices: [number, number][] = [];
    for (let i = 0; i < elementLength; i++) {
      const rad = -(Math.PI * 2 * i) / elementLength + Math.PI;
      vertices.push([Math.sin(rad), Math.cos(rad)]);
    }
    return vertices;
  }, [elementLength]);

  // when tScore is provided while previousTScore is not.
  const averagePentagonVertices: [number, number][] = useMemo(() => {
    const vertices: [number, number][] = [];
    for (let i = 0; i < elementLength; i++) {
      const rad = -(Math.PI * 2 * i) / elementLength + Math.PI;
      vertices.push([Math.sin(rad) * 0.5, Math.cos(rad) * 0.5]);
    }
    return vertices;
  }, [elementLength]);

  const lines: {
    angleRad: number;
    color: string;
    from: [number, number];
    length: number;
    width: number;
  }[] = useMemo(() => {
    const lines: {
      angleRad: number;
      color: string;
      from: [number, number];
      length: number;
      width: number;
    }[] = [];

    // outer pentagon
    for (let i = 0; i < elementLength; i++) {
      const rad1 = -(Math.PI * 2 * i) / elementLength + Math.PI;
      const rad2 =
        -(Math.PI * 2 * ((i + 1) % elementLength)) / elementLength + Math.PI;

      const from: [number, number] = [Math.sin(rad1), Math.cos(rad1)];
      const to: [number, number] = [Math.sin(rad2), Math.cos(rad2)];

      lines.push({
        ...calcLine(from, to),
        color: 'grey.600',
        width: 1,
      });
    }

    // axis
    for (let i = 0; i < elementLength; i++) {
      const rad = -(Math.PI * 2 * i) / elementLength + Math.PI;

      const from: [number, number] = [0, 0];
      const to: [number, number] = [Math.sin(rad), Math.cos(rad)];

      lines.push({
        ...calcLine(from, to),
        color: 'grey.600',
        width: 1,
      });
    }

    // axis dots
    const dotWidth = 0.05;
    for (let i = 0; i < elementLength; i++) {
      [0.2, 0.4, 0.6, 0.8].forEach((len) => {
        const rad = -(Math.PI * 2 * i) / elementLength;

        const fromBeforeRotation: [number, number] = [-dotWidth, -len];
        const toBeforeRotation: [number, number] = [dotWidth, -len];

        const from: [number, number] = [
          fromBeforeRotation[0] * Math.cos(rad) -
            fromBeforeRotation[1] * Math.sin(rad),
          fromBeforeRotation[0] * Math.sin(rad) +
            fromBeforeRotation[1] * Math.cos(rad),
        ];
        const to: [number, number] = [
          toBeforeRotation[0] * Math.cos(rad) -
            toBeforeRotation[1] * Math.sin(rad),
          toBeforeRotation[0] * Math.sin(rad) +
            toBeforeRotation[1] * Math.cos(rad),
        ];

        lines.push({
          ...calcLine(from, to),
          color: 'grey.600',
          width: 1,
        });
      });
    }

    // previous lines
    if (previousTScore) {
      for (let i = 0; i < elementLength; i++) {
        const v1 = previousTScore[OrderedBacteria[i]];
        const v2 = previousTScore[OrderedBacteria[(i + 1) % elementLength]];

        const rad1 = -(Math.PI * 2 * i) / elementLength + Math.PI;
        const rad2 =
          -(Math.PI * 2 * ((i + 1) % elementLength)) / elementLength + Math.PI;

        const from: [number, number] = [
          (Math.sin(rad1) * v1) / 100,
          (Math.cos(rad1) * v1) / 100,
        ];
        const to: [number, number] = [
          (Math.sin(rad2) * v2) / 100,
          (Math.cos(rad2) * v2) / 100,
        ];

        lines.push({
          ...calcLine(from, to),
          color: 'grey.600',
          width: 2,
        });
      }
    }

    // current lines
    if (tScore) {
      for (let i = 0; i < elementLength; i++) {
        const v1 = tScore[OrderedBacteria[i]];
        const v2 = tScore[OrderedBacteria[(i + 1) % elementLength]];

        const rad1 = -(Math.PI * 2 * i) / elementLength + Math.PI;
        const rad2 =
          -(Math.PI * 2 * ((i + 1) % elementLength)) / elementLength + Math.PI;

        const from: [number, number] = [
          (Math.sin(rad1) * v1) / 100,
          (Math.cos(rad1) * v1) / 100,
        ];
        const to: [number, number] = [
          (Math.sin(rad2) * v2) / 100,
          (Math.cos(rad2) * v2) / 100,
        ];

        lines.push({
          ...calcLine(from, to),
          color: '#FF8718',
          width: 2,
        });
      }
    }

    return lines;
  }, [elementLength, tScore, previousTScore]);

  return (
    <>
      <Stack gap="3px">
        {tScore && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '15px',
              lineHeight: '15px',
              p: '0',
            }}
          >
            <Box
              sx={{
                border: 'solid 1px #FF8718',
                display: 'inline-block',
                height: 0,
                mr: '5px',
                width: '16.8px',
              }}
            />
            <Typography fontSize="10px" lineHeight="15px" fontWeight="600">
              {previousTScore ? '最新の検査結果' : '今回の検査結果'}
            </Typography>
          </Box>
        )}
        {previousTScore && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '15px',
              lineHeight: '15px',
              p: '0',
            }}
          >
            <Box
              sx={{
                border: 'solid 1px #B0B7C3',
                display: 'inline-block',
                height: 0,
                mr: '5px',
                width: '16.8px',
              }}
            />
            <Typography fontSize="10px" lineHeight="15px" fontWeight="600">
              過去の検査結果
            </Typography>
          </Box>
        )}
      </Stack>
      <Box
        sx={{
          overflow: 'hidden',
          position: 'relative',
          width: '100%',
        }}
      >
        <Box
          sx={{
            paddingTop: '111%',
          }}
        />
        <Box
          sx={{
            bottom: 'min(80px, 20vw)',
            left: 'min(80px, 20vw)',
            position: 'absolute',
            right: 'min(80px, 20vw)',
            top: 'calc(10% + min(80px, 20vw))',
          }}
        >
          {!tScore && (
            <Box
              sx={{
                bgcolor: '#808080',
                bottom: 0,
                clipPath: `polygon(${fillRectVertices
                  .map(
                    ([x, y]) => `${(x / 2) * 100 + 50}% ${(y / 2) * 100 + 50}%`
                  )
                  .join(',')})`,
                left: 0,
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            />
          )}
          {!!tScore && !previousTScore && (
            <Box
              sx={{
                bgcolor: '#D9D9D9',
                bottom: 0,
                clipPath: `polygon(${averagePentagonVertices
                  .map(
                    ([x, y]) => `${(x / 2) * 100 + 50}% ${(y / 2) * 100 + 50}%`
                  )
                  .join(',')})`,
                left: 0,
                opacity: 0.5,
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            />
          )}
          {lines.map(({ from: [x, y], length, angleRad, color, width }, i) => (
            <Box
              key={i}
              sx={{
                borderTop: `solid ${width}px`,
                color,
                height: 0,
                left: `${(x / 2) * 100 + 50}%`,
                position: 'absolute',
                top: `${(y / 2) * 100 + 50}%`,
                transform: `rotate(${angleRad}rad)`,
                transformOrigin: 'left',
                width: `${(length / 2) * 100}%`,
              }}
            />
          ))}
          {valueDisplay.map(({ label, position: [x, y] }, i) => (
            <Box
              key={i}
              sx={{
                bgcolor: 'rgba(255, 255, 255, 0.5)',
                color: '#FF8718',
                fontSize: '12px',
                fontWeight: '600',
                left: `${(x / 2) * 100 + 50}%`,
                position: 'absolute',
                top: `${(y / 2) * 100 + 50}%`,
                transform: 'translateX(-50%) translateY(-50%)',
              }}
            >
              {label}
            </Box>
          ))}
          {bacteriaDisplay.map(({ label, position: [x, y], imageUrl }, i) => (
            <Box
              key={i}
              sx={{
                left: `${(x / 2) * 100 + 50}%`,
                position: 'absolute',
                top: `${(y / 2) * 100 + 50}%`,
                transform: 'translateX(-50%) translateY(-50%)',
                width: '200px',
              }}
            >
              <Box>
                <img
                  width="25"
                  height="25"
                  src={imageUrl}
                  alt={label.join('')}
                />
              </Box>
              <Stack>
                {label.map((l, i) => (
                  <Typography
                    fontSize="12px"
                    fontWeight="600"
                    textAlign="center"
                    key={i}
                  >
                    {l}
                  </Typography>
                ))}
              </Stack>
            </Box>
          ))}
          {!tScore && (
            <Box
              sx={{
                color: 'white',
                fontSize: '11px',
                fontWeight: '600',
                left: '50%',
                position: 'absolute',
                textAlign: 'center',
                top: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
                width: '100%',
              }}
            >
              検査結果が出ませんでした
            </Box>
          )}
          {!!tScore && !previousTScore && (
            <Box
              sx={{
                color: '#828A9B',
                fontSize: '11px',
                fontWeight: '600',
                left: '50%',
                position: 'absolute',
                textAlign: 'center',
                top: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
                width: '100%',
              }}
            >
              平均値
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
