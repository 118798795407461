import * as _ from 'lodash';
import page from 'page';

import {
  ERRORS,
  TEST_KIT_PREPARE_COLLECTION_PAGE,
  TEST_KIT_ADD_BARCODE_PAGE,
  REGISTER_PAGE,
  REGISTER_COMPLETE_PAGE,
  REGISTER_CONFIRMATION_PAGE,
  TEST_KIT_COLLECTION_INSTRUCTIONS_PAGE,
  TEST_KIT_POSTAGE_INSTRUCTIONS_PAGE,
  MY_PAGE,
  SURVEY_PAGE,
  SURVEY_PAGE_QUESTIONS,
  ALL_SURVEY,
  SURVEY_PAGE_QUESTIONS_WITH_CONDITIONALS,
  REPORTS_LIST,
  COLUMN_PAGE,
  ColumnCategory,
  CategoryToColumnsMap,
  TEST_KIT_BARCODE_ATTEMPT_PAGE,
  FAQ_PAGE,
  ColumnCategories,
  COLUMN_LIST_PAGE,
  ColumnCategoryToURLSlug,
  CUSTOMER_FEEDBACK_PAGE,
  PURCHASE_HISTORY_PAGE,
  PRODUCT_DETAIL_FIRST_SET_PAGE,
  PRODUCT_DETAIL_CHECK_KIT_PAGE,
  PRODUCT_DETAIL_DRINK_PAGE,
  PRODUCT_LIST_PAGE,
  ALLERGY_INGREDIENT_PRODUCTS,
  CONCEPT_PAGE,
  TEASER_PAGE,
} from '@/constants';
import { refreshAccessTokenAndSetUserState } from '@/domain/middlewares/auth';
import {
  getLoggedInUserState,
  getOneCompleteAssessmentById,
  getPurchases,
} from '@/domain/middlewares/router/helpers';
import { getState, store } from '@/domain/store';
import * as reducers from '@/domain/store/reducers';
import { getUserAssessmentRoute } from '@/domain/utils/assessment';
import { createLogger } from '@/domain/utils/logger';
import * as network from '@/domain/utils/network';
import { normalizeAssessment } from '@/domain/utils/normalizers';
import {
  restoreQuestionsInputSelection,
  calculateQuestionNumberOffset,
} from '@/domain/utils/survey';
import { AppRoute } from '@/types';
import { AssessmentStatus } from '@/types/network';

const logger = createLogger('@domain/handlers');

export async function topRouteHandler(context: PageJS.Context) {
  reducers.setCurrentRoute(store, AppRoute.TOP);
  await getLoggedInUserState();
}

export function notFoundRouteHandler(context: PageJS.Context) {
  reducers.setCurrentRoute(store, AppRoute.NOT_FOUND);
}

/**
 * authCallbackRouteHandler
 *
 * @remarks
 * A route handler for login callback of IDPF
 * - Refresh access token and get meijiId of the session user
 * - Update user state with access token and meijiId
 * - Redirect to /products
 * - When any of requests to IDPF get failed, redirect to top page

 *
 * @param context - PageJS context
 */
export async function authCallbackRouteHandler(context: PageJS.Context) {
  try {
    await refreshAccessTokenAndSetUserState(true);
    page('/products');
  } catch (err) {
    logger.error(err);
    reducers.updateSnackbar(store, {
      message: ERRORS.AUTH_ERROR,
      type: 'error',
      visible: true,
    });
    page('/');
  }
}

export function loginRouteHandler(context: PageJS.Context) {
  reducers.setCurrentRoute(store, AppRoute.LOGIN);
}

export async function requestApplicationRegistrationHandler(
  context: PageJS.Context
) {
  const state = getState();
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.REGISTER,
    isPrivate: true,
    title: REGISTER_PAGE.PAGE_TITLE,
  });

  //Get User data
  reducers.setUser(store, {
    ...state.user,
    isNew: false,
    meijiId: _.get(state, 'user.meijiId'),
    token: _.get(state, 'user.token'),
  });
  logger.info('stored user');
  reducers.setCurrentRoute(store, AppRoute.REGISTER);
}

/**
 * A route handler for register confirmation route
 *
 * @param context - PageJS context
 */
export async function registerConfirmationHandler(context: PageJS.Context) {
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.REGISTER_CONFIRMATION,
    isPrivate: true,
    title: REGISTER_CONFIRMATION_PAGE.PAGE_TITLE,
  });
  reducers.setCurrentRoute(store, AppRoute.REGISTER_CONFIRMATION);
}

/**
 * A route handler for register complete route
 *
 * @param context - PageJS context
 */
export async function registerCompletedHandler(context: PageJS.Context) {
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.REGISTER_COMPLETE,
    isPrivate: true,
    title: REGISTER_COMPLETE_PAGE.PAGE_TITLE,
  });
  reducers.setCurrentRoute(store, AppRoute.REGISTER_COMPLETE);
  reducers.resetRegisterForm(store);
}

export async function assessmentAddByScanHandler(context: PageJS.Context) {
  const state = store.deref();
  if (state.ongoingAssessment?.testKitId) {
    page('/test-kit/before-sample-collection');
    return;
  }
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.BARCODE_SCAN,
    isPrivate: true,
    title: TEST_KIT_ADD_BARCODE_PAGE.PAGE_TITLE,
  });
  reducers.setCurrentRoute(store, AppRoute.BARCODE_SCAN);
}

export async function assessmentBarcodeScanAttemptHandler(
  context: PageJS.Context
) {
  if (!getState().currentRoute) {
    page('/my-page');
  }
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.BARCODE_SCAN_ATTEMPT,
    isPrivate: true,
    title: TEST_KIT_BARCODE_ATTEMPT_PAGE.PAGE_TITLE,
  });
  reducers.setCurrentRoute(store, AppRoute.BARCODE_SCAN_ATTEMPT);
}

export async function assessmentPrepareCollectionHandler(
  context: PageJS.Context
) {
  const state = store.deref();
  if (!state.ongoingAssessment?.testKitId) {
    page('/test-kit/barcode-scan');
    return;
  }
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.TEST_KIT_PREPARE_COLLECTION,
    isPrivate: true,
    title: TEST_KIT_PREPARE_COLLECTION_PAGE.PAGE_TITLE,
  });
  reducers.setCurrentRoute(store, AppRoute.TEST_KIT_PREPARE_COLLECTION);
}

export async function assessmentCollectionInstructionsHandler(
  context: PageJS.Context
) {
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.TEST_KIT_COLLECTION_INSTRUCTIONS,
    isPrivate: true,
    title: TEST_KIT_COLLECTION_INSTRUCTIONS_PAGE.PAGE_TITLE,
  });
  reducers.setCurrentRoute(store, AppRoute.TEST_KIT_COLLECTION_INSTRUCTIONS);
}

export async function assessmentPostageInstructionsHandler(
  context: PageJS.Context
) {
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.TEST_KIT_POSTAGE_INSTRUCTIONS,
    isPrivate: true,
    title: TEST_KIT_POSTAGE_INSTRUCTIONS_PAGE.PAGE_TITLE,
  });
  reducers.setCurrentRoute(store, AppRoute.TEST_KIT_POSTAGE_INSTRUCTIONS);
}

/**
 * myPageHandler
 *
 * @remarks
 * A route handler for my-page
 * - Could redirect to either /agreement or /my-page/reports according to the user's status
 *
 * @param context - PageJS context
 */
export async function myPageHandler(context: PageJS.Context) {
  try {
    reducers.updateLoading(store, 'applicationIsLoading', true);
    const currentState = getState();
    const route = getUserAssessmentRoute(currentState?.assessments); // @alex-basal What is this used for?
    if (route.url !== location.pathname) {
      page(route.url);
      return;
    }
    reducers.setCustomRouteMeta(store, {
      id: AppRoute.MY_PAGE,
      isPrivate: true,
      title: MY_PAGE.PAGE_TITLE,
    });
    reducers.setCurrentRoute(store, AppRoute.MY_PAGE);

    // Get and set latest report
    const oneCompletedAssessmentResponse = await network.getAssessments(
      {
        limit: '1',
        meijiId: currentState.user.meijiId,
        sort: { field: 'updatedAt', order: 'desc' },
        status: AssessmentStatus.COMPLETE,
      },
      currentState.user.token
    );

    //updated at is null but do we care?
    const completedAssessment = normalizeAssessment(
      oneCompletedAssessmentResponse.data[0],
      currentState.user.meijiId,
      ''
    );

    if (!_.isNil(completedAssessment) && completedAssessment.report != null) {
      reducers.setLatestReport(store, {
        ...completedAssessment.report,
        assessmentId: completedAssessment._id,
        reportDate: completedAssessment.testCompletedAt ?? '',
      });
    } else {
      reducers.setLatestReport(store, undefined);
    }
  } catch (error) {
    logger.error(error);
    reducers.updateSnackbar(store, {
      message: ERRORS.GET_ASSESSMENTS_ERROR,
      type: 'error',
      visible: true,
    });
  }
  reducers.updateLoading(store, 'applicationIsLoading', false);
}

export async function faqRouteHandler(context: PageJS.Context) {
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.FAQ,
    isPrivate: false, //! check this
    title: FAQ_PAGE.PAGE_TITLE,
  });
  reducers.setCurrentRoute(store, AppRoute.FAQ);
  await getLoggedInUserState();
}

export async function conceptRouteHandler(context: PageJS.Context) {
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.CONCEPT,
    isPrivate: false,
    title: CONCEPT_PAGE.PAGE_TITLE,
  });
  reducers.setCurrentRoute(store, AppRoute.CONCEPT);
  await getLoggedInUserState();
}

export async function teaserRouteHandler(context: PageJS.Context) {
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.TEASER,
    isPrivate: false,
    title: TEASER_PAGE.ROUTE_TITLE,
  });
  reducers.setCurrentRoute(store, AppRoute.TEASER);
}

/**
 * myPageReportsHandler
 *
 * @remarks
 * A route handler for my page report
 * - get assessment by id from network (if not already present)
 * - set to selectedAssessment
 *
 * @param context - PageJS context
 */
export async function myPageReportsHandler(context: PageJS.Context) {
  try {
    reducers.updateLoading(store, 'applicationIsLoading', true);
    reducers.setCustomRouteMeta(store, {
      id: AppRoute.MY_PAGE_REPORTS,
      isPrivate: true,
      title: MY_PAGE.PAGE_TITLE,
    });
    reducers.setCurrentRoute(store, AppRoute.MY_PAGE_REPORTS);

    const assessmentId = context.params.assessmentId;
    const currentState = getState();

    // If we already have the same assessment in state, don't go to network
    if (context.params.assessmentId !== currentState.selectedAssessment?._id) {
      const selectedAssessment = await getOneCompleteAssessmentById(
        assessmentId,
        currentState.user.meijiId,
        currentState.user.token
      );
      if (_.isNil(selectedAssessment)) throw new Error('Assessment not found');

      reducers.setSelectedAssessment(
        store,
        // TODO updatedAt is on the order, not the assessment
        normalizeAssessment(selectedAssessment, currentState.user.meijiId, '')
      );
    }
  } catch (error) {
    reducers.updateSnackbar(store, {
      message: ERRORS.GET_REPORT_ERROR,
      type: 'error',
      visible: true,
    });
  }
  reducers.updateLoading(store, 'applicationIsLoading', false);
}

/**
 * myPageReportsListHandler
 *
 * @remarks
 * List completed reports
 *
 * @param context - PageJS context
 */
export async function myPageReportsListHandler(context: PageJS.Context) {
  try {
    reducers.updateLoading(store, 'applicationIsLoading', true);
    reducers.setCustomRouteMeta(store, {
      id: AppRoute.MY_PAGE_REPORTS_LIST,
      isPrivate: true,
      title: REPORTS_LIST.PAGE_TITLE,
    });
    reducers.setCurrentRoute(store, AppRoute.MY_PAGE_REPORTS_LIST);
  } catch (error) {
    logger.error(error);
    reducers.updateSnackbar(store, {
      message: ERRORS.GET_REPORT_ERROR,
      type: 'error',
      visible: true,
    });
    page('/');
  }

  reducers.updateLoading(store, 'applicationIsLoading', false);
}

/**
 * myPageReportMissionsHandler
 *
 * @remarks
 * A route handler for challenges page in report page
 *
 * @param context - PageJS context
 */
export async function myPageReportChallengesHandler(context: PageJS.Context) {
  const assessmentId = context.params?.assessmentId;
  const currentState = getState();
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.MY_PAGE_REPORT_CHALLENGES,
    isPrivate: true,
    title: MY_PAGE.PAGE_TITLE,
  });

  // If we already have the same assessment in state, don't go to network
  if (context.params?.assessmentId !== currentState.selectedAssessment?._id) {
    const selectedAssessment = await getOneCompleteAssessmentById(
      assessmentId,
      currentState.user.meijiId,
      currentState.user.token
    );
    if (_.isNil(selectedAssessment)) throw new Error('Assessment not found');

    reducers.setSelectedAssessment(
      store,
      // TODO updatedAt is on the order not the assessment
      normalizeAssessment(selectedAssessment, currentState.user.meijiId, '')
    );
  }

  reducers.setCurrentRoute(store, AppRoute.MY_PAGE_REPORT_CHALLENGES);
}

export async function productDetailDrinkHandler(context: PageJS.Context) {
  reducers.setCurrentRoute(store, AppRoute.DRINK);
}

/**
 * Column page
 *
 * @remarks
 * A route handler for columns. Takes `column` as a parameter, like `iga-3`.
 *
 * @param context - PageJS context
 */
export async function columnPageHandler(context: PageJS.Context) {
  const column = context.params.column.split('-');
  if (column.length !== 2) {
    page('/columns');
    return;
  }

  const [categoryFromParam, numberFromParam] = column;
  const category: ColumnCategory | null =
    ColumnCategoryToURLSlug.find(({ slug }) => slug === categoryFromParam)
      ?.category ?? null;
  if (!category) {
    page('/columns');
    return;
  }

  const number = parseInt(numberFromParam, 10);
  if (CategoryToColumnsMap[category].length < number) {
    page('/columns');
    return;
  }

  reducers.setColumn(store, category, number);
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.COLUMN,
    isPrivate: false,
    title: COLUMN_PAGE.PAGE_TITLE,
  });
  reducers.setCurrentRoute(store, AppRoute.COLUMN);
  // @yu-basal I added this helper here so the top bar displays correctly.  Feel free to refactor if necessary.
  await getLoggedInUserState();
}

/**
 * Column page
 *
 * @remarks
 * A route handler for columns list.
 *
 * @param context - PageJS context
 */
export async function columnListPageHandler(context: PageJS.Context) {
  const query = new URLSearchParams(context.querystring);
  const categoryQuery = query.get('category');
  if (categoryQuery) {
    const category = ColumnCategories.find((c) => c === categoryQuery);
    if (!category) {
      // no matched category
      page('/columns');
      return;
    }
    reducers.setColumnListCategory(store, category);
  } else {
    reducers.setColumnListCategory(store, null);
  }

  reducers.setCustomRouteMeta(store, {
    id: AppRoute.COLUMN_LIST,
    isPrivate: false,
    title: COLUMN_LIST_PAGE.PAGE_TITLE,
  });
  reducers.setCurrentRoute(store, AppRoute.COLUMN_LIST);
  // @yu-basal I added this helper here so the top bar displays correctly.  Feel free to refactor if necessary.
  await getLoggedInUserState();
}

/**
 * surveyPageHandler
 *
 * @remarks
 * A route handler for all survey pages
 *
 * @param context - PageJS context
 */
export async function surveyPageHandler(context: PageJS.Context) {
  logger.info('context', context.params);
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.SURVEY_PAGE,
    isPrivate: true,
    title: SURVEY_PAGE.PAGE_TITLE,
  });

  const surveyRouter = _.get(context, 'params.page', '1');
  const previousAnswers = getState().surveyAnswers;
  switch (surveyRouter) {
    case '1':
      reducers.setCurrentSurveyPage(store, {
        index: _.parseInt(surveyRouter),
        questionErrors: [],
        questionOffset: 0,
        questions: SURVEY_PAGE_QUESTIONS[0],
      });
      restoreQuestionsInputSelection(
        store,
        getState().surveyAnswers,
        SURVEY_PAGE_QUESTIONS[0]
      );
      break;
    case '2':
      reducers.setCurrentSurveyPage(store, {
        index: _.parseInt(surveyRouter),
        questionErrors: [],
        questionOffset: calculateQuestionNumberOffset(
          previousAnswers,
          SURVEY_PAGE_QUESTIONS_WITH_CONDITIONALS[1],
          ALL_SURVEY
        ),
        questions: SURVEY_PAGE_QUESTIONS[1],
      });
      restoreQuestionsInputSelection(
        store,
        getState().surveyAnswers,
        SURVEY_PAGE_QUESTIONS[1]
      );
      break;
    default:
      reducers.setCurrentRoute(store, AppRoute.NOT_FOUND);
      return;
  }
  reducers.setCurrentRoute(store, AppRoute.SURVEY_PAGE);
}

/**
 * customer feedback page
 *
 * @remarks
 * A route handler for customer voices page.
 *
 * @param context - PageJS context
 */
export async function customerFeedbackPageHandler(context: PageJS.Context) {
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.CUSTOMER_FEEDBACK,
    isPrivate: false,
    title: CUSTOMER_FEEDBACK_PAGE.PAGE_TITLE,
  });
  reducers.setCurrentRoute(store, AppRoute.CUSTOMER_FEEDBACK);
  await getLoggedInUserState();
}

/**
 * purchase history page
 *
 * @remarks
 * A route handler for purchase history page.
 * sets the user purchases to state.purchases
 * @param context - PageJS context
 */
export async function purchaseHistoryPageHandler(context: PageJS.Context) {
  try {
    reducers.updateLoading(store, 'applicationIsLoading', true);
    reducers.setCurrentRoute(store, AppRoute.PURCHASE_HISTORY);
    reducers.setCustomRouteMeta(store, {
      id: AppRoute.PURCHASE_HISTORY,
      isPrivate: true,
      title: PURCHASE_HISTORY_PAGE.PAGE_TITLE,
    });

    const currentState = getState();

    await getPurchases(currentState.user.meijiId, currentState.user.token);
  } catch (err) {
    logger.error(err);
    reducers.updateSnackbar(store, {
      message: ERRORS.GET_ORDERS_ERROR,
      type: 'error',
      visible: true,
    });
    page('/');
  }
  reducers.updateLoading(store, 'applicationIsLoading', false);
}

/**
 * products page
 *
 * @remarks
 * A route handler for products list.
 *
 * @param context - PageJS context
 */
export async function productsListPageHandler(context: PageJS.Context) {
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.PRODUCTS,
    isPrivate: false,
    title: PRODUCT_LIST_PAGE.PAGE_TITLE,
  });
  reducers.setCurrentRoute(store, AppRoute.PRODUCTS);
  await getLoggedInUserState();
}

export async function allergyIngredientProductsHandler(
  context: PageJS.Context
) {
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.ALLERGY_INGREDIENT_PRODUCTS,
    isPrivate: false,
    title: ALLERGY_INGREDIENT_PRODUCTS.PAGE_TITLE,
  });
  reducers.setCurrentRoute(store, AppRoute.ALLERGY_INGREDIENT_PRODUCTS);
}

/**
 * product detail- Test Kit
 * Two Test Kit Types- first-set and check-kit (default)
 *
 * @remarks
 * A route handler for product-details (test kit).
 *
 * @param context - PageJS context
 */
export async function productTestKitPageHandler(context: PageJS.Context) {
  reducers.updateLoading(store, 'applicationIsLoading', true);
  logger.info('[productTestKitPageHandler] context', context.params.product);
  const product = context.params.product;
  switch (product) {
    case 'first-set':
      reducers.setCustomRouteMeta(store, {
        id: AppRoute.FIRST_SET,
        isPrivate: false,
        title: PRODUCT_DETAIL_FIRST_SET_PAGE.PAGE_TITLE,
      });
      reducers.setCurrentRoute(store, AppRoute.FIRST_SET);
      break;
    case 'check-kit':
      reducers.setCustomRouteMeta(store, {
        id: AppRoute.CHECK_KIT,
        isPrivate: false,
        title: PRODUCT_DETAIL_CHECK_KIT_PAGE.PAGE_TITLE,
      });
      reducers.setCurrentRoute(store, AppRoute.CHECK_KIT);
      break;
    default:
      reducers.setCurrentRoute(store, AppRoute.NOT_FOUND);
      return;
  }
  reducers.updateLoading(store, 'applicationIsLoading', false);
  await getLoggedInUserState();
}

/**
 * product detail- Drink
 *
 * @remarks
 * A route handler for product-details (drink).
 *
 * @param context - PageJS context
 */
export async function productDrinkPageHandler(context: PageJS.Context) {
  logger.info('[productDrinkPageHandler]');
  reducers.setCustomRouteMeta(store, {
    id: AppRoute.DRINK,
    isPrivate: false,
    title: PRODUCT_DETAIL_DRINK_PAGE.PAGE_TITLE,
  });
  reducers.setCurrentRoute(store, AppRoute.DRINK);
  await getLoggedInUserState();
}
