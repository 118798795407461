import { Box, Grid, Typography, Button } from '@mui/material';

import { DiscountLabel } from '@/view/components/DiscountLabel';
import { ShippingLabel } from '@/view/components/ShippingLabel';
import { TextHighlight } from '@/view/components/TextHighlight';
import { STYLES } from '@/view/styling/theme';

type Props = {
  image: string;
  imageCaption?: string;
  marginX?: string | number;
  nextButton?: {
    label: string;
    onClick: () => void;
  };
  price: {
    DISCOUNT?: {
      TEXT?: string;
      VALUE: string;
    };
    PURCHASE_FREQUENCY?: string | JSX.Element;
    SHIPPING_PRICE?: string;
    VALUE: string;
  }[];
  styleOverride?: {
    buttonVariant?: 'text' | 'contained' | 'outlined';
    image?: {
      height: string;
      width: string;
    };
    imageCaption?: {
      color?: string;
      fontSize?: number | string;
      fontWeight?: number | string;
      lineHeight?: number | string;
    };
    priceItemWrapper?: {
      alignItems?: string;
      color?: string;
      display?: string;
      fontSize?: string;
      fontWeight?: number;
      lineHeight?: string;
    };
    pricing?: {
      paddingTop?: number | string;
    };
    purchaseFrequencyText?: {
      fontSize?: string;
      fontWeight?: number;
      lineHeight?: string;
    };
    purchaseTaxText?: {
      fontSize?: string;
      fontWeight?: number;
      lineHeight?: string;
    };
    title?: {
      color?: string;
      fontSize?: number | string;
      fontWeight?: number | string;
      lineHeight?: number | string;
    };
  };
  subTitle: string;
  taxText: string;
  title: string;
};

export function ProductListItem({
  image,
  imageCaption,
  subTitle,
  marginX = '0px',
  nextButton,
  price,
  taxText,
  title,
  styleOverride,
}: Props) {
  return (
    <Grid container direction="column" gap={0.5}>
      <Grid item xs={12}>
        <Box style={{ position: 'relative' }}>
          <img
            width={styleOverride?.image ? styleOverride.image.width : '100%'}
            height={styleOverride?.image ? styleOverride.image.height : 'auto'}
            src={image}
            style={{ overflow: 'hidden' }}
            alt={`${title} 画像`}
          />
          {imageCaption && (
            <Typography
              variant="subtitle2"
              sx={STYLES.productListItem.imageCaption}
            >
              {imageCaption}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '4px', marginX }}>
        <TextHighlight
          text={subTitle}
          commonTextStyle={{
            ...STYLES.productListItem.subTitle,
            ...styleOverride?.imageCaption,
          }}
          highlightTextStyle={{ ...styleOverride?.imageCaption }}
          normalTextStyle={{ ...styleOverride?.imageCaption }}
        />
      </Grid>
      <Grid item xs={12} sx={{ marginX }}>
        <Typography
          component="h3"
          sx={{ ...STYLES.productListItem.title, ...styleOverride?.title }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginX, ...styleOverride?.pricing }}>
        <Grid container spacing={1}>
          {price.map((price, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sx={STYLES.productListItem.priceSectionWrapper}
            >
              <Typography
                variant="body1"
                sx={{
                  ...STYLES.productListItem.priceItemWrapper,
                  ...styleOverride?.priceItemWrapper,
                }}
              >
                <span
                  style={{
                    ...STYLES.productListItem.purchaseFrequencyText,
                    ...styleOverride?.purchaseFrequencyText,
                  }}
                >
                  {price.PURCHASE_FREQUENCY}
                </span>
                {price.VALUE}
                <span
                  style={{
                    ...STYLES.productListItem.purchaseTaxText,
                    ...styleOverride?.purchaseTaxText,
                  }}
                >
                  ({taxText})
                </span>
              </Typography>
              {price.DISCOUNT && (
                <DiscountLabel
                  amount={price.DISCOUNT.VALUE}
                  description={price.DISCOUNT.TEXT}
                  marginLeft={'12px'}
                />
              )}
              {price.SHIPPING_PRICE && (
                <ShippingLabel
                  amount={price.SHIPPING_PRICE}
                  marginLeft={'16px'}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
      {nextButton && nextButton.label && (
        <Grid item xs={12} sx={{ marginX }}>
          <Button
            size="large"
            variant={
              styleOverride?.buttonVariant
                ? styleOverride.buttonVariant
                : 'outlined'
            }
            sx={{
              ...STYLES.secondaryButtonBottomPage,
              color: styleOverride?.buttonVariant
                ? 'primary.secondary'
                : 'primary.main',
              marginTop: 3,
            }}
            fullWidth
            onClick={nextButton.onClick}
          >
            <Typography sx={STYLES.productListItem.nextButtonLabel}>
              {nextButton.label}
            </Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
