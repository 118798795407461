import { Box, Typography, Container } from '@mui/material';

import { CONCEPT_PAGE } from '@/constants';
import { State } from '@/types';
import { FONT_SIZES } from '@/view/styling/theme';

export function Concept({ state }: { state: State }) {
  return (
    <Container disableGutters>
      <Box sx={{ mb: 4 }}>
        <img
          src={CONCEPT_PAGE.HEADER_IMAGE_PATH}
          alt="Inner Garden"
          style={{ width: '100%' }}
        />
      </Box>
      <Box sx={{ mb: '-24px', textAlign: 'center' }}>
        <Typography
          sx={{
            fontSize: FONT_SIZES.mmd,
            fontWeight: 600,
            whiteSpace: 'pre-wrap',
          }}
        >
          {CONCEPT_PAGE.MAIN_MESSAGE_TITLE}
        </Typography>
      </Box>
      <Box sx={{ mb: 8, textAlign: 'center' }}>
        <Typography sx={{ whiteSpace: 'pre-wrap' }}>
          {CONCEPT_PAGE.MAIN_MESSAGE_TEXT}
        </Typography>
      </Box>
      <Box>
        <img
          style={{
            height: 'auto',
            maxWidth: '290px',
          }}
          src={CONCEPT_PAGE.MASCOT_IMAGE_PATH}
        />
      </Box>
    </Container>
  );
}
