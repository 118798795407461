import { Box, Grid, Typography } from '@mui/material';

import { TEASER_PAGE } from '@/constants';
import { SpeechBubble } from '@/view/components/SpeechBubble';
import { TextHighlight } from '@/view/components/TextHighlight';
import {
  STYLES,
  FONT_SIZES,
  LINE_HEIGHTS,
  TEASER_STYLES,
} from '@/view/styling/theme';

const info = TEASER_PAGE.INFORMATION;

export function TeaserInformation({ showsPieChartSection = true }) {
  return (
    <Grid container sx={STYLES.topPage.information.grid}>
      <Grid item sx={{ marginTop: '18px' }}>
        <TextHighlight
          text={info.BODY_CAPTION_1}
          commonTextStyle={STYLES.topPage.information.header}
        />
      </Grid>
      <Grid item sx={{ marginTop: '36px' }}>
        <Box>
          <SpeechBubble tipSize="12px" style={{ width: '70%' }}>
            <TextHighlight
              commonTextStyle={{
                fontSize: FONT_SIZES.sm,
                lineHeight: LINE_HEIGHTS.md,
              }}
              text={info.BODY_CAPTION_1_FOOTER.BODY_LINE_1}
            />
          </SpeechBubble>
          <img
            style={{ marginTop: '-8px', width: '85%' }}
            src={info.BODY_CAPTION_1_FOOTER.BODY_IMAGE_1}
          />
        </Box>
      </Grid>
      <Grid item container sx={{ marginTop: '40px' }}>
        <Grid item>
          <Typography sx={TEASER_STYLES.information.description}>
            {info.BODY_LINE_2}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction={'column'} sx={{ marginTop: '40px' }}>
        <Grid item width="100%">
          <img style={{ width: '100%' }} src={info.BODY_IMAGE_2} />
        </Grid>
        <Grid item sx={{ marginTop: '16px' }}>
          <Typography sx={TEASER_STYLES.information.description}>
            {info.BODY_LINE_1}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction={'column'}
        sx={{ marginBottom: '20px', marginTop: '40px' }}
      >
        <Grid item width="100%">
          <img style={{ width: '100%' }} src={info.BODY_IMAGE_3} />
        </Grid>
        <Grid item sx={{ marginTop: '16px' }}>
          <Typography sx={TEASER_STYLES.information.description}>
            {info.BODY_LINE_3}
          </Typography>
        </Grid>
      </Grid>
      {showsPieChartSection && (
        <Grid
          item
          container
          marginBottom={5}
          rowSpacing={3}
          sx={{ justifyContent: 'center' }}
        >
          <Grid item>
            <TextHighlight
              text={info.BODY_CAPTION_2}
              commonTextStyle={STYLES.topPage.information.header}
            />
          </Grid>
          <Grid item width="100%">
            <SpeechBubble text={info.BODY_LINE_4} />
            <img style={{ width: '80%' }} src={info.BODY_IMAGE_4} />
            <Box sx={{ pt: 3 }}>
              <Typography sx={{ fontSize: FONT_SIZES.xs }}>
                {info.REMARK}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
