import React from 'react';

import { createLogger } from '@/domain/utils/logger';
import { InternalServerError } from '@/view/pages/InternalServerError';

type Props = {
  children: React.ReactElement;
  onCatchError?: (error: Error, errorInfo?: unknown) => void;
};

type State = {
  hasError: boolean;
};

const logger = createLogger('@ErrorBoundary');

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
    this.eventHandler = this.updateError.bind(this);
  }

  eventHandler: (event: PromiseRejectionEvent) => void;

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  updateError(event: PromiseRejectionEvent) {
    logger.error('Caught a unhandled rejection', event);
    this.setState({ hasError: true });
    this.props.onCatchError?.(event.reason);
  }

  componentDidCatch(error: Error, errorInfo: unknown) {
    logger.error('Caught a uncaught error', error, errorInfo);
    this.props.onCatchError?.(error, errorInfo);
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.eventHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.eventHandler);
  }

  render() {
    if (this.state.hasError) {
      return <InternalServerError />;
    }
    return this.props.children;
  }
}
