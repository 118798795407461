import { Grid, Typography, Box, Card } from '@mui/material';
import { useState } from 'react';

import { STYLES } from '@/view/styling/theme';

type Props = {
  body1: string;
  body2: string;
  body3: string;
  imagePerson: string;
  readless: string;
  readmore: string;
  title: string;
};

const FullDescription = ({
  text1,
  text2,
}: {
  text1: string;
  text2: string;
}) => (
  <>
    <Box sx={{ mt: 2 }}>
      <Typography component="p" sx={STYLES.topPage.voicesSectionBody}>
        {text1}
      </Typography>
    </Box>
    <Box sx={{ mt: 2 }}>
      <Typography component="p" sx={STYLES.topPage.voicesSectionBody}>
        {text2}
      </Typography>
    </Box>
  </>
);

const truncateText = (text: string, length: number) => {
  if (text.length <= length) {
    return text;
  }
  return text.substring(0, length) + '...';
};

export function VoicesItemCard({
  body1,
  body2,
  body3,
  imagePerson,
  readless,
  readmore,
  title,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const truncatedTextLength = body1.length > 28 ? 39 : 53;

  return (
    <Card sx={{ boxShadow: 'none', width: '270px' }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        gap={2}
        sx={{
          border: 1,
          borderColor: 'grey.100',
          borderRadius: 3,
          minHeight: '272px',
          padding: '24px',
        }}
      >
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} width="100%">
              <Box sx={{ mb: 2 }}>
                <img width="56px" height="56px" src={imagePerson} />
              </Box>
            </Grid>
            <Grid item xs={12} width="100%">
              <Typography component="h5" sx={STYLES.topPage.voicesSectionTitle}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12} width="100%">
              <Typography
                component="h3"
                sx={STYLES.topPage.voicesSectionSubtitle}
              >
                {body1}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {isExpanded ? (
            <FullDescription text1={body2} text2={body3} />
          ) : (
            <Typography component="p" sx={STYLES.topPage.voicesSectionBody}>
              {truncateText(body2 + body3, truncatedTextLength)}
              <Typography
                component="span"
                onClick={handleExpandClick}
                sx={{
                  ...STYLES.topPage.voicesSectionBody,
                  ...STYLES.topPage.voicesTextExpand,
                }}
              >
                {readmore}
              </Typography>
            </Typography>
          )}
          {isExpanded && (
            <Typography
              component="span"
              onClick={handleExpandClick}
              sx={{
                ...STYLES.topPage.voicesSectionBody,
                ...STYLES.topPage.voicesTextExpand,
              }}
            >
              {readless}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}
