import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ComponentType } from 'react';

import { BACTERIA_TYPE } from '@/constants';
import { BacteriaType, NormalizedAssessment } from '@/types/network';
import { STYLES } from '@/view/styling/theme';

function getBacteriaImage(primaryBacteria: BacteriaType | 'UNMEASURABLE') {
  if (primaryBacteria === 'UNMEASURABLE')
    return `/images/reports/emoji_grey.svg`;
  return BACTERIA_TYPE[primaryBacteria].IMAGE_PATH;
}

export const AssessmentReportCard: ComponentType<{
  displayGardenScore?: boolean;
  report: NormalizedAssessment['report'];
  testCompletedAt: string;
}> = ({ displayGardenScore = true, report, testCompletedAt }) => {
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item xs={10}>
        <Grid container gap={1}>
          <Grid
            item
            xs={1.5}
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box sx={STYLES.emojiBox}>
              <img
                src={getBacteriaImage(
                  report?.reportSummary.primaryBacteria || 'UNMEASURABLE'
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={9.5}>
            <Grid container sx={{ ml: 1 }}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: 'grey.300',
                    fontSize: '14px',
                    fontWeight: 300,
                    lineHeight: '21px',
                  }}
                >
                  {dayjs(testCompletedAt).format('YYYY年M月D日')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: 'text.primary',
                    fontSize: '16px',
                    fontWeight: 600,
                    lineHeight: '24px',
                  }}
                >
                  {report?.reportSummary?.title === '測定不可'
                    ? report?.reportSummary?.title
                    : `${report?.reportSummary?.title}タイプ`}
                </Typography>
              </Grid>
              {displayGardenScore && (
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: 'text.primary',
                      fontSize: '12px',
                      fontWeight: 600,
                      lineHeight: '18px',
                    }}
                  >
                    <Box component="span" sx={{ mr: 2 }}>
                      ガーデンスコア
                    </Box>
                    {report?.reportSummary.gardenScore || `-`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={1}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <ArrowForwardIosIcon
          sx={{
            color: 'grey.600',
            height: 15,
          }}
        />
      </Grid>
    </Grid>
  );
};
