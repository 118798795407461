import * as _ from 'lodash';

import {
  verifyAccessToken,
  getAccessTokenAndSessionUser,
  redirectToLogin,
  getTermsUrl,
} from '@/domain/middlewares/auth/utils';
import { getState, store } from '@/domain/store';
import * as reducers from '@/domain/store/reducers';
import { createLogger } from '@/domain/utils/logger';

const logger = createLogger('@domain/middlewares/auth');

/**
 * Refresh access token
 *
 * @param forceRefreshing - A optional value. If it's true, force refresh
 * @param skipRedirecting - A optional value. If it's true, skip redirecting to the login page
 * @remarks
 * - Check if the access token in the state is valid
 * - If invalid, refresh the token.
 * - If the request for the refreshing token is failed (and skipRedirecting is not true), redirect to login page or login page on IDPF if already on login page
 */
export async function refreshAccessTokenAndSetUserState(
  forceRefreshing?: boolean,
  skipRedirecting?: boolean
) {
  logger.debug('refreshAccessTokenAndSetUserState');
  const state = getState();
  if (!forceRefreshing && (await verifyAccessToken(state.user.token))) {
    logger.debug('access token is valid');
    return {
      token: state.user.token,
      userId: state.user.meijiId,
    };
  }
  const res = await getAccessTokenAndSessionUser();
  if (!res) {
    logger.debug('refreshAccessTokenAndSetUserState: Session error');
    if (!skipRedirecting) {
      redirectToLogin();
    }
    throw new Error('Session error');
  }
  const meijiId = `${res.user.userId || ''}`;
  const yearOfBirth = `${res.user.yearOfBirth || ''}`;
  const monthOfBirth = `${res.user.monthOfBirth || ''}`;
  const dayOfBirth = `${res.user.dayOfBirth || ''}`;
  const token = res.accessToken;
  if (!state.termsUrl) {
    const termsUrl = await getTermsUrl();
    reducers.setTermsUrl(store, termsUrl || '');
  }
  reducers.setUser(store, {
    ...state.user,
    ..._.omit(res.user, [
      'userId',
      'yearOfBirth',
      'monthOfBirth',
      'dayOfBirth',
    ]),
    dayOfBirth,
    meijiId,
    monthOfBirth,
    token,
    yearOfBirth,
  });
  return {
    token,
    userId: meijiId,
  };
}
