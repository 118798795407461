import { Box, Collapse, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';

import { GraphBar } from '@/types/index';
import { ReportBar } from '@/view/components/Report/ReportBar';
import { GraphLegend } from '@/view/components/Report/ReportLegend';
import {
  ReportTitleWithAdvice,
  RightActionType,
} from '@/view/components/Report/ReportTitleWithAdvice';
import { BORDER_RADIUS } from '@/view/styling/theme';

type Props = {
  children?: React.ReactNode;
  firstBar: GraphBar;
  isAboveTarget: boolean;
  legend: string[];
  secondBar: GraphBar;
  showChildren: boolean;
  title: string;
};

export function ReportGraphCard({
  firstBar,
  secondBar,
  legend,
  title,
  isAboveTarget,
  showChildren = false,
  children,
}: Props) {
  const [shouldShowAdvice, setShowAdvice] = useState(showChildren);
  const rightActionType: RightActionType = isAboveTarget
    ? 'good'
    : 'kaizen-needed';

  useEffect(() => {
    setShowAdvice(showChildren);
  }, [showChildren]);

  return (
    <Box
      sx={{
        bgcolor: 'common.white',
        borderRadius: BORDER_RADIUS.lg,
      }}
    >
      <Grid container>
        <Grid item xs={12} mx={1} mb={2}>
          <ReportTitleWithAdvice
            isOpen={shouldShowAdvice}
            onClick={
              children ? () => setShowAdvice(!shouldShowAdvice) : undefined
            }
            rightActionType={rightActionType}
            title={title}
          />
        </Grid>
        <Grid item xs={12} mb={1}>
          <ReportBar bar={firstBar} length={legend.length} />
        </Grid>
        <Grid item xs={12} mb={1}>
          <ReportBar bar={secondBar} length={legend.length} />
        </Grid>
        <GraphLegend list={legend} showBorderBottom={shouldShowAdvice} />
      </Grid>
      <Collapse in={shouldShowAdvice}>{children}</Collapse>
    </Box>
  );
}
