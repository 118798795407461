/* eslint-disable @typescript-eslint/naming-convention */
import { createTheme } from '@mui/material/styles';

export const FONT_SIZES = {
  lg: '20px',
  md: '16px',
  mmd: '18px',
  sm: '14px',
  xl: '24px',
  xs: '12px',
  xxl: '26px',
  xxs: '11px',
  xxxl: '32px',
  xxxs: '10px',
};
export const LINE_HEIGHTS = {
  lg: '24px',
  md: '20px',
  mmd: '22px',
  sm: '16px',
  xl: '28.8px',
  xxl: '30px',
  xxxl: '36px',
  xxxxl: '39px',
};
export const BORDER_RADIUS = {
  lg: 1.5,
  md: 1,
  sm: 0.8,
  xl: 3,
  xs: 0.5,
};

export const defaultThemeColor = '#FF8718';

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          fontWeight: 300,
        },
        root: ({ theme }) => ({
          '&.Mui-disabled': {
            color: theme.palette.common.white,
          },
          '&.MuiButtonGroup-grouped': {
            '&.active': {
              '& .MuiSvgIcon-root ': {
                color: theme.palette.primary.main,
                height: '16px',
                marginRight: '8px',
                width: '16px',
              },
              backgroundColor: 'rgba(238, 71, 71, 0.1)',
              fontWeight: 400,
            },
            borderColor: theme.palette.grey[100],
            fontSize: FONT_SIZES.md,
            height: '48px',
            lineHeight: '48px',
            padding: [0, '24px'],
          },
          borderRadius: 6,
          boxShadow: 'none',
          color: theme.palette.common.white,
          fontWeight: 600,
          height: 46,
          lineHeight: 1,
          textTransform: 'none',
        }),
        sizeLarge: {
          fontSize: FONT_SIZES.md,
        },
        sizeMedium: {
          fontWeight: 600,
          height: 38,
          lineHeight: 1,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: '48px',
          paddingLeft: 16,
          paddingRight: 16,
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: '16px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          [`&.checked`]: {
            backgroundColor: `rgba(238, 71, 71, 0.06);`,
          },
          borderRadius: 6,
          margin: 0,
          padding: 8,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 10,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#000',
          fontSize: FONT_SIZES.sm,
          fontWeight: 800,
          marginBottom: 4,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingBottom: 14.5,
          paddingTop: 14.5,
        },
        notchedOutline: ({ theme }) => ({
          '.MuiInputBase-readOnly &&': {
            borderColor: theme.palette.grey[100],
          },
          borderColor: theme.palette.grey[600],
          borderRadius: 6,
        }),
        root: ({ theme }) => ({
          '&.MuiInputBase-readOnly': {
            backgroundColor: theme.palette.grey[50],
          },
        }),
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: ({ theme }) => ({
          borderColor: theme.palette.grey[600],
          marginLeft: -4,
          marginRight: -4,
        }),
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&,&.Mui-completed': {
            color: theme.palette.grey[100],
          },
        }),
        text: {
          fontSize: FONT_SIZES.xxxs,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          '&.MuiStepLabel-alternativeLabel': {
            marginTop: 10,
          },
          color: theme.palette.grey[600],
          fontSize: FONT_SIZES.xxs,
          fontWeight: 800,
          whiteSpace: 'pre',
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'normal',
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: 'span',
          body2: 'span',
          caption: 'small',
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'span',
          subtitle2: 'span',
        },
      },
    },
  },
  palette: {
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    error: {
      main: '#FF002E',
    },
    grey: {
      '100': '#DBDFE5',
      '200': '#edf2ff',
      '300': '#828A9B',
      '400': '#5A5D65',
      '50': '#F3F5F7',
      '500': '#B4B9C3',
      '600': '#B0B7C3',
    },
    info: {
      light: '#50ADD4',
      main: '#0087D2',
    },
    primary: {
      '100': 'rgba(238, 71, 71, 0.1)',
      '50': 'rgba(255, 84, 0, 0.06)',
      main: '#FF8718',
    },
    text: {
      primary: '#2F2F2F',
      secondary: 'rgba(0, 0, 0, 0.38)',
    },
  },
  typography: {
    caption: {
      color: 'grey.300',
      fontSize: 14,
      fontWeight: 400,
      marginBottom: 0,
    },
    fontFamily: [
      '"Hiragino Kaku Gothic Pro"',
      '"Noto Sans JP"',
      'sans-serif',
    ].join(','),
    h2: {
      fontSize: FONT_SIZES.md,
      fontWeight: 800,
      lineHeight: 4,
      textAlign: 'left',
    },

    h3: {
      fontSize: FONT_SIZES.xs,
      fontWeight: 800,
    },

    h5: {
      color: 'rgba(0, 0, 0, 0.38)',
      fontSize: FONT_SIZES.lg,
      marginBottom: 0,
      textAlign: 'left',
    },
    //question title
    h6: {
      fontSize: FONT_SIZES.md,
      fontWeight: 800,
      marginBottom: 0,
      textAlign: 'left',
    },
  },
});

export const EMOJI_BACKGROUND_COLOR = {
  blue: 'rgba(102, 179, 211, 0.1)',
  grey: 'rgba(130, 138, 155, 0.1)',
  red: 'rgba(238, 70, 70, 0.1)',
  yellow: 'rgba(233, 172, 80, 0.1)',
};

export const TEASER_STYLES = {
  discountCouponImage: {
    backgroundColor: 'rgba(255, 84, 0, 0.06)',
    borderRadius: '6px',
    width: '100%',
  },
  heroTitle: {
    color: '#2F2F2F',
    fontSize: '29.93px',
    fontWeight: 700,
    lineHeight: '43.7px',
    whiteSpace: 'pre-wrap',
  },
  information: {
    description: {
      color: '#2F2F2F',
      display: 'inline-block',
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '23.36px',
      textAlign: 'left',
      whiteSpace: 'pre-wrap',
    },
  },
  preHeroTitle: {
    color: '#1C1C1E',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '23.04px',
    whiteSpace: 'pre-wrap',
  },
  reportContent: {
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '28.8px',
  },
  sectionTitle: {
    color: defaultThemeColor,
    fontFamily: 'Roboto',
    fontWeight: 600,
    lineHeight: '18.75px',
    size: '16px',
  },
  teaserHero: {
    backgroundColor: '#F8F6F4',
    minHeight: 620, // prevent overflow when browser height is too small
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('sm')]: { height: '70vh' },
    [theme.breakpoints.between('sm', 'md')]: { height: '60vh' },
    [theme.breakpoints.up('md')]: { height: '65vh' },
    [theme.breakpoints.up('lg')]: { height: '75vh' },
  },
};

export const STYLES = {
  accordionFaq: {
    '&:before': {
      display: 'none',
    },
    '&:first-of-type': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    '&:last-of-type': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    border: '1px solid',
    borderColor: 'grey.100',
    borderRadius: '8px',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  accordionTitle: {
    fontSize: FONT_SIZES.md,
    lineHeight: '22.4px',
    whiteSpace: 'pre-wrap',
  },
  accordionTitleExpand: {
    fontSize: FONT_SIZES.md,
    fontWeight: 700,
    lineHeight: '22.4px',
    whiteSpace: 'pre-wrap',
  },
  accordionTitle_Q: {
    color: 'primary.main',
    fontSize: FONT_SIZES.md,
    fontWeight: 600,
    lineHeight: '24px',
    paddingRight: 2,
    whiteSpace: 'pre-wrap',
  },
  bottomAlignFooter: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  carousel: {
    container: {
      display: 'flex',
      flex: '0 0 100%',
      height: '100%',
      margin: '0 auto',
      maxWidth: {
        md: 'md',
        sm: 'sm',
      },
    },
    item: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      width: '100%',
    },
    track: {
      animation: 'slide 20s linear infinite',
      display: 'flex',
      gap: '20px',
      paddingRight: '20px',
      willChange: 'transform',
    },
  },
  closeIconAccountDrawer: {
    color: 'common.white',
    height: '56px',
    left: `calc(100% - 246px - 50px)`,
    position: 'fixed',
    top: '-5px',
    width: '56px',
    zIndex: 9999,
  },
  closeIconGeneralDrawer: {
    color: 'common.white',
    height: '56px',
    position: 'fixed',
    right: `calc(100% - 246px - 50px)`,
    top: '-5px',
    width: '56px',
    zIndex: 9999,
  },
  customModal: {
    '&.MuiDialog-paperScrollPaper': {
      opacity: 1,
      transform: 'translateY(0)',
    },
    borderRadius: '12px',
    minWidth: '343px',
    opacity: 0,
    transform: 'translateY(-40px)',
    transition: 'opacity 500ms ease-out, transform 500ms ease-out',
  },
  discountTag: {
    description: {
      color: 'primary.main',
      display: 'block',
      fontSize: FONT_SIZES.xxxs,
      fontWeight: 400,
      lineHeight: '10px',
      marginTop: '2px',
    },
    discountAmount: {
      color: 'primary.main',
      display: 'block',
      fontSize: FONT_SIZES.sm,
      fontWeight: 590,
      lineHeight: '14px',
    },
    discountTagWrapper: {
      alignItems: 'center',
      backgroundColor: 'primary.50',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      padding: '5px',
    },
  },
  drinkList: {
    listItemDescription: {
      color: 'text.primary',
      fontSize: FONT_SIZES.sm,
      fontWeight: 300,
      lineHeight: '19.6px',
    },
    listItemImageWrapper: { marginLeft: '-16px' },
    listItemTitle: {
      color: 'text.primary',
      fontSize: FONT_SIZES.md,
      fontWeight: 600,
      lineHeight: '22.4px',
      whiteSpace: 'pre-line',
    },
    listItemWrapper: { height: '102px' },
    listWrapper: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginX: 2,
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
      },
    },
    moreInfo: {
      fontSize: FONT_SIZES.sm,
      fontWeight: 300,
      lineHeight: '19.6px',
    },
    title: {
      fontSize: FONT_SIZES.lg,
      fontWeight: 600,
      lineHeight: '33.2px',
      textAlign: 'center',
      whiteSpace: 'pre-line',
    },
  },
  emojiBox: {
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    height: '48px',
    justifyContent: 'center',
    textAlign: 'center',
    width: '48px',
  },
  flipImage: { transform: `scale(-1, 1)` },
  footer: {
    backgroundColor: '#ECEFF2',
    paddingBottom: 3,
    paddingTop: 4,
    paddingX: 2,
  },
  fullWidthCenterAlignedFlexbox: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  greyBoxInstructionCard: {
    description: {
      fontSize: FONT_SIZES.md,
      fontWeight: 300,
      lineHeight: LINE_HEIGHTS.xl,
      whiteSpace: 'pre-wrap',
    },
    title: {
      fontSize: FONT_SIZES.md,
      fontWeight: 600,
      lineHeight: LINE_HEIGHTS.xl,
    },
  },
  greyBoxStepsWrapper: {
    alignContent: 'center',
    backgroundColor: 'grey.50',
    borderRadius: '12px',
    height: '184px',
    justifyContent: 'center',
  },
  hero: {
    backgroundColor: '#F8F6F4',
    height: '70vh',
    minHeight: 600,
    // prevent overflow when browser height is too small
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.between('sm', 'md')]: { height: '75vh' },
  },
  hero_title: {
    fontSize: FONT_SIZES.lg,
    fontWeight: 800,
    whiteSpace: 'pre-wrap',
  },
  hero_title2: {
    fontSize: FONT_SIZES.xxxl,
    fontWeight: 800,
    whiteSpace: 'pre-wrap',
  },
  instruction: {
    bulletList: {
      color: theme.palette.error.main,
      listStyleType: 'disc',
    },
    pageTitle: {
      display: 'block',
      fontSize: FONT_SIZES.lg,
      fontWeight: 600,
      lineHeight: LINE_HEIGHTS.xl,
      textAlign: 'center',
      width: '100%',
    },
    speechBubble: {
      backgroundColor: '#fff7f7',
      borderRadius: BORDER_RADIUS.xl,
      padding: '20px',
      width: '100%',
    },
    topWarningDescription: {
      color: 'error.main',
      fontSize: FONT_SIZES.md,
      lineHeight: LINE_HEIGHTS.md,
    },
    topWarningTitle: {
      color: 'error.main',
      fontSize: FONT_SIZES.md,
      fontWeight: 600,
      lineHeight: LINE_HEIGHTS.md,
    },
  },
  latestReportCard: {
    borderColor: 'transparent',
    borderRadius: 3,
    boxShadow: 0,
    m: 0,
    paddingLeft: 2,
    paddingRight: 2,
  },
  leftImageRightTextGrid: {
    backgroundColor: 'grey.50',
    borderRadius: '8px',
    marginBottom: '8px',
  },
  mainHeading: {
    display: 'block',
    fontSize: FONT_SIZES.md,
    fontWeight: 600,
    lineHeight: LINE_HEIGHTS.xl,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    width: '100%',
  },
  myPageHeading: {
    display: 'block',
    fontSize: FONT_SIZES.lg,
    fontWeight: 600,
    marginTop: '24px',
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
    width: '100%',
  },
  myPageMascot: {
    marginBottom: 2,
    marginTop: 8,
    textAlign: 'center',
  },
  orderList: {
    listItemDescription: {
      color: theme.palette.grey[300],
      fontSize: FONT_SIZES.sm,
      fontWeight: 300,
      lineHeight: '21px',
    },
    listItemImageWrapper: {
      display: 'flex',
      height: '100px',
      marginLeft: '-16px',
      width: '110px',
    },
    listItemTitle: {
      color: 'text.primary',
      fontSize: FONT_SIZES.md,
      fontWeight: 600,
      lineHeight: '22.4px',
      whiteSpace: 'pre-line',
    },
    listItemWrapper: { height: '100%' },
    listWrapper: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginX: 2,
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
      },
    },
    moreInfo: {
      fontSize: FONT_SIZES.sm,
      fontWeight: 300,
      lineHeight: '19.6px',
    },
    tag: {
      background: theme.palette.common.white,
      borderColor: theme.palette.grey[100],
      borderRadius: '4px',
      fontSize: '11px',
      fontWeight: 300,
      height: 19,
      lineHeight: '11px',
      margin: 0,
      padding: 0,
      width: 60,
    },
    title: {
      fontSize: FONT_SIZES.lg,
      fontWeight: 600,
      lineHeight: '33.2px',
      textAlign: 'center',
      whiteSpace: 'pre-line',
    },
  },
  pageTitle: {
    color: 'text.primary',
    display: 'block',
    fontSize: FONT_SIZES.mmd,
    fontWeight: 600,
    lineHeight: LINE_HEIGHTS.xl,
    paddingY: 2,
    width: '100%',
  },
  pageTitleForProducts: {
    color: 'text.primary',
    display: 'block',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '33.6px',
    marginLeft: 0,
    paddingY: 2,
    width: '100%',
  },
  pageTitleWithBackButton: {
    color: 'text.primary',
    display: 'block',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '22px',
    paddingY: 2,
    width: '100%',
  },
  productAccordion: {
    border: 'none',
    boxShadow: 'none',
    margin: 0,
    paddingBottom: '12px',
    paddingTop: '12px',
  },
  productAccordionDetails: {
    padding: 0,
    pb: 2,
    pt: 1,
  },
  productAccordionImage: {
    height: 100,
    width: 100,
  },
  productDetailFirstSet: {
    explainingCocoaButton: {
      borderColor: 'grey.100',
      color: 'text.primary',
      fontSize: FONT_SIZES.sm,
      fontWeight: 300,
      lineHeight: LINE_HEIGHTS.md,
      textAlign: 'center',
      textTransform: 'none',
    },
    productInfoRowTitle: {
      fontSize: FONT_SIZES.sm,
      fontWeight: 300,
      lineHeight: '22.4px',
    },
  },
  productDetailItem: {
    imageWrapper: { marginLeft: '-16px' },
    priceItemWrapper: {
      alignItems: 'center',
      color: 'text.primary',
      display: 'flex',
      fontSize: FONT_SIZES.md,
      fontWeight: 600,
      lineHeight: '16px',
    },
    priceSectionWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '5px',
    },
    purchaseFrequencyText: {
      fontSize: FONT_SIZES.xs,
      fontWeight: 400,
      lineHeight: '12px',
    },
    purchaseTaxText: {
      fontSize: FONT_SIZES.xxs,
      fontWeight: 400,
      lineHeight: '11px',
      marginLeft: '6px',
    },
    sectionTitle: {
      color: 'text.primary',
      fontSize: FONT_SIZES.lg,
      fontWeight: 600,
      lineHeight: '30px',
    },
    subTitle: {
      color: 'text.primary',
      fontSize: FONT_SIZES.xs,
      fontWeight: 300,
      lineHeight: '16.8px',
    },
    title: {
      color: 'text.primary',
      fontSize: FONT_SIZES.md,
      fontWeight: 600,
      lineHeight: '22.4px',
    },
  },
  productInfoRowBox: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
  productInfoRowGridContainer: {
    alignItems: 'stretch',
    flexWrap: 'nowrap',
    pb: '1px',
  },
  productListItem: {
    imageCaption: {
      fontSize: FONT_SIZES.xxs,
      fontWeight: 300,
      left: '50%',
      lineHeight: LINE_HEIGHTS.sm,
      opacity: 1,
      position: 'absolute',
      textAlign: 'center',
      top: '80%',
      transform: 'translate(-50%, 50%)',
      width: '100%',
    },
    nextButtonLabel: {
      fontSize: FONT_SIZES.md,
      fontWeight: 600,
      lineHeight: LINE_HEIGHTS.md,
    },
    priceItemWrapper: {
      alignItems: 'center',
      color: 'text.primary',
      display: 'flex',
      fontSize: FONT_SIZES.xl,
      fontWeight: 600,
      lineHeight: '24px',
    },
    priceSectionWrapper: {
      alignItems: 'center',
      display: 'flex',
      marginTop: '0px',
    },
    purchaseFrequencyText: {
      fontSize: FONT_SIZES.md,
      fontWeight: 400,
      lineHeight: LINE_HEIGHTS.sm,
    },
    purchaseTaxText: {
      fontSize: FONT_SIZES.sm,
      fontWeight: 400,
      lineHeight: '14px',
      marginLeft: '8px',
    },
    sellerText: {
      color: 'grey.300',
      fontSize: FONT_SIZES.xxs,
      fontWeight: 300,
      lineHeight: LINE_HEIGHTS.md,
    },
    subTitle: {
      color: 'text.primary',
      fontSize: FONT_SIZES.sm,
      fontWeight: 600,
      lineHeight: '19.6px',
    },
    tag: {
      background: theme.palette.common.white,
      borderColor: theme.palette.grey[100],
      borderRadius: '4px',
      lineHeight: 'normal',
      marginRight: '8px',
      width: 70,
    },
    title: {
      color: 'text.primary',
      fontSize: FONT_SIZES.lg,
      fontWeight: 600,
      lineHeight: '28px',
    },
  },
  productNameBox: {
    flexGrow: 1,
    marginLeft: 2,
    marginRight: 4,
  },
  productNameTypography: {
    fontSize: FONT_SIZES.md,
    fontWeight: 600,
    lineHeight: '22.4px',
    marginBottom: 0,
    textAlign: 'left',
  },
  purchaseProductCard: {
    purchaseCardWrapper: {
      border: '1px solid',
      borderColor: 'grey.600',
      borderRadius: '8px',
      padding: 2,
    },
    subtitle: {
      color: '#818487',
      display: 'block',
      fontSize: FONT_SIZES.sm,
      fontWeight: 600,
      lineHeight: LINE_HEIGHTS.sm,
    },
    title: {
      color: 'primary.main',
      display: 'block',
      fontSize: FONT_SIZES.lg,
      fontWeight: 600,
      lineHeight: LINE_HEIGHTS.md,
      marginBottom: 1,
    },
  },
  reportList: {
    applyForTestText: {
      color: 'primary.main',
      display: 'block',
      fontSize: FONT_SIZES.sm,
      lineHeight: LINE_HEIGHTS.mmd,
    },
    bacteriaDescription: {
      image: {
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'center',
      },
      item: {
        description: {
          color: 'text.primary',
          fontSize: FONT_SIZES.sm,
          fontWeight: 300,
          lineHeight: LINE_HEIGHTS.mmd,
        },
        title: {
          color: 'text.primary',
          fontSize: FONT_SIZES.sm,
          fontWeight: 600,
          lineHeight: LINE_HEIGHTS.md,
          whiteSpace: 'pre-line',
        },
      },
      title: {
        fontSize: FONT_SIZES.sm,
        fontWeight: 600,
        lineHeight: LINE_HEIGHTS.mmd,
        whiteSpace: 'pre-line',
      },
    },
    speechBubble: {
      backgroundColor: '#F3F5F7',
      borderRadius: BORDER_RADIUS.xl,
      marginTop: 3,
      padding: 2,
      width: '100%',
    },
    yourGardenScore: {
      fontSize: FONT_SIZES.sm,
      fontWeight: 600,
      lineHeight: LINE_HEIGHTS.mmd,
    },
  },
  reportRankingCard: {
    borderRadius: 3,
    boxShadow: 0,
    height: '180px',
    m: 0,
    paddingLeft: 2,
    paddingRight: 0,
  },
  secondaryButton: {
    borderColor: 'grey.100',
    borderRadius: '6px',
    height: '35px',
    width: '59px',
  },
  secondaryButtonBottomPage: {
    '&.MuiButton-root:hover': {
      '@media (hover: none)': {
        borderColor: 'primary.main',
        color: 'primary.main',
        lineHeight: '21.44px',
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
      },
    },
    borderColor: 'primary.main',
    color: 'primary.main',
    lineHeight: '21.44px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  secondaryButtonModal: {
    '&.MuiButton-root:hover': {
      '@media (hover: none)': {
        borderColor: 'primary.main',
        color: 'primary.main',
      },
    },
    backgroundColor: 'common.white',
    borderColor: 'grey.100',
    color: 'text.primary',
    fontSize: FONT_SIZES.md,
    fontWeight: 600,
    lineHeight: LINE_HEIGHTS.md,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  shippingTag: {
    freeShippingText: {
      color: 'grey.300',
      fontSize: FONT_SIZES.sm,
      fontWeight: 400,
      lineHeight: '14px',
    },
    shippingIcon: {
      color: 'grey.600',
      height: '100%',
      transform: 'rotateY(180deg)',
      width: '30px',
    },
    shippingIconAmount: {
      color: 'common.white',
      fontSize: FONT_SIZES.sm,
      fontWeight: 600,
      left: '17px',
      position: 'absolute',
      top: '3px',
    },
    shippingIconCurrency: {
      color: 'common.white',
      fontSize: '9px',
      fontWeight: 600,
      left: '10px',
      position: 'absolute',
      top: '9px',
    },
    shippingIconWrapper: {
      height: '30px',
      mr: '5px',
      position: 'relative',
      width: '30px',
    },
    shippingTagWrapper: {
      alignItems: 'center',
      border: '1px solid',
      borderColor: 'grey.600',
      display: 'flex',
      paddingX: '4px',
    },
  },
  smallOutlinedIconButton: {
    '.MuiButton-startIcon': {
      mr: 0.5,
    },
    ':hover': {
      borderColor: 'grey.100',
    },
    borderColor: 'grey.100',
    borderRadius: 6,
    height: 32,
    mt: -1,
  },
  speechBubble: {
    // cSpell:disable-next-line
    backgroundColor: theme.palette.common.white,
    borderRadius: 17,

    padding: [1, 1],
    whiteSpace: 'pre-wrap',
  },
  suffixPlacement: {
    display: 'block',
    marginLeft: 1,
    marginTop: 4,
    maxHeight: 24,
  },
  tableSectionTitle: {
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '22.4px',
  },
  textHighlight: {
    commonStyle: {
      fontSize: FONT_SIZES.lg,
      fontWeight: 800,
      lineHeight: LINE_HEIGHTS.lg,
      whiteSpace: 'pre-wrap',
    },
    highlightTextStyle: {
      color: '#F7871A',
    },
    normalTextStyle: {
      color: 'common.black',
    },
  },
  topBarAccountDrawer: {
    '& .MuiDrawer-paper': {
      backgroundColor: 'grey.50',
    },
  },
  topBarAccountDrawerAccountCircleIcon: {
    color: '#8AC1D8',
    fontSize: '36px',
    lineHeight: '36px',
  },
  topBarAccountDrawerListItem: {
    '& .MuiListItemText-root': {
      alignContent: 'center',
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '108px',
    paddingTop: '20px',
  },
  topBarAccountDrawerListItemIcon: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  topBarDrawer: {
    width: 246,
  },
  topBarDrawerListItemIcon: {
    color: '#B0B7C3',
    height: '20px',
    width: '20px',
  },
  topBarGeneralDrawerListItem: {
    '& .MuiListItemIcon-root': {
      minWidth: '36px',
    },
    height: '56px',
  },
  topPage: {
    aboutImage: {
      marginRight: '-16px',
      marginTop: '-22px',
    },
    buttonText: {
      fontSize: FONT_SIZES.md,
      fontWeight: 300,
      lineHeight: '22.4px',
      whiteSpace: 'pre-wrap',
    },
    buttonTextRounded: {
      fontSize: FONT_SIZES.xs,
      fontWeight: 600,
      lineHeight: '16.8px',
      ml: 1,
    },
    caution: {
      grid: {
        background: '#F8F6F4',
        justifyContent: 'center',
        p: 2.5,
        // textAlign: 'center',
      },
    },
    chatDialog: {
      alignItems: 'center',
      backgroundColor: 'common.white',
      borderRadius: '12px',
      display: 'flex',
      height: '82px',
      marginLeft: '42px',
      position: 'relative',
    },
    chatDialogText: {
      fontSize: FONT_SIZES.sm,
      fontWeight: 300,
      lineHeight: '25.2px',
      whiteSpace: 'pre-wrap',
    },
    chatDialogTextWrapper: {
      marginLeft: '18px',
      marginRight: '15px',
      position: 'relative',
    },
    chatDialogTip: {
      backgroundColor: 'common.white',
      borderRadius: '10px',
      height: '26px',
      left: '-18px',
      marginLeft: 1,
      position: 'absolute',
      transform: 'scale3d(1, 0.6, 1) rotate(45deg)',
      width: '26px',
    },
    imageCaption: {
      color: 'grey.300',
      fontSize: FONT_SIZES.xxs,
      fontWeight: 300,
      lineHeight: '19.8px',
    },
    imageOverflowHidden: {
      overflow: 'hidden',
    },
    imageOverflowVisible: {
      overflow: 'visible',
    },
    information: {
      description: {
        display: 'inline-block',
        fontSize: FONT_SIZES.mmd,
        fontWeight: 300,
        lineHeight: LINE_HEIGHTS.lg,
        mt: 4,
        textAlign: 'left',
        whiteSpace: 'pre-wrap',
      },
      grid: {
        background: '#F8F6F4',
        justifyContent: 'center',
        p: 2.5,
        textAlign: 'center',
      },
      header: {
        fontSize: FONT_SIZES.xxl,
        fontWeight: 600,
        lineHeight: LINE_HEIGHTS.xxxl,
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
      },
    },
    mainArrowDown: {
      color: 'primary.main',
    },
    menu: {
      borderColor: '#E4E4E6',
      borderRadius: '24px',
      height: '92px',
    },
    menuText: {
      alignItems: 'center',
      display: 'flex',
      fontSize: FONT_SIZES.sm,
      fontWeight: 300,
      height: '40px',
      justifyContent: 'center',
      lineHeight: LINE_HEIGHTS.md,
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
    pageDescription1: {
      alignItems: 'center',
      display: 'flex',
      fontSize: FONT_SIZES.md,
      fontWeight: 300,
      justifyContent: 'center',
      lineHeight: '26.4px',
      marginBottom: 1,
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
    pageDescription2: {
      alignItems: 'center',
      color: 'grey.400',
      display: 'flex',
      fontSize: FONT_SIZES.xs,
      fontWeight: 300,
      justifyContent: 'center',
      lineHeight: '19.8px',
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
    partner: {
      grid: {
        background: '#F8F6F4',
        justifyContent: 'center',
        p: 2.5,
        // textAlign: 'center',
      },
    },
    product: {
      button: {
        backgroundColor: '#F7871A',
        marginBottom: 5,
        marginTop: 5,
      },
      container: {
        background: '#F8F6F4',
        justifyContent: 'center',
      },
      description: {
        display: 'inline-block',
        fontSize: FONT_SIZES.mmd,
        fontWeight: 800,
        lineHeight: LINE_HEIGHTS.lg,
        marginBottom: 2,
        pl: 1,
        textAlign: 'left',
        whiteSpace: 'pre-wrap',
      },
      grid: {
        background: '#F8F6F4',
        justifyContent: 'center',
        textAlign: 'left',
      },
      header: {
        fontSize: FONT_SIZES.xl,
        fontWeight: 800,
        lineHeight: LINE_HEIGHTS.lg,
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
      },
      imageSubtext: {
        fontSize: FONT_SIZES.xs,
        lineHeight: LINE_HEIGHTS.xl,
        pl: 1,
        py: 1,
      },
    },
    report: {
      description: {
        display: 'inline-block',
        fontSize: FONT_SIZES.md,
        lineHeight: LINE_HEIGHTS.lg,
        textAlign: 'left',
        whiteSpace: 'pre-wrap',
      },
      grid: {
        background: '#F8F6F4',
        justifyContent: 'center',
        p: 2.5,
        textAlign: 'center',
      },
    },
    secondaryButton: {
      backgroundColor: 'common.white',
      borderColor: 'grey.100',
      color: 'common.black',
      marginTop: 2,
    },
    secondaryButtonRounded: {
      ' .MuiButton-endIcon': {
        '& > *:nth-of-type(1)': {
          fontSize: FONT_SIZES.xl,
        },
      },
      ':hover': {
        backgroundColor: 'grey.50',
        borderColor: 'grey.100',
      },
      backgroundColor: 'common.white',
      borderColor: 'grey.100',
      borderRadius: '18px',
      color: 'common.black',
      height: 32,
      marginBottom: 3,
      marginTop: 1,
    },
    sectionTitle1: {
      color: '#FF8718', // 'primary.main',
      fontSize: FONT_SIZES.md,
      fontWeight: 700,
      lineHeight: '22.4px',
      whiteSpace: 'pre-wrap',
    },
    sectionTitle2: {
      fontSize: FONT_SIZES.xxl,
      fontWeight: 600,
      lineHeight: '36.4px',
      mt: 1,
      whiteSpace: 'pre-wrap',
    },
    serviceContent: {
      display: 'flex',
      flexDirection: 'column',
      py: 2,
      whiteSpace: 'pre-wrap',
    },
    serviceTitle: {
      display: 'flex',
      flexDirection: 'column',
      py: 2,
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
    stepSubSectionTitle: {
      alignItems: 'center',
      display: 'flex',
      fontSize: '18px',
      fontWeight: 600,
      justifyContent: 'center',
      lineHeight: '27px',
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
    stepWhiteBoxWrapper: {
      alignContent: 'center',
      alignItems: 'center',
      backgroundColor: 'common.white',
      border: '1px solid #DFE3E8',
      borderRadius: 2,
      justifyContent: 'center',
      marginBottom: 1.5,
      p: 2,
    },
    voicesSectionBody: {
      fontSize: FONT_SIZES.sm,
      fontWeight: 300,
      lineHeight: '22.4px',
      whiteSpace: 'pre-wrap',
    },
    voicesSectionPageBody: {
      fontSize: FONT_SIZES.md,
      fontWeight: 300,
      lineHeight: '25.6px',
      whiteSpace: 'pre-wrap',
    },
    voicesSectionSubtitle: {
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: LINE_HEIGHTS.lg,
      whiteSpace: 'pre-wrap',
    },
    voicesSectionTitle: {
      color: 'grey.300',
      fontSize: FONT_SIZES.xs,
      fontWeight: 600,
      lineHeight: '18px',
      whiteSpace: 'pre-wrap',
    },
    voicesTextExpand: { color: 'grey.300', cursor: 'pointer' },
  },
  whiteBackdrop: {
    backgroundColor: 'rgba(256,256,256,0.4)',
    zIndex: theme.zIndex.drawer + 1,
  },
};
