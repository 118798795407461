import { Box, Grid } from '@mui/material';

import { TEASER_PAGE } from '@/constants';
import { State } from '@/types';
import { Coupon } from '@/view/components/Teaser/Coupon';
import { DiscountedProduct } from '@/view/components/Teaser/DiscountedProduct';
import { Explanation } from '@/view/components/Teaser/Explanation';
import { Inquiry } from '@/view/components/Teaser/Inquiry';
import { TeaserCaution } from '@/view/components/Teaser/TeaserCaution';
import { TeaserFact } from '@/view/components/Teaser/TeaserFact';
import { TeaserHero } from '@/view/components/Teaser/TeaserHero';
import { TeaserInformation } from '@/view/components/Teaser/TeaserInformation';
import { TeaserReport } from '@/view/components/Teaser/TeaserReport';
import { TeaserService } from '@/view/components/Teaser/TeaserService';

export function Teaser({ state }: { state: State }) {
  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <TeaserHero />
        </Grid>
        <Grid item xs={12}>
          <Explanation />
        </Grid>
        <Grid item xs={12}>
          <Coupon hasBottomSection />
        </Grid>
        <Grid item xs={12}>
          <TeaserInformation showsPieChartSection={false} />
        </Grid>
        <Grid item xs={12}>
          <TeaserService serviceData={TEASER_PAGE.SERVICES} />
        </Grid>
        <Grid item xs={12}>
          <TeaserReport customSubtitle="腸内検査でわかること" />
        </Grid>
        <Grid item xs={12}>
          <DiscountedProduct />
        </Grid>
        <Grid item xs={12}>
          <TeaserFact />
        </Grid>
        <Grid item xs={12}>
          <Coupon />
        </Grid>
        <Grid item xs={12}>
          <TeaserCaution />
        </Grid>
        <Grid item xs={12}>
          <Inquiry />
        </Grid>
      </Grid>
    </Box>
  );
}
