import { Box, Button, Grid, Typography } from '@mui/material';

import * as user from '@/domain/middlewares/user';
import { STYLES } from '@/view/styling/theme';
const { MEIJI_SHOPIFY_CART_PRODUCTION_URL } = import.meta.env;

export function Coupon({
  hasBottomSection = false,
}: {
  hasBottomSection?: boolean;
}) {
  return (
    <Grid
      container
      sx={{ backgroundColor: 'white', padding: '10px', width: '100%' }}
    >
      <Grid
        container
        item
        sx={{
          backgroundColor: 'rgba(255, 84, 0, 0.06)',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'center',
          padding: '40px 16px 40px 16px',
          textAlign: 'center',
          width: '100%',
        }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: '26px',
              fontWeight: 600,
              lineHeight: '37.96px',
              textAlign: 'center',
              whiteSpace: 'pre-wrap',
            }}
          >
            {`インナーガーデンの\n先行予約受付中`}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ marginBottom: '-8px', marginTop: '32px' }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '28.8px',
              textAlign: 'center',
            }}
          >
            {`＼今ならクーポンプレゼント！／`}
          </Typography>
        </Grid>

        <Box sx={{ pt: 1 }}>
          <img src="/images/teaser/big_coupon.svg" />
        </Box>

        <Grid item xs={12} gap={2}>
          <Button
            size="large"
            variant="contained"
            sx={{
              ...STYLES.secondaryButtonBottomPage,
              color: 'white',
              height: '52px',
              marginTop: '32px',
            }}
            fullWidth
            onClick={() =>
              user.navigateToExternal(MEIJI_SHOPIFY_CART_PRODUCTION_URL)
            }
          >
            <Typography
              sx={{
                ...STYLES.productListItem.nextButtonLabel,
                color: 'white',
              }}
            >
              {`クーポンをゲットする`}
            </Typography>
          </Button>
          <Box sx={{ pt: 2 }}>
            <Typography
              sx={{
                color: '#2F2F2F',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                marginTop: '16px',
                textAlign: 'center',
              }}
            >
              {`※クーポン獲得時に発売開始のご案内を希望される方にはサービス開始メールを配信させていただきます。`}
            </Typography>
          </Box>
        </Grid>

        {hasBottomSection && (
          <Grid item xs={12} sx={{ marginTop: '32px' }}>
            <Typography
              sx={{
                color: '#828A9B',
                fontSize: '11px',
                fontWeight: 300,
                lineHeight: '20px',
                textAlign: 'center',
              }}
            >
              {`※販売者は株式会社Wellnizeとなります`}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
