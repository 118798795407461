// cspell:ignore Makino Nutri Nutr Scand Acta Odontol Scand Appl Physiol Metab NAMPT Acad Osteoporos
/* eslint-disable @typescript-eslint/naming-convention */
import * as _ from 'lodash';

import packageShipmentDefault from '@/assets/package-shipment-default.svg';
import packageShipmentTimer from '@/assets/package-shipment-timer.svg';
import { getSurveyWithoutExcludedQuestions } from '@/domain/utils/survey';
import {
  SurveyComponentType,
  EmojiLevelItem,
  Question,
  Questions,
  AppRoute,
  BacteriaType,
} from '@/types';
import { AssessmentStatus } from '@/types/network';

const GENERIC_WORDS = {
  TEST_APPLICATION: '検査申し込み',
} as const;

export const NOT_FOUND_PAGE = {
  BODY_LINE_1: '指定したページが\n見つかりませんでした',
  BODY_LINE_2: '以下の原因が考えられます',
  BODY_LINE_3: '●URLが間違っている可能性があります。',
  ERROR_CODE: '404',
  LINK_LABEL_1: '免疫チェックトップへ',
  PAGE_TITLE: 'Not Found',
};

export const INTERNAL_SERVER_ERROR_PAGE = {
  BODY_LINE_1: 'サーバー内でエラーが発生しました',
  BODY_LINE_2: '以下の原因が考えられます',
  BODY_LINE_3:
    '●メンテナンス中であるか、プログラムにエラーが発生している可能性があります。',
  ERROR_CODE: '500',
  LINK_LABEL_1: '免疫チェックトップへ',
  PAGE_TITLE: 'Internal Server Error',
};

export const TEASER_PAGE = {
  APP_ID: 'teaser',
  CAUTION: [
    {
      content: [
        {
          text: '・本サイトではインナーガーデンの先行割引クーポンが獲得できます。購入時には再度購入手続きが必要となります。',
        },
      ],
    },
    {
      content: [
        {
          text: '・販売開始は12月半ばを予定しております。販売日時が正式に決まりましたら、事前にメールでご連絡いたします。',
        },
      ],
    },
    {
      content: [
        {
          text: '・先行割引クーポンの対象商品は「【初回限定】腸内フローラ検査＋パーソナルココア飲料(約1か月分）のセット」となります。',
        },
      ],
    },
    {
      content: [
        {
          text: '・先行割引クーポンの獲得期限は2024年12月9日となります。',
        },
      ],
    },
    {
      content: [
        {
          text: '・先行割引クーポンの使用期限は2025年3月31日となります。',
        },
      ],
    },
    {
      content: [
        {
          text: '・先行割引クーポンの獲得数は1人1個までとなります。',
        },
      ],
    },
    {
      content: [
        {
          text: '・先行割引クーポン獲得ボタンをクリックして手続きが完了したら、',
        },
        {
          link: 'mailto:noreply@pre-storeinfo.innergarden.meiji.com',
          text: 'noreply@pre-storeinfo.innergarden.meiji.com',
        },
        {
          text: 'からクーポンコードをお送りします。本メールアドレスからのメールを受信できるよう設定お願いします。',
        },
      ],
    },
    {
      content: [
        {
          text: '・クーポンコードをSNSで公開したり他人に譲渡することは禁止です。',
        },
      ],
    },
    {
      content: [
        {
          text: '・先行割引クーポン獲得時には、請求先住所の入力が必要となりますが、クーポンのご請求は発生いたしません。なお、商品購入時には配送先住所の入力も求められますので、必ず配送先住所をご入力ください。',
        },
      ],
    },
    {
      content: [
        {
          text: '・商品購入時には明治会員IDへの登録が必須となります。',
        },
      ],
    },
    {
      content: [
        {
          text: '・商品の販売者は株式会社Wellnizeとなります。',
        },
      ],
    },
    {
      content: [
        {
          text: '・個人情報はSSLで暗号化されております。',
        },
      ],
    },
    {
      content: [
        {
          subContent: [
            { text: '・' },
            {
              link: 'https://wellnize.life/privacypolicy/latest',
              text: '株式会社Wellnize プライバシーポリシー',
            },
          ],
          text: '・個人情報の取り扱いは下記をご覧ください。',
        },
      ],
    },
    {
      content: [
        {
          subContent: [
            { text: '・' },
            {
              link: 'https://id.meiji.co.jp/contact/input',
              text: 'https://id.meiji.co.jp/contact/input',
            },
          ],
          text: '・その他問い合わせは明治会員サポートセンターにお願いいたします。',
        },
      ],
    },
  ],
  EXPLANATION: {
    CONTENT: [
      { id: 1, text: `たとえば性格だったり、血液型だったり。` },
      {
        id: 2,
        text: `心やカラダのケアもそういったことから\nはじまったりします。`,
      },
      { id: 3, text: `おなじように、\n自分の腸内を知ってみませんか。` },
      {
        id: 4,
        text: `腸内細菌を測定し\n主要な5つのタイプに分類し、\nその人の腸内にあった成分を摂取していく。`,
      },
      {
        id: 5,
        text: `そして、その成分は腸内細菌のチカラによって\n腸内で健康成分に変化していく。`,
      },
      { id: 6, text: `腸内から、つまりカラダの中から\n健康を育てていく。` },
      {
        id: 7,
        text: `私たち人間の健康の源は、\n私たち自身が持っていたのです。`,
      },
      { id: 8, text: `健康は、摂取から体内産生の時代へ。` },
      {
        id: 9,
        text: `さぁ、腸内タイプに合わせた、\n健康新習慣をはじめませんか`,
      },
    ],
    TITLE: '「自分のことを知りたい」',
  },
  HERO: {
    DISCOUNT_COUPON_IMAGE: '/images/teaser/hero_discount_coupon.svg',
    IMAGE_LIST: [
      '/images/teaser/drink_product_1.png',
      '/images/teaser/drink_product_2.png',
      '/images/teaser/drink_product_3.png',
      '/images/teaser/drink_product_4.png',
      '/images/teaser/drink_product_5.png',
    ],
    LOGO: '/images/teaser/logo.svg',
    TITLE: '腸内検査と商品が\nセットになった健康新習慣',
  },
  INFORMATION: {
    BODY_CAPTION_1:
      '人それぞれ\n<hl>腸内細菌のタイプ</hl>が\n違うって知っていますか？',
    BODY_CAPTION_1_FOOTER: {
      BODY_IMAGE_1: '/images/top-page/bacteria_characters.png',
      BODY_LINE_1: 'この検査でわかる\n菌のタイプは<hl>5種類！</hl>',
    },
    BODY_CAPTION_2: '<hl>おいし</hl>さも続ける\nための秘訣です。',
    BODY_CAPTION_3: 'おいしさも、効果の実感\nも、続けるための秘訣です',
    BODY_IMAGE_2: '/images/teaser/about_01.png',
    BODY_IMAGE_3: '/images/testing.png',
    BODY_IMAGE_4: '/images/top-page/topGraph.png',
    BODY_LINE_1:
      '腸内には様々な菌が住んでいますが、菌によって好きな栄養源が異なります。そのため、体内で短鎖脂肪酸の量を増やすには、あなたの腸内細菌に合わせた栄養源を摂取することが大切です。',
    BODY_LINE_2:
      '腸内細菌にも栄養源が必要って知ってますか？その多くは食物繊維とオリゴ糖です。腸内細菌がそれらの栄養源を分解すると短鎖脂肪酸というカラダに良い成分が体内産生されます。',
    BODY_LINE_3:
      'わたしたちは、明治の長年にわたる腸内環境の研究と商品開発力に基づいて、5つの腸内タイプ別に、それぞれの最適な素材の入った商品を提供します。',
    BODY_LINE_4: '1ヵ月継続飲用後のおいしさ評価',
    BODY_LINE_4_HIGHLIGHTED: '1ヵ月継続飲用後の<hl>おいしさ評価</hl>',
    REMARK: '23/06　当社調べ　腸内環境の関心が高い92名',
  },
  REPORT: {
    BODY_IMAGE_1: '/images/top-page/step5.png',
    BODY_IMAGE_3: '/images/top-page/report2.png',
    BUBBLE_TEXT_HIGHLIGHTED: '1ヵ月継続飲用後の<hl>効果の実感</hl>',
    CAPTION_1:
      '代表的な5種の腸内細菌の量とバランスから、あなたの腸内タイプを分類して最適な素材が入った商品を提示します。',
    CAPTION_3:
      'さらに、腸に影響を与える生活習慣の実践度を他の人と比較したり、改善ポイントを確認することで、生活習慣を見直すことができます。 あなたがどれくらい腸に影響を与える生活ができているのか確認しましょう！',
    FACT_IMAGE_1: '/images/top-page/graph2.png',
    REMARK: '23/06　当社調べ　腸内環境の関心が高い92名',
    TITLE: '腸内フローラ検査で\nわかること',
  },
  ROUTE_TITLE: 'キャンペーン2024',
  SERVICES: [
    {
      content:
        '最初に腸内フローラ検査を行い、あなたの腸内タイプを判別します。あわせて、腸に影響する生活習慣のアドバイスやチャレンジミッションも提供します。\nお家で採便してポストに投函するだけなので、簡単に腸内タイプがわかります。\n\n※本サービスは病気の有無や重さを診断するものではありません。',
      image: '/images/teaser/teaser_service01.png',
      title: 'あなたの腸内タイプを検査',
    },
    {
      alert: '※ この中から１種類の商品が届きます。',
      content:
        '腸内タイプに適した素材の入ったオリジナル商品をお届けします。\nこの商品は、あなたの腸内タイプが短鎖脂肪酸を産生しやすい素材が入っております。',
      image: '/images/teaser/products_step.png',
      title: '腸内タイプに合った商品が届く',
    },
    {
      content:
        'あなたの腸内タイプに適した素材の入ったオリジナル商品を飲み続けて、あなたの腸内細菌をサポートしましょう。\nまた、美味しいココア飲料のため、毎日楽しく続けることができます。',
      image: '/images/teaser/service_03.png',
      title: '続けることで腸内環境が整う',
    },
  ],
};

export const TOP_PAGE = {
  CAUTION: {
    HEADER: '注意事項',
    TEXT: [
      '本サービスの検査は生活習慣の見直しのきっかけとするもので、病気の有無や重さを診断するものではありません。',
      '本サービスの商品は検査で判明した腸内タイプに適している素材が入ったものであり、商品そのものが腸内タイプに適しているわけではございません。',
      '本サービスの検査は申し込み後6か月以内に必ず便検体を郵送してください。期限を超えると検査結果が出ません。',
      '便採取のときに、必ず検査キットに貼付されている検査受付番号をマイページ上で登録してください。検査受付番号を登録しないと検査結果が出ません。',
    ],
  },
  HERO: {
    IMAGE_LIST: [
      '/images/top-page/drink_product_1.png',
      '/images/top-page/drink_product_2.png',
      '/images/top-page/drink_product_3.png',
      '/images/top-page/drink_product_4.png',
      '/images/top-page/drink_product_5.png',
    ],
    MEIJI_LOGO: '/images/top-page/meiji_logo.svg',
    TITLE: '腸内検査と商品が\nセットになった健康新習慣',
  },
  INFORMATION: {
    BODY_CAPTION_1:
      '人それぞれ\n<hl>腸内細菌のタイプ</hl>が\n違うって知っていますか？',
    BODY_CAPTION_1_FOOTER: {
      BODY_IMAGE_1: '/images/top-page/bacteria_characters.png',
      BODY_LINE_1: 'この検査でわかる\n菌のタイプは<hl>5種類！</hl>',
    },
    BODY_CAPTION_2: '<hl>おいし</hl>さも続ける\nための秘訣です。',
    BODY_CAPTION_3: 'おいしさも、効果の実感\nも、続けるための秘訣です',
    BODY_IMAGE_1: '/images/top-page/people.png',
    BODY_IMAGE_2: '/images/teaser/products_step.png',
    BODY_IMAGE_3: '/images/testing.png',
    BODY_IMAGE_4: '/images/top-page/topGraph.png',
    BODY_LINE_1:
      '腸内には様々な菌が住んでいますが、菌によって好きな栄養源が異なります。そのため、体内で短鎖脂肪酸の量を増やすには、あなたの腸内細菌に合わせた栄養源を摂取することが大切です。',
    BODY_LINE_2:
      '腸内細菌にも栄養源が必要って知ってますか？その多くは食物繊維とオリゴ糖です。腸内細菌がそれらの栄養源を分解すると短鎖脂肪酸というカラダに良い成分が体内産生されます。',
    BODY_LINE_3:
      'わたしたちは、明治の長年にわたる腸内環境の研究と商品開発力に基づいて、5つの腸内タイプ別に、それぞれの最適な素材の入った商品を提供します。',
    BODY_LINE_4: '1ヵ月継続飲用後のおいしさ評価',
    BODY_LINE_4_HIGHLIGHTED: '1ヵ月継続飲用後の<hl>おいしさ評価</hl>',
    REMARK: '23/06　当社調べ　腸内環境の関心が高い92名',
  },
  NEXT_BUTTON: 'インナーガーデンの申し込みへ',
  PARTNER: {
    AVATAR_IMAGE_PATH: '/images/top-page/person.png',
    HEADER: '腸内環境研究のプロフェッショナルが監修',
    LOGO_IMAGE_PATH: '/images/top-page/logo_example.png',
    TEXT: '昨今ライフスタイルが大きく変化する中、健康に対する意識が高まってきています。一人ひとりのニーズに合わせた食の提案はヘルスケアのみならずウェルビーイングの観点からも重要です。腸内フローラが私たちの健康に大きな影響を与えることから、腸内フローラを「もう一つの臓器®」と捉え、その機能を引き出すことが理想の自分になるための鍵になると考えています。自分の腸内環境を知り、最適なドリンクで腸内フローラを育て、日常生活に活力と調和をもたらすことでウェルビーイングを感じていただければ幸いです。',
  },
  PRODUCT: {
    FIRST_PURCHASE: {
      PRODUCT_1: {
        DESCRIPTION:
          '【初回限定】腸内フローラ検査＋パーソナルココア飲料(約1ヶ月分)のセット',
        IMAGE: '/images/top-page/products2.png',
        IMAGE_SUBTEXT: '検査と商品の単品購入合計より<hl>3,000円</hl>お得！',
        PRICE_1: '¥12,000',
        PRICE_1_TAG: '送料無料',
        TAG_IMAGE: '/images/top-page/free_shipping.svg',
      },
      PRODUCT_2: {
        DESCRIPTION_1: 'パーソナルココア飲料(約1か月分)',
        DESCRIPTION_2: '腸内フローラ検査',
        HEADER: '２回目以降は単品で\nご購入いただけます',
        IMAGE_1: '/images/top-page/bottle.png',
        IMAGE_1_SUBTEXT: '商品のみ購入したい人向け',
        IMAGE_2: '/images/top-page/product.png',
        IMAGE_2_SUBTEXT: '腸内環境のみ知りたい方向け',
        PRICE_1: '¥6,000',
        PRICE_1_TAG: '１回購入',
        PRICE_2: '¥9,000',
        PRICE_2_TAG: '定期購入',
      },
      TITLE: '初回お試しセット',
    },
    READ_MORE: '詳しく見る',
    RETURNING_VISIT: {
      PRODUCT_1: {
        DESCRIPTION: 'パーソナルココア飲料(約1ヶ月分)',
        IMAGE_1: '/images/top-page/step5.png',
        IMAGE_1_SUBTEXT: '商品のみ購入したい人向け',
        PRICE_1: '¥6,000',
        PRICE_1_TAG: '１回購入',
        PRICE_2: '¥5,400',
        PRICE_2_TAG: '定期購入',
      },
      PRODUCT_2: {
        DESCRIPTION: '腸内フローラ検査',
        IMAGE: 'https://placehold.co/400x350',
        IMAGE_SUBTEXT: '腸内環境のみ知りたい方向け',
        PRICE: '¥9,000',
      },
      TITLE: 'ドリンクも検査も\nあなたのタイミングで',
    },
  },
  REPORT: {
    BODY_IMAGE_1: '/images/top-page/step5.png',
    BODY_IMAGE_2: '/images/top-page/report1.png',
    BODY_IMAGE_3: '/images/top-page/report2.png',
    BUBBLE_SUB_TEXT: '※効果とは継続飲用で感じた変化を指します',
    BUBBLE_TEXT: '1ヵ月継続飲用後の効果の実感',
    BUBBLE_TEXT_HIGHLIGHTED: '1ヵ月継続飲用後の<hl>効果の実感</hl>',
    CAPTION_1:
      '代表的な5種の腸内細菌の量とバランスから、あなたの腸内タイプを分類して最適な素材が入った商品を提示します。',
    CAPTION_2:
      'また、腸内細菌の量とバランス、生活習慣の実践度を総合的に加味したオリジナル指標であるガーデンスコアも算出します。',
    CAPTION_3:
      'さらに、腸に影響を与える生活習慣の実践度を他の人と比較したり、改善ポイントを確認することで、生活習慣を見直すことができます。 あなたがどれくらい腸に影響を与える生活ができているのか確認しましょう！',
    FACT_HEADER: '82%の人が効果を実感!',
    FACT_IMAGE_1: '/images/top-page/graph2.png',
    REMARK: '23/06　当社調べ　腸内環境の関心が高い92名',
    TITLE: '腸内フローラ検査で\nわかること',
  },
  SECTION_BODY_1: [
    {
      BODY: '送っていただいた便から、あなたの腸内に多い菌を測定し、あなたのタイプ判定を行います。\nそして、その菌が好む、あなたのタイプに適した素材が入った商品をお届けします。\nまた、生活習慣アンケートから、あなたの腸活実践度に合わせた改善ポイントをご提案いたします。ご自分の腸内フローラの特徴を把握し、腸内環境を良くするヒントを探しましょう。',
    },
  ],
  SECTION_BODY_3: {},
  SECTION_BODY_7: {
    BODY: [
      // first testimonial - 40代 女性
      {
        BODY_1:
          '家でスムーズに検査ができて、自分の腸内に合った商品を届けてくれる',
        BODY_2:
          '最初は検査が面倒だと思っていたが、説明通りにやればストレスなくスムーズにできました。',
        BODY_3:
          '自分の腸内に合った商品を届けてくれて、2、3日で変化を感じ始めた。検査結果に基づいた商品なので信頼感があったし、続けることができました。',
        IMAGE_PATH: '/images/reports/customer_01.png',
        TITLE: '40代 女性',
      },
      // second testimonial - 30代 男性
      {
        BODY_1:
          '商品と生活習慣の改善でびっくりするくらいの変化を感じ、カラダが軽くなる',
        BODY_2:
          '始めて１週間ぐらいでびっくりするくらい変化を感じ、カラダが軽くなった気がします。',
        BODY_3:
          '商品も純粋なココア味で続けやすいです。また、アドバイスも取り組みやすく、日常生活に取り入れやすいのが良いです。',
        IMAGE_PATH: '/images/reports/customer_03.png',
        TITLE: '30代 男性',
      },
      // third testimonial - 40代 女性
      {
        BODY_1: 'レポートもわかりやすく、届いた商品も続けやすい',
        BODY_2:
          '自宅で簡単に検査ができて、自分の腸内タイプが明確にわかります。ほかの人との比較や腸内に多い菌の説明が視覚的でわかりやすいです。',
        BODY_3:
          '商品は美味しく飲みやすく、分量も125mlとちょうどよく続けやすいです。アドバイスも参考になりました。例えば、おすすめ食材も関連するものを取り入れてみようかなと思いました。',
        IMAGE_PATH: '/images/reports/customer_02.png',
        TITLE: '40代 女性',
      },
      // fourth testimonial - 40代 男性
      {
        BODY_1: '自分の腸内にカスタマイズしてくれる新しいサービス',
        BODY_2:
          '検査でチェックしたデータを基に自分に合った飲料を提供される、自分用にカスタマイズしてくれる良いサービス。おすすめの腸習慣アドバイスも参考になり、やりたい気持ちが高まります。',
        BODY_3:
          '続けると便の硬さもちょうど良くニオイも気にならなくなり、具合が悪くなることがなくなり、体調も良いです。',
        IMAGE_PATH: '/images/reports/customer_04.png',
        TITLE: '40代 男性',
      },
    ],
    BODY_TITLE_1: 'Voices',
    BODY_TITLE_2: 'お客様の声',
    BUTTON_TITLE: 'すべて見る',
    READLESS: '閉じる',
    READMORE: '続きを読む',
  },
  SECTION_BODY_8: {
    BODY: [
      {
        CATEGORY: 'INTESTINAL_BACTERIA',
        IMAGE_PATH: `/images/column/intestinal_column_01_01.png`,
        NUMBER: 1,
        SECTION_TITLE:
          '炭水化物好きの腸内細菌たちが、日本人の健康を支えている？',
        TITLE:
          '腸内細菌叢はその地域の食生活を反映――日本人の腸にビフィズス菌が多いわけ',
      },
      {
        CATEGORY: 'INTESTINAL_BACTERIA',
        IMAGE_PATH: `/images/column/intestinal_column_02_01.png`,
        NUMBER: 2,
        SECTION_TITLE: '腸内細菌叢への遺伝子の影響は限定的',
        TITLE: '腸内環境を決めるのは遺伝子？それとも食事？',
      },
      {
        CATEGORY: 'INTESTINAL_BACTERIA',
        IMAGE_PATH: `/images/column/intestinal_column_03_01.png`,
        NUMBER: 3,
        SECTION_TITLE: '食事の改善から効果の体感を得るには2週間から1か月程度',
        TITLE: '腸内環境から体感を得るにはどのくらいの期間が必要なのか',
      },
      {
        CATEGORY: 'FOOD_AND_INTESTINE',
        IMAGE_PATH: `/images/column/food_column_01_01.png`,
        NUMBER: 1,
        SECTION_TITLE: 'バランスのいい食生活が腸の健康維持の基本',
        TITLE: '食物繊維が多い食品＋有用菌入り発酵乳製品で、腸内細菌叢が豊かに',
      },
      {
        CATEGORY: 'EXERCISE_AND_INTESTINE',
        IMAGE_PATH: `/images/column/exercise_column_01_01.png`,
        NUMBER: 1,
        SECTION_TITLE: '「有酸素運動が腸の健康機能に効果的！',
        TITLE: '「毎日の早歩きで腸が元気に！排便機能が改善し腸内細菌叢も整う',
      },
    ],
    BODY_TITLE_1: 'COLUMN',
    BODY_TITLE_2: '腸に関するコラム',
    BUTTON_TITLE: 'すべて見る',
  },
  SERVICES: [
    {
      content:
        '最初に腸内フローラ検査を行い、あなたの腸内タイプを判別します。あわせて、腸に影響する生活習慣のアドバイスやチャレンジミッションも提供します。\nお家で採便してポストに投函するだけなので、簡単に腸内タイプがわかります。\n\n※本サービスは病気の有無や重さを診断するものではありません。',
      image: '/images/top-page/service_01.png',
      title: 'あなたの腸内タイプを検査',
    },
    {
      content:
        '腸内タイプに適した素材の入ったオリジナル商品をお届けします。\nこの商品は、あなたの腸内タイプが短鎖脂肪酸を産生しやすい素材が入っております。',
      image: '/images/top-page/products_step.png',
      title: '腸内タイプに合った商品が届く',
    },
    {
      content:
        'あなたの腸内タイプに適した素材の入ったオリジナル商品を飲み続けて、あなたの腸内細菌をサポートしましょう。\nまた、美味しいココア飲料のため、毎日楽しく続けることができます。',
      image: '/images/teaser/service_03.png',
      title: '続けることで腸内環境が整う',
    },
  ],
  STEPS_DATA: [
    {
      subTitle: 'サービスの申し込み',
      text: '「インナーガーデンの申し込みへ」ボタンから、サービスの申し込みに進んでください。申し込みには明治会員IDでのログインが必要です。\nインナーガーデンを申し込みいただくと、数日以内に検査キットがお届け先に届きます。',
      title: '01',
      type: 'TextOnly',
    },
    {
      image: '/images/top-page/step2.png',
      imageWidth: '100%',
      subTitle: '検査キットの内容を確認',
      text: '検査キットが届きましたら内容物をご確認ください。\n\n＜キット内容物＞\n・サービス説明書\n・採便キット\n・採便シート\n・採便説明書\n・返信用封筒',
      title: '02',
      type: 'imageWithText',
    },
    {
      image: '/images/top-page/step3.png',
      subTitle: '検査の開始',
      text: 'マイページ内の「検査のお申し込みへ」ボタンをクリックし、アンケートへのご回答と案内に従って便の採取を行ってください。',
      title: '03',
      type: 'imageWithText',
    },
    {
      image: '/images/top-page/step4.png',
      subTitle: '採取した検体を\n返送用封筒に入れて投函',
      text: '検査キットに同封されている返送用封筒に便を採取した容器を入れて、1週間以内にポストへご投函ください。',
      title: '04',
      type: 'imageWithText',
    },
    {
      image: '/images/top-page/step5.png',
      imageTitle: '検査結果イメージ',
      imageWidth: '100%',
      subTitle: 'マイページで検査結果を確認',
      text: '検査結果が出ましたらメールでお知らせいたします。レポート発行次第、原則1週間以内にお送りします。マイページで検査結果をご確認ください。',
      title: '05',
      type: 'imageWithText',
    },
    {
      image: '/images/top-page/step6.svg',
      imageWidth: '100%',
      subTitle: 'オリジナル商品の提供',
      text: 'あなたの腸内タイプに適した素材の入ったオリジナル商品をお届けします。',
      title: '06',
      type: 'imageWithText',
    },
  ],
};

// ++++++++++++++
export const LOGIN_PAGE = {
  BODY_LINE_1:
    'インナーガーデンのお申し込み、検査結果の\n確認には明治会員IDでのログインが必要です',
  PAGE_TITLE: '明治会員IDでログイン',
  SUBMIT_BUTTON: 'ログインまたは新規登録へ',
};

export const TERMS_AGREEMENT_PAGE = {
  BODY_LINE_1:
    '研究参加に同意される場合は\n「20歳以上なので、同意して研究に参加する」\nにチェックを入れて、次のページに進んでください。',
  BODY_LINE_2: '20歳以上なので、同意して研究に参加する',
  PAGE_TITLE: '研究参加への同意確認',
  SUBMIT_BUTTON: '次へ',
};

export const REGISTER_PAGE = {
  BODY_CAPTION_1: '必須',
  BODY_CAPTION_2: '任意',
  FORM_FIELDS: {
    ADDRESS_LINE1: {
      ERROR_MESSAGE: '都道府県を選択してください',
      LABEL: '都道府県',
      PLACEHOLDER: '都道府県を選択',
    },
    ADDRESS_LINE2: {
      ERROR_MESSAGE: '市区町村の入力は必須です',
      LABEL: '市区町村',
      PLACEHOLDER: '市区町村を入力',
    },
    ADDRESS_LINE3: {
      ERROR_MESSAGE: '丁目・番地・号の入力は必須です',
      LABEL: '丁目・番地・号',
      PLACEHOLDER: '丁目・番地・号を入力',
    },
    ADDRESS_LINE4: {
      ERROR_MESSAGE: '',
      LABEL: '建物名 / 会社名',
      PLACEHOLDER: '建物名 / 会社名を入力',
    },
    ADDRESS_LINE5: {
      ERROR_MESSAGE: '',
      LABEL: '部屋番号',
      PLACEHOLDER: '部屋番号を入力',
    },
    BIRTHDAY: {
      ERROR_MESSAGE: '生年月日の入力は必須です',
      LABEL: '生年月日',
      PLACEHOLDER: '例: 20XX年XX月XX日',
    },
    NAME: {
      ERROR_MESSAGE: '氏名の入力は必須です',
      LABEL: '氏名',
      PLACEHOLDER: '氏名',
    },
    PHONE: {
      ERROR_MESSAGE: '電話番号の入力は必須です',
      LABEL: '電話番号',
      PLACEHOLDER: '例: 090XXXXXXXX',
    },
    POSTCODE: {
      ERROR_MESSAGE: '郵便番号の入力は必須です',
      LABEL: '郵便番号',
      PLACEHOLDERS: ['000', '0000'],
    },
    SEX: {
      ERROR_MESSAGE: '性別の入力は必須です',
      LABEL: '性別',
      PLACEHOLDER: '男性',
    },
  },
  FORM_TITLE: '検査キットのお届け先設定',
  PAGE_TITLE: '免疫チェックサービスの申し込み',
  SUBMIT_BUTTON: 'お申し込み内容の確認へ',
};

export const REGISTER_COMPLETE_PAGE = {
  BODY_LINE_1: '1週間程度で検査キットが\nお手元に届きます',
  PAGE_TITLE:
    '免疫チェックサービスをお申し込み\nいただきありがとうございました',
  SUBMIT_BUTTON: 'マイページへ',
};

export const REGISTER_CONFIRMATION_PAGE = {
  APPLICATION_TITLE: 'お申し込みの検査',
  CHANGE_BUTTON: 'お届け先の変更',
  DELIVERY_TITLE: 'お届け先',
  PAGE_TITLE: 'お申込み内容の確認',
  PHONE: '電話番号：',
  POSTCODE_MARK: '〒',
  PRODUCT_NAME: '免疫チェックサービス',
  SUBMIT_BUTTON: 'お申込みを確定する',
};

export const REPORT_PAGE = {
  PAGE_TITLE: 'マイページ',
};

export const REGISTER_PHASE_STEPPER = [
  'お届け先設定',
  'お申し込み内容の確認',
  '完了',
];

export const COLLECTION_PHASE_STEPPER = [
  '検査受付番号\n登録',
  '準備',
  'アンケート',
  '採取',
  '投函',
];

export const TEST_KIT_ADD_BARCODE_PAGE = {
  ANIMATION: '/images/reports/qr-code-animation.json',
  CAPTION: '※ボタンをタップするとカメラを起動します。',
  CAPTION_2: '※カメラでQRコードの写真を撮影してください。',
  CAPTION_3:
    '※QRコードは5〜10cmほど離れて、真上から撮影すると読み込みやすいです',
  ENABLE_CAMERA: 'QRコードで検査受付番号を読み込む',
  FORM_TITLE: '検査受付番号',
  HELPER_TEXT: '※QRコードは株式会社デンソーウェーブの登録商標です。',
  IMAGE: '/images/reports/testkit_howto01.png',
  MODAL_CAPTION1: '※「コードの撮影へ」ボタンをタップすると カメラを起動します',
  MODAL_CAPTION2:
    '※コードは5〜10cmほど離れて、真上からピントを合わせて撮影してください',
  MODAL_TITLE: 'コードを撮影してください',
  PAGE_TITLE: '検査受付番号を登録してください',
  PLACEHOLDER: '検査受付番号を入力',
  PURCHASE_TEST_KIT: '検査キットを持っていない方はこちら',
  SCAN_CAPTION: '読み込みができない場合は、検査受付番号を直接ご入力ください。',
  SPEECH_BUBBLE:
    '*検査受付番号はサービス説明書の記入欄に記載し、検査終了まで保管してください。',
  SUBMIT_BUTTON: '検査受付番号を登録',
};

export const TEST_KIT_BARCODE_ATTEMPT_PAGE = {
  PAGE_TITLE: '検査受付番号',
};

export const TEST_KIT_PREPARE_COLLECTION_PAGE = {
  CANCEL_BUTTON: '準備がまだなのでまた後で',
  INSTRUCTION: {
    CONTENT: [
      '●液体が誤って皮膚に付いたり目や口に入った場合は、水で充分に洗い流してください。瞬時に害を与えるものではございません。刺激が残る場合、必要に応じて医師の診察を受けてください。',
      '●お子様の手の届かない場所に保管し、お子様が誤飲されないようにご注意ください。',
      '●採取した便は7日以内に投函ください。採取から投函までに時間がかかると検査の精度が落ちる可能性がございます。',
      '●尿や経血が混じらないように採便してください。',
      '●抗生物質を2週間以内に服用している場合は、腸内フローラの情報が、適切に測定されない可能性があります。',
      '●採便容器に入っている淡青色の保存液です。検査に必要なため捨てないでください。',
    ],
    TITLE: '必須事項',
  },
  PAGE_TITLE: `検体採取時の注意事項を\nご確認ください`,
  SUBMIT_BUTTON: 'アンケート回答をはじめる',
};

export const MY_PAGE = {
  BODY_LINE:
    '検査キットが届いたら下記のボタンを\nタップして検査へ進んでください',
  IMMUNOLOGY_COLUMNS: 'おすすめの免疫コラム',
  IMMUNOLOGY_COLUMNS_BUTTON: 'すべて見る',
  PAGE_TITLE: 'マイページ',
  PAYMENT_COMPLETE: {
    BODY_LINE:
      '検査キットが届いたら下記のボタンを\u200Bタップして検査へ進んでください',
    IMAGE_1: `/images/reports/test_tube_horizontal.svg`,
    IMAGE_2: '/images/top-page/bacteria_characters.png',
  },
  REPORT_CARD: {
    BODY: {
      bacteroides:
        '世界中の多くの人が持っている、一般的な腸内細菌のグループの一つです。特に高脂肪、高たんぱく質な欧米型の食事の人が多く持っていることが知られています。',
      bifidobacterium:
        'アジア圏、特に日本人の腸内フローラで多く見られる腸内細菌のグループの一つです。日常的に乳製品を摂取する人に多いと言われています。',
      faecalibacterium:
        '世界中の多くの人が持っている腸内細菌のグループの一つです。アスリートや運動をしている人に比較的多い菌と言われています。',
      prevotella:
        '穀物などの食物繊維を多く含む食品をたくさん摂取する人に多いと言われています。日本人では1週間当たりの運動時間が長い人に多い傾向があると言われています。',
      ruminococcus:
        '様々な機能を持った腸内細菌が属するグループです。日本人では成人後の女性が比較的多い菌と言われています。',
    },
    IMAGE_BLUE: `/images/reports/emoji_blue.svg`,
    IMAGE_GREEN: `/images/reports/emoji_green.svg`,
    IMAGE_ORANGE: `/images/reports/emoji_orange.svg`,
    IMAGE_RED: `/images/reports/emoji_red.svg`,
    IMAGE_YELLOW: `/images/reports/emoji_yellow.svg`,
  },
  SUBMIT_BUTTON: '検査をはじめる',
};

export const MY_PAGE_RE_START = {
  BODY_CAPTION: '*前回閉じた画面から再開できます',
  BODY_LINE_1: '便の採取前です',
  BODY_LINE_2: '便の投函前です',
  IMAGE_1: '/images/my-page/test_tube_preparing_survey.svg',
  IMAGE_2: '/images/my-page/test_tube_preparing_post.svg',
  IMAGE_3: '/images/top-page/bacteria_characters.png',
  PAGE_TITLE: 'マイページ',
  SUBMIT_BUTTON: '検査を再開する',
};

export const MY_PAGE_READY_FOR_POST = {
  BODY_CAPTION_1:
    '※採取からご投函までに時間がかかると\n検査結果の精度が落ちる可能性があります',
  BODY_CAPTION_2:
    '※検査結果は検査状況等により遅れる\n可能性もございます。予めご了承ください。',
  BODY_LINE_1: '便は採取から2日以内に\n投函してね',
  BODY_LINE_2: '検査完了まで3〜4週間ほどお待ちください',
  PAGE_TITLE: 'マイページ',
  SUBMIT_BUTTON: '検査をはじめる',
};

export const REPORTS_LIST = {
  BACTERIA_DESCRIPTION: '菌の説明',
  GARDEN_SCORE_DEFINITION:
    '*ガーデンスコアとは、腸内細菌の量とバランス、生活習慣の実践度から算出したインナーガーデン独自の腸内スコアです。',
  IMAGE_PATH: '/images/top-page/bacteria_characters.png',
  NO_REPORTS: {
    IMAGE_PATH: '/images/top-page/bacteria_characters_02.png',
    TEXT: '検査結果はまだありません',
  },
  PAGE_TITLE: '検査結果一覧',
  TEST_APPLICATION: GENERIC_WORDS.TEST_APPLICATION,
  YOUR_GARDEN_SCORE: 'あなたのガーデンスコア*',
};

export const SURVEY_PAGE = {
  BODY_LINE_1: 'ページ目',
  BODY_LINE_2: '現在の体調について質問にご回答ください',
  CANCEL_BUTTON: '戻る',
  LAST_PAGE_OF_SURVEY: 2,
  NEXT_BUTTON: '次へ',
  PAGE_TITLE: '現在の生活習慣アンケート',
  SUBMIT_BUTTON: '便の採取方法へ',
};

export const TEST_KIT_CONFIRMATION_PAGE = {
  NO: 'いいえ',
  PAGE_TITLE: '検査キットを持っていますか？',
  YES: 'はい',
};

export const FAQ_PAGE = {
  CONTACT_BUTTON: 'お問い合わせフォーム',
  CONTACT_TITLE: 'フォームで問い合わせる',
  DESCRIPTION_1:
    'インナーガーデンに寄せいただくよくあるご質問を掲載しています。お問い合わせ前にご確認ください。',
  DESCRIPTION_2:
    'よくあるご質問で回答が見つからなかった場合は、下記ボタンよりお問い合わせください。',
  IMAGE_1: '/images/reports/main05.png',
  IMAGE_2: '/images/reports/main11.png',
  PAGE_TITLE: 'よくある質問',
};

export const COLUMN_PAGE = {
  PAGE_TITLE: '免疫コラム',
};
export const COLUMN_LIST_PAGE = {
  PAGE_TITLE: '腸に関するコラム',
};

export const CONCEPT_PAGE = {
  HEADER_IMAGE_PATH: '/images/concept/concept.jpg',
  MAIN_MESSAGE_TEXT: `
  たとえば性格だったり、血液型だったり。\n
  心やカラダのケアもそういったことから
  はじまったりします。\n
  おなじように、自分の腸内を知ってみませんか。\n
  腸内細菌を測定し
  主要な5つのタイプに分類し
  その人の腸内にあった成分を摂取していく。\n
  そして、その成分は腸内細菌のチカラによって
  腸内で健康成分に変化していく。\n
  腸内から、つまりカラダの中から
  健康を育てていく。\n
  私たち人間の健康の源は
  私たち自身が持っていたのです。\n
  健康は、摂取から体内産生の時代へ。\n
  さあ、腸内タイプに合わせた、
  健康新習慣をはじめませんか。
`,
  MAIN_MESSAGE_TITLE: '「自分のことを知りたい」\n\n',
  MASCOT_IMAGE_PATH: '/images/top-page/bacteria_characters.png',
  PAGE_TITLE: 'インナーガーデンのコンセプト',
};

export const ALLERGY_INGREDIENT_PRODUCTS = {
  PAGE_TITLE: '商品詳細情報',
};

export const TEST_KIT_POSTAGE_INSTRUCTIONS_PAGE = {
  BACK_BUTTON: '戻る',
  IMAGE_PATH: '/images/top-page/bacteria_characters.png',
  INSTRUCTIONS: [
    // Step 8
    {
      DESCRIPTION:
        '同封の返送用封筒に容器を入れ、封筒上部の両面シールをはがして、しっかりと封筒を閉じてください。',
      IMAGE_PATH: '/images/collection-steps/testkit_howto09.png',
      TITLE: '8.返送用封筒へ',
    },
    // Step 9
    {
      DESCRIPTION: '採取した便をポストに投函してください。',
      IMAGE_PATH: '/images/collection-steps/testkit_howto10.png',
      TITLE: '9.ポストへ投函',
    },
  ],
  PAGE_TITLE: '便の投函',
  SUBMIT_BUTTON: '投函準備完了',
  SUBMIT_BUTTON_WARNING: '( ⚠ 必ず押してください )',
  TOP_WARNING: {
    DESCRIPTION: `このページ下部にある投函準備完了ボタンを押さないと、検査が完了しません。`,
    TITLE: 'ご注意ください',
  },
};

export const TEST_KIT_COLLECTION_INSTRUCTIONS_PAGE = {
  IMAGE_PATH: '/images/top-page/bacteria_characters.png',
  INSTRUCTIONS: [
    // Step 1
    {
      DESCRIPTION:
        '同封の採便シート「べんとりくん™」を袋から出して説明書に従い便器にセットしてください。\n採便シートを便器にセットする前に、必ず排尿を済ませておいてください。',
      IMAGE_PATH: '/images/collection-steps/testkit_howto01.png',
      TITLE: '1.採便用シートをセット',
    },
    // Step 2
    {
      DESCRIPTION:
        '採便用シート上に排便します。採便用シートを使用しない場合、トイレットペーパー等を便器に敷いて、代用してください。',
      IMAGE_PATH: '/images/collection-steps/testkit_howto02.png',
      TITLE: '2.排便',
    },
    // Step 3a
    {
      DESCRIPTION:
        '反時計周りにキャップを回し、蓋を開けて、スプーン部位を引き上げます。\n※液をこぼさないようにご注意ください。',
      IMAGE_PATH: '/images/collection-steps/testkit_howto03.png',
      TITLE: '3.便採取容器準備',
    },
    // Step 3b
    {
      DESCRIPTION:
        '外箱の穴に採便容器を差し込み、採便時に採便容器の一時置き場としてご使用いただけます。\n※ご使用の際は、箱を組み立てた状態で、安定した場所に置いてご使用ください。',
      IMAGE_PATH: '/images/collection-steps/testkit_howto04.png',
    },
    // Step 4
    {
      DESCRIPTION:
        'スプーンで便を採取します。採取量の目安は「5.採取量」をご参照ください。便は<span style="color: #FF002E;">1回のみ</span>採取してください。',
      IMAGE_PATH: '/images/collection-steps/testkit_howto05.png',
      TITLE: '4.採取',
    },
    // Step 5
    {
      DESCRIPTION:
        'ブラシ部分を元のチューブに戻し、柄の部分を回しながら固く締めてください。密閉した後、容器を5～6回振って中身を混ぜます。',
      IMAGE_PATH: '/images/collection-steps/testkit_howto06.png',
      TITLE: '5.採取量',
    },
    // Step 6
    {
      DESCRIPTION:
        '採取したスプーンを採便容器に入れて、<span style="color: #FF002E;">キャップをしっかり閉め</span>、便が保存液とよく混ざるように数回振ってください。\n※振っても液がこぼれないことを確認してください。',
      IMAGE_PATH: '/images/collection-steps/testkit_howto07.png',
      TITLE: '6.採取完了',
    },
    // Step 7
    {
      DESCRIPTION:
        'チャック付きパウチに吸水シートを入れ、その中に採便容器を入れ、チャックを閉めます。\n<span style="color: #FF002E;">袋がきちんと密閉されたことを確認してください。</span>',
      IMAGE_PATH: '/images/collection-steps/testkit_howto08.png',
      TITLE: '7.容器をチャック付き袋へ',
    },
  ],

  NEXT_BUTTON: '便の投函へ',
  PAGE_TITLE: '便採取',
  TOP_WARNING: {
    DESCRIPTION_1: `採便用シートを便器に準備する前に、必ず排尿を済ませておいてください。`,
    DESCRIPTION_2: `採便の際は付属の採便取説明書をご確認ください。`,
    TITLE: 'ご注意ください',
  },
};

export const MY_PAGE_REPORT = {
  ADVICES_DISPLAYED_BY_DEFAULT: [''],
  ADVICE_SECTION_TITLE: '生活習慣アドバイス',
  ANNOTATION_AVERAGE_IGA:
    '※男女のデータは、本サービスの性別解答欄で男性・女性を選択した方の結果に基づくものです',
  ANNOTATION_SAMPLE_POPULATION: '調査対象: 20代〜70代の男女905名',
  AVERAGE_IGA_VALUE_OF_OTHER_GENERATIONS: '他の年代の平均値',
  BACTERIA_NO_DATA: '測定不可',
  BODY_LINE_1: 'あなたの検査結果と腸活アドバイスになります。',
  BUTTON_TITLE_FOR_DESCRIPTION_OF_INTESTINAL_BACTERIA:
    '腸内フローラ検査について',
  CHALLENGE_SECTION_TITLE: 'すべてのチャレンジ',
  GARDEN_SCORE_DESCRIPTION:
    'ガーデンスコアとは、腸内細菌の量とバランス、生活習慣の実践度から算出したインナーガーデン独自の腸内スコアです。',
  GARDEN_SCORE_TITLE: 'ガーデンスコアとは',
  GRAPH_ACTION_TEXT: '改善点をみる',
  GRAPH_LABEL_1: 'あなたの結果',
  GRAPH_LABEL_2: '平均値',
  GRAPH_LABEL_3: 'あなたの結果',
  GRAPH_LABEL_4: '検査を受けている人の平均',
  GRAPH_SECTION_TITLE: 'あなたのガーデンスコア',
  HABIT_SECTION_TITLE: 'あなたの腸活実践度',
  IGA_GRAPH_LEGEND_FEMALE: '女',
  IGA_GRAPH_LEGEND_MALE: '男',
  MISSION_BUBBLE_TEXT:
    '検査結果にもとづいた、あなたにオススメのチャレンジです。',
  RANK_FIRST_BAR_PREFIX: 'あなた',
  RANK_SECOND_BAR_PREFIX: '平均',
  RANK_SECTION_TITLE: 'あなたのランク',
  TAB_1: '検査結果',
  TAB_2: 'チャレンジ',
  THUMB_UP_TEXT: '良い点をみる',
  TYPE: 'タイプ',
  UNSUPPORTED_REPORT_FORMAT: 'レポートを表示できません',
  UNSUPPORTED_REPORT_FORMAT_DESCRIPTION: 'このレポートは表示できません',
  UNSUPPORTED_REPORT_FORMAT_TITLE:
    'このレポートのバージョンはサポートされなくなりました',
  YOUR_TYPE: 'あなたのタイプ',
};

const HEIGHT: Question = {
  children: _.range(100, 201, 1).map((l, v, a) => ({
    label: l + _.get({ [0]: '以下', [a.length - 1]: '以上' }, v, ''),
    value: `${v + 1}`,
  })),
  component: SurveyComponentType.SELECT,
  description: '',
  hideNumber: false,
  id: 'HEIGHT',
  placeholder: '身長を選択',
  selectedValue: '',
  suffix: 'cm',
  title: 'あなたの現在の身長を記入してください',
};

const WEIGHT: Question = {
  children: _.range(20, 201, 1).map((l, v, a) => ({
    label: l + _.get({ [0]: '以下', [a.length - 1]: '以上' }, v, ''),
    value: `${v + 1}`,
  })),
  component: SurveyComponentType.SELECT,
  description: '',
  hideNumber: false,
  id: 'WEIGHT',
  placeholder: '体重を選択',
  selectedValue: '',
  suffix: 'kg',
  title: 'あなたの現在の体重を記入してください',
};

const HOW_OFTEN_ALCOHOL: Question = {
  children: [
    { label: '①飲まない(飲めない)', value: '1' },
    { label: '②ほとんど飲まない', value: '2' },
    { label: '③月に1～3日', value: '3' },
    { label: '④週1～2日', value: '4' },
    { label: '⑤週3～4日', value: '5' },
    { label: '⑥週5～6日', value: '6' },
    { label: '⑦毎日', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'HOW_OFTEN_ALCOHOL',
  selectedValue: '',
  suffix: '',
  title: 'お酒はどのくらいの頻度で飲みますか？',
};

const HOW_MUCH_ALCOHOL: Question = {
  children: [
    { label: '①1合未満', value: '1' },
    { label: '②1合以上2合未満', value: '2' },
    { label: '③2合以上3合未満', value: '3' },
    { label: '④3合以上4合未満', value: '4' },
    { label: '⑤4合以上5合未満', value: '5' },
    { label: '⑥5合以上6合未満', value: '6' },
    { label: '⑦6合以上', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description:
    '＊1合の目安：日本酒180ml、ビール中瓶1本(500ml)、焼酎110ml、ウイスキー60ml、ワイン2杯(240ml)',
  hideNumber: false,
  id: 'HOW_MUCH_ALCOHOL',
  selectedValue: '',
  suffix: '',
  title:
    '前の質問で③〜⑦に回答した人はお答えください。お酒を飲む日は、1日に平均どのくらいの量を飲みますか？',
};

const SMOKE: Question = {
  children: [
    { label: '①毎日吸っている', value: '1' },
    { label: '②時々吸う日がある', value: '2' },
    { label: '③1か月以上吸ってない', value: '3' },
    { label: '④吸わない', value: '4' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'SMOKE',
  selectedValue: '',
  suffix: '',
  title: 'タバコは吸いますか？',
};

const FERMENTED_FOOD: Question = {
  children: [
    { label: '①食べない(食べられない)', value: '1' },
    { label: '②ほとんど食べない', value: '2' },
    { label: '③月に1～3日', value: '3' },
    { label: '④週1～2日', value: '4' },
    { label: '⑤週3～4日', value: '5' },
    { label: '⑥週5～6日', value: '6' },
    { label: '⑦毎日', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '＊発酵食品：ヨーグルト、チーズ、納豆、漬物、味噌など',
  hideNumber: false,
  id: 'FERMENTED_FOOD',
  selectedValue: '',
  suffix: '',
  title: '腸の健康のために発酵食品をどのくらいの頻度で食べますか？',
};

const HOURS_SLEPT_PAST_MONTH: Question = {
  children: _.range(0, 19, 1).map((l, v) => ({
    label: `${l}`,
    value: `${v + 1}`,
  })),
  childrenSecondary: _.range(0, 60, 10).map((l, v) => ({
    label: `${l}`,
    value: `${v + 1}`,
  })),
  component: SurveyComponentType.SELECT_INLINE,
  description: '1日平均',
  hideNumber: false,
  id: 'HOURS_SLEPT_PAST_MONTH',
  placeholder: '時間を選択',
  selectedValue: '',
  suffix: ['時間', '分'],
  title: '最近1か月間において、あなたの実際の睡眠時間は何時間くらいでしたか？',
};

const HOW_OFTEN_SLEEPINESS: Question = {
  children: [
    { label: '①すごく眠気を感じている', value: '1' },
    { label: '②まあ眠気を感じている', value: '2' },
    { label: '③どちらかといえば眠気を感じている', value: '3' },
    { label: '④どちらでもない', value: '4' },
    { label: '⑤どちらかといえば眠気を感じていない', value: '5' },
    { label: '⑥あまり眠気は感じていない', value: '6' },
    { label: '⑦全く眠気は感じていない', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'HOW_OFTEN_SLEEPINESS',
  selectedValue: '',
  suffix: '',
  title: `直近の1か月で活動期間中に眠気を感じることはありますか？`,
};

const HOW_OFTEN_EXERCISE: Question = {
  children: [
    { label: '①実施していない', value: '1' },
    { label: '②ほとんど実施していない', value: '2' },
    { label: '③月に1～3日', value: '3' },
    { label: '④週1～2日', value: '4' },
    { label: '⑤週3～4日', value: '5' },
    { label: '⑥週5～6日', value: '6' },
    { label: '⑦毎日', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'HOW_OFTEN_EXERCISE',
  selectedValue: '',
  suffix: '',
  title: `運動の習慣はありますか？`,
};

const HOW_OFTEN_IRRITATING: Question = {
  children: [
    { label: '①すごくイライラする', value: '1' },
    { label: '②まあイライラする', value: '2' },
    { label: '③どちらかといえばイライラする', value: '3' },
    { label: '④どちらでもない', value: '4' },
    { label: '⑤どちらかといえばイライラしない', value: '5' },
    { label: '⑥あまりイライラしない', value: '6' },
    { label: '⑦全くイライラしない', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'HOW_OFTEN_IRRITATING',
  selectedValue: '',
  suffix: '',
  title: `直近の1か月の気分はどうですか?`,
};

const HOW_OFTEN_STRESS: Question = {
  children: [
    { label: '①すごくストレスを感じている', value: '1' },
    { label: '②まあストレスを感じている', value: '2' },
    { label: '③どちらかといえばストレスを感じている', value: '3' },
    { label: '④どちらでもない', value: '4' },
    { label: '⑤どちらかといえばストレスを感じていない ', value: '5' },
    { label: '⑥あまりストレスを感じていない ', value: '6' },
    { label: '⑦全くストレスを感じていない', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'HOW_OFTEN_STRESS',
  selectedValue: '',
  suffix: '',
  title: `直近の1か月でストレスを感じることはありますか？`,
};

const HOW_OFTEN_FATIGUE: Question = {
  children: [
    { label: '①すごく疲労感を感じている', value: '1' },
    { label: '②まあ疲労感を感じている', value: '2' },
    { label: '③どちらかといえば疲労感を感じている', value: '3' },
    { label: '④どちらでもない', value: '4' },
    { label: '⑤どちらかといえば疲労感を感じていない ', value: '5' },
    { label: '⑥あまり疲労感を感じていない ', value: '6' },
    { label: '⑦全く疲労感を感じていない', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'HOW_OFTEN_FATIGUE',
  selectedValue: '',
  suffix: '',
  title: `直近の1か月で疲労感を感じることはありますか？`,
};

const HOW_OFTEN_EATING: Question = {
  children: [
    { label: '①不規則', value: '1' },
    { label: '②1日1食', value: '2' },
    { label: '③1日2食', value: '3' },
    { label: '④1日3食以上', value: '4' },
  ],
  component: SurveyComponentType.RADIO,
  description: '※おやつ・間食は除きます。',
  hideNumber: false,
  id: 'HOW_OFTEN_EATING',
  selectedValue: '',
  suffix: '',
  title: '1日の食事の回数はどのくらいですか？',
};

const HOW_MUCH_WATER: Question = {
  children: [
    { label: '350以下', value: '1' },
    { label: '500', value: '2' },
    { label: '1,000', value: '3' },
    { label: '1,500', value: '4' },
    { label: '2,000', value: '5' },
    { label: '2,500', value: '6' },
    { label: '3,000以上', value: '7' },
  ],
  component: SurveyComponentType.SELECT,
  description: '',
  hideNumber: false,
  id: 'HOW_MUCH_WATER',
  placeholder: '摂取量を選択',
  selectedValue: '',
  suffix: 'ml',
  title: '1日の水分の摂取量はどれぐらいですか？',
};

const HOW_OFTEN_EAT_VEGETABLES: Question = {
  children: [
    { label: '①食べない(食べられない)', value: '1' },
    { label: '②月に1～3日', value: '2' },
    { label: '③週1～2日', value: '3' },
    { label: '④週3～4日', value: '4' },
    { label: '⑤週5～6日 ', value: '5' },
    { label: '⑥毎日1回 ', value: '6' },
    { label: '⑦毎日2～3回', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'HOW_OFTEN_EAT_VEGETABLES',
  selectedValue: '',
  suffix: '',
  title: '野菜を食材としてどのくらいの頻度で食べますか？',
};

const HOW_OFTEN_EAT_FRUITS: Question = {
  children: [
    { label: '①食べない(食べられない)', value: '1' },
    { label: '②月に1～3日', value: '2' },
    { label: '③週1～2日', value: '3' },
    { label: '④週3～4日', value: '4' },
    { label: '⑤週5～6日 ', value: '5' },
    { label: '⑥毎日1回 ', value: '6' },
    { label: '⑦毎日2～3回', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'HOW_OFTEN_EAT_FRUITS',
  selectedValue: '',
  suffix: '',
  title: '果物を食材としてどのくらいの頻度で食べますか？',
};

const HOW_OFTEN_EAT_BEANS: Question = {
  children: [
    { label: '①食べない(食べられない)', value: '1' },
    { label: '②月に1～3日', value: '2' },
    { label: '③週1～2日', value: '3' },
    { label: '④週3～4日', value: '4' },
    { label: '⑤週5～6日 ', value: '5' },
    { label: '⑥毎日1回 ', value: '6' },
    { label: '⑦毎日2～3回', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'HOW_OFTEN_EAT_BEANS',
  selectedValue: '',
  suffix: '',
  title: '豆類を食材としてどのくらいの頻度で食べますか？',
};

const HOW_OFTEN_EAT_MEAT: Question = {
  children: [
    { label: '①食べない(食べられない)', value: '1' },
    { label: '②月に1～3日', value: '2' },
    { label: '③週1～2日', value: '3' },
    { label: '④週3～4日', value: '4' },
    { label: '⑤週5～6日 ', value: '5' },
    { label: '⑥毎日1回 ', value: '6' },
    { label: '⑦毎日2～3回', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'HOW_OFTEN_EAT_MEAT',
  selectedValue: '',
  suffix: '',
  title: '肉類を食材としてどのくらいの頻度で食べますか？',
};

const HOW_OFTEN_EAT_SEAWEED: Question = {
  children: [
    { label: '①食べない(食べられない)', value: '1' },
    { label: '②月に1～3日', value: '2' },
    { label: '③週1～2日', value: '3' },
    { label: '④週3～4日', value: '4' },
    { label: '⑤週5～6日 ', value: '5' },
    { label: '⑥毎日1回 ', value: '6' },
    { label: '⑦毎日2～3回', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'HOW_OFTEN_EAT_SEAWEED',
  selectedValue: '',
  suffix: '',
  title: '海藻を食材としてどのくらいの頻度で食べますか？',
};

const FOOD_FOR_FLORA: Question = {
  children: [
    { label: '①食べない(食べられない)', value: '1' },
    { label: '②ほとんど食べない', value: '2' },
    { label: '③月に1～3日', value: '3' },
    { label: '④週1～2日', value: '4' },
    { label: '⑤週3～4日', value: '5' },
    { label: '⑥週5～6日', value: '6' },
    { label: '⑦毎日', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'FOOD_FOR_FLORA',
  placeholder: '',
  selectedValue: '',
  suffix: '',
  title:
    '腸の健康のためにオリゴ糖や全粒穀物、食物繊維などの腸内フローラのエサとなるものをどのくらいの頻度で食べますか？',
};

const SUPPLEMENTS: Question = {
  children: [
    { label: '①食べない(食べられない)', value: '1' },
    { label: '②ほとんど食べない', value: '2' },
    { label: '③月に1～3日', value: '3' },
    { label: '④週1～2日', value: '4' },
    { label: '⑤週3～4日', value: '5' },
    { label: '⑥週5～6日', value: '6' },
    { label: '⑦毎日', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'SUPPLEMENTS',
  selectedValue: '',
  suffix: '',
  title:
    '腸の健康のためにビフィズス菌や乳酸菌などが含まれるサプリメントをどのくらいの頻度で食べますか？',
};

const REGULAR_LIFE: Question = {
  children: [
    { label: '①実施していない', value: '1' },
    { label: '②ほとんど実施していない', value: '2' },
    { label: '③月に1～3日', value: '3' },
    { label: '④週1～2日', value: '4' },
    { label: '⑤週3～4日', value: '5' },
    { label: '⑥週5～6日', value: '6' },
    { label: '⑦毎日', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'REGULAR_LIFE',
  placeholder: '',
  selectedValue: '',
  suffix: '',
  title:
    '腸の健康のために睡眠不足を避け、規則的な生活をどのくらい行っていますか？',
};

const STRESS: Question = {
  children: [
    { label: '①実施していない', value: '1' },
    { label: '②ほとんど実施していない', value: '2' },
    { label: '③月に1～3日', value: '3' },
    { label: '④週1～2日', value: '4' },
    { label: '⑤週3～4日', value: '5' },
    { label: '⑥週5～6日', value: '6' },
    { label: '⑦毎日', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'STRESS',
  placeholder: '',
  selectedValue: '',
  suffix: '',
  title:
    '腸の健康のためにストレス・疲れを解消する生活をどのくらい行っていますか？',
};

const RECORD_EVACUATION: Question = {
  children: [
    { label: '①実施していない', value: '1' },
    { label: '②ほとんど実施していない', value: '2' },
    { label: '③月に1～3日', value: '3' },
    { label: '④週1～2日', value: '4' },
    { label: '⑤週3～4日', value: '5' },
    { label: '⑥週5～6日', value: '6' },
    { label: '⑦毎日', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'RECORD_EVACUATION',
  selectedValue: '',
  suffix: '',
  title: '便の状態をどのくらい記録していますか？（アプリ等含む）',
};

const BOWEL_MASSAGE: Question = {
  children: [
    { label: '①実施していない', value: '1' },
    { label: '②ほとんど実施していない', value: '2' },
    { label: '③月に1～3日', value: '3' },
    { label: '④週1～2日', value: '4' },
    { label: '⑤週3～4日', value: '5' },
    { label: '⑥週5～6日', value: '6' },
    { label: '⑦毎日', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'BOWEL_MASSAGE',
  selectedValue: '',
  suffix: '',
  title: '腸の健康のために、腸マッサージをどのくらい行っていますか？',
};

const YOGA: Question = {
  children: [
    { label: '①実施していない', value: '1' },
    { label: '②ほとんど実施していない', value: '2' },
    { label: '③月に1～3日', value: '3' },
    { label: '④週1～2日', value: '4' },
    { label: '⑤週3～4日', value: '5' },
    { label: '⑥週5～6日', value: '6' },
    { label: '⑦毎日', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'YOGA',
  selectedValue: '',
  suffix: '',
  title: '腸の健康のために、ヨガや呼吸法をどのくらい行っていますか？',
};

const EXERCISE: Question = {
  children: [
    { label: '①実施していない', value: '1' },
    { label: '②ほとんど実施していない', value: '2' },
    { label: '③月に1～3日', value: '3' },
    { label: '④週1～2日', value: '4' },
    { label: '⑤週3～4日', value: '5' },
    { label: '⑥週5～6日', value: '6' },
    { label: '⑦毎日', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description:
    '※大股でのウォーキングやお腹回りを直接刺激するストレッチなどを含みます。',
  hideNumber: false,
  id: 'EXERCISE',
  selectedValue: '',
  suffix: '',
  title:
    '腸の健康のために、腸の筋肉を刺激する適度な運動や体操をどのくらい行っていますか？',
};

const HOW_DO_YOU_FEEL_ABOUT_FLORA: Question = {
  children: [
    { label: '①自分の健康課題としての関心事ではない', value: '1' },
    {
      label: '②自分の健康課題として関心はあるが、上位には入らない',
      value: '2',
    },
    { label: '③自分の健康課題の中で、関心の上位に入る', value: '3' },
    { label: '④自分の健康課題の中で、最も関心が強い', value: '4' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'HOW_DO_YOU_FEEL_ABOUT_FLORA',
  selectedValue: '',
  suffix: '',
  title:
    'あなたは普段、ご自身の「腸内環境（腸の健康状態）」についてどのように感じていますか？',
};

const HOW_DO_YOU_FEEL_ABOUT_FLORA_EXERCISE: Question = {
  children: [
    { label: '①現状の対策（腸活）をおこなっていない', value: '1' },
    {
      label: '②現状の対策（腸活）に満足しており、他の対策方法に興味はない',
      value: '2',
    },
    {
      label:
        '③ある程度満足しているが、もっと良くなる方法があるなら、実施してみたい',
      value: '3',
    },
    {
      label:
        '④満足しきれておらず、もっと良くなる方法があるなら、ぜひ実施したい',
      value: '4',
    },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'HOW_DO_YOU_FEEL_ABOUT_FLORA_EXERCISE',
  selectedValue: '',
  suffix: '',
  title:
    'あなたが普段行っている「腸内環境」の改善のための対策（腸活）について、どのように感じていますか？',
};

const HOW_MUCH_FOR_FLORA_PER_MONTH: Question = {
  children: [
    { label: '①1,000円未満', value: '1' },
    { label: '②1,000円以上2,000円未満', value: '2' },
    { label: '③2,000円以上3,000円未満', value: '3' },
    { label: '④3,000円以上4,000円未満', value: '4' },
    { label: '⑤4,000円以上5,000円未満', value: '5' },
    { label: '⑥5,000円以上10,000円未満', value: '6' },
    { label: '⑦10,000円以上', value: '7' },
  ],
  component: SurveyComponentType.RADIO,
  description: '',
  hideNumber: false,
  id: 'HOW_MUCH_FOR_FLORA_PER_MONTH',
  selectedValue: '',
  suffix: '',
  title: 'あなたは、1ヶ月で腸活にどの程度の金額をかけてもいいと思いますか？',
};

export const SexQuestions = {
  female: { label: '女性', value: '2' },
  male: { label: '男性', value: '1' },
  other: { label: 'その他', value: '3' },
  unanswered: { label: '回答しない', value: '4' },
} as const;

export const AgeQuestions = [
  { label: '10代', maxAge: 19, minAge: 10, value: '1' },
  { label: '20代', maxAge: 29, minAge: 20, value: '2' },
  { label: '30代', maxAge: 39, minAge: 30, value: '3' },
  { label: '40代', maxAge: 49, minAge: 40, value: '4' },
  { label: '50代', maxAge: 59, minAge: 50, value: '5' },
  { label: '60代', maxAge: 69, minAge: 60, value: '6' },
  {
    label: '70代以上',
    maxAge: Number.POSITIVE_INFINITY,
    minAge: 70,
    value: '7',
  },
] as const;

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const QUESTIONS: Questions = {
  HEIGHT,
  WEIGHT,
  HOW_OFTEN_ALCOHOL,
  HOW_MUCH_ALCOHOL,
  SMOKE,
  HOURS_SLEPT_PAST_MONTH,
  HOW_OFTEN_SLEEPINESS,
  HOW_OFTEN_EXERCISE,
  HOW_OFTEN_IRRITATING,
  HOW_OFTEN_STRESS,
  HOW_OFTEN_FATIGUE,
  HOW_OFTEN_EATING,
  HOW_MUCH_WATER,
  HOW_OFTEN_EAT_VEGETABLES,
  HOW_OFTEN_EAT_FRUITS,
  HOW_OFTEN_EAT_BEANS,
  HOW_OFTEN_EAT_MEAT,
  HOW_OFTEN_EAT_SEAWEED,
  FERMENTED_FOOD,
  FOOD_FOR_FLORA,
  SUPPLEMENTS,
  REGULAR_LIFE,
  STRESS,
  RECORD_EVACUATION,
  BOWEL_MASSAGE,
  YOGA,
  EXERCISE,
  HOW_DO_YOU_FEEL_ABOUT_FLORA,
  HOW_DO_YOU_FEEL_ABOUT_FLORA_EXERCISE,
  HOW_MUCH_FOR_FLORA_PER_MONTH,
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

export const ALL_SURVEY: Question[] = [
  QUESTIONS.HEIGHT,
  QUESTIONS.WEIGHT,
  QUESTIONS.HOW_OFTEN_ALCOHOL,
  QUESTIONS.HOW_MUCH_ALCOHOL,
  QUESTIONS.SMOKE,
  QUESTIONS.HOURS_SLEPT_PAST_MONTH,
  QUESTIONS.HOW_OFTEN_SLEEPINESS,
  QUESTIONS.HOW_OFTEN_EXERCISE,
  QUESTIONS.HOW_OFTEN_IRRITATING,
  QUESTIONS.HOW_OFTEN_STRESS,
  QUESTIONS.HOW_OFTEN_FATIGUE,
  QUESTIONS.HOW_OFTEN_EATING,
  QUESTIONS.HOW_MUCH_WATER,
  QUESTIONS.HOW_OFTEN_EAT_VEGETABLES,
  QUESTIONS.HOW_OFTEN_EAT_FRUITS,
  QUESTIONS.HOW_OFTEN_EAT_BEANS,
  QUESTIONS.HOW_OFTEN_EAT_MEAT,
  QUESTIONS.HOW_OFTEN_EAT_SEAWEED,
  QUESTIONS.FERMENTED_FOOD,
  QUESTIONS.FOOD_FOR_FLORA,
  QUESTIONS.SUPPLEMENTS,
  QUESTIONS.REGULAR_LIFE,
  QUESTIONS.STRESS,
  QUESTIONS.RECORD_EVACUATION,
  QUESTIONS.BOWEL_MASSAGE,
  QUESTIONS.YOGA,
  QUESTIONS.EXERCISE,
  QUESTIONS.HOW_DO_YOU_FEEL_ABOUT_FLORA,
  QUESTIONS.HOW_DO_YOU_FEEL_ABOUT_FLORA_EXERCISE,
  QUESTIONS.HOW_MUCH_FOR_FLORA_PER_MONTH,
];

export const SURVEY_PAGE_QUESTIONS_WITH_CONDITIONALS: Question[][] = [
  // Survey Page 1
  [
    QUESTIONS.HEIGHT,
    QUESTIONS.WEIGHT,
    QUESTIONS.HOW_OFTEN_ALCOHOL,
    QUESTIONS.HOW_MUCH_ALCOHOL,
    QUESTIONS.SMOKE,
    QUESTIONS.HOURS_SLEPT_PAST_MONTH,
    QUESTIONS.HOW_OFTEN_SLEEPINESS,
    QUESTIONS.HOW_OFTEN_EXERCISE,
    QUESTIONS.HOW_OFTEN_IRRITATING,
    QUESTIONS.HOW_OFTEN_STRESS,
    QUESTIONS.HOW_OFTEN_FATIGUE,
    QUESTIONS.HOW_OFTEN_EATING,
    QUESTIONS.HOW_MUCH_WATER,
    QUESTIONS.HOW_OFTEN_EAT_VEGETABLES,
    QUESTIONS.HOW_OFTEN_EAT_FRUITS,
    QUESTIONS.HOW_OFTEN_EAT_BEANS,
    QUESTIONS.HOW_OFTEN_EAT_MEAT,
  ],
  // Survey Page 2
  [
    QUESTIONS.HOW_OFTEN_EAT_SEAWEED,
    QUESTIONS.FERMENTED_FOOD,
    QUESTIONS.FOOD_FOR_FLORA,
    QUESTIONS.SUPPLEMENTS,
    QUESTIONS.REGULAR_LIFE,
    QUESTIONS.STRESS,
    QUESTIONS.RECORD_EVACUATION,
    QUESTIONS.BOWEL_MASSAGE,
    QUESTIONS.YOGA,
    QUESTIONS.EXERCISE,
    QUESTIONS.HOW_DO_YOU_FEEL_ABOUT_FLORA,
    QUESTIONS.HOW_DO_YOU_FEEL_ABOUT_FLORA_EXERCISE,
    QUESTIONS.HOW_MUCH_FOR_FLORA_PER_MONTH,
  ],
];

export const SURVEY_PAGE_QUESTIONS = getSurveyWithoutExcludedQuestions(
  SURVEY_PAGE_QUESTIONS_WITH_CONDITIONALS,
  [QUESTIONS.HOW_MUCH_ALCOHOL]
);

export const RANK_THRESHOLD = [102.8, 96.6, 38.6] as const;

export const EMOJI_LEVEL: EmojiLevelItem[] = [
  {
    color: 'red',
    header: 'Aランク',
    key: 'rankA',
    text: `${RANK_THRESHOLD[0]}μg/min以上`,
  },
  {
    color: 'yellow',
    header: 'Bランク',
    key: 'rankB',
    text: `${RANK_THRESHOLD[1]}μg/min以上`,
  },
  {
    color: 'blue',
    header: 'Cランク',
    key: 'rankC',
    text: `${RANK_THRESHOLD[2]}μg/min以上`,
  },
  {
    color: 'grey',
    header: 'Dランク',
    key: 'rankD',
    text: `${RANK_THRESHOLD[2]}μg/min以上`,
  },
];

export const PREFECTURES = [
  { label: '北海道', value: '北海道' },
  { label: '青森県', value: '青森県' },
  { label: '岩手県', value: '岩手県' },
  { label: '宮城県', value: '宮城県' },
  { label: '秋田県', value: '秋田県' },
  { label: '山形県', value: '山形県' },
  { label: '福島県', value: '福島県' },
  { label: '茨城県', value: '茨城県' },
  { label: '栃木県', value: '栃木県' },
  { label: '群馬県', value: '群馬県' },
  { label: '埼玉県', value: '埼玉県' },
  { label: '千葉県', value: '千葉県' },
  { label: '東京都', value: '東京都' },
  { label: '神奈川県', value: '神奈川県' },
  { label: '新潟県', value: '新潟県' },
  { label: '富山県', value: '富山県' },
  { label: '石川県', value: '石川県' },
  { label: '福井県', value: '福井県' },
  { label: '山梨県', value: '山梨県' },
  { label: '長野県', value: '長野県' },
  { label: '岐阜県', value: '岐阜県' },
  { label: '静岡県', value: '静岡県' },
  { label: '愛知県', value: '愛知県' },
  { label: '三重県', value: '三重県' },
  { label: '滋賀県', value: '滋賀県' },
  { label: '京都府', value: '京都府' },
  { label: '大阪府', value: '大阪府' },
  { label: '兵庫県', value: '兵庫県' },
  { label: '奈良県', value: '奈良県' },
  { label: '和歌山県', value: '和歌山県' },
  { label: '鳥取県', value: '鳥取県' },
  { label: '島根県', value: '島根県' },
  { label: '岡山県', value: '岡山県' },
  { label: '広島県', value: '広島県' },
  { label: '山口県', value: '山口県' },
  { label: '徳島県', value: '徳島県' },
  { label: '香川県', value: '香川県' },
  { label: '愛媛県', value: '愛媛県' },
  { label: '高知県', value: '高知県' },
  { label: '福岡県', value: '福岡県' },
  { label: '佐賀県', value: '佐賀県' },
  { label: '長崎県', value: '長崎県' },
  { label: '熊本県', value: '熊本県' },
  { label: '大分県', value: '大分県' },
  { label: '宮崎県', value: '宮崎県' },
  { label: '鹿児島県', value: '鹿児島県' },
  { label: '沖縄県', value: '沖縄県' },
];

export const ERRORS = {
  AUTH_ERROR: 'ユーザー認証に失敗しました。',
  BROWSER_NOT_SUPPORT_CAMERA:
    'ご使用のブラウザはQRコードの読み込みをサポートしていません。数字を直接ご入力ください。',
  FAILED_TO_BARCODE_INPUT: '検査受付番号が正しくありません。',
  FAILED_TO_CAMERA_PERMISSION:
    'カメラへのアクセスが拒否されました。QRコードを読み込むには、再度上記ボタンをクリックしカメラへのアクセスを許可してください。（うまくいかない場合はブラウザの設定を確認してください）',
  FAILED_TO_SCAN:
    'データの読み込みに失敗しました。再度お試しいただくか、数字を直接ご入力ください。',
  GET_ASSESSMENTS_ERROR: 'テストデータ取得に失敗しました。',
  GET_ORDERS_ERROR: '注文データ取得に失敗しました。',
  GET_REPORT_ERROR: 'レポートを取得できません',
  INPUT_ERROR: '入力値が正しくありません',
  INTERNAL_ERROR_PARAM_NOT_FOUND: '内部エラーです (#PNF)',
  NOT_ANSWERED: '下記の質問が未回答です',
  RESTORING_ANSWERS:
    '回答済みの質問の復元中にエラーが発生しました。ブラウザをリロードして再度お試しください。',
  SUBMIT_DELIVERY_REQUEST_ERROR: '申し込み情報の登録に失敗しました。',
  SUBMIT_SURVEY_ERROR: 'アンケートの送信に失敗しました。',
  SURVEY_AUTO_SAVE_ERROR: '回答の保存に失敗しました。',
  SURVEY_UNANSWERED_ERROR:
    '未回答の質問があります。すべての質問に回答してください。',
  TEST_KIT_ID_REGISTRATION_ERROR: 'テストキットIDの登録に失敗しました。',
  UPDATE_ASSESSMENT_COLLECTION_TIME_ERROR: '登録に失敗しました。',
  UPDATE_ASSESSMENT_STATUS_ERROR: '検査進捗の更新に失敗しました。',
};

export const EXPLAINING_FLORA_CHECK = {
  BODY_1:
    '代表的な5種の腸内細菌の量とバランスから、あなたの腸内タイプを分類して最適な素材が入った商品を提示します。',
  BODY_2:
    'また、腸内細菌の量とバランス、生活習慣の実践度を総合的に加味したオリジナル指標であるガーデンスコアも算出します。',
  BODY_3:
    'さらに、腸に影響を与える生活習慣の実践度を他の人と比較したり、改善ポイントを確認することで、生活習慣を見直すことができます。\u200Bあなたがどれくらい腸に影響を与える生活ができているのか確認しましょう！',
  CLOSE_BUTTON_TEXT: '閉じる',
  IMAGE_1: '/images/reports/explaining_flora_check_01.svg',
  IMAGE_2: '/images/reports/explaining_flora_check_02.svg',
  IMAGE_3: '/images/reports/explaining_flora_check_03.svg',
  TITLE: '腸内フローラ検査について',
};

export const EXPLAINING_COCOA = {
  BODY_1: [
    'チョコレートやココアの原料となるカカオは、なんと5000年以上前から食べられていたことが分かっています！ 最初はエクアドルで食用として使われ、その後メキシコあたりで栽培が始まりました。 マヤ文明やアステカ文明では、カカオは神秘的な力を持つものとして大切にされ、儀式や薬、貢物、お金など様々な用途で使われていました。これほどの価値を持つほどのものを味わえるのは、特別な人だけです。王族などの特権階級、戦士だけが「力を与える」とする、チョコレートドリンクを飲むことができました。当時のチョコレートは、カカオ豆をすりつぶし、水、唐辛子、とうもろこしなどを混ぜたどろどろの飲み物でした。',
  ],
  BODY_2: [
    '19世紀までのチョコレート飲料は水や牛乳に溶けにくく、酸味が強く、独特の臭いがありました。しかし、オランダ人のバンホーテン氏が2つの発明でこれを解決しました。',
    '1つ目は、アルカリ処理で酸味を中和し、まろやかで深い味わいを実現したことです。2つ目は、圧搾機で油脂分を取り除き、水や牛乳に溶けやすいココアパウダーを作ったことです。',
    'これらの発明により、現代のように美味しく飲みやすいココアが誕生したのです。バンホーテン氏のおかげで、私たちは毎日美味しいココアを楽しめるんですね！',
  ],
  BODY_3: [
    'ココアは、美味しさだけでなく、健康にも嬉しい栄養素が豊富に含まれています。特に注目したいのが、ココアポリフェノールとテオブロミン、そして食物繊維です！',
    'ココアポリフェノールは高い抗酸化作用や抗菌作用など様々な生理活性を有します。テオブロミンは、ココアに含まれる苦み成分でココア特有の成分です。ポリフェノール同様に抗酸化作用や抗炎症作用があります。',
    'ココアに含まれる食物繊維は、特にリグニンという成分が豊富です。リグニンは、他の食物繊維と比べて消化されにくいため、腸内まで届きやすいと言われています。',
  ],
  CLOSE_BUTTON_TEXT: '閉じる',
  IMAGE_1: '/images/reports/cocoa_beans.jpg',
  IMAGE_2: '/images/reports/cocoa_drink.jpg',
  IMAGE_3: '/images/reports/cocoa_powder.jpg',
  SUBTITLE_1: 'カカオの起源を知ろう',
  SUBTITLE_2: 'ココアの誕生',
  SUBTITLE_3: 'ココアの特徴',
  TITLE: 'ココアの歴史と特徴を知ろう！',
};

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const TEST_STEPS_MODAL = {
  TITLE: '検査ステップ',
  IMAGE_SECTION_1_TEXT: '検査お申し込み',
  BODY_TEXT_1:
    '検査キットをすでにお持ちの場合は「免疫チェック(検査キット配送なし)」検査キットをまだお持ちでない方は「免疫チェック」をお申し込みください。',
  IMAGE_SECTION_2_TEXT: '検査キットを確認',
  IMAGE_SECTION_3_TEXT: 'マイページ内の「検査をはじめる」ボタンをクリック',
  BODY_TEXT_2:
    '検査キットを確認後、免疫チェックマイページへアクセスしてください',
  IMAGE_SECTION_4_TEXT: '検査受付番号のメモまたは写真撮影',
  IMAGE_SECTION_5_TEXT: '唾液を採取',
  IMAGE_SECTION_6_TEXT: '採取した唾液を投函',
  BODY_TEXT_3: '〜 検査（約2週間）〜',
  IMAGE_SECTION_7_TEXT: 'マイページ内にて検査結果を確認',
  CLOSE_BUTTON_TEXT: '閉じる',
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

export const TOP_BAR = {
  ACCOUNT: 'アカウント',
  CONCEPT: 'わたしたちの想い',
  CONTACT_US: 'お問い合わせ',
  CUSTOMER_FEEDBACK: 'お客様の声',
  FAQ: 'よくあるご質問',
  HOME: 'ホーム',
  INTESTINE_COLUMN: '腸に関するコラム',
  LOGIN: 'ログイン',
  LOGOUT: 'ログアウト',
  MEIJI_ID: '明治会員ID',
  MY_PAGE: 'マイページ',
  PRODUCT_LIST: '商品一覧',
  PURCHASE_HISTORY: '購入履歴',
  SHOW_MENU: [
    'CUSTOMER_FEEDBACK',
    'FAQ',
    'COLUMN',
    'COLUMN_LIST',
    'CONCEPT',
    'MY_PAGE',
    'MY_PAGE_REPORTS',
    'MY_PAGE_REPORTS_LIST',
    'MY_PAGE_REPORT_CHALLENGES',
    'NOT_FOUND',
    'REGISTER',
    'REGISTER_COMPLETE',
    'REGISTER_CONFIRMATION',
    'SURVEY_PAGE',
    'TEST_KIT_COLLECTION_INSTRUCTIONS',
    'TEST_KIT_POSTAGE_INSTRUCTIONS',
    'TEST_KIT_PREPARE_COLLECTION',
    'TOP',
    'CHECK_KIT',
    'FIRST_SET',
    'PRODUCTS',
    'DRINK',
    'PURCHASE_HISTORY',
  ],
  TEST: '検査',
  TEST_APPLICATION: GENERIC_WORDS.TEST_APPLICATION,
  TEST_RESULT: '検査結果一覧',
};

export const GREY_PAGES: (AppRoute | null)[] = [
  AppRoute.MY_PAGE,
  AppRoute.MY_PAGE_REPORTS_LIST,
  AppRoute.COLUMN_LIST,
  AppRoute.CUSTOMER_FEEDBACK,
  AppRoute.PURCHASE_HISTORY,
];

export const FOOTER = {
  COPYRIGHT: 'Copyright Meiji Co., Ltd. All Rights Reserved',
  LARGE_FOOTER_ROUTE: [
    'ALLERGY_INGREDIENT_PRODUCTS',
    'CHECK_KIT',
    'CONCEPT',
    'CUSTOMER_FEEDBACK',
    'DRINK',
    'FAQ',
    'FIRST_SET',
    'COLUMN_LIST',
    'COLUMN',
    'TOP',
    'PRODUCTS',
    'FIRST_SET',
    'ALLERGY_INGREDIENT_PRODUCTS',
    'TEASER',
  ],
  LINKS: [
    { name: '個人情報保護について', url: 'https://www.meiji.co.jp/privacy/' },
  ],
  LOGOUT: 'ログアウト',
  TERMS_LINK_TEXT: '利用規約',
  TERMS_LINK_URL: '/terms/v20241101',
};

// cspell:ignore Shōsei
// MEMO: Shōsei Yū <yu@basal.dev>
//       Use this ONLY if AVERAGE IGA is not available in the Report Result data
export const AVERAGE_IGA: {
  female: number;
  generation: string;
  male: number;
  maxAge: number;
  minAge: number;
}[] = [
  {
    female: 276.0,
    generation: '20代',
    male: 352.3,
    maxAge: 29,
    minAge: 20,
  },
  {
    female: 280.5,
    generation: '30代',
    male: 329.9,
    maxAge: 39,
    minAge: 30,
  },
  {
    female: 253.6,
    generation: '40代',
    male: 329.1,
    maxAge: 49,
    minAge: 40,
  },
  {
    female: 285.9,
    generation: '50代',
    male: 320.7,
    maxAge: 59,
    minAge: 50,
  },
  {
    female: 221.9,
    generation: '60歳以上',
    male: 230.5,
    maxAge: Number.POSITIVE_INFINITY,
    minAge: 60,
  },
];

// TODO move to theme.ts
export const REPORT_BAR_MALE_COLOR = '#53B4DE';
export const REPORT_BAR_FEMALE_COLOR = '#EF83B0';

export const ONGOING_ASSESSMENT_STATUSES: AssessmentStatus[] = [
  AssessmentStatus.START_APPLICATION,
  AssessmentStatus.READY_FOR_POST,
  AssessmentStatus.PAYMENT_COMPLETE,
  AssessmentStatus.PREPARING_SURVEY,
  AssessmentStatus.PREPARING_POST,
  AssessmentStatus.TESTING,
];

export const UNDER_ASSESSMENT_STATUSES: AssessmentStatus[] = [
  AssessmentStatus.READY_FOR_POST,
  AssessmentStatus.PAYMENT_COMPLETE,
  AssessmentStatus.PREPARING_SURVEY,
  AssessmentStatus.PREPARING_POST,
  AssessmentStatus.TESTING,
];

export const ColumnCategories = [
  'FOOD_AND_INTESTINE',
  'EXERCISE_AND_INTESTINE',
  'LIFESTYLE_MENTAL',
  'INTESTINAL_BACTERIA',
  'OTHERS',
] as const;
export type ColumnCategory = (typeof ColumnCategories)[number];

export const ChallengeCategories = [
  'FOOD_AND_GUT',
  'LIFE_AND_GUT',
  'EXERCISE_AND_GUT',
] as const;

export type ChallengeCategory = (typeof ChallengeCategories)[number];

export const ColumnCategoryJA: { [key in ColumnCategory]: string } = {
  EXERCISE_AND_INTESTINE: '運動・身体活動コラム',
  FOOD_AND_INTESTINE: '食事のコラム',
  INTESTINAL_BACTERIA: '腸内細菌・短鎖脂肪酸のコラム',
  LIFESTYLE_MENTAL: '生活習慣・メンタルのコラム',
  OTHERS: 'その他のコラム',
};

export const ColumnCategoryJAShort: { [key in ColumnCategory]: string } = {
  EXERCISE_AND_INTESTINE: '運動・身体活動',
  FOOD_AND_INTESTINE: '食事',
  INTESTINAL_BACTERIA: '腸内細菌・短鎖脂肪酸',
  LIFESTYLE_MENTAL: '生活習慣・メンタル',
  OTHERS: 'その他',
};

export const ChallengeCategoryJAShort: { [key in ChallengeCategory]: string } =
  {
    EXERCISE_AND_GUT: '腸を健康にする運動習慣',
    FOOD_AND_GUT: '腸を健康にする食習慣',
    LIFE_AND_GUT: '腸を健康にする生活習慣',
  };

export const ColumnCategoryToURLSlug: {
  category: ColumnCategory;
  slug: string;
}[] = [
  { category: 'FOOD_AND_INTESTINE', slug: 'food' },
  { category: 'EXERCISE_AND_INTESTINE', slug: 'exercise' },
  { category: 'LIFESTYLE_MENTAL', slug: 'lifestyle' },
  { category: 'INTESTINAL_BACTERIA', slug: 'bacteria' },
  { category: 'OTHERS', slug: 'other' },
];

type Column = {
  category: ColumnCategory;
  headerImage: string;
  id: string;
  lastUpdated?: string;
  sections: {
    image: string;
    imageLegend?: string[];
    reference?: string[];
    text: string[];
    title: string;
  }[];
  title: string;
};

const REFERENCE_HEADER_TEXT = '参考文献 : \n';

const INTESTINAL_COLUMNS: Column[] = [
  {
    category: 'INTESTINAL_BACTERIA',
    headerImage: 'intestinal_column_01_01',
    id: 'intestinal_column_01',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'intestinal_column_01_02',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊    DNA Res. 2016 Apr;23(2):125-33.\n',
          'https://academic.oup.com/dnaresearch/article/23/2/125/1745357?login=false',
        ],
        text: [
          '食生活が違えば、腸に住む細菌の種類にも違いがあるのでしょうか？　答えはイエス。生活圏によって腸内細菌叢も異なり、独自のバランスでその国の人々の健康を守っているのです。それでは、日本人の腸内細菌叢にはどのような特徴があるのでしょうか。\n日本、中国、欧州や南北アメリカなど12カ国の人たちの腸内細菌叢を比較したところ、日本人には、他の11カ国と比べて「ビフィズス菌」「ブラウティア菌」といった炭水化物（糖質）を栄養源にする菌が多いことがわかりました（＊）。',
        ],
        title: '炭水化物好きの腸内細菌たちが、日本人の健康を支えている？',
      },
      {
        image: 'intestinal_column_01_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊＊　Nat Commun. 2022 Aug 18;13(1):4477.\n',
          'https://www.nature.com/articles/s41467-022-32015-7\n',
          '＊＊＊　Nature. 2010 Apr 8;464(7290):908-12.\n',
          'https://www.nature.com/articles/nature08937',
        ],
        text: [
          'ビフィズス菌に比べて知名度の低いブラウティア菌ですが、健康な日本人に多い典型的な菌で、アミノ酸のオルニチンなどを作って、抗肥満作用を発揮しているとする報告があります（＊＊）。\nこれらの菌たちの栄養源のことを考えると、話題の「糖質制限食」が本当に私たち日本人に合うのかということも、腸の健康を含めて考えなければならないのではないでしょうか。\nまた、北米の人などではほとんど発見されないのに、日本人の腸には海藻の多糖類を分解する酵素を持つ細菌がいることもわかっています（＊＊＊）。\nこのように、日本ならではの食生活が私たちの腸内細菌叢を作ってきました。しかし、近年、ビフィズス菌が少ない人が増えているという調査結果も出ています。急激な食事の変化が腸内細菌に影響を及ぼしているのか、腸の声にも耳を傾けてみませんか。',
        ],
        title: '健康な日本人に多いブラウティア菌で注目される抗肥満作用',
      },
    ],
    title:
      '腸内細菌叢はその地域の食生活を反映――日本人の腸にビフィズス菌が多いわけ',
  },
  {
    category: 'INTESTINAL_BACTERIA',
    headerImage: 'intestinal_column_02_01',
    id: 'intestinal_column_02',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'intestinal_column_02_02',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊    Scepanovic, P. et al. 2019, Microbiome, 7(1):130\n',
          'https://microbiomejournal.biomedcentral.com/articles/10.1186/s40168-019-0747-x\n',
        ],
        text: [
          '腸内細菌叢のバランスは個々人で異なると言われていますが、この違いは何に由来するものなのでしょうか。個人差でまず思いつくのは、私達生物の設計図とも言われる「遺伝子・ゲノム」ですが、実は腸内細菌叢に限っては遺伝子の違いはあまり影響しないと言われています。実際に遺伝的背景が同じ一卵性双生児の腸内細菌叢を比較してみると、二卵性双生児と同程度の違いがあることが報告されています（＊）。',
        ],
        title: '腸内細菌叢への遺伝子の影響は限定的',
      },
      {
        image: 'intestinal_column_02_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊＊   Asnicar, F. et al. 2021, Nat. Med. 27(2):321\n',
          'https://www.nature.com/articles/s41591-020-01183-8\n',
        ],
        text: [
          'では腸内細菌叢に影響を与える因子は何かというと、抗生物質などいろいろあるのですが、私たちの生活に一番身近なものだと、それは「食事」であると考えられています（＊＊）。特に食物繊維などを豊富に含む植物性由来の食品の多様性が腸内細菌叢のバランスには大きく影響すると言われています。遺伝子は変えられないが、腸内細菌叢は食事によって生まれたあとに決まっていくものなのです。\n腸にいい食生活を過ごすことで、自分にとって良い腸内環境をつくっていきましょう。',
        ],
        title: '腸内環境に重要なのは食事！',
      },
    ],
    title: '腸内環境を決めるのは遺伝子？それとも食事？',
  },
  {
    category: 'INTESTINAL_BACTERIA',
    headerImage: 'intestinal_column_03_01',
    id: 'intestinal_column_03',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'intestinal_column_03_02',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊    Johnson, AJ. et al. 2020, Front. Nutr. doi:10.3389/fnut.2020.00079\n',
          'https://www.frontiersin.org/articles/10.3389/fnut.2020.00079/full\n',
        ],
        text: [
          '腸内環境を良くしようとヨーグルトやオリゴ糖などを食べてみたのに、効果が体感できずに数日で食べるのをやめてしまった人もいるのではないでしょうか？\n腸内環境は変えるのには時間がかかると言われており、体感に繋がる効果が現れるのは早くても数週間から1ヶ月程度はかかると言われています（＊）。これには食べたものの種類や個人差など多様な条件が複雑に関わるため、一概には言えないところもあります。',
        ],
        title: '食事の改善から効果の体感を得るには2週間から1か月程度',
      },
      {
        image: 'intestinal_column_03_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊＊　David, LA. et al. 2014, Nature, 505(7484):559\n',
          'https://www.nature.com/articles/nature12820\n',
        ],
        text: [
          'ベジタリアンの人がいきなり一切野菜を食べないで肉食のみの生活に切り替るなど、極端に食習慣を変えると、腸内細菌のバランスは数日のうちに変化することが報告されています（＊＊）。\n一方で、余程極端な食生活の切り替えではない限り、腸内環境を変化させるには時間がかかるのです。もし新しい食品などを取り入れるなら、まずは2週間、長い目で試してみましょう。もしかしたらあなたの腸内環境にあった食品が見つかるかもしれません。',
        ],
        title: '最低2週間は腸にいい食事を試してみよう！',
      },
    ],
    title: '腸内環境から体感を得るにはどのくらいの期間が必要なのか',
  },
];

const FOOD_COLUMNS: Column[] = [
  {
    category: 'FOOD_AND_INTESTINE',
    headerImage: 'food_column_01_01',
    id: 'food_column_012345678',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'food_column_01_02',
        text: [
          '腸内に多くの種類の細菌がいることは腸の健康状態を表す指標の一つ。菌たちはそれぞれ好きな栄養源も違うので、バラエティ豊かな食生活を意識したいところです。\n実際、18歳～60歳までの432人を対象に、食事内容と腸内細菌の関係を分析したところ、バランスのいい食生活の指針となる「食事ガイドライン」（後述する米国のガイドライン）に沿った食事をとっている人ほど、腸内細菌の多様性が豊かな（腸内細菌の種類が多い）腸内環境を持っていることがわかりました。',
        ],
        title: 'バランスのいい食生活が腸の健康維持の基本',
      },
      {
        image: 'food_column_01_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          'J Nutr. 2023 Jul;153(7):2004-2015.\nhttps://www.sciencedirect.com/science/article/pii/S0022316623126216?via%3Dihub#appsec1',
        ],
        text: [
          '食品の中では、食事ガイドラインでも重視されている野菜、豆類、全粒穀物、乳製品の摂取スコアが高いことが、腸内細菌の多様性に確かな貢献をしていました。 \nこれらの食品からとれるさまざまな種類の食物繊維が腸の中に住む多様な細菌の生存をサポートしており、乳製品では特に発酵乳製品から有用菌を多くとっている場合に、腸内細菌の種類も増加しているとのことです。',
        ],
        title: '各種の食物繊維と有用菌で多くの腸内細菌が元気に',
      },
    ],
    title: '食物繊維が多い食品＋有用菌入り発酵乳製品で、腸内細菌叢が豊かに',
  },
  {
    category: 'FOOD_AND_INTESTINE',
    headerImage: 'food_column_02_01',
    id: 'food_column_02',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'food_column_02_02',
        text: [
          '発酵食品は、食材が発酵するプロセスで新たな化合物ができるなど栄養価値が高まることで注目されます。世界中で食されるヨーグルトでは各種機能性が研究されていますが、たとえば肥満大国といわれる米国では、体重増加抑制作用が注目されています。\n  米国で実施された３つの大規模健康調査の対象者（計男性2万2,557人、女性9万8,320人）を4年間追跡したところ、各種食品中ヨーグルトを常食している人たちで、平均0.82 ポンド（＝0.37㎏)と最も大きな体重減少が見られました。',
        ],
        title:
          '腸内細菌叢に変化をもたらすヨーグルトが太りにくい食品No.1という結果も',
      },
      {
        image: 'food_column_02_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊　N Engl J Med. 2011 Jun 23;364(25):2392-404.\n',
          'https://www.nejm.org/doi/full/10.1056/nejmoa1014296\n',
          '＊＊　J Nutr. 2021 Aug; 151(8): 2383–2389.\n',
          'https://www.sciencedirect.com/science/article/pii/S0022316622002930?via%3Dihub\n',
          '＊＊＊　ヨーグルト中のカルシウム量は、全脂無糖タイプで100gに120ml（日本食品成分表増補2023年版より）。',
        ],
        text: [
          '上記の研究を行ったチームは、ヨーグルト摂取による腸内細菌叢の変化が体重抑制に影響を与えた可能性があるとしています（＊）。\nまた、カルシウムには脂肪蓄積を阻害する作用があるため、ヨーグルトを多く食べてカルシウム摂取量が増えることも肥満抑制に有効だとする研究者もいます。実際に、30年間にわたって計4,097人のアメリカ人（51％が女性）を追跡した試験では、総カルシウム摂取量が多いほど肥満が抑制されていたという結果が出ています（＊＊）。\n  ヨーグルトに含まれる有用菌、発酵生成物、牛乳由来のカルシウムなど複数の成分が太りにくさにつながっているのでしょう。',
        ],
        title: '腸内細菌叢の変化やカルシウム摂取量が太りにくさに影響か？',
      },
    ],
    title: 'ヨーグルトを毎日食べると太りにくくなる?!',
  },
  {
    category: 'FOOD_AND_INTESTINE',
    headerImage: 'food_column_03_01',
    id: 'food_column_03',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'food_column_03_02',
        text: [
          '炭水化物の中でも、消化吸収されにくく腸内細菌のエサになる食物繊維（発酵性食物繊維とも呼ばれる）やオリゴ糖をきちんととらないとどうなるのか。マウスでこれを検証した実験があります。この研究者たちは、このような炭水化物をMAC（Microbiota-accessible carbohydrates、腸内細菌がエサにできる炭水化物）と呼んでいます。\n最初MACをたくさん含むエサを食べていたマウスを低MAC食にしたところ、腸内細菌の3分の1近い種類が失われ、再び高MAC食に戻しても、そのほとんどが失われたままになってしまったのです。',
        ],
        title: '食物繊維・オリゴ糖摂取量が激減したら腸内細菌の3分の１が消滅',
      },
      {
        image: 'food_column_03_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊    Nature.2016 Jan 14;529(7585):212-5.\n',
          'https://www.nature.com/articles/nature16504',
          '＊＊　Science. 2023 Dec 15;382(6676):eadj3502.\n',
          'https://www.science.org/doi/10.1126/science.adj3502?url_ver=Z39.88-2003&rfr_id=ori:rid:crossref.org&rfr_dat=cr_pub%20%200pubmed',
        ],
        text: [
          'さらに、この状態の親マウスから生まれた子どものマウスは最初から腸内細菌の種類が少なめで、高MAC食で育てていても、菌の種類が親の世代ほどまで増えることはありませんでした。そしてその状態は子孫に受け継がれていったのです（＊）。\nこれは、実験室で行われた動物試験であって、現実の人間社会にそのままあてはめることはできませんが、腸内細菌の種類が豊富なほうが病原体の感染に強いなど健康度合いが高いことが多くの研究によって報告されています（＊＊）。\n菌たちのエサである食物繊維を意識してとることの大切さを示唆しているといえそうです。',
        ],
        title: '細菌が減少した親の腸の状態は、その子供にも受け継がれる?!',
      },
    ],
    title: '食物繊維とオリゴ糖は菌のエサ！採らないと腸内細菌の種類が減る',
  },
  {
    category: 'FOOD_AND_INTESTINE',
    headerImage: 'food_column_04_01',
    id: 'food_column_04',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'food_column_04_02',
        text: [
          '乳酸菌やビフィズス菌など、世界で多くの種類のプロバイオティクスが、発酵乳やサプリメントなど様々な商品形態で売られています。これらをとることが私たちにどのような恩恵をもたらしてくれるかを、15件の臨床試験に参加した788人分のデータを統合して分析した結果、総コレステロール値、LDLコレステロール値、BMI（体格指数）、ウエストサイズ、炎症指標が低下することが確認されました。',
        ],
        title:
          '乳酸菌などのプロバイオティクス摂取でコレストロール値やBMI、炎症指標が低下',
      },
      {
        image: 'food_column_04_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊   Ann Med. 2015;47(6):430-40.\n',
          'https://www.tandfonline.com/doi/full/10.3109/07853890.2015.1071872\n',
          '＊＊　Clin Nutr. 2021 Aug;40(8):4915-4931.\n',
          'https://www.clinicalnutritionjournal.com/article/S0261-5614(21)00319-8/abstract',
        ],
        text: [
          'さらに、総コレステロール値、LDLコレステロール値の低下については、①サプリよりも発酵乳製品（飲料もしくはヨーグルト）をとる、②少なくとも8週間以上摂取、③一種類の菌より複数の菌をとる、という3つの条件で確かな成果が得られていたようです（＊）。\nプロバイオティクス摂取が肥満抑制に与える影響を26件の臨床試験（計1,720人分のデータ）を基に分析した別の研究でも、体重、BMI、ウエストサイズが減少することが確認されています。こちらも、体脂肪率を減らすのに必要な摂取期間は、前述の研究同様に「8週間以上」でした（＊＊）。\nまずは、2ヶ月ほど連続してプロバイオティクスを摂取し、お腹まわりなど体の変化や実感を確かめましょう。',
        ],
        title:
          '8週間以上連続してとるのがおすすめ！\nサプリではなく発酵乳製品がいいという報告も',
      },
    ],
    title: 'プロバイオティクス（有用菌）をとることで腸から全身の健康が整う',
  },
  {
    category: 'FOOD_AND_INTESTINE',
    headerImage: 'food_column_05_01',
    id: 'food_column_05',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'food_column_05_02',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊    Hehemann, JH. et al. 2010, Nature, 464(7290):908\n',
          'https://www.nature.com/articles/nature08937',
        ],
        text: [
          '草食動物は肉食動物などが持っていない食物繊維を分解する酵素を持つことで、植物からエネルギーを作ることができます。なんと、日本人の腸にも「特別な酵素を有する腸内細菌」がいることをご存知ですか？\nバクテロイデス・プレビウス（Bacteroides plebius）という腸内細菌、実は日本人の腸でだけ「海苔などの海藻の成分を分解する酵素」を保有していることが報告されています。欧米人もバクテロイデス・プレビウスを持っているのですが、欧米人の腸にいるものは海藻成分を分解できないようです（＊）。',
        ],
        title: '日本人は海藻を分解する菌を持っている',
      },
      {
        image: 'food_column_05_03',
        text: [
          'なぜ日本人のバクテロイデス・プレビウスだけが、海藻を分解する酵素を保有しているのでしょうか？それは日本人の食文化と密接に関係しているといわれています。\n日本人は古くから、海苔や昆布などの海藻を食生活に取り入れてきました。海藻を頻繁に摂取することで、海藻に付着する海洋細菌が腸内に入り込み、バクテロイデス・プレビウスに海藻を分解する遺伝子を伝えた可能性があります。\n海藻を食べる習慣が、バクテロイデス・プレビウスの遺伝子変異を促し、海藻分解能力を獲得させた可能性は、非常に興味深い仮説です。',
        ],
        title: '海中微生物から新たな遺伝子を獲得！？',
      },
    ],
    title: '海藻を分解する腸内細菌は日本人特有？',
  },
  {
    category: 'FOOD_AND_INTESTINE',
    headerImage: 'food_column_06_01',
    id: 'food_column_06',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'food_column_06_02',
        text: [
          '「腸内環境を良くするには食物繊維やオリゴ糖を摂ろう」という話はよく耳にするかと思います。もちろん、食物繊維やオリゴ糖をたくさん食べると腸内環境にいい影響を与えますが、さらに腸内環境を良くするための栄養素があります。それは、ビタミンやミネラルなどの微量栄養素です。\n食物繊維やオリゴ糖が腸内細菌の主食だとすると、ビタミンやミネラルなどの微量栄養素はそれらの副菜。腸内細菌が主食をしっかりと代謝していくには、副菜であるビタミンやミネラルなどの微量栄養素も必要不可欠です。',
        ],
        title: '腸内細菌にとっての副菜となるビタミン・ミネラル',
      },
      {
        image: 'food_column_06_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊    Pham, VT. et al. Nutr. Res. 2021, 95:35\n',
          'https://www.sciencedirect.com/science/article/pii/S0271531721000580\n',
          '＊＊　Littlejohn, PT. et al. 2023, Nat. Microbiol. 2023, 8(12):2392\n',
          'https://www.nature.com/articles/s41564-023-01519-3',
        ],
        text: [
          '実際に特定のビタミンを摂取することで腸内細菌叢の多様性が増え、食物繊維から作られる短鎖脂肪酸の産生量が増えるという報告があります（＊）。最近の研究では、微量栄養素の欠乏は腸内細菌の抗生物質耐性（病原菌が抗生物質に耐性を持ち、抗生物質が効かなくなってしまう）を高めることに繋がるかもしれないとも言われています（＊＊）。\n結局のところ、食物繊維やオリゴ糖の摂取は必須ですが、その上で効果を最大限に発揮するには微量栄養素も含めた「バランスの良い食事」が腸内環境を豊かにする一番の近道となるのです。',
        ],
        title: '腸内細菌が薬の効果にも影響を与えている？',
      },
    ],
    title: '食物繊維だけでは足らない？腸内環境を豊かにするには',
  },
];

const EXERCISE_COLUMNS: Column[] = [
  {
    category: 'EXERCISE_AND_INTESTINE',
    headerImage: 'exercise_column_01_01',
    id: 'exercise_column_01',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'exercise_column_01_02',
        text: [
          'ウォーキングのような有酸素運動と筋トレタイプの運動ではどちらにより整腸作用があるのでしょうか？\n65歳以上で座っている時間が長い日本女性32人が、有酸素運動に取り組むグループと筋トレを行うグループに分かれて12週間続けた結果を見てみましょう。\n前者は毎日、普通以上の速さ（3メッツ以上）で1時間ウォーキングを行い、後者は体幹を鍛えるトレーニングを毎週1時間グループで実施し、さらに毎日家でも続けるように指導されました。',
        ],
        title: '有酸素運動が腸の健康機能に効果的！',
      },
      {
        image: 'exercise_column_01_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊    Nutrients. 2019 Apr; 11(4): 868.\n',
          'https://www.mdpi.com/2072-6643/11/4/868#B39-nutrients-11-00868\n',
          '＊＊　Geriatr Gerontol Int. 2010 Jul:10 Suppl 1:S236-43.\n',
          'https://onlinelibrary.wiley.com/doi/10.1111/j.1447-0594.2010.00589.x\n',
        ],
        text: [
          'すると12週間後に、早歩きをした人たちで、肥満やメタボリックシンドロームのリスク低下に関わるとされるバクテロイデス門の菌数が有意差をもって増えていました（＊）。\n特に、毎日20分以上早歩きをした人の腸では、よりバクテロイデスの量が増え、排便も改善しました。\nまた、「1日8,000歩かつ20分早歩き」を行う65歳以上の人では、メタボリックシンドローム、高血圧、糖尿病などのリスクが低いという、群馬県中之条町で長期にわたって住民を追跡した研究の結果もあります（＊＊）。\nつまり、「20分の早足ウォーキング」は、腸内環境を整え、同時に全身の健康度を高める効率的な運動といっていいでのではないでしょうか。',
        ],
        title: '20分の早足歩きで一挙両得。腸が整い、全身の健康維持にも効果',
      },
    ],
    title: '毎日の早歩きで腸が元気に！排便機能が改善し腸内細菌叢も整う',
  },
  {
    category: 'EXERCISE_AND_INTESTINE',
    headerImage: 'exercise_column_02_01',
    id: 'exercise_column_02',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'exercise_column_02_02',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊    Complement Ther Med. 2020 Nov:54:102553.\n',
          'https://www.sciencedirect.com/science/article/abs/pii/S0965229920318203?via%3Dihub\n',
        ],
        text: [
          '「腸もみ」といった腸のマッサージは、便秘の女性などに人気があります。実際にその効果のほどはどうなのでしょうか？\n　腹部マッサージに関する10件の研究とその対象者計464人分のデータを解析したところ、便通が改善されたり、胃腸の調子がよくなる、腹部膨満感が減少するといった効果が得られることが確認されました。腹部をマッサージすることで副交感神経の活動が活発化する、腸のぜんどう運動が促されるなどでこのような結果が得られるようです（＊）。',
        ],
        title: '腸もみで副交感神経が優位に',
      },
      {
        image: 'exercise_column_02_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊＊（Complement Ther Med\n. 2022 Nov:70:102861.\n',
          'https://www.sciencedirect.com/science/article/pii/S0965229922000632\n',
        ],
        text: [
          '腹部のマッサージは、便秘対策などの直接的な腸ケアとして行われるだけではなく、メンタル面のケアなどにも用いられたりします。過去30年分の研究をまとめた報告では、「痛みをうったえる子供が泣く時間が大幅に短縮した」「アロマを使用した腹部マッサージで月経関連の不調が改善」、「心理的ストレスや不安が軽減」といった多くの事例が紹介されています（＊＊）\n「背に腹は代えられない」ということわざがあるほど、大事な場所とされてきた腸に「手当て」をするマッサージは、お腹のケアにとどまらず、不安緩和作用も持つようです。\n　ただし、施術技術の差や受ける側の個人差もありますので、万人に効くわけではないことには留意も必要です。',
        ],
        title: '月経関連の不調、不安緩和まで。腸の手当ては全身の癒しに',
      },
    ],
    title: '腸マッサージで改善するのは便秘だけではない',
  },
  {
    category: 'EXERCISE_AND_INTESTINE',
    headerImage: 'exercise_column_03_01',
    id: 'exercise_column_03',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'exercise_column_03_02',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊    Exp Gerontol. 2019 Nov:127:110722.\n',
          'https://www.sciencedirect.com/science/article/abs/pii/S0531556519304772?via%3Dihub\n',
        ],
        text: [
          '長寿な人には腸内細菌叢にも特徴があり、それを助けるような物質が作られているのではないか――。それを解き明かそうとする研究が世界中で進められています。\nその中から、腸内細菌叢と元気な高齢者の筋力に関係があるとする研究をご紹介しましょう。\n加齢などにより筋肉量が減少して、筋力や身体機能が低下することをサルコペニアと呼びますが、これは寿命にまで影響することがわかっています。そこで、a. 身体機能が低い高齢者（11人）とｂ. 高い高齢者（18人）の便を、腸に菌がいないマウス（無菌マウス）に移植して、腸内細菌の影響が身体機能にどう表れるかを調べました。\nすると、ｂのマウスはａのマウスより、握力が平均6.4％増加したのです。そして、bのマウスには、ヒトでも筋力がある高齢者に多いという報告がある「プレボテラ」「バルネシエラ」といった腸内細菌が多くなっていました。「プレボテラ」は炎症を抑えるプロピオン酸、「バルネシエラ」は病原体から守る酢酸といった有用物質を作ります（＊）。',
        ],
        title: '身体機能の高い高齢者の便をマウスに移植したら筋力がアップ！',
      },
      {
        image: 'exercise_column_03_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊＊　Clin Nutr. 2022 Jul;41(7):1491-1500.\n',
          'https://www.clinicalnutritionjournal.com/article/S0261-5614(22)00159-5/abstract\n',
        ],
        text: [
          '一方、筋肉量が少ない高齢の便中には、短鎖脂肪酸の一つ酪酸が少ないとする研究もあり、便中に酪酸が少ないことがサルコペニア・リスクの指標として使えるのではないかとする声もあります（＊＊）。\n腸内細菌が作る有用物質として注目されるプロピオン酸、酢酸、酪酸は短鎖脂肪酸と呼ばれます。サルコペニア予防のためには、短鎖脂肪酸を作りだす腸内細菌たちが元気を保てる環境の維持がカギの一つと言えそうです。そのためにも、こうした菌たちのエサになる食物繊維類はしっかりとるようにしたいものです。',
        ],
        title: '筋肉量が少ない高齢者の腸では酪酸生成量が少ない',
      },
    ],
    title: '筋力がある高齢者には、短鎖脂肪酸を作り出す腸内細菌が多い',
  },
  {
    category: 'EXERCISE_AND_INTESTINE',
    headerImage: 'exercise_column_04_01',
    id: 'exercise_column_04',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'exercise_column_04_02',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊    Clarke, SF. et al. 2014, Gut, 63(12):1913\n',
          'https://gut.bmj.com/content/63/12/1913\n',
          '＊＊  Barton, W. et al. 2018, Gut, 67(4):625\n',
          'https://gut.bmj.com/content/67/4/625',
        ],
        text: [
          'アスリートは競技力向上の為に日々トレーニングと食事コントロールを積んでいます。では、彼らの腸内環境は一般人とどの程度異なるのでしょうか。競技種目による違いはあれど、多くのアスリートの腸内環境は一般人と比べて多様性が高い（＊）、短鎖脂肪酸の産生量が高い（＊＊）など、腸内環境として好ましい特徴があると言われています。また、アスリートは、一般の人よりも腸内環境が安定していると言われています。腸内環境が安定していると、体調を崩しにくくなり、パフォーマンスを維持しやすくなると言われています。',
        ],
        title: 'アスリートは短鎖脂肪酸の産生量が高い',
      },
      {
        image: 'exercise_column_04_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊＊＊  Morita, H. et al. 2023, Sci. Adv. 9(4):eadd2120\n',
          'https://www.science.org/doi/10.1126/sciadv.add2120',
        ],
        text: [
          'アスリートの日々の努力は、その競技の上達だけでなく、良い腸内環境を作るのにも貢献しているのかもしれませんが、実は腸内環境を良くすること自体が、彼らの競技力の向上にも寄与している可能性が明らかになってきています。\nある研究では特定の腸内細菌がいるヒトでは、特定のオリゴ糖を食べることで持久力の向上が認められたことが報告されています（＊＊＊）。アスリートと腸内細菌は、互いを培い高め合うパートナーなのかもしれません。',
        ],
        title: 'オリゴ糖を食べることで持久力向上も！？',
      },
    ],
    title: 'アスリートと腸内環境の関係性',
  },
];

const LIFESTYLE_COLUMNS: Column[] = [
  {
    category: 'LIFESTYLE_MENTAL',
    headerImage: 'lifestyle_column_01_01',
    id: 'lifestyle_column_01',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'lifestyle_column_01_02',
        text: [
          '「平日は忙しいから休日に寝だめ」という生活で就寝・起床リズムにずれが生じると、うつ状態やメタボになるリスクが高いことがわかってきました。このようなリズムの乱れ、「ソーシャル・ジェットラグ（SJL）」は、腸内細菌叢にも悪影響を与えるようです。\n934人を対象にSJLと腸内細菌叢の関係を調べたところ、平日と休日の睡眠時間帯が90分ずれているだけで、正常な睡眠をとっている人たちと17種類の腸内細菌の量で差が出ていました。そしてSJLの人たちの腸に多い菌は、肥満や心血管の健康状態などの指標となる血液成分値の悪さと関連していたのです。',
        ],
        title: '休日寝だめで、肥満や心血管病リスクと関係する菌が増える?!',
      },
      {
        image: 'lifestyle_column_01_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊    Eur J Nutr. 2023 Dec;62(8):3135-3147.\n',
          'https://link.springer.com/article/10.1007/s00394-023-03204-x\n',
          '＊＊　Microorganisms. 2019 Jan 31;7(2):41.\n',
          'https://www.mdpi.com/2076-2607/7/2/41',
        ],
        text: [
          'ソーシャル・ジェットラグ（SJL）の人たちの群では、砂糖入り飲料の摂取量増加が見られ、果物やナッツの摂取量が少なく、全体的な食事の質が低下しているという特徴も見られました。SJLは食生活の質の低下につながる傾向があり、そのため腸内細菌も乱れていくようです（＊）。\nまた逆に、腸内細菌叢が悪化することで、睡眠不足や質の低下が起こる可能性があるとする報告もあります（＊＊）。\n良い睡眠と腸の健康を考えた食事は、表裏一体と言えそうです。',
        ],
        title: '睡眠が乱れることにより生活の質も低下するのが原因かも',
      },
    ],
    title: '不規則な睡眠が腸内細菌叢の乱れを招く',
  },
  {
    category: 'LIFESTYLE_MENTAL',
    headerImage: 'lifestyle_column_02_01',
    id: 'lifestyle_column_02',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'lifestyle_column_02_02',
        text: [
          '強いストレスを受けると、お腹をくだすという人もいるのでは。ここまででなくとも、腸と脳が関係しあっているなと感じる機会は多いのではないでしょうか。\n58人の健康な日本人男性で行った研究では、心理社会的なストレスを感じやすく、ストレスで心拍数が上がりやすかったり、気持ちがへこむ人の腸内細菌叢には特徴があることがわかりました。',
        ],
        title: 'ストレスを受けて心拍数が上がりやすい人には腸活が必要かも',
      },
      {
        image: 'lifestyle_column_02_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          'Neurobiol Stress. 2022 Aug 12:20:100479.\n',
          'https://www.sciencedirect.com/science/article/pii/S2352289522000546?via%3Dihub',
        ],
        text: [
          'この研究では、脳の前頭前野における心理社会的ストレス反応の強さと腸内細菌叢の関係を調べました。ストレスを感じやすい人に刺激を与えると、脳でストレス反応の強さを表す「酸化ヘモグロビン（oxy-Hb）」という数値が高まりました。\nそして、この反応が強い人の腸ほど、うつ病患者で多いと報告されているプロテオバクテリア門の細菌の占有率が有意に高かったのです。逆に、健康維持作用で注目される物質・酪酸を作る細菌や乳酸菌などが含まれるファーミキューテス門の細菌は少ない状態でした。\n研究者たちは、ストレスに弱い人に勧めたい腸活の例として、ストレスの影響を軽減することが確認されているビフィズス菌を増やすことを挙げています。',
        ],
        title: 'うつ病の人に多いとされる腸内細菌が増えている可能性',
      },
    ],
    title: 'ストレスに弱い人には、ある種の腸内細菌が多い?!',
  },
  {
    category: 'LIFESTYLE_MENTAL',
    headerImage: 'lifestyle_column_03_01',
    id: 'lifestyle_column_03',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'lifestyle_column_03_02',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊    Front Psychiatry. 2018 Mar 13:9:44.\n',
          'https://www.frontiersin.org/journals/psychiatry/articles/10.3389/fpsyt.2018.00044/full',
        ],
        text: [
          '呼吸法を伴う瞑想やヨガは、不安やうつ状態を和らげる方法として注目されていますが、腸に対する影響はどうなのでしょうか。\n多くの研究から瞑想やヨガがメンタル面に良い理由を考察した研究者グループは、腸に張り巡らされた自律神経が呼吸による影響を強く受けること、そして腸内細菌が自律神経と相互作用を持つ関係にあることを挙げています（＊）。\n実際に、瞑想によって腸内細菌叢も変わるようです。瞑想を長く実践しているベジタリアン12人と、普通の食事をしている瞑想経験のない健常者12人の腸内細菌を比べたところ、瞑想者の腸には健康に寄与する腸内細菌が多いことがわかりました。',
        ],
        title: '腸の自律神経は呼吸のリズムや腸内細菌叢の影響を受ける',
      },
      {
        image: 'lifestyle_column_03_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊＊　Evid Based Complement Alternat Med. 2020 Jul 5:2020:9517897.\n',
          'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7358775/',
        ],
        text: [
          '菜食の影響も大きいとは思われますが、腸自体の健康から免疫・代謝の維持まで広い働きを持つ酪酸という有用物質を作り出す「ロゼブリア」「サブドリグラヌルム」「ラクノスピラ」といった菌の量は、瞑想継続年数に比例して増していたそうです。\n「瞑想と呼吸で中枢神経機能を調節することが、腸内細菌叢の変化にまでつながることを実証した」と研究チーム。せっかくなので、ヨガや呼吸法を取り入れる際には、植物性食品が多い食事にすると相乗効果が見込めそうですね。',
        ],
        title: '瞑想年数に比例して、健康に寄与する酪酸を作る腸内細菌が増えた',
      },
    ],
    title: '瞑想やヨガがメンタルにいいのは腸内細菌叢まで変わるからかも',
  },
  {
    category: 'LIFESTYLE_MENTAL',
    headerImage: 'lifestyle_column_04_01',
    id: 'lifestyle_column_04',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'lifestyle_column_04_02',
        text: [
          '「陽の光を浴びると幸せホルモンが作られる」という言葉を耳にしたことはありませんか？この幸せホルモンはセロトニンと呼ばれる神経伝達物質で、精神を安定させて幸福感を得やすくする作用があると言われています。\n例えば、日光をたくさん浴びると、網膜から光が取り入れられセロトニン神経が活性化し、セロトニンが分泌されやすくなることがわかっています。セロトニンは幸福感を生み出すだけでなく、ストレスの軽減や睡眠の質を向上することも明らかになっています。',
        ],
        title: '幸せホルモン「セロトニン」',
      },
      {
        image: 'lifestyle_column_04_03',
        reference: [
          '参考文献 :\n ',
          '＊    メタジェン\n',
          '腸内細菌叢の土着細菌が宿主のセロトニン生合成を調節する:細胞 (cell.com)\n',
          'https://www.cell.com/cell/fulltext/S0092-8674(15)00248-2',
        ],
        text: [
          '実はこのセロトニン、その多くは脳だけではなく腸でも作られています。腸内細菌の中にセロトニンを作り出す菌がいたり、腸内細菌の作用により腸からセロトニンがたくさん作られたりします（＊）。この「腸で作られたセロトニン」は脳に作用するかどうかはまだ解明されていませんが、脳が美味しいものを食べたら幸せに感じるのは、もしかしたらお腹の中で腸内細菌が幸せホルモンを作ってくれているからかもしれませんね。',
        ],
        title: '幸せホルモンは脳ではなく腸で作られる！',
      },
    ],
    title: '腸に良い生活習慣で幸せになれる！？',
  },
  {
    category: 'LIFESTYLE_MENTAL',
    headerImage: 'lifestyle_column_05_01',
    id: 'lifestyle_column_05',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'lifestyle_column_05_02',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊    酪酸はヒトとマウスの睡眠不足による腸粘膜障害を改善 |微生物学スペクトラム (asm.org)\n',
          'https://journals.asm.org/doi/10.1128/spectrum.02000-22',
        ],
        text: [
          '世界的に見ても睡眠時間が短いわれわれ日本人、皆さんも忙しいときに長時間仕事をしたり、ついつい趣味に没頭したり、徹夜をしたことがありませんか？\n実はたった一晩の徹夜でも腸内環境に影響があることがあります。ある研究では、一晩徹夜をすると腸内細菌叢が乱れて、短鎖脂肪酸の一種である「酪酸」が減ってしまい、1週間経っても酪酸の量がもとに戻らないことも報告されています（＊）。',
        ],
        title: '一晩の徹夜がお腹を乱す',
      },
      {
        image: 'lifestyle_column_05_03',
        text: [
          '酪酸は、腸内細菌の一種である酪酸菌が食物繊維を分解して生成する短鎖脂肪酸です。酪酸は、腸内を弱酸性に保ち、悪玉菌の増殖を抑制したり、大腸上皮細胞のエネルギー源となり腸の働きを維持します。また、お腹の中で作られる短鎖脂肪酸の中でも、特に免疫機能に関係していると言われています。',
          '腸内環境は上り一日下り一時。皆さんが積み上げてきた良い腸内環境を徹夜が一瞬で乱す可能性があります。忙しいときでもしっかり睡眠を取って、体調を維持していきましょう。',
        ],
        title: '酪酸は免疫機能に関係',
      },
    ],
    title: '徹夜は酪酸の天敵！睡眠をしっかりとって体調管理を',
  },
  {
    category: 'LIFESTYLE_MENTAL',
    headerImage: 'lifestyle_column_06_01',
    id: 'lifestyle_column_06',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'lifestyle_column_06_02',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊    Sudo, N. et al. 2004, J. Physiol. 558(Pt 1):263\n',
          'https://physoc.onlinelibrary.wiley.com/doi/epdf/10.1113/jphysiol.2004.063388',
        ],
        text: [
          '現代人の大きな問題の一つといえば「ストレス」です。実はこのストレスへの耐性が腸内環境によってもたらされているかもしれないことを知っていますか？\nマウスを用いた動物試験では、腸内細菌が全くいないマウスと比べて、腸内細菌がいるマウスの方がストレスに耐性があることが報告されています（＊）。腸内細菌叢とストレスには密接な関係があるのです。',
        ],
        title: '腸内細菌叢が多様だとストレス耐性が高い',
      },
      {
        image: 'lifestyle_column_06_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊＊  Matsunaga, M. et al. 2024, Commun. Biol. 7:235\n',
          'https://www.nature.com/articles/s42003-024-05884-5',
        ],
        text: [
          'また、腸内細菌叢の多様性は、ストレス耐性だけでなく、ストレス回復力にも影響があります。近年はヒトでの研究報告も増えてきており、例えば、腸内細菌叢の多様性が、母親の育児ストレスへの耐性だけでなく、回復力にも関連していることが報告されています（＊＊）。お腹が満たされるとイライラが緩和する、という経験をされた方も多いと思いますが、その背景には腸内環境があるのかもしれませんね。',
        ],
        title: '腸内細菌叢はストレス回復力にも影響',
      },
    ],
    title: '健全なる精神は健全なる腸から宿る？',
  },
];

const OTHER_COLUMNS: Column[] = [
  {
    category: 'OTHERS',
    headerImage: 'other_column_01_01',
    id: 'other_column_01',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'other_column_01_02',
        text: [
          '便秘は単に便秘にあらず。近年、便秘は腹部の張りによる不快感や肌荒れを招くばかりでなく、長く続くと思わぬリスクにつながることがわかってきました。\n中高年の日本人男性約1万9,000人、女性約2万3,000人を3～16年にわたって追跡した大規模調査では、排便回数が少ないこと、便が硬いことの双方が認知症リスクの高さと関係していました。\n排便回数が少ない場合、特に男性でリスクが高くなりました。「1日1回」の人たちに比べて、「週に3～4回」しか出ない人の認知症リスクは1.46倍、「週3回未満」の人では1.79倍になっていたのです。女性では「週3回未満」でも認知症リスクは1.29倍でした。',
        ],
        title: '便秘による認知機能低下リスクは女性より男性の方が高め',
      },
      {
        image: 'other_column_01_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊    Public Health. 2023 Aug:221:31-38.\n',
          'https://www.sciencedirect.com/science/article/abs/pii/S0033350623001671?via%3Dihub',
        ],
        text: [
          '便の硬さでは、「普通の便」の人に比べると、「非常に硬い便」の男性では認知症リスクが2.18倍、女性では1.84倍と排便回数以上にリスクが高くなりました（＊）。\n便秘が続くと腸内細菌叢が変わって悪玉物質が増え、それが傷ついた腸の粘膜を通り抜けて脳にまで達し、炎症を起こすのではないかと考えられています。\n便秘でいきむことも危険です。いきむと血圧が上がり、全身の血管や心臓に大きな負担がかかります。高齢者では脳卒中の引き金になることも。\n便意をがまんしない、十分な量の食物繊維や水分をとる、整腸作用が確認された乳酸菌やビフィズス菌を試してみるといったことを日常から心がけましょう。便はそもそも、“出すもの”ではなく、“自然な便意で出る”のが正常なのです。',
        ],
        title: '排便時のいきみで血圧上昇、脳卒中も',
      },
    ],
    title: '便秘にも硬い便にも注意。どちらも認知機能に影響',
  },
  {
    category: 'OTHERS',
    headerImage: 'other_column_02_01',
    id: 'other_column_02',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'other_column_02_02',
        text: [
          '皆さんは日常の中で発酵食品を取っていますか？\n発酵食品を摂取する習慣は体に良いと一般的に言われています。実は、私たちのカラダの中でも発酵が起こり、良い物質を産生しています。そして、それは腸内で行われます。私達の腸は腸内細菌が様々な代謝物質を作り出すいわば発酵タンクの働きをしているのです。では、腸内細菌がしっかり発酵するには何が必要でしょうか。重要な要素の一つが「温度管理」です。',
        ],
        title: '私たちは体の中で自ら発酵している',
      },
      {
        image: 'other_column_02_03',
        reference: [
          REFERENCE_HEADER_TEXT,
          '＊    Ichikawa, N. et al. 2022, Metabolites, 12(1):20\n',
          'https://www.mdpi.com/2218-1989/12/1/20\n',
          '＊＊　Nagai, M. et al. 2023, Nat. Commun. 14(1):3863\n',
          'https://www.nature.com/articles/s41467-023-39569-0',
        ],
        text: [
          '温度管理が不十分なパンが発酵されずまったく膨らまないないように、私達のお腹の中の菌も温度が適切でないと十分に働けません。実際にマウスを用いた動物試験では、マウスを毎日3時間ほど寒い場所に置いておくと、腸内細菌が作る短鎖脂肪酸の産生量が減少することが報告されています（＊）。他にも、腸内細菌はウイルス感染の重症化を抑制すると言われており、この重症化抑制には体温が上がっていることが重要という報告もあります（＊＊）。しっかりと身体を温めておくことは、腸内環境にとっても大切なことと言えそうです。',
        ],
        title: '体内発酵に必要な温度管理',
      },
    ],
    title: '身体を冷やすのは腸にも良くない',
  },
  {
    category: 'OTHERS',
    headerImage: 'other_column_03_01',
    id: 'other_column_03',
    lastUpdated: '2024-08-06',
    sections: [
      {
        image: 'other_column_03_02',
        text: [
          'チョコレートやココアの原料となるカカオは、なんと5,000年以上前から食べられていたことが分かっています！ 最初はエクアドルで食用として使われ、その後メキシコあたりで栽培が始まりました。 マヤ文明やアステカ文明では、カカオは神秘的な力を持つものとして大切にされ、儀式や薬、貢物、お金など様々な用途で使われていました。これほどの価値を持つほどのものを味わえるのは、特別な人だけです。王族などの特権階級、戦士だけが「力を与える」とする、チョコレートドリンクを飲むことができました。当時のチョコレートは、カカオ豆をすりつぶし、水、唐辛子、とうもろこしなどを混ぜたどろどろの飲み物でした。',
        ],
        title: 'カカオの起源を知ろう',
      },
      {
        image: 'other_column_03_03',
        text: [
          '19世紀までのチョコレート飲料は水や牛乳に溶けにくく、酸味が強く、独特の臭いがありました。しかし、オランダ人のバンホーテン氏が2つの発明でこれを解決しました。\n1つ目は、アルカリ処理で酸味を中和し、まろやかで深い味わいを実現したことです。2つ目は、圧搾機で油脂分を取り除き、水や牛乳に溶けやすいココアパウダーを作ったことです。\nこれらの発明により、現代のように美味しく飲みやすいココアが誕生したのです。バンホーテン氏のおかげで、私たちは毎日美味しいココアを楽しめるんですね！',
        ],
        title: 'ココアの誕生',
      },
      {
        image: 'other_column_03_04',
        text: [
          'ココアは、美味しさだけでなく、健康にも嬉しい栄養素が豊富に含まれています。特に注目したいのが、ココアポリフェノールとテオブロミン、そして食物繊維です！\nココアポリフェノールは高い抗酸化作用や抗菌作用など様々な生理活性を有します。テオブロミンは、ココアに含まれる苦み成分でココア特有の成分です。ポリフェノール同様に抗酸化作用や抗炎症作用があります。\nココアに含まれる食物繊維は、特にリグニンという成分が豊富です。リグニンは、他の食物繊維と比べて消化されにくいため、腸内まで届きやすいと言われています。',
        ],
        title: 'ココアの特徴',
      },
    ],
    title: 'ココアの豆知識！ココアの起源と特徴とは？',
  },
];

export const CategoryToColumnsMap: { [key in ColumnCategory]: Column[] } = {
  EXERCISE_AND_INTESTINE: EXERCISE_COLUMNS,
  FOOD_AND_INTESTINE: FOOD_COLUMNS,
  INTESTINAL_BACTERIA: INTESTINAL_COLUMNS,
  LIFESTYLE_MENTAL: LIFESTYLE_COLUMNS,
  OTHERS: OTHER_COLUMNS,
};

export const ColumnCategoryDescription: {
  [key in ColumnCategory | 'ALL']: {
    text: string[];
  };
} = {
  ALL: {
    text: ['腸に関する情報をご紹介します！'],
  },
  EXERCISE_AND_INTESTINE: {
    text: ['腸と運動・身体活動に関する情報をご紹介します！'],
  },
  FOOD_AND_INTESTINE: {
    text: ['腸と食事に関する情報をご紹介します！'],
  },
  INTESTINAL_BACTERIA: {
    text: ['腸内細菌・短鎖脂肪酸に関する情報をご紹介します！'],
  },
  LIFESTYLE_MENTAL: {
    text: ['腸と生活習慣・メンタルに関する情報をご紹介します！'],
  },
  OTHERS: {
    text: ['腸に関するその他情報をご紹介します'],
  },
};

export const REPORT_IMAGE = {
  BODY: [
    {
      BODY: 'あなたのランクとIgA値を確認でき、平均値と比較できます。',
      BODY_TITLE: 'あなたのランクとIgA値を確認できます',
      IMAGE_CAPTION: 'レポートイメージ① : あなたのランクとIgA値',
      IMAGE_PATH: '/images/reports/Report%20image%2001.png',
    },
    {
      BODY: 'あなたの検査結果に基づいた生活習慣アドバイスを確認できます。',
      BODY_TITLE: '検査結果に基づいたアドバイスを確認できます',
      IMAGE_CAPTION: 'レポートイメージ② : あなたの生活習慣表',
      IMAGE_PATH: '/images/reports/Report%20image%2002.png',
    },
    {
      BODY: '生活の中に簡単に取り入れられるチャレンジです。',
      BODY_TITLE: 'より良い免疫状態を目指すためのチャレンジを確認できます',
      IMAGE_CAPTION: 'レポートイメージ③ : チャレンジ',
      IMAGE_PATH: '/images/reports/Report%20image%2003.png',
    },
  ],
  CLOSE_BUTTON_TEXT: '閉じる',
  TITLE: 'レポートイメージ',
};

export const CUSTOMER_FEEDBACK_PAGE = {
  DESCRIPTION: 'インナーガーデンについてのお客様の声を集めました。',
  IMAGE: '/images/top-page/bacteria_characters.png',
  PAGE_TITLE: 'お客様の声',
};

const PRODUCTS: { [key: string]: { IMAGE_PATH: string } } = {
  BACTEROIDES: {
    IMAGE_PATH: '/images/products/drink_2.png',
  },
  BIFIDOBACTERIUM: {
    IMAGE_PATH: '/images/products/drink_1.png',
  },
  DEFAULT_DRINK: {
    IMAGE_PATH: '/images/products/drink.jpg',
  },
  DEFAULT_SET: {
    IMAGE_PATH: '/images/products/check-kit.jpg',
  },
  FAECALIBACTERIUM: {
    IMAGE_PATH: '/images/products/drink_3.png',
  },
  FIRST_SET: {
    IMAGE_PATH: '/images/products/first-set.jpg',
  },
  PREVOTELLA: {
    IMAGE_PATH: '/images/products/drink_4.png',
  },
  RUMINOCOCCUS: {
    IMAGE_PATH: '/images/products/drink_5.png',
  },
};

export const PURCHASE_HISTORY_PAGE = {
  CANCELLED: 'に定期購入が解約されました',
  DATE_LABEL: '購入完了日：',
  EMPTY: '購入履歴がありません',
  IMAGE: '/images/top-page/bacteria_characters_02.png',
  PAGE_TITLE: '購入履歴',
  PRODUCTS,
  SUB_PAGE_BUTTON_LABEL: '定期購入マイページへ',
  SUB_PAGE_URL: '/subscription',
  TAG_CANCELLED: '定期購入 : 解約済',
  TAG_TITLE: '定期購入',
};

export const DISCOUNTED_PRODUCT_LIST_PAGE = {
  BACTERIAL_TYPES: [
    {
      IMAGE_PATH: '/images/teaser/bifidobacterium.png',
      TEXT: 'ラフィノース、フラクトオリゴ糖が含まれたココア飲料です。',
      TITLE: 'ビフィドバクテリウム',
    },
    {
      IMAGE_PATH: '/images/teaser/bacteroids.png',
      TEXT: 'シクロデキストリン、フラクトオリゴ糖が含まれたココア飲料です。',
      TITLE: 'バクテロイデス',
    },
    {
      IMAGE_PATH: '/images/teaser/faecalibacterium.png',
      TEXT: 'ガラクトオリゴ糖、フラクトオリゴ糖が含まれたココア飲料です。',
      TITLE: 'フィーカリバクテリウム',
    },
    {
      IMAGE_PATH: '/images/teaser/prevotella.png',
      TEXT: 'キシロオリゴ糖、フラクトオリゴ糖が含まれたココア飲料です。',
      TITLE: 'プレボテラ',
    },
    {
      IMAGE_PATH: '/images/teaser/ruminococcus.png',
      TEXT: 'イヌリン、フラクトオリゴ糖が含まれたココア飲料です。',
      TITLE: 'ルミノコッカス',
    },
  ],
  BUTTON_TEXT: 'クーポンをゲットする',
  DETAIL_PAGE: 'detail_page',
  DISCOUNTED_PRICE: '¥10,000',
  IMAGE_PATH: '/images/teaser/discounted_product.jpg',
  PAGE_TITLE: 'page_title',
  PRICE: '¥12,000',
  PRICE_TAX_TEXT: '税込',
  SELLER_TEXT: '※販売者は株式会社Wellnizeとなります',
  SHIPPING_AMOUNT: '0',
  SPEECH_BUBBLE_IMAGE_PATH: '/images/teaser/mascots.png',
  SPEECH_BUBBLE_TEXT:
    '5種類の中からあなたの腸内タイプ\nにあった商品をお届けします',
  SUBTITLE: 'subtitle',
  TAG: '送料無料',
  TITLE:
    '【初回限定】腸内フローラ検査＋パーソナルココア飲料(約1ヶ月分)のセット',
};

export const PRODUCT_LIST_PAGE = {
  BODY: [
    {
      DETAIL_PAGE: 'first-set',
      IMAGE_PATH: '/images/products/first-set.jpg',
      PRICE: [
        {
          SHIPPING_PRICE: '0',
          VALUE: '¥12,000',
        },
      ],
      SUBTITLE: `検査と商品の単品購入合計より<hl>3,000円</hl>お得！`,
      TITLE:
        '【初回限定】腸内フローラ検査＋パーソナルココア飲料(約1ヶ月分)のセット',
    },
    {
      DETAIL_PAGE: 'check-kit',
      IMAGE_PATH: '/images/products/check-kit.jpg',
      PRICE: [
        {
          VALUE: '¥9,000',
        },
      ],
      SUBTITLE: '腸内環境のみ知りたい方向け',
      TITLE: '腸内フローラ検査',
    },
    {
      DETAIL_PAGE: 'drink',
      IMAGE_CAPTION: '※この中から1種類の商品が届きます。',
      IMAGE_PATH: '/images/products/drink.jpg',
      PRICE: [
        {
          PURCHASE_FREQUENCY: '【1回購入】',
          VALUE: '¥6,000',
        },
        {
          DISCOUNT: {
            TEXT: '*税抜価格から',
            VALUE: '11',
          },
          PURCHASE_FREQUENCY: '【定期購入】',
          VALUE: '¥5,400',
        },
      ],
      SUBTITLE: '商品のみ購入したい方向け',
      TITLE: 'パーソナルココア飲料(約1ヶ月分)',
    },
  ],
  BUTTON_TEXT: '詳しく見る',
  PAGE_TITLE: '商品一覧',
  PRICE_TAX_TEXT: '税込',
  SELLER_TEXT: '※販売者は株式会社Wellnizeとなります',
};

export const DRINKS_DETAILS = {
  BODY: [
    {
      DESCRIPTION: 'ラフィノース、フラクトオリゴ糖が含まれたココア飲料です。',
      IMAGE_PATH: '/images/products/drink_1.png',
      TITLE: 'ビフィドバクテリウム\nタイプ',
    },
    {
      DESCRIPTION:
        'シクロデキストリン、フラクトオリゴ糖が含まれたココア飲料です。',
      IMAGE_PATH: '/images/products/drink_2.png',
      TITLE: 'バクテロイデス\nタイプ',
    },
    {
      DESCRIPTION:
        'ガラクトオリゴ糖、フラクトオリゴ糖が含まれたココア飲料です。',
      IMAGE_PATH: '/images/products/drink_3.png',
      TITLE: 'フィーカリバクテリウム\nタイプ',
    },
    {
      DESCRIPTION: 'キシロオリゴ糖、フラクトオリゴ糖が含まれたココア飲料です。',
      IMAGE_PATH: '/images/products/drink_4.png',
      TITLE: 'プレボテラ\nタイプ',
    },
    {
      DESCRIPTION: 'イヌリン、フラクトオリゴ糖が含まれたココア飲料です。',
      IMAGE_PATH: '/images/products/drink_5.png',
      TITLE: 'ルミノコッカス\nタイプ',
    },
  ],
  IMAGE_PATH: '/images/top-page/bacteria_characters.png',
  MORE_INFO:
    '商品詳細は、<a href="/products/allergy-ingredient" style="color: #0000EE; text-decoration: underline;">こちら</a>をご確認ください。',
  TITLE:
    '5種類の中からあなたの腸内タイプにあった素材の入った商品をお届けします',
};

export const PRODUCT_DETAIL_FIRST_SET_PAGE = {
  BUTTON_LABEL: {
    DISABLED: '初回限定の商品です',
    ENABLED: '購入手続きに進む',
    MORE_INFORMATION: 'ココアの歴史と特徴を知ろう！',
  },
  PAGE_TITLE: '初回限定セット',
  SECTION_BODY_1: {
    CONTENT: '内容',
    PRODUCT_TEXT: {
      DESCRIPTION: `パーソナルココア飲料125ml × 24本`,
      TITLE: '＜商品＞',
    },
    TEST_KIT_TEXT: {
      /* eslint-disable no-irregular-whitespace */
      DESCRIPTION: `□サービス説明書\n□採便キット\n　・採便容器\n　・チャック付きパウチ\n　・吸水シート\n□採便説明書\n□採便用シート\n□返送用封筒\n□腸内フローラ検査レポート*`,
      /* eslint-enable no-irregular-whitespace */
      TITLE: '＜検査キット＞',
      WARNING:
        '*検査レポートは検査完了後、マイページからご覧いただけます。レポート用紙はお届けいたしませんのでご了承ください。',
    },
  },
  TITLE_3: '2回目以降は単品、またはお得な定期購買でご購入いただけます',
};

export const PRODUCT_DETAIL_CHECK_KIT_PAGE = {
  BUTTON_LABEL: {
    DISABLED: '初回検査済みの方向けの商品です',
    ENABLED: '購入手続きに進む',
  },
  DESCRIPTION: '腸内環境のみ知りたい方向け',
  PAGE_TITLE: '腸内フローラ検査',
  PRICE: '¥9,000',
  PRODUCT_DETAIL: {
    SECTION_TITLE: 'おすすめ商品',
    TAX_TEXT: '税込み',
  },
  SECTION_BODY: {
    CONTENT: '内容',
    /* eslint-disable no-irregular-whitespace */
    DESCRIPTION: `□サービス説明書\n□採便キット\n　・採便容器\n　・チャック付きパウチ\n　・吸水シート\n□採便説明書\n□採便用シート\n□返送用封筒\n□腸内フローラ検査レポート*`,
    /* eslint-enable no-irregular-whitespace */
    HEADER_IMAGE: '/images/products/check-kit.jpg',
    WARNING:
      '*検査レポートは検査完了後、マイページからご覧いただけます。レポート用紙はお届けいたしませんのでご了承ください。',
  },
  SELLER_TEXT: '※販売者は株式会社Wellnizeとなります',
  TAX_TEXT: '（税込）',
};

export const PRODUCT_DETAIL_DRINK_PAGE = {
  BUTTON: {
    TEXT: '購入手続きに進む',
    TEXT_DISABLED: '検査後にご購入いただけます',
    TYPE: {
      oneTimePurchase: {
        icon: packageShipmentDefault,
        subtitle: 'まずは1ヶ月お試し',
        title: '1回購入',
      },
      subscription: {
        icon: packageShipmentTimer,
        subtitle: '税抜き価格から11%OFF',
        title: '定期購入',
      },
    },
  },
  PAGE_TITLE: 'パーソナルココア飲料',
  PRODUCT_DETAIL_ITEM: {
    TEXT: '税込み',
    TITLE: 'おすすめ商品',
  },
  PRODUCT_INFO: {
    LABEL: '内容',
    VALUE: 'パーソナルココア飲料125ml × 24本',
  },
};

export const PRODUCT_DRINK = {
  BACTEROIDES: {
    description:
      'あなたの腸内タイプ「バクテロイデス」に合った素材の入った商品をお届けします。',
    image: '/images/products/product2.png',
    price: [
      {
        PURCHASE_FREQUENCY: '【1回購入】',
        VALUE: '¥6,000',
      },
      {
        DISCOUNT: {
          TEXT: '*税抜価格から',
          VALUE: '11',
        },
        PURCHASE_FREQUENCY: '【定期購入】',
        VALUE: '¥5,400',
      },
    ],
    productVariant: import.meta.env.MEIJI_SHOPIFY_PRODUCT_VARIANT_BACTEROIDES,
    sellingPlan: import.meta.env.MEIJI_SHOPIFY_SELLING_PLAN_BACTEROIDES,
    subTitle: '商品のみ購入したい方向け',
    taxText: '税込',
    title: 'パーソナルココア飲料(約1ヶ月分)',
  },
  BIFIDOBACTERIUM: {
    description:
      'あなたの腸内タイプ「ビフィドバクテリウム」に合った素材の入った商品をお届けします。',
    image: '/images/products/product1.png',
    price: [
      {
        PURCHASE_FREQUENCY: '【1回購入】',
        VALUE: '¥6,000',
      },
      {
        DISCOUNT: {
          TEXT: '*税抜価格から',
          VALUE: '11',
        },
        PURCHASE_FREQUENCY: '【定期購入】',
        VALUE: '¥5,400',
      },
    ],
    productVariant: import.meta.env
      .MEIJI_SHOPIFY_PRODUCT_VARIANT_BIFIDOBACTERIUM,
    sellingPlan: import.meta.env.MEIJI_SHOPIFY_SELLING_PLAN_BIFIDOBACTERIUM,
    subTitle: '商品のみ購入したい方向け',
    taxText: '税込',
    title: 'パーソナルココア飲料(約1ヶ月分)',
  },
  FAECALIBACTERIUM: {
    description:
      'あなたの腸内タイプ「フィーカリバクテリウム」に合った素材の入った商品をお届けします。',
    image: '/images/products/product3.png',
    price: [
      {
        PURCHASE_FREQUENCY: '【1回購入】',
        VALUE: '¥6,000',
      },
      {
        DISCOUNT: {
          TEXT: '*税抜価格から',
          VALUE: '11',
        },
        PURCHASE_FREQUENCY: '【定期購入】',
        VALUE: '¥5,400',
      },
    ],
    productVariant: import.meta.env
      .MEIJI_SHOPIFY_PRODUCT_VARIANT_FAECALIBACTERIUM,
    sellingPlan: import.meta.env.MEIJI_SHOPIFY_SELLING_PLAN_FAECALIBACTERIUM,
    subTitle: '商品のみ購入したい方向け',
    taxText: '税込',
    title: 'パーソナルココア飲料(約1ヶ月分)',
  },
  None: {
    description: '',
    image: '/images/products/drink.jpg',
    price: [
      {
        PURCHASE_FREQUENCY: '【1回購入】',
        VALUE: '¥6,000',
      },
      {
        DISCOUNT: {
          TEXT: '*税抜価格から',
          VALUE: '11',
        },
        PURCHASE_FREQUENCY: '【定期購入】',
        VALUE: '¥5,400',
      },
    ],
    productVariant: '',
    sellingPlan: '',
    subTitle: '商品のみ購入したい方向け',
    taxText: '税込',
    title: 'パーソナルココア飲料(約1ヶ月分)',
  },
  PREVOTELLA: {
    description:
      'あなたの腸内タイプ「プレボテラ」に合った素材の入った商品をお届けします。',
    image: '/images/products/product4.png',
    price: [
      {
        PURCHASE_FREQUENCY: '【1回購入】',
        VALUE: '¥6,000',
      },
      {
        DISCOUNT: {
          TEXT: '*税抜価格から',
          VALUE: '11',
        },
        PURCHASE_FREQUENCY: '【定期購入】',
        VALUE: '¥5,400',
      },
    ],
    productVariant: import.meta.env.MEIJI_SHOPIFY_PRODUCT_VARIANT_PREVOTELLA,
    sellingPlan: import.meta.env.MEIJI_SHOPIFY_SELLING_PLAN_PREVOTELLA,
    subTitle: '商品のみ購入したい方向け',
    taxText: '税込',
    title: 'パーソナルココア飲料(約1ヶ月分)',
  },
  RUMINOCOCCUS: {
    description:
      'あなたの腸内タイプ「ルミノコッカス」に合った素材の入った商品をお届けします。',
    image: '/images/products/product5.png',
    price: [
      {
        PURCHASE_FREQUENCY: '【1回購入】',
        VALUE: '¥6,000',
      },
      {
        DISCOUNT: {
          TEXT: '*税抜価格から',
          VALUE: '11',
        },
        PURCHASE_FREQUENCY: '【定期購入】',
        VALUE: '¥5,400',
      },
    ],
    productVariant: import.meta.env.MEIJI_SHOPIFY_PRODUCT_VARIANT_RUMINOCOCCUS,
    sellingPlan: import.meta.env.MEIJI_SHOPIFY_SELLING_PLAN_RUMINOCOCCUS,
    subTitle: '商品のみ購入したい方向け',
    taxText: '税込',
    title: 'パーソナルココア飲料(約1ヶ月分)',
  },
} as const;

export type ProductDrinkConfig =
  (typeof PRODUCT_DRINK)[keyof typeof PRODUCT_DRINK];

export const CONTACT_FORM_ELEMENT_ID = 'faq-contact-form';

export const REPORT_BACTERIA_DESCRIPTION: {
  // explanation, reference
  [key in BacteriaType]: [string[], string[] | null];
} = {
  BACTEROIDES: [
    [
      '「バクテロイデス」という腸内細菌がたくさんいる腸内タイプです。',
      'バクテロイデスは世界中の多くの人が持っている、最も一般的な腸内細菌のグループの一つです。特に高脂肪、高たんぱく質な欧米型の食事を摂取する人が多く持っていることが知られています[1]。',

      '日本人では、年齢を重ねるにつれこの菌が増える傾向にあるとも言われています[2]。多種多様な機能を持つ菌が含まれており、日本人に特有の海苔を分解する菌[3]や、持久力の向上に関係する菌がこのグループには含まれます[4]。',

      '幼少期にバクテロイデスが多い子供ほど認知能力や言語能力が高いという報告があります[5]。ほとんどの女の子はバクテロイデスが多いので、男の子よりも話し始めるのが早いのかもしれません。お腹の中では主に酢酸、プロピオン酸という短鎖脂肪酸を作ってくれています。',

      'あなたの腸内に最も多くいるバクテロイデスが好む栄養源を与えて、腸内を元気にしましょう。',
    ],
    [
      '[1]Science. 2011 Oct 7;334(6052):105-8. ',
      '[2]BMC Microbiol. 2016 May 25:16:90',
      '[3]Nature. 2010 Apr 8;464(7290):908-12.',
      '[4]Sci Adv. 2023 Jan 25;9(4):eadd2120.',
      '[5]Gut Microbes. 2021 Jan-Dec;13(1):1-17',
    ],
  ],
  BIFIDOBACTERIUM: [
    [
      '皆さんもご存知「ビフィズス菌」がたくさんいる腸内タイプです。',
      'ビフィズス菌はアジア圏、特に日本人の腸内フローラで多く見られる腸内細菌のグループの一つです[1]。日本人の中では特に女性に多い傾向があると言われており、年齢を重ねるにつれて減っていく傾向にあるとも言われています[2]。食生活では、日常的に乳製品を摂取する人に多いとも言われています[3]。',
      'また、赤ちゃんにも多いと言われており、特に母乳で育てられた赤ちゃんでは大量のビフィドバクテリウムが発見されることがあるそうです[4]。お母さんの腸内環境は自分自身だけでなく、赤ちゃんにとっても影響があるのかもしれません。',
      'お腹の中では乳酸や酢酸という物質を作ってくれており、乳酸は他の菌によって酢酸やプロピオン酸、酪酸の産生に使われます[5]。',
      'あなたの腸内に最も多くいるビフィドバクテリウムが好む栄養源を与えて、腸内を元気にしましょう。',
    ],
    [
      '[1]DNA Research, 2016 Apr;23(2): pp. 125–133',
      '[2]Nat Rev Gastroenterol Hepatol. 2022 Sep;19(9):565-584.',
      '[3]Gut Microbes. 2020 Nov 9;12(1):1799533.',
      '[4]mBio. 2022 Jun 28;13(3):e0129922.',
      '[5]Nat Rev Microbiol. 2014 Oct;12(10):661-72.',
    ],
  ],
  FAECALIBACTERIUM: [
    [
      '「フィーカリバクテリウム」という腸内細菌がたくさんいる腸内タイプです。',
      'フィーカリバクテリウムは世界中の多くの人が持っている腸内細菌のグループの一つです。この菌が多い方は腸内フローラが安定しているという研究も報告されています[1]。この菌は年齢を重ねるに連れ減る傾向がありますが、健康な高齢者だとこの減り方が比較的緩やかと言われています[2]。',
      'また、アスリートや運動をしている人に比較的多い菌とも言われています[3]。これはフィーカリバクテリウムが疲れやすさ[4]や感染症[5]に影響を与える可能性があるという報告があり、健康な高齢者、アスリートや運動をする人に多いことと関係があるかもしれません。お腹の中では主に酪酸という短鎖脂肪酸を作ってくれます。',
      'あなたの腸内に最も多くいるフィーカリバクテリウムが好む栄養源を与えて、腸内を元気にしましょう。',
    ],
    [
      '[1]Cell Host Microbe. 2022 May 11;30(5):726-739.e3.',
      '[2]Nat Rev Gastroenterol Hepatol. 2022 Sep;19(9):565-584.',
      '[3]Front Microbiol. 2022 Aug 3:13:984867.',
      '[4] Cell Host Microbe. 2023 Feb 8;31(2):288-304.e8.',
      '[5] Gut. 2021 Apr;70(4):698-706.',
    ],
  ],
  None: [
    [
      '糞便量の不足、食べ物かすなどの異物の混入等により正しく測定が出来ませんでした。そのため、今回はあなたの腸内タイプ分けの判定をすることが出来ませんでした。',
      '再検査をご希望の方は、腸内フローラ検査単品をお申し込みください。',
    ],
    null,
  ],
  PREVOTELLA: [
    [
      '「プレボテラ」という腸内細菌がたくさんいる腸内タイプです。',
      'プレボテラは穀物などの食物繊維を多く含む食品をたくさん摂取する人に多いと言われており、[1]ベジタリアンなどでも多いとされています。逆に糖質制限や油の多い食事を摂取すると顕著に減ることも知られています[2]。',
      '日本人では40代以降の男性や1週間当たりの運動時間が長い人に多い傾向があるとも言われています[3]。',
      'また、定期的に心のリフレッシュ（瞑想など）を行った人[4]や実際にそのような生活をしているチベット仏教の僧侶はプレボテラが多いという報告があります。お腹の中では酢酸やプロピオン酸という短鎖脂肪酸を作ってくれます[5]。',
      'あなたの腸内に最も多くいるプレボテラが好む栄養源を与えて、腸内を元気にしましょう。',
    ],
    [
      '[1]Science. 2011 Oct 7;334(6052):105-8.',
      '[2]Int J Syst Evol Microbiol. 2007 May;57(Pt 5):941-946.',
      '[3]Microbiome. 2017 Aug 10;5(1):98.',
      '[4]Gen Psychiatr. 2023 Jan 3;36(1):e100893.',
      '[5]Nat Rev Microbiol. 2014 Oct;12(10):661-72.',
    ],
  ],
  RUMINOCOCCUS: [
    [
      '「ルミノコッカス」という腸内細菌がたくさんいる腸内タイプです。',
      'ルミノコッカスは様々な機能を持った腸内細菌が属するグループです。このグループに属する腸内細菌にはレジスタントスターチ（冷えたご飯などに含まれる、小腸で分解されずに大腸まで届くでんぷん）を分解する腸内細菌も含まれています[1]。日本人では成人後の女性が比較的多く持つ菌であると言われています[2]。',
      'ルミノコッカスが多い人はエネルギーの吸収量が少ないという報告[3]があり、同じ食事をしても他の腸内細菌タイプのひとより太りにくい可能性があります。',
      'また、この菌が多い方では、腸内フローラの多様性（お腹にいる菌の種類の豊富さを示す指標）が高いことが多いとも言われています[4]。ルミノコッカスは多様な菌が含まれたグループで、短鎖脂肪酸（酢酸・プロピオン酸・酪酸）の産生に幅広く関わります[5]。',
      'あなたの腸内に最も多くいるルミノコッカスが好む栄養源を与えて、腸内を元気にしましょう。',
    ],
    [
      '[1]Microbiome. 2016 Jun 29;4(1):33.',
      '[2]J Gastroenterol. 2019 Jan;54(1):53-63.',
      '[3] Microbiome. 2022 Dec 12;10(1):223.',
      '[4]Nat Microbiol. 2018 Jan;3(1):8-16',
      '[5]Nat Rev Microbiol. 2014 Oct;12(10):661-72.',
    ],
  ],
};

export const BACTERIA_NAME_JP: {
  [key in Exclude<BacteriaType, 'None'>]: string;
} = {
  BACTEROIDES: 'バクテロイデス',
  BIFIDOBACTERIUM: 'ビフィドバクテリウム',
  FAECALIBACTERIUM: 'フィーカリバクテリウム',
  PREVOTELLA: 'プレボテラ',
  RUMINOCOCCUS: 'ルミノコッカス',
};

export const BACTERIA_IMAGE: { [key in BacteriaType]: string } = {
  BACTEROIDES: MY_PAGE.REPORT_CARD.IMAGE_RED,
  BIFIDOBACTERIUM: MY_PAGE.REPORT_CARD.IMAGE_BLUE,
  FAECALIBACTERIUM: MY_PAGE.REPORT_CARD.IMAGE_YELLOW,
  None: '/images/reports/unknown_bacteria.svg',
  PREVOTELLA: MY_PAGE.REPORT_CARD.IMAGE_ORANGE,
  RUMINOCOCCUS: MY_PAGE.REPORT_CARD.IMAGE_GREEN,
};

type Description = { description: string[]; title: string[] };
const Fructooligosaccharide: Description = {
  description: [
    'フラクトオリゴ糖は難消化性のオリゴ糖で糖として吸収されず、大腸で腸内細菌の栄養源になることが知られています。その結果、短鎖脂肪酸が腸内で生成されます。',
  ],
  title: ['フラクトオリゴ糖'],
};
export const RECOMMENDED_MATERIALS: {
  [key in BacteriaType]: Description[];
} = {
  BACTEROIDES: [
    {
      description: [
        '環状オリゴ糖とも呼ばれるオリゴ糖の1種です。通常のオリゴ糖が鎖状なのに対して、その鎖が環状につながった構造をしています。大腸でバクテロイデスなどの腸内細菌の栄養源になることが知られています。その結果、短鎖脂肪酸が腸内で生成されます。',
      ],
      title: ['シクロデキストリン'],
    },
    Fructooligosaccharide,
  ],
  BIFIDOBACTERIUM: [
    {
      description: [
        'ラフィノースは天然に存在するオリゴ糖の１種で、キャベツ、ブロッコリー、アスパラガス、大豆、トウモロコシ、など様々な野菜（植物）に含まれています。大腸でビフィドバクテリウムなどの腸内細菌の栄養源になることが知られています。その結果、短鎖脂肪酸が腸内で生成されます。',
      ],
      title: ['ラフィノース'],
    },
    Fructooligosaccharide,
  ],
  FAECALIBACTERIUM: [
    {
      description: [
        'ガラクトオリゴ糖は母乳や牛乳、乳製品に含まれるオリゴ糖の1種です。大腸でルミノコッカスなどの腸内細菌の栄養源になることが知られています。その結果、短鎖脂肪酸が腸内で生成されます。',
      ],
      title: ['ガラクトオリゴ糖'],
    },
    Fructooligosaccharide,
  ],
  None: [
    {
      description: [
        '糞便量の不足、食べ物かすなどの異物の混入等により正しく測定が出来ませんでした。そのため、今回はあなたにおすすめの素材を判定することが出来ませんでした。',
      ],
      title: ['測定不可のためおすすめ素材の提示はできません'],
    },
  ],
  PREVOTELLA: [
    {
      description: [
        'キシロオリゴ糖は希少糖の1種であるキシロースが鎖のようにつながった構造をしています。大腸でプレボテラなどの腸内細菌の栄養源になることが知られています。その結果、短鎖脂肪酸が腸内で生成されます。',
      ],
      title: ['キシロオリゴ糖'],
    },
    Fructooligosaccharide,
  ],
  RUMINOCOCCUS: [
    {
      description: [
        '自然界で様々な植物によって作られる、分子量の小さい水溶性の食物繊維です。大腸でルミノコッカスなどの腸内細菌の栄養源になることが知られています。その結果、短鎖脂肪酸が腸内で生成されます。',
      ],
      title: ['イヌリン'],
    },
    Fructooligosaccharide,
  ],
};
export const RECOMMENDED_PRODUCTS: {
  [key in BacteriaType]: {
    description: string[];
    imageUrl: string;
    title: string[];
  };
} = {
  BACTEROIDES: {
    description: [
      '甘さ控えめすっきりココア飲料',
      'シクロデキストリン＆フラクトオリゴ糖配合',
    ],
    imageUrl: '/images/reports/drink_bacteroides.png',
    title: ['明治　インナーガーデン', '125ml　バクテロイデスタイプ'],
  },
  BIFIDOBACTERIUM: {
    description: [
      '甘さ控えめすっきりココア飲料',
      'ラフィノース＆フラクトオリゴ糖配合',
    ],
    imageUrl: '/images/reports/drink_bifidobacterium.png',
    title: ['明治　インナーガーデン', '125ml　ビフィドバクテリウムタイプ'],
  },
  FAECALIBACTERIUM: {
    description: [
      '甘さ控えめすっきりココア飲料',
      'ガラクトオリゴ糖＆フラクトオリゴ糖配合',
    ],
    imageUrl: '/images/reports/drink_faecalibacterium.png',
    title: ['明治　インナーガーデン', '125ml　フィーカリバクテリウムタイプ'],
  },
  None: {
    description: [
      '今回はあなたにおすすめの商品を判定することが出来ませんでした。',
      '',
      '※ココアドリンクのセットをご購入の方は、ほとんどの日本人が保有しているバクテロイデスに適した素材の入った商品をお届けします。詳細は<a href="/faq#detail-9-7" style="color: #0000EE; text-decoration: underline;">こちら</a>をご覧ください。',
    ],
    imageUrl: '/images/reports/drink_unknown.png',
    title: ['測定不可のためおすすめ商品の提示はできません'],
  },
  PREVOTELLA: {
    description: [
      '甘さ控えめすっきりココア飲料',
      'キシロオリゴ糖＆フラクトオリゴ糖配合',
    ],
    imageUrl: '/images/reports/drink_prevotella.png',
    title: ['明治　インナーガーデン', '125ml　プレボテラタイプ'],
  },
  RUMINOCOCCUS: {
    description: [
      '甘さ控えめすっきりココア飲料',
      'イヌリン＆フラクトオリゴ糖配合',
    ],
    imageUrl: '/images/reports/drink_ruminococcus.png',
    title: ['明治　インナーガーデン', '125ml　ルミノコッカスタイプ'],
  },
};

export const RECOMMENDED_PRODUCTS_CAUTION = [
  '摂取して数日間は腸内環境が変わりやすくなるため、おなかの調子が変化する可能性がございます。',
  '量や頻度をご調整いただきながら続けることをおすすめします。',
  '気になる場合は、かかりつけのお医者様にご相談ください。',
];

export const MY_PAGE_REPORT_CHALLENGES = [
  {
    categoryTitle: '腸を健康にする食習慣',
    children: [
      {
        description:
          '朝、コップ１杯分の水分を飲むようにしましょう！冷たい水である必要はなく、お湯でもお茶でもコーヒーでも炭酸水でも結構です！水分をとることで胃腸を目覚めさせる準備運動になります。\nまた、朝食は必ず食べましょう。朝ご飯が胃に入ることで腸を刺激します。１日の始まりに刺激を与えることは大腸にとっても効果的です。',
        id: 1,
        image: 'challenge_food1.png',
        title: '朝の一杯で胃腸を目覚めさせよう！',
      },
      {
        description:
          '食事はただ食べればよいのではなく、どのように食べるかも重要です。スマホを見ながら食べたり仕事をしながら食事をすると、どうしても急いで食べようとしたり消化が悪くなります。そうすると、栄養価の高い食事であっても、その効果を十分に発揮できません。\n\n逆に、食事の際にスマホ等を手放すことで、しっかり味わうようになります。よく噛んで食べるようになり、消化もよくなり、食事に対する満足度も高まります。\n\n「急がば回れ」！忙しいため食事の時間を短縮したい人も多いかと思いますが、急いで食べるとかえって疲れてしまい、消化管の環境も悪くなり、仕事のパフォーマンスにも悪影響を及ぼすかもしれません。食事に集中し、きちんと消化することで必要なエネルギーをきちんと得られるようにしましょう！',
        id: 2,
        image: 'challenge_food2.png',
        title: 'スマホは見ずに！食事をきちんと味わおう！',
      },
      {
        description:
          '水分を1日1.5～2リットル摂取することが様々な場所でお勧めされていますが、腸の調子に応じて水分補給を使い分けてみませんか！？\n\nお腹が緩いときはミネラル分が少なめの軟水を。胃腸に負担をかけずに老廃物を出せるため、下痢気味の人にも向いています。\n便秘で腸が重いときにはミネラル分が多い硬水を。体への浸透圧が高く腸の水分が保たれるので便を柔らかくすることができます。\n\n一気に飲むのではなく、こまめに、なるべく常温だと腸に負担掛けずに水分補給できます！',
        id: 3,
        image: 'challenge_food3.png',
        title: '腸の調子に合った水分補給を！',
      },
      {
        description:
          '腸内環境を良くするための方法は、良い食材をとったり良い運動を実践するだけではありません。\n消化力も重要です！\n消化には、口、胃、小腸がありますが。特に口の消化には唾液が重要です。唾液をいっぱい出すことは感染症予防にもつながります！\n\n日々の食生活でよく噛んで唾液がいっぱい出るようにしましょう。\n目安は1回につき10回以上噛むこと！',
        id: 4,
        image: 'challenge_food4.png',
        title: '腸内環境には消化力が重要！',
      },
      {
        description:
          '腸にとっては何も食べない時間も大切です！何も食べない時間を長く設けると体脂肪が落ちやすいといわれています。毎日実践する必要はないので、無理せずチャレンジしてみましょう！\n\n【食事の時間をあける際のポイント】\n・朝食から夕食までの時間を可能な限り8時間程度以内に設定する',
        id: 5,
        image: 'challenge_food5.png',
        title: '食事の時間をあけて腸の動きを活性化させよう！',
      },
      {
        description:
          '基本的に朝に栄養を取ったほうがその効果が高くなります。\nたとえば、、、\n\n食物繊維は夜よりも朝にとったほうが腸活に良いです。\nタンパク質も朝にとったほうが筋肉量増加に寄与します。\n\n朝は忙しいかもしれませんので、昼までには多くの栄養を摂取しましょう！',
        id: 6,
        image: 'challenge_food6.png',
        title: 'なるべく昼までに栄養補給！',
      },
      {
        description:
          '体に良い働きをする菌をとることは腸にとってとても大切。発酵食品などの良い菌そのものを含む食品（いわゆるプロバイオティクス）を積極的にとって、良い菌の量を増やしたり、食物繊維など腸内にいる菌のエサとなる食品（いわゆるプレバイオティクス）を積極的に食べるよう心がけましょう！',
        id: 7,
        image: 'challenge_food7.png',
        title: '腸内細菌を意識した食習慣を心がけよう！',
      },
      {
        description:
          'オリーブオイルにはオレイン酸が多く含まれます。オレイン酸は腸を刺激し排便をスムーズにすると考えられます。腸内を滑りやすくし、腸の運動を活発にすることにより便秘も解消し、腸内をデトックスしてくれます！\nただし、摂りすぎには注意が必要なため、毎日の食事に1日大さじ１～3杯を目安にオリーブオイルを足してみてください！',
        id: 8,
        image: 'challenge_food8.png',
        title: 'オリーブオイルで腸内デトックス！',
      },
      {
        description:
          '小腸の上皮細胞は、アミノ酸、脂肪酸、グルコースなどの体外からの栄養素を吸収する役割を担っています。また、それ以外にも消化吸収や免疫などにも関係するホルモンを出していることが知られています。このように大事な役割を担う小腸上皮細胞ですが、不規則な食生活などで衰えてしまうことが、様々な病気の要因の一つになると言われています。\n小腸は、アミノ酸の一種であるグルタミンを主なエネルギー源としています。食品では肉や魚などのタンパク質に豊富に含まれるため、これらの食品を意識して摂ることで小腸の健康を維持しましょう！',
        id: 9,
        image: 'challenge_food9.png',
        title: 'グルタミンを通じて小腸にも栄養を供給しよう！',
      },
      {
        description:
          '腸内ではたらく菌が好きな食物繊維がたくさん取れる腸活味噌汁を食べて、短鎖脂肪酸をいっぱい出しましょう！\n\n【腸活味噌汁の具材】\n・わかめ…腸内細菌のエサとなる水溶性食物繊維がたっぷりと入っています\n・豆類…腸内細菌のエサとなる水溶性食物繊維とレジスタントスターチが多く含まれます\n・しいたけ、ナメコなどのキノコ類…腸内細菌のエサとなる水溶性食物繊維だけでなく、免疫力アップにも効果があります\n・大根おろし…食物繊維と消化酵素がはいってます\n・レンコンなどの根菜…便のかさを増す不溶性食物繊維がたっぷり含まれています\n・ その他食材…さらなる食物繊維の摂取のために季節の野菜を取るもよし、タンパク質を摂取するために肉を取るのもよし、食べて楽しい食材を入れましょう',
        id: 10,
        image: 'challenge_food10.png',
        title: '作ってみよう！腸活味噌汁',
      },
      {
        description:
          '最近の研究では、水溶性食物繊維や発酵性の食物繊維が腸内細菌のエサになり、短鎖脂肪酸を多く出すことがわかっています。\n\n水溶性食物繊維、発酵性食物繊維の両方を兼ね備えているのが大麦、雑穀、小麦などです。\n特に全粒であるほど食物繊維が多く含まれます。\n食品を主食として下記食材を1日1回は取り入れましょう！\n\n【腸活のための主食の例】\n・玄米\n・大麦入り米\n・雑穀米\n・全粒分パン\n・ライ麦パン\n・オーツなどが入ったシリアル',
        id: 11,
        image: 'challenge_food11.png',
        title: '1日1回主食で腸活を！',
      },
    ],
    id: 1,
  },
  {
    categoryTitle: '腸を健康にする生活習慣',
    children: [
      {
        description:
          'お口の中には細菌がたくさん潜んでいます。歯磨きをせずにそのまま朝ご飯を食べると、菌も一緒に飲み込みこんでしまい、腸内細菌のバランスを崩してしまうことに！\nなので、朝起きたらまずは歯磨きすることを心がけましょう！\n少なくとも、うがいはしたほうがよいかも！',
        id: 1,
        image: 'challenge_life1.png',
        title: '腸内細菌バランスのために朝起きたら歯を磨こう！',
      },
      {
        description:
          '出た便の状態を見ることで、腸活の効果を確かめることができます！以下のような便が出ていると腸内環境が整っているとされています：\n\n1. いきまずスルッと出る\n2. あまり臭わない\n3. 練り歯磨き程度の硬さ\n4. 色が黄色から黄褐色\n5. 量は大きなバナナ型で長さ20㎝ほど',
        id: 2,
        image: 'challenge_life2.png',
        title: '便の確認は腸活の効果の確認！',
      },
      {
        description:
          '朝日を浴びることは腸活に限らず、様々な効果があります。\n毎朝起きてから少しの時間でいいので朝日を浴びましょう！\n\n【朝日の効果】\n・体内時計のリセット\n・幸せホルモンセロトニンの生成\n＊セロトニン生成は免疫、睡眠、メンタル安定など様々な効果があります。',
        id: 3,
        image: 'challenge_life3.png',
        title: '朝日を浴びよう！',
      },
      {
        description:
          '腸をあたためることで、腸内の働きが活発になります。食べ物の選び方や調理方法を少し意識するだけでも、日々の積み重ねで腸活の効果が増えるかも！\n\n【腸を温める食材】\n・根菜系の食材…根菜は腸を温める効果があります\n・りんご・桃…果物の中では腸を冷やさない効果があります\n・薬味…ネギ、しょうが、ミョウガなどは腸だけでなく体全体が温まります\n・スパイス…腸を温めるだけでなく血流もよくなります',
        id: 4,
        image: 'challenge_life4.png',
        title: '腸を温めよう！',
      },
      {
        description:
          '笑うことは腸の健康にとっても大切！笑うことで腹筋が収縮し、腸の動きを活発化させることに繋がります。また、笑うことでストレスを解消し、腸内環境に影響するホルモンバランスを整えます。ちなみにこれは「作り笑い」であっても効果があるようです！\n面白い動画でも見て、腸と一緒にひと笑いするのはいかが？',
        id: 5,
        image: 'challenge_life5.png',
        title: '面白い動画を見て腸も笑わせよう！',
      },
    ],
    id: 1,
  },
  {
    categoryTitle: '腸を健康にする運動習慣',
    children: [
      {
        description:
          'ウォーキングをすることで、便を排出する力を鍛えられます。腸の健康のために1日9000歩（90分程度）のウォーキングを心がけてみましょう！\n\n【ウォーキングのポイント】\n・1回のウォーキングで9000歩以上歩く必要はなく、朝・夕方に分けても大丈夫です\n・階段の上り下りなどの負荷があるとさらに効果的です！',
        id: 1,
        image: 'challenge_exercise1.png',
        title: 'ウォーキングで便を出す力を鍛えよう！',
      },
      {
        description:
          '15分程度の軽い運動は腸活に限らず、全般的な健康維持の効果があります。\n便秘気味の方はスクワットと軽めのジョギングがおすすめです。\n下痢気味の人は、ウォーキングや軽い階段上り下りがおすすめです。\n特に、座りっぱなしの人は血流も悪くなっているので、思いだした時にこまめに軽い運動を心がけてみましょう！',
        id: 2,
        image: 'challenge_exercise2.png',
        title: '15分程度の軽い運動で腸にいい影響を！',
      },
      {
        description:
          '踊ることは腸にも効果的！踊ることで全身の筋肉を動かし、腸のぜん動運動を促進します。また、踊ることは楽しく運動することにもつながり、腸内環境に影響する代謝を高めます。腸と一緒に、たまには踊ってみませんか？',
        id: 3,
        image: 'challenge_exercise3.png',
        title: '腸と一緒に踊りませんか？',
      },
      {
        description:
          '一見すると訳が分からないかもしれませんが、腸活にはとても効果的なんです！\nまず、歌うこと自体、気分があがり腸内環境に影響する神経の働きを改善します。また、歌うことで腹式呼吸になり腸に酸素を送ることができます。\n\nそして、歌うことで横隔膜を使い、その横隔膜の刺激で腸も動くため、便秘解消などにも効果的と言われています！この横隔膜の動きを最大限に意識しやすいのが四つん這いの状態なのです！\n\n【四つん這いの際に意識するポイント】\n・四つん這いの状態で、口を開けて、おなかを背中の方向に凹ませるだけで息が口から吐き出せることを意識します。\n・その際、おなかの動きと一緒に胸の下あたりも上下することも意識します。これが横隔膜なので、今度はその胸の下の上下だけで息を吐きだします。\n・これを「ハッハッハツ」とテンポよく繰り返し吐き出します。\n\nちなみに立ったまま腰を前屈みにするだけでも横隔膜を感じられるため、妊婦さんはこの方法も実践してみてください！',
        id: 4,
        image: 'challenge_exercise4.png',
        title: '四つん這いになって「腹の底」から歌ってみよう！',
      },
      {
        description:
          '小指のツボを押すことで腸の動きを活性化しましょう！\n「井穴(せいけつ)」というツボを押して痛いときは腸の動きが悪くなりがちです。\n隙間時間にぜひチャレンジしてみましょう！\n\n【ツボ押しのやり方】\n１．小指の爪の左右の生え際を、もう一方の手の親指とひとさし指でギュッとつまんで押す\n２．つまんだ指を引っ張って離す\n３．これを10回繰り返す\n４．もう一方の小指も同じようにつまんで、引っ張って離す',
        id: 5,
        image: 'challenge_exercise5.png',
        title: '指のツボ押しで腸の動きを活発に！',
      },
      {
        description:
          '腕の外側にある支構というツボは、大腸とつながりがあると考えられています。\n手洗いなどのふとした時にやってみると、腸の動きがよくなって便秘改善につながるかも！\n\n【ツボ押しのやり方】\n１．手首のシワ（外側）から腕に向かって、指4本分空けた場所（外側）をもう一方の手の親指でギュッと押す\n２．深呼吸しながら程よく痛い程度にゆっくりとギューッと10秒ほど押していく\n３．もう一方の腕にも繰り返す',
        id: 6,
        image: 'challenge_exercise6.png',
        title: '腸めぐりを良くする腕マッサージ！',
      },
      {
        description:
          '足の付け根にはリンパが集合していて老廃物が溜まりやすくなってます。\nちょっと刺激してあげるだけで老廃物が流れ、便秘解消やデトックスができます！\n\n【リンパ流しストレッチの方法】\n１．まずは仰向けに寝てください\n２．両手を両足の付け根（股関節と太ももの間）にセット\n３．太ももを上に上げて膝をお腹に近づけるときに指でぐっと刺激\n４．これを左右10回繰り返します',
        id: 7,
        image: 'challenge_exercise7.png',
        title: 'リンパ流しで便秘解消とデトックス！',
      },
    ],
    id: 1,
  },
];
/* eslint-disable sort-keys-fix/sort-keys-fix */
export const BACTERIA_TYPE = {
  BIFIDOBACTERIUM: {
    DESCRIPTION:
      'アジア圏、特に日本人の腸内フローラで多く見られる腸内細菌のグループの一つです。日常的に乳製品を摂取する人に多いと言われています。',
    IMAGE_PATH: '/images/reports/emoji_blue.svg',
    KEY: 'BIFIDOBACTERIUM',
    TITLE: 'ビフィドバクテリウム',
  },
  BACTEROIDES: {
    DESCRIPTION:
      '世界中の多くの人が持っている、一般的な腸内細菌のグループの一つです。特に高脂肪、高たんぱく質な欧米型の食事の人が多く持っていることが知られています。',
    IMAGE_PATH: '/images/reports/emoji_red.svg',
    KEY: 'BACTEROIDES',
    TITLE: 'バクテロイデス',
  },
  FAECALIBACTERIUM: {
    DESCRIPTION:
      '世界中の多くの人が持っている腸内細菌のグループの一つです。アスリートや運動をしている人に比較的多い菌と言われています。',
    IMAGE_PATH: '/images/reports/emoji_yellow.svg',
    KEY: 'FAECALIBACTERIUM',
    TITLE: 'フィーカリバクテリウム',
  },
  PREVOTELLA: {
    DESCRIPTION:
      '穀物などの食物繊維を多く含む食品をたくさん摂取する人に多いと言われています。日本人では1週間当たりの運動時間が長い人に多い傾向があると言われています。',
    IMAGE_PATH: '/images/reports/emoji_orange.svg',
    KEY: 'PREVOTELLA',
    TITLE: 'プレボテラ',
  },
  RUMINOCOCCUS: {
    DESCRIPTION:
      '様々な機能を持った腸内細菌が属するグループです。日本人では成人後の女性が比較的多い菌と言われています。',
    IMAGE_PATH: '/images/reports/emoji_green.svg',
    KEY: 'RUMINOCOCCUS',
    TITLE: 'ルミノコッカス',
  },
} as const;
/* eslint-enable sort-keys-fix/sort-keys-fix */

export type BacteriaTypeConfig =
  (typeof BACTERIA_TYPE)[keyof typeof BACTERIA_TYPE];
