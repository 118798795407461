import { Grid } from '@mui/material';

import { GraphBar } from '@/types/index';

type Props = { bar: GraphBar; length: number };

export function ReportBar({ length, bar }: Props) {
  return (
    <Grid container sx={{ height: '20px' }}>
      {Array(length)
        .fill(0)
        .map((empty, i) => {
          const n = i + 1;
          return (
            <Grid
              key={`bar-${n}`}
              xs={12 / length}
              item
              sx={{
                backgroundColor:
                  n > bar.start && n <= bar.end ? bar.color : 'transparent',
              }}
            />
          );
        })}
    </Grid>
  );
}
