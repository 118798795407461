import { Box, Chip, Grid, Typography } from '@mui/material';

import { PRODUCT_LIST_PAGE, TOP_PAGE } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { ProductListItem } from '@/view/components/ProductListItem';
import { SectionTitle } from '@/view/components/TopPage/SectionTitle';
import { STYLES } from '@/view/styling/theme';

const CONSTANTS = TOP_PAGE.PRODUCT;

interface ProductProps {
  firstVisit: boolean;
  isAuthenticated: boolean;
}

const Tag = ({ text }: { text: string }) => {
  return (
    <Chip
      label={text}
      size="small"
      variant="outlined"
      sx={STYLES.productListItem.tag}
    />
  );
};

export function Product({ firstVisit, isAuthenticated }: ProductProps) {
  const primary =
    firstVisit || !isAuthenticated
      ? PRODUCT_LIST_PAGE.BODY.filter(
          (product) => product.DETAIL_PAGE === 'first-set'
        )
      : PRODUCT_LIST_PAGE.BODY.filter(
          (product) =>
            product.DETAIL_PAGE === 'check-kit' ||
            product.DETAIL_PAGE === 'drink'
        ).reverse();

  const secondary =
    firstVisit || !isAuthenticated
      ? PRODUCT_LIST_PAGE.BODY.filter(
          (product) =>
            product.DETAIL_PAGE === 'check-kit' ||
            product.DETAIL_PAGE === 'drink'
        )
      : [];

  return (
    <Box sx={STYLES.topPage.product.container}>
      <Grid container sx={STYLES.topPage.product.grid}>
        <SectionTitle
          title={'Product'}
          subtitle={
            firstVisit
              ? CONSTANTS.FIRST_PURCHASE.TITLE
              : CONSTANTS.RETURNING_VISIT.TITLE
          }
        />
      </Grid>
      <Box sx={{ pb: 5 }}>
        <Grid
          container
          sx={STYLES.topPage.product.grid}
          direction="column"
          rowSpacing={5}
        >
          {primary.map(
            (product, index) =>
              product && (
                <Grid item marginX={2} key={index}>
                  <ProductListItem
                    subTitle={product.SUBTITLE}
                    image={product.IMAGE_PATH}
                    imageCaption={product.IMAGE_CAPTION}
                    price={product.PRICE.map(
                      (price: {
                        DISCOUNT?: {
                          TEXT?: string;
                          VALUE: string;
                        };
                        PURCHASE_FREQUENCY?: string;
                        SHIPPING_PRICE?: string;
                        VALUE: string;
                      }) => {
                        const purchaseFrequencyText =
                          price.PURCHASE_FREQUENCY?.replace('】', '')?.replace(
                            '【',
                            ''
                          );

                        return {
                          PURCHASE_FREQUENCY: purchaseFrequencyText
                            ? Tag({ text: purchaseFrequencyText })
                            : undefined,
                          SHIPPING_PRICE: price.SHIPPING_PRICE,
                          VALUE: price.VALUE,
                        };
                      }
                    )}
                    title={product.TITLE}
                    styleOverride={{
                      buttonVariant: 'contained',
                      image: { height: '100%', width: '100%' },
                      imageCaption: {
                        fontSize: '0.75rem',
                        fontWeight: 600,
                        lineHeight: '1rem',
                      },
                      priceItemWrapper:
                        product.PRICE.length > 1
                          ? {
                              fontSize: '16px',
                            }
                          : {},
                      pricing: { paddingTop: '16px' },
                      purchaseFrequencyText: {
                        fontSize: '11px',
                        lineHeight: '0rem',
                      },
                      purchaseTaxText: {
                        fontSize: '11px',
                        lineHeight: '0rem',
                      },
                      title: { fontSize: '1rem', lineHeight: '1.5rem' },
                    }}
                    nextButton={{
                      label: PRODUCT_LIST_PAGE.BUTTON_TEXT,
                      onClick: () =>
                        user.navigateTo(`/products/${product.DETAIL_PAGE}`),
                    }}
                    taxText={PRODUCT_LIST_PAGE.PRICE_TAX_TEXT}
                  />
                </Grid>
              )
          )}
        </Grid>
      </Box>
      <Box sx={{ pb: 2.5, px: 2 }}>
        {(firstVisit || !isAuthenticated) && (
          <Grid container sx={{ pb: 2.5 }} direction="column">
            <Typography sx={STYLES.topPage.product.header}>
              {CONSTANTS.FIRST_PURCHASE.PRODUCT_2.HEADER}
            </Typography>
          </Grid>
        )}

        <Grid container direction="row" spacing={2}>
          {secondary.map(
            (product, index) =>
              product && (
                <Grid item key={index} xs={6} md={6}>
                  <ProductListItem
                    subTitle={product.SUBTITLE}
                    image={product.IMAGE_PATH}
                    price={[{ VALUE: product.PRICE[0]?.VALUE }]}
                    title={product.TITLE}
                    taxText={PRODUCT_LIST_PAGE.PRICE_TAX_TEXT}
                    styleOverride={{
                      image: { height: '100%', width: '100%' },
                      imageCaption: {
                        fontSize: '0.75rem',
                        fontWeight: 300,
                        lineHeight: '1rem',
                      },
                      priceItemWrapper: {
                        fontSize: '16px',
                      },
                      pricing: { paddingTop: '16px' },
                      purchaseFrequencyText: {
                        fontSize: '11px',
                        lineHeight: '0rem',
                      },
                      purchaseTaxText: {
                        fontSize: '11px',
                        lineHeight: '0rem',
                      },
                      title: { fontSize: '1rem', lineHeight: '1.5rem' },
                    }}
                  />
                </Grid>
              )
          )}
        </Grid>
      </Box>
    </Box>
  );
}
