import { ArrowDropDown } from '@mui/icons-material';
import {
  Grid,
  MenuItem,
  Select,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { Question, OnQuestionAnswerChanged } from '@/types';
import { STYLES } from '@/view/styling/theme';

type Props = Question & {
  isAutoSaving: boolean;
  onChange: OnQuestionAnswerChanged;
  questionNumber: number;
};

export function SurveySelectInlineItem({
  children,
  childrenSecondary,
  id,
  title,
  onChange,
  questionNumber,
  description,
  selectedValue,
  placeholder,
  suffix,
  isAutoSaving,
}: Props) {
  const [combinedValue, setCombinedValue] = useState(`${selectedValue}`);
  const [activeOptionId, setActiveOptionId] = useState('');
  useEffect(() => {
    if (!_.isEmpty(combinedValue)) {
      const onChangeHandler = async () => {
        await onChange({ key: id, value: combinedValue });
        setActiveOptionId('');
      };
      void onChangeHandler();
    }
  }, [combinedValue]);

  const children1SelectedValue = _.get(
    `${selectedValue}`.split(','),
    '[0]',
    ''
  );
  const children2SelectedValue = _.get(
    `${selectedValue}`.split(','),
    '[1]',
    ''
  );
  return (
    <Grid container id={id} key={id} direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h6">
          {questionNumber ? `Q${questionNumber}.` : ''} {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption" sx={{ color: 'grey.300' }}>
          {description}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row">
          <Grid item xs={4}>
            <Select
              data-testid={id + '_LEFT'}
              sx={{
                pointerEvents: isAutoSaving ? 'none' : 'auto',
                width: '100% ',
              }}
              value={children1SelectedValue}
              displayEmpty
              onChange={(e) => {
                setActiveOptionId('LHS');
                setCombinedValue(
                  `${e.target.value},${_.get(
                    `${selectedValue}`.split(','),
                    '[1]',
                    ''
                  )}`
                );
              }}
              IconComponent={
                activeOptionId === 'LHS'
                  ? () => (
                      <Box>
                        <CircularProgress
                          size={20}
                          sx={{ mr: '12px', position: 'relative', top: '2px' }}
                        />
                      </Box>
                    )
                  : ArrowDropDown
              }
            >
              <MenuItem disabled value="">
                {placeholder}
              </MenuItem>
              {children.map((item) => {
                return (
                  <MenuItem
                    data-testid={id + '_' + _.get(item, 'value')}
                    key={_.get(item, 'label') + '_' + _.get(item, 'value')}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={STYLES.suffixPlacement}>{suffix[0]}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Select
              data-testid={id + '_RIGHT'}
              sx={{
                pointerEvents: isAutoSaving ? 'none' : 'auto',
                width: '100% ',
              }}
              value={children2SelectedValue}
              displayEmpty
              onChange={(e) => {
                setActiveOptionId('RHS');
                setCombinedValue(
                  `${_.get(`${selectedValue}`.split(','), '[0]', '')},${
                    e.target.value
                  }`
                );
              }}
              IconComponent={
                activeOptionId === 'RHS'
                  ? () => (
                      <Box>
                        <CircularProgress
                          size={20}
                          sx={{ mr: '12px', position: 'relative', top: '2px' }}
                        />
                      </Box>
                    )
                  : ArrowDropDown
              }
            >
              <MenuItem disabled value="">
                {placeholder}
              </MenuItem>
              {(childrenSecondary || []).map((item) => {
                return (
                  <MenuItem
                    key={_.get(item, 'label') + '_' + _.get(item, 'value')}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={STYLES.suffixPlacement}>{suffix[1]}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
