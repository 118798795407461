export const data = [
  {
    categoryTitle: '腸内フローラ検査の申込・配送・キャンセルについて',

    children: [
      //! section 1-1
      {
        content: [
          'インナーガーデンのＴＯＰページ(https://floracheck.meiji.co.jp/) より進んでいただき、明治会員IDの登録を行い、指定の方法に従ってサービスのお申し込みをしていただきます。',
          '「明治会員IDの登録」と「サービス申込」の２ステップを完了しないと検査キットが届きません。',
        ],
        id: 1,
        section: '1-1',
        title: 'どのように腸内フローラ検査を申し込めばいいかわかりません。',
      },
      //! section 1-2
      {
        content: [
          'インナーガーデンのマイページのログインはこちらからお進みください。',
          '【マイページＵＲＬ】\n https://floracheck.meiji.co.jp/my-page',
        ],
        id: 2,
        section: '1-2',
        title:
          '検索などから直接、明治会員IDにログインすると、明治会員IDのマイページに飛んでしまいます。',
      },
      //! section 1-3
      {
        content: [
          '明治会員ID登録の後に、インナーガーデン(https://floracheck.meiji.co.jp/my-page)にお申込みいただく必要がございます。\n申し込み完了次第、郵便ポストに郵送いたします。',
          'インナーガーデンの検査のお申込みが完了されても検査キットが届かない方は、明治会員サポートセンターまでお問合せください。\n調査後ご連絡させていただきます。',
          '【明治会員サポートセンター】\n https://id.meiji.co.jp/contact/input',
        ],
        id: 3,
        section: '1-3',
        title:
          '腸内フローラ検査を申し込んだにもかかわらず、検査キットが届きません。',
      },
      // ! section 1-4
      {
        content: ['インナーガーデンは現在国内のみのご提供となっています。'],
        id: 4,
        section: '1-4',
        title: '検査キットの海外への発送は行っていますか？',
      },
      // ! section 1-5
      {
        content: [
          '検査キット申し込み後、通常２～３日でお届けします。\n1週間以上たっても届かない場合は明治会員サポートセンターまでお問合せください。',
          '【明治会員サポートセンター】\n https://id.meiji.co.jp/contact/input',
        ],
        id: 5,
        section: '1-5',
        title: '申し込みから何日くらいで検査キットが届きますか？',
      },
      // ! section 1-6
      {
        content: ['検査に申し込んだご本人様のみ受けられます。'],
        id: 6,
        section: '1-6',
        title: '腸内フローラ検査は本人以外も受けられますか？',
      },
      // ! section 1-7
      {
        content: [
          '初回申し込みの方に限り、腸内フローラ検査とパーソナルココア飲料をセットで12,000円(税込)でご購入いただけます。初回のセットは送料無料です。',
          '2回目以降、腸内フローラ検査をお申し込みの方は、9,000円(税込)でご購入いただけます。別途、送料330円(税込)が掛かります。',
          '詳しくは商品一覧ページをご覧ください。',
          '【商品一覧ページ】\n https://floracheck.meiji.co.jp/products',
        ],
        id: 7,
        section: '1-7',
        title: '腸内フローラ検査の申し込みにはいくらかかりますか？',
      },
      // ! section 1-8
      {
        content: [
          'クレジットカード（JCB、Visa、Mastercard、AMEX等）がご利用いただけます。',
        ],
        id: 8,
        section: '1-8',
        title: '申し込みにはどのような決済方法がありますか？',
      },
      // ! section 1-9
      {
        content: [
          '申込後8日以内であれば、弊社から送付の検査キットが未開封の場合に限り、返品・返金を承ります。\n検査申込後9日以降、および検査キット開封後の返品・返金は、当社の責に帰すべき事由がある場合を除き、お受けいたしかねます。返品・返金については、明治会員サポートセンターまでお問合せください。お客様のご都合による返品の際の送料は、お客様のご負担とさせて頂きます。',
          '【明治会員サポートセンター】\n https://id.meiji.co.jp/contact/input',
        ],
        id: 9,
        section: '1-9',
        title: '腸内フローラ検査の返品・返金はできますか？',
      },
      // ! section 1-10
      {
        content: ['18歳以上を対象としております。'],
        id: 10,
        section: '1-10',
        title: '年齢制限はありますか？',
      },
      // ! section 1-11
      {
        content: [
          'インナーガーデンはお客様の腸内タイプを特定して、短鎖脂肪酸を体内産生しやすい最適な素材を提供するサービスとなります。',
          'そのため、初回は腸内フローラ検査と商品(飲料)のセットのみ販売しております。\n2回目以降のご購入から腸内フローラ検査単品をお申込いただけます。',
        ],
        id: 11,
        section: '1-11',
        title: '初回に腸内フローラ検査単品を申し込むことはできますか？',
      },
    ],
    //* section 1
    id: 1,
  },
  {
    categoryTitle: '会員ID新規登録・ログインについて',

    children: [
      //  ! section 2-1
      {
        content: [
          '明治会員IDサービスへの新規会員登録がお済みでない方は、新規会員登録をお願いいたします。 既に会員登録されている方は、「メールアドレスを忘れました。」「パスワードを忘れました。」をご覧ください。',
        ],
        id: 1,
        section: '2-1',
        title: 'ログインができません。',
      },
      // ! section 2-2
      {
        content: [
          '【明治会員IDログインページ】から、「新規会員登録」ボタンをクリックして登録を行ってください。\nセキュリティを確保するために、登録するメールアドレスと電話番号それぞれに確認メッセージを送信させていただきます。\n電話番号は、SMS（ショートメッセージサービス）を受信可能な電話番号（携帯電話・スマートフォン等）をご利用ください。',
          '明治会員IDのログインはこちらからお進みください。\n https://floracheck.meiji.co.jp/login',
        ],
        id: 2,
        section: '2-2',
        title: '新規会員登録はどのように行えばいいですか？',
      },
      // ! section 2-3
      {
        content: [
          'エラーメッセージに従い、対応をお願いします。\n登録ができない場合は、以下の原因が考えられます。',
          '\u00B7 すでに別のIDで登録されている電話番号を⼊⼒している\n\u00B7 登録ができない電話番号を⼊⼒している（固定電話等）\n\u00B7 全⾓で⼊⼒している\n\u00B7 「-」（ハイフン）を⼊⼒している',
        ],
        id: 3,
        section: '2-3',
        title: '電話番号が登録できません。',
      },
      // ! section 2-4
      {
        content: [
          '新規会員登録画面にて「認証コードを再送信する」をクリックしてください。\nそれでも認証コードが届かない場合は、電話番号入力画面に戻って頂き、SMS（ショートメッセージサービス）が受信可能な携帯電話の番号が入力されているか、電話番号の間違いがないか、キャリア設定にてSMSを受信できる設定になっているかのご確認をお願いします。',
        ],
        id: 4,
        section: '2-4',
        title: '登録した電話番号に確認メッセージが届きません。',
      },
      // ! section 2-5
      {
        content: [
          '迷惑メールフォルダまたはゴミ箱フォルダに入っている可能性が考えられますので、メールフォルダの確認をお願いします。\n確認してもメールが見当たらない場合は、メールアドレスの誤記入が考えられますので、メールアドレス入力画面に戻り再入力をする、もしくは改めて新規登録を実施してください。',
        ],
        id: 5,
        section: '2-5',
        title: '登録したメールアドレスに確認メールが届きません。',
      },
      // ! section 2-6
      {
        content: [
          '明治会員サポートセンターに問い合わせください。ご本人様確認をさせていただき、お客様の登録情報を確認させていただきます。',
          '【明治会員サポートセンター】\n https://id.meiji.co.jp/contact/input',
        ],
        id: 6,
        section: '2-6',
        title: 'メールアドレスを忘れました。',
      },
      // ! section 2-7
      {
        content: [
          '【明治会員IDログインページ】の「パスワードを忘れた方」ボタンをクリックしてください。\n登録したメールアドレスに、パスワード再設定用のメールを送信いたします。',
          '明治会員IDのログインはこちらからお進みください。\nhttps://floracheck.meiji.co.jp/login',
        ],
        id: 7,
        section: '2-7',
        title: 'パスワードを忘れました。',
      },
      // ! section 2-8
      {
        content: [
          '明治会員IDサービスへログイン後にSNS連携を設定することで、そのSNSアカウントでログインしている方は、メールアドレスとパスワードを入力せずに明治会員IDサービスにもログインができるようになります。',
        ],
        id: 8,
        section: '2-8',
        title: '「SNSでログインする」とはなんですか？',
      },
      // ! section 2-9
      {
        content: [
          '既にログイン状態のPCやスマートフォンに、別のご家族のアカウントをログインする場合は、ログアウトが必要になります。\nマイページ右上にある「マイページ」ボタンをクリックいただき、ログアウトを選択してください。なお、同じアカウントを複数人でご利用いただくことはお断りしております。',
        ],
        id: 9,
        section: '2-9',
        title: '他の家族にログインさせたい。',
      },
      // ! section 2-10
      {
        content: [
          '恐れ入りますが、複数人で一つのアカウントを使用することはできません。\nご家族で検査を受けられる場合は、お一人様おひとつのアカウントが必要となります。',
        ],
        id: 10,
        section: '2-10',
        title:
          '家族で検査を受けたが、家族全員の情報を一つのアカウント上で管理したいです。',
      },
    ],
    // * section 2
    id: 2,
  },
  //* section 3
  {
    categoryTitle: '会員IDマイページについて',
    children: [
      // ! section 3-1
      {
        content: [
          '明治会員IDマイページ(https://id.meiji.co.jp/user/edit)にて、基本情報のメールアドレス横の「変更」ボタンをクリックしてください。\nセキュリティを確保するために、ご登録の電話番号に確認メッセージを送信させていただきます。',
          'また、注文完了、商品配送、定期購入などに関するメール受信先アドレスの変更については、明治会員サポートセンターにて登録メールアドレスの変更を行わせていただきます。 お手数をおかけしますが、注文メール等に関するメールアドレスを変更したい旨を下記URLより必須事項を入力の上、お問い合わせください。',
          'お問い合わせを確認後、数日内に変更を行わせていただきます。',
          '【明治会員サポートセンター】\n  https://id.meiji.co.jp/contact/input',
        ],
        id: 1,
        section: '3-1',
        title: '登録したメールアドレスを変更したいです。',
      },
      // ! section 3-2
      {
        content: [
          '明治会員IDマイページ(https://id.meiji.co.jp/user/edit)にて、基本情報の電話番号横の「変更」ボタンをクリックしてください。\nセキュリティを確保するために、ご登録のメールアドレスに確認メッセージを送信させていただきます。',
        ],
        id: 2,
        section: '3-2',
        title: '電話番号を変更したいです。',
      },
      // ! section 3-3
      {
        content: [
          '明治会員IDマイページ(https://id.meiji.co.jp/user/edit)にて、基本情報のパスワード横の「変更」ボタンをクリックしてください。\nセキュリティを確保するために、ご登録の電話番号に確認メッセージを送信させていただきます。',
        ],
        id: 3,
        section: '3-3',
        title: 'パスワードを変更したいです',
      },
      // ! section 3-4
      {
        content: [
          '明治会員IDマイページ(https://id.meiji.co.jp/user/edit)にて、個人情報の右部にある「変更」ボタンをクリックしてください。',
        ],
        id: 4,
        section: '3-4',
        title: '個人情報を変更したいです。',
      },
      // ! section 3-5
      {
        content: [
          '明治会員IDと各種SNSを連携することで、メールアドレスやパスワードを入力せずに、SNSアカウントを利用したログインが可能になります。\n対象のSNSはLINE・Facebook・Google・Yahoo!Japan・Appleです。',
        ],
        id: 5,
        section: '3-5',
        title: 'SNS連携とはなんですか？',
      },
      // ! section 3-6
      {
        content: [
          'ログインの際に登録しているメールアドレスもしくは電話番号に確認メッセージを送る機能です。お客様のセキュリティ向上に繋がりますので、ご利用ください。',
        ],
        id: 6,
        section: '3-6',
        title: '2段階認証とはなんですか？',
      },
    ],
    id: 3,
  },
  // * section 4
  {
    categoryTitle: '会員ID退会について',
    children: [
      // ! section 4-1
      {
        content: [
          'インナーガーデンを退会したい場合は、明治会員IDサービスを退会する必要があります。\n明治会員IDサービスを退会しますと、すべての明治会員IDに紐づくサービスを利用できなくなることをご了承くださいませ。',
          '明治会員IDサービスを退会せずに、インナーガーデンのデータのみを消去したい場合は、明治会員サポートセンターにお問合せください。',
          '【明治会員サポートセンター】\n  https://id.meiji.co.jp/contact/input',
        ],
        id: 1,
        section: '4-1',
        title: 'インナーガーデンを退会したいです。',
      },
      // ! section 4-2
      {
        content: [
          '明治会員IDマイページ(https://id.meiji.co.jp/user/edit)下部の「退会手続き」ボタンをクリックしてください。\n退会手続きページにて「明治会員IDサービスから退会する」をクリックしてください。\n明治会員IDサービスから退会されますと、ご利用の全てのアプリが利用できなくなります。ご注意ください。',
        ],
        id: 2,
        section: '4-2',
        title: '明治会員IDサービスを退会したいです。',
      },
      // ! section 4-3
      {
        content: [
          'データを復旧させることは出来ないため、新規会員登録を御願いします。',
        ],
        id: 3,
        section: '4-3',
        title:
          '間違えて、退会手続きをしてしまいました。復会する方法はありますか？ ',
      },
    ],
    id: 4,
  },
  // * section 5
  {
    categoryTitle: '採便について',
    children: [
      // ! section 5-1
      {
        content: [
          '検査キットに内包されている採便説明書、またはインナーガーデンのマイページの採便の手順をご確認ください。',
          '【マイページＵＲＬ】\n https://floracheck.meiji.co.jp/my-page',
        ],
        id: 1,
        section: '5-1',
        title: '採便方法がわかりません。',
      },
      // ! section 5-2
      {
        content: [
          '明治会員サポートセンターまでお問い合わせください。検査キットの交換ができかねる場合がございますこと、ご了承ください。',
          '【明治会員サポートセンター】\n  https://id.meiji.co.jp/contact/input',
        ],
        id: 2,
        section: '5-2',
        title:
          '採便キットを紛失・破壊してしまった場合は、どうすればよいですか ？',
      },
      // ! section 5-3
      {
        content: [
          'インナーガーデンのマイページにログインすると、検査の手順が記載されていますので、そちらをご確認ください。',
          '【マイページＵＲＬ】\n https://floracheck.meiji.co.jp/my-page',
        ],
        id: 3,
        section: '5-3',
        title: 'サービス説明書を紛失した場合はどうすればよいですか？',
      },
      // ! section 5-4
      {
        content: [
          '同梱されていない場合は大変申し訳ございません。\nご自身で角２サイズの封筒をご用意いただき、郵送してください。封筒に下記を記載してください。',
          '明治インナーガーデン\n合同会社H.U.グループ中央研究所　インナーガーデン事務局\n担当　行\n便検体在中・機械処理厳禁',
          '恐れ入りますが、切手代はご自身でご用意ください。',
          'ご不明な場合は明治会員サポートセンターまでお問い合わせください。',
          '【明治会員サポートセンター】\n  https://id.meiji.co.jp/contact/input',
        ],
        id: 4,
        section: '5-4',
        title: '返送用封筒を紛失した場合はどうすればよいですか？ ',
      },
      // ! section 5-5
      {
        content: [
          'インナーガーデンのマイページにログインして手順を進めていただくと採便の手順が記載されていますので、そちらをご確認ください。',
          '【マイページＵＲＬ】\n  https://floracheck.meiji.co.jp/my-page',
        ],
        id: 5,
        section: '5-5',
        title: '採便説明書を紛失した場合はどうすればよいですか？',
      },
      // ! section 5-6
      {
        content: [
          '吸水シートは検体が万が一漏れたときのために入れております。\n採便後は、採便容器の蓋をしっかり閉めて、ティッシュペーパーやキッチンペーパー、ビニール袋などにくるんで送ってください。',
          'ご不明な場合は明治会員サポートセンターまでお問い合わせください。',
          '【明治会員サポートセンター】\nお問い合わせ：https://id.meiji.co.jp/contact/input',
        ],
        id: 6,
        section: '5-6',
        title: '吸水シートを紛失した場合はどうすればよいですか？',
      },
      // ! section 5-7
      {
        content: [
          '吸水シートは採便済容器と一緒にチャック付きビニール袋に入れてください。\n詳しくは、マイページの検査の手順をご確認ください。',
          '【マイページＵＲＬ】\n  https://floracheck.meiji.co.jp/my-page',
        ],
        id: 7,
        section: '5-7',
        title: '吸水シートをどのように使えばいいですか？ ',
      },
      // ! section 5-8
      {
        content: [
          'ビニール袋は検体が万が一漏れたときのために入れております。\n採便後は、別のビニール袋やファスナー付きプラスチックバッグなどの袋にくるんで送ってください。',
          'ご不明な場合は明治会員サポートセンターまでお問い合わせください。',
          '【明治会員サポートセンター】\n  https://id.meiji.co.jp/contact/input',
        ],
        id: 8,
        section: '5-8',
        title: 'ビニール袋を紛失した場合はどうすればよいですか？ ',
      },
      // ! section 5-9
      {
        content: [
          'べんとりくんがなくても採便はできます。\nトイレットペーパーなどを敷いて水に浸っていない箇所を採便してください。',
        ],
        id: 9,
        section: '5-9',
        title:
          'べんとりくんを紛失・破壊してしまった場合は、どうすればよいですか ？ ',
      },
      // ! section 5-10
      {
        content: [
          '検査キットに内包されている採便説明書、またはマイページの検査の手順をご確認ください。',
          '【マイページＵＲＬ】\n  https://floracheck.meiji.co.jp/my-page',
        ],
        id: 10,
        section: '5-10',
        title: 'べんとりくんの使い方がわかりません。 ',
      },
      // ! section 5-11
      {
        content: [
          'お客様が採取した便を保存する試薬となります。\n検査に必要なので、捨てずに必ずそのまま採便してください。\n採便後に、便を液につけて容器に蓋をしてください。',
        ],
        id: 11,
        section: '5-11',
        title: '採便容器に入っている液体は何ですか？ ',
      },
      // ! section 5-12
      {
        content: [
          '採便した便が完全に液に浸れば問題ございません。\n便が浸らない場合や、液をすべてこぼしてしまった場合は明治会員サポートセンターまでお問い合わせください。',
          '【明治会員サポートセンター】\n  https://id.meiji.co.jp/contact/input',
        ],
        id: 12,
        section: '5-12',
        title: '採便容器の液をこぼしてしまいました。 ',
      },
      // ! section 5-13
      {
        content: [
          '腸内フローラ検査は申し込みから6か月までに便を郵送しないと検査結果が出ません。',
        ],
        id: 13,
        section: '5-13',
        title: 'いつまでに便検体を送ればよいですか？ ',
      },
      // ! section 5-14
      {
        content: ['採便前日・当日の食事・飲酒・喫煙に制限はありません。'],
        id: 14,
        section: '5-14',
        title: '前日・当日に食事や飲酒、喫煙の制限はありますか？ ',
      },
      // ! section 5-15
      {
        content: [
          '無理に便を採取していただく必要はありません。\n体調が治り次第、改めて採取してください。',
        ],
        id: 15,
        section: '5-15',
        title: '体調が悪くなってしまった日に採便しても大丈夫ですか？ ',
      },
      // ! section 5-16
      {
        content: [
          '生理中でも、採便は可能です。\n経血がついている部分を避けてください。',
        ],
        id: 16,
        section: '5-16',
        title: '生理中に採便は可能でしょうか？ ',
      },
      // ! section 5-17
      {
        content: [
          '日常的に服用している以外で薬を服用している場合は、採便を避けてください。\n特に抗生物質は腸内細菌への影響があります。',
        ],
        id: 17,
        section: '5-17',
        title: '薬を服用している場合、採便は可能でしょうか？ ',
      },
      // ! section 5-18
      {
        content: [
          'いつでも構いません。ただし、採便してから1週間以内に郵便ポストに投函してください。',
        ],
        id: 18,
        section: '5-18',
        title: '採便はいつ行えばいいですか？ ',
      },
      // ! section 5-19
      {
        content: [
          'ご無理のない範囲で採取いただき、提出してください。\n可能な範囲で検査結果をお届けします。',
        ],
        id: 19,
        section: '5-19',
        title: '便があまり出ない場合、どうすればよいですか？ ',
      },
      // ! section 5-20
      {
        content: [
          '便を採取する前に異物混入しないようにできるだけ務めてください。入ってしまった場合でも、そのまま送ってください。\n可能な範囲で検査結果をお届けします。',
        ],
        id: 20,
        section: '5-20',
        title:
          '誤って採便キットに別の物を入れてしまった場合は、どうすればいいですか？ ',
      },
      // ! section 5-21
      {
        content: [
          '液体が誤って皮膚に付いたり目や口に入った場合には、水で十分に洗い流してください。瞬時に害を与えるものではございません。\n刺激が残る場合は、必要に応じて医師にご相談ください。',
        ],
        id: 21,
        section: '5-21',
        title: '保存液が手や目に付きました。どうすればよいですか？ ',
      },
      // ! section 5-22
      {
        content: [
          '正しい検査結果が出ない可能性があるため、別の便を採取してください。',
        ],
        id: 22,
        section: '5-22',
        title: '便に血や尿など別のものが混ざりました。どうすればよいですか？ ',
      },
    ],
    id: 5,
  },
  // * section 6
  {
    categoryTitle: '検査受付番号登録・生活習慣アンケート入力について',
    children: [
      // ! section 6-1
      {
        content: [
          '検査受付番号とは、お客様の採便容器および検体を識別するための番号です。採便容器に貼付されているQRコード下部の14桁の数字となります。\n採便の前に、マイページにて検査受付番号を登録していただきます。登録はカメラ撮影で行えます。詳しくはWEBサイトの説明をご確認ください。',
          '＊検査受付番号のご登録がいただけない場合、検査を実施することができません。必ずご登録をお願いいたします。\n＊QRコードは株式会社デンソーウェーブの登録商標です。',
          '【マイページＵＲＬ】\n  https://floracheck.meiji.co.jp/my-page',
        ],
        id: 1,
        section: '6-1',
        title: '検査受付番号が分かりません。 検査受付番号とは何ですか？',
      },
      // ! section 6-2
      {
        content: [
          '採便容器に貼付されているQRコードを光の反射に注意していただき、なるべく正面から撮影してください。\n撮影された写真で検査キットを読み込めない場合は、お手数ですが、採便容器添付のQRコード下部にある14桁の数字を直接ご入力ください。',
          '＊QRコードは株式会社デンソーウェーブの登録商標です。',
        ],
        id: 2,
        section: '6-2',
        title: '検査受付番号を読み込みできません。どうすればよいですか？',
      },
      // ! section 6-3
      {
        content: [
          '採取した便検体をポストに投函するまでに、マイページ上で検査受付番号のご登録をお願いします。',
          '＊ご登録がいただけないと検査結果をご覧いただくことができません。',
        ],
        id: 3,
        section: '6-3',
        title: 'いつまでにマイページで検査受付番号の登録を行えばよいですか？',
      },
      // ! section 6-4
      {
        content: [
          '採便による腸内フローラ検査結果と生活習慣アンケートを基に検査レポートを作成いたします。\nまた将来的には、より充実したサービスを提供するための分析に活用させていただく可能性がございます。',
          '生活習慣アンケートにご回答いただけない場合、検査結果をご覧いただくことができません。',
          '【マイページＵＲＬ】\n  https://floracheck.meiji.co.jp/my-page',
        ],
        id: 4,
        section: '6-4',
        title: '生活習慣アンケートは何に使いますか？',
      },
      // ! section 6-5
      {
        content: [
          '採取した便検体をポストに投函するまでには必ずマイページから生活習慣アンケートに回答いただくようお願いいたします。',
          '※ご回答いただけない場合、検査結果をご覧いただくことができません。',
          '【マイページＵＲＬ】\n  https://floracheck.meiji.co.jp/my-page',
        ],
        id: 5,
        section: '6-5',
        title: '生活習慣アンケートへの回答はいつまでに行えばよいですか？',
      },
      // ! section 6-6
      {
        content: [
          '回答を途中でやめてもそれまでに回答したデータは残ります。\n再開するときにマイページにアクセスすると、途中からアンケートにご回答いただくことが可能です。',
          '【マイページＵＲＬ】\n  https://floracheck.meiji.co.jp/my-page',
        ],
        id: 6,
        section: '6-6',
        title: '生活習慣アンケートへの回答を途中でやめた場合どうなりますか？',
      },
      // ! section 6-7
      {
        content: [
          '採便当日に生活習慣アンケートすべての設問にご回答お願いいたします。\nご回答いただけない場合、検査結果をご覧いただくことができません。',
          '【マイページＵＲＬ】\n  https://floracheck.meiji.co.jp/my-page',
        ],
        id: 7,
        section: '6-7',
        title: '生活習慣アンケートはすべて回答しなければならないですか？',
      },
      // ! section 6-8
      {
        content: [
          '腸内フローラのエサとは、小腸で吸収されずに、大腸まで届き、大腸にいる腸内細菌が発酵を行う際のエネルギー源となる食物繊維（特に全粒穀物などに多く含まれる）やオリゴ糖を意味します。',
        ],
        id: 8,
        section: '6-8',
        title:
          '生活習慣アンケート内の「腸内フローラのエサ」とは何を指しますか？',
      },
      // ! section 6-9
      {
        content: [
          '腹部を刺激するようなエクササイズや体操のことで、腸の健康のために大股でのウォーキングや腰をひねるストレッチ、足を上げ腹筋などの運動を指します。',
        ],
        id: 9,
        section: '6-9',
        title:
          '生活習慣アンケート内の「腸の筋肉を刺激する適度な運動」とはどのようなものですか？',
      },
    ],
    id: 6,
  },
  // * section 7
  {
    categoryTitle: '投函について',
    children: [
      // ! section 7-1
      {
        content: [
          '採便したら返送用封筒に入れて1週間以内に郵便ポストに投函してください。',
          '＊投函前に「検査受付番号登録」「生活習慣アンケート記入」を必ず完了してください。完了しないと検査結果が閲覧できません。',
        ],
        id: 1,
        section: '7-1',
        title: '便検体はいつどこに提出すればよいですか？',
      },
      // ! section 7-2
      {
        content: [
          '便の持ち運びや保管については、高温多湿を避けた常温で保存いただければ、問題ございません。\n採便から1週間以内に郵便ポストに投函してください。',
        ],
        id: 2,
        section: '7-2',
        title:
          '採便してから郵送するまでに、持ち運びや保管で気を付けることはありますか？',
      },
      // ! section 7-3
      {
        content: [
          '返送用封筒には切手を貼らずそのまま郵便ポストに投函してください。',
        ],
        id: 3,
        section: '7-3',
        title: '返送用封筒に切手は必要ですか？',
      },
      // ! section 7-4
      {
        content: ['土日祝日に投函いただいても構いません。'],
        id: 4,
        section: '7-4',
        title: '土日祝日に投函してもよいですか？',
      },
      // ! section 7-5
      {
        content: [
          'お気づき次第、早急に送ってください。\n可能な範囲で検査結果をお届けします。',
        ],
        id: 5,
        section: '7-5',
        title:
          '便を採取してから1週間以上たってしまいました。どうすればよいですか？',
      },
      // ! section 7-6
      {
        content: [
          '採便容器はしっかりふたを閉めて、吸水シートと一緒にチャック付きビニール袋に入れてから、返送用封筒に入れてください。\n詳しくは、マイページの検査の手順をご確認ください。',
          '【マイページＵＲＬ】\n  https://floracheck.meiji.co.jp/my-page',
        ],
        id: 6,
        section: '7-6',
        title: '採便済容器はそのまま返送用封筒に入れてよいですか？',
      },
    ],
    id: 7,
  },
  // * section 8
  {
    categoryTitle: '腸内フローラ検査結果について',
    children: [
      // ! section 8-1
      {
        content: [
          '当社の委託先のH.U.グループ中央研究所にて測定されます。なお、H.U.グループ中央研究所へは匿名化されたサンプルのみが渡されますので、個人情報は保護されています。',
        ],
        id: 1,
        section: '8-1',
        title: '便検体はどこで検査されますか？',
      },
      // ! section 8-2
      {
        content: [
          '本検査は、生活習慣の見直しのきっかけとする検査で、病気の有無や重さを診断するものではありません。',
        ],
        id: 2,
        section: '8-2',
        title: '疾患のリスクが分かるでしょうか？',
      },
      // ! section 8-3
      {
        content: [
          '検査結果は便検体を郵便ポストに投函後、2-4週間程でWEBサービスのマイページにて閲覧ができます。\n検査結果が出ましたら、明治インナーガーデン事務局よりお客様にご連絡いたします。',
          '【マイページＵＲＬ】\n  https://floracheck.meiji.co.jp/my-page',
        ],
        id: 3,
        section: '8-3',
        title: '検査結果はいつどこで見れますか？',
      },
      // ! section 8-4
      {
        content: [
          '本検査は、生活習慣改善を意識付けるために実施しております。病気の診断を行うものではありませんが、ご心配な場合はかかりつけの医師へご相談ください。',
        ],
        id: 4,
        section: '8-4',
        title: '検査結果が悪かった場合、再検査や医師の診療等が必要ですか？',
      },
      // ! section 8-5
      {
        content: [
          '検査結果は唾液検体を郵便ポストに投函後、2-4週間程でWEBサービスのマイページにて閲覧ができます。\n検査結果が出ましたら、お客様にメールにてご連絡いたします。',
        ],
        id: 5,
        section: '8-5',
        title: '検査結果が届くまで、どのくらいの日数がかかりますか？',
      },
      // ! section 8-6
      {
        content: [
          '便検体投函後、2-4週間程でWEBサービスのマイページにて閲覧が可能となります。\n検査結果が届かない場合は、明治会員サポートセンターまでお問い合わせください。',
          '【明治会員サポートセンター】\n  https://id.meiji.co.jp/contact/input',
        ],
        id: 6,
        section: '8-6',
        title: '検査結果が届きません。',
      },
      // ! section 8-7
      {
        content: [
          '糞便量の不足、食べ物かすなどの異物の混入等により正しく測定が出来ず、タイプ分けの判定が出来ない場合は「測定不可」となります。',
        ],
        id: 7,
        section: '8-7',
        title:
          '腸内フローラのタイプが記載されていませんが、何かの間違いでしょうか？',
      },
      // ! section 8-8
      {
        content: ['レポートの閲覧期限は無期限となります。'],
        id: 8,
        section: '8-8',
        title: '検査結果を閲覧できる期限はありますか？',
      },
      // ! section 8-9
      {
        content: [
          '測定不可は測定した結果タイプ分けが困難だった場合に表示されます。\nもう一度測定したい場合は、腸内フローラ検査をご購入いただく必要がございます。',
          '【明治会員サポートセンター】\n  https://id.meiji.co.jp/contact/input',
        ],
        id: 9,
        section: '8-9',
        title: '測定不可が出ましたがもう一度測定できますか？',
      },
    ],
    id: 8,
  },
  // * section 9
  {
    categoryTitle:
      '腸内フローラ検査と商品(飲料)セットの申込・配送・キャンセルについて',
    children: [
      // ! section 9-1
      {
        content: [
          'インナーガーデンのＴＯＰページ(https://floracheck.meiji.co.jp/) より進んでいただき、明治会員IDの登録を行っていただきます。その後、商品ページにて「腸内フローラ検査とパーソナルココア飲料のセット(約1か月分)」をお申し込みいただけます。',
          '＊腸内フローラ検査とパーソナルココア飲料のセット申し込みは初回限定となります。',
        ],
        id: 1,
        section: '9-1',
        title:
          'どのように腸内フローラ検査と商品(飲料)のセットを申し込めばいいかわかりません。',
      },
      // ! section 9-2
      {
        content: [
          'インナーガーデンのＴＯＰページ(https://floracheck.meiji.co.jp/) より進んでいただき、明治会員IDの登録を行っていただきます。その後、商品ページにて「腸内フローラ検査とパーソナルココア飲料のセット(約1か月分)」をお申し込みいただけます。',
          'なお、腸内フローラ検査とパーソナルココア飲料のセット申し込みは初回限定となりますので、2回目以降の購入の方はお申込みいただけません。',
        ],
        id: 2,
        section: '9-2',
        title: '腸内フローラ検査と商品(飲料)のセットが申し込めません。',
      },
      // ! section 9-3
      {
        content: [
          'クレジットカード（JCB、Visa、Mastercard、AMEX等）がご利用いただけます。',
        ],
        id: 3,
        section: '9-3',
        title:
          '腸内フローラ検査と商品(飲料)のセットの申し込みにはどのような決済方法がありますか？',
      },
      // ! section 9-4
      {
        content: [
          '初回申し込みの方に限り、腸内フローラ検査とパーソナルココア飲料をセットで12,000円(税込)でご購入いただけます。送料は無料です。',
          '詳しくは商品一覧ページをご覧ください。',
          '【商品一覧ページ】\n  https://floracheck.meiji.co.jp/products',
        ],
        id: 4,
        section: '9-4',
        title:
          '腸内フローラ検査と商品(飲料)のセットの申し込みにはいくらかかりますか？',
      },
      // ! section 9-5
      {
        content: [
          'お客様の腸内タイプに合った素材の入った商品をお届けします。\nその素材は、腸内で短鎖脂肪酸を増やすと言われています。',
        ],
        id: 5,
        section: '9-5',
        title:
          '検査と商品(飲料)のセットの場合、検査後にはどのような商品が届きますか？',
      },
      // ! section 9-6
      {
        content: [
          '2回目以降のご購入のお客様は商品1か月分、または定期購買コースのご購入が可能です。\n詳しくは商品ページをご確認ください。',
          '【商品一覧ページ】\n  https://floracheck.meiji.co.jp/products',
        ],
        id: 6,
        section: '9-6',
        title:
          '商品(飲料)を飲み切った後に再度購入するときにはどうすればいいでしょうか？',
      },
      // ! section 9-7
      {
        content: [
          'ほとんどの日本人が保有しているバクテロイデスに適した素材の入った商品をお届けします。',
          '再検査をご希望の方は、腸内フローラ検査単品をお申し込みください。',
        ],
        id: 7,
        section: '9-7',
        title:
          '腸内フローラ検査と商品(飲料)のセットにおいて、検査結果が測定不可だった場合に商品は届きますか？',
      },
      // ! section 9-8
      {
        content: [
          '申込後8日以内であれば、弊社から送付の検査キットが未開封の場合に限り、返品・返金を承ります。\n検査申込後9日以降、および検査キット開封後の返品・返金は、当社の責に帰すべき事由がある場合を除き、商品も含めてお受けいたしかねます。',
          '返品・返金については、明治会員サポートセンターまでお問合せください。お客様のご都合による返品の際の送料は、お客様のご負担とさせて頂きます。',
          '【明治会員サポートセンター】\n  https://id.meiji.co.jp/contact/input',
        ],
        id: 8,
        section: '9-8',
        title:
          '腸内フローラ検査と商品(飲料)のセットの返品・返金はできますか？ ',
      },
      // ! section 9-9
      {
        content: [
          '原則として、検査完了後の商品の返品・返金は、当社の責に帰すべき事由がある場合を除き、お受けいたしかねます。\nご不明な点がございましたら、明治会員サポートセンターまでお問合せください。',
          '【明治会員サポートセンター】\n  https://id.meiji.co.jp/contact/input',
        ],
        id: 9,
        section: '9-9',
        title:
          '腸内フローラ検査と商品(飲料)のセットにおいて、検査完了後に商品の返品・返金はできますか？',
      },
    ],
    id: 9,
  },
  // * section 10
  {
    categoryTitle: '商品(飲料)の申込・配送・キャンセルについて',
    children: [
      // ! section 10-1
      {
        content: [
          '明治会員サポートセンターまでお問合せください。調査次第ご回答させていただきます。',
          '【明治会員サポートセンター】\n  https://id.meiji.co.jp/contact/input',
        ],
        id: 1,
        section: '10-1',
        title: '商品(飲料)が届きません。',
      },
      // ! section 10-2
      {
        content: [
          '商品申し込み後、通常２～３日でお届けします。\nまた、検査サービスと飲料のセットメニューをご購入の場合は、検査結果をお届け後、通常２～３日ででお届けします。',
          '1週間以上たっても届かない場合は明治会員サポートセンターまでお問合せください。',
          '【明治会員サポートセンター】\n  https://id.meiji.co.jp/contact/input',
        ],
        id: 2,
        section: '10-2',
        title: '申し込みから何日くらいで商品(飲料)が届きますか ？',
      },
      // ! section 10-3
      {
        content: [
          'インナーガーデンのＴＯＰページ(https://floracheck.meiji.co.jp/) より進んでいただき、明治会員IDの登録を行っていただきます。その後、商品ページにて「パーソナルココア飲料」をご購入いただけます。',
          '＊商品(パーソナルココア飲料)単品、および定期購買は2回目以降からご購入できます。',
        ],
        id: 3,
        section: '10-3',
        title: 'どのように商品(飲料)を購入すればいいかわかりません。',
      },
      // ! section 10-4
      {
        content: [
          'クレジットカード（JCB、Visa、Mastercard、AMEX等）がご利用いただけます。',
        ],
        id: 4,
        section: '10-4',
        title: '商品(飲料)の申し込みにはどのような決済方法がありますか？',
      },
      // ! section 10-5
      {
        content: ['インナーガーデンは現在国内のみのご提供となっています。'],
        id: 5,
        section: '10-5',
        title: '商品(飲料)の海外への発送は行っていますか？',
      },
      // ! section 10-6
      {
        content: [
          '商品(パーソナルココア飲料)の定期購買は可能です。\n税抜価格で11%引きでご購入可能です。',
          'インナーガーデンのTOPページ(https://floracheck.meiji.co.jp/) より進んでいただき、明治会員IDの登録を行っていただきます。',
          'その後、商品ページにて「パーソナルココア飲料(定期購買)」をお申し込みいただけます。',
          '【商品一覧ページ】\n  https://floracheck.meiji.co.jp/products',
        ],
        id: 6,
        section: '10-6',
        title: '商品(飲料)の定期購買はできますか？ ',
      },
      // ! section 10-7
      {
        content: [
          'お客様の長期不在、指定されたお届け先情報の不備等、お客様の都合により商品の引渡しができない場合は、再配送料、その他の費用を実費で請求させていただく場合があります。',
          'なお、複数回に渡り商品の引渡しができない状況が継続する場合、当社は、商品の出荷を拒否し、ご注文をキャンセルできるものとします。',
        ],
        id: 7,
        section: '10-7',
        title: '商品(飲料)が一度配送されたが受け取れませんでした。',
      },
      // ! section 10-8
      {
        content: [
          '申込後8日以内であれば、弊社から送付の商品が入っている段ボールが未開封の場合に限り、返品・返金を承ります。\n検査申込後9日以降、および商品が入っている段ボール開封後の返品・返金は、当社の責に帰すべき事由がある場合を除き、商品も含めてお受けいたしかねます。',
          '返品・返金については、明治会員サポートセンターまでお問合せください。お客様のご都合による返品の際の送料は、お客様のご負担とさせて頂きます。',
          '【明治会員サポートセンター】\n  https://id.meiji.co.jp/contact/input',
        ],
        id: 8,
        section: '10-8',
        title: '商品(飲料)の返品・返金はできますか？',
      },
      // ! section 10-9
      {
        content: [
          'インナーガーデンのTOPページ(https://floracheck.meiji.co.jp/) より進んでいただき、明治会員IDの登録を行っていただきます。その後、商品ページにて「パーソナルココア飲料」をお申し込みいただけます。',
          'なお、パーソナルココア飲料は2回目以降のご購入の方のみお申込みいただけます。',
        ],
        id: 9,
        section: '10-9',
        title: '商品(飲料)の単品・定期購買の注文ができません。',
      },
      // ! section 10-10
      {
        content: [
          '本島は一律で650円(税別)となります。\n離島にお住いの場合は、購入画面にて配送料をご確認ください。',
          'なお、腸内フローラ検査＋パーソナルココア飲料(約一か月分)のセット、腸内フローラ検査単品は配送料はかかりません。',
        ],
        id: 10,
        section: '10-10',
        title: '商品(飲料)の配送料はいくらですか？ ',
      },
      // ! section 10-11
      {
        content: [
          '腸内には様々な菌が住んでいますが、菌によって好きな栄養源が異なります。腸内細菌がそれらの栄養源を摂取するとカラダに良い成分とされる短鎖脂肪酸が産生されます。\nインナーガーデンは、腸内の菌を測定してお客様の腸内タイプを5タイプに分類し、それぞれの腸内タイプ別に最適な素材の入った商品を提供します。',
        ],
        id: 11,
        section: '10-11',
        title: '腸内タイプに最適な商品(飲料)とは何でしょうか？',
      },
      // ! section 10-12
      {
        content: [
          'インナーガーデンはお客様の腸内タイプを特定して、短鎖脂肪酸を体内産生しやすい最適な素材を提供するサービスとなります。\nよって、お客様の腸内タイプと異なる商品を提供することはできません。',
          '将来的には、特定の菌を増やすために、お客様が特定の素材の入った商品を選択できるサービスをご提供する可能性があります。',
        ],
        id: 12,
        section: '10-12',
        title: '自分の腸内タイプと異なる商品(飲料)を購入できますか？',
      },
      // ! section 10-13
      {
        content: [
          'インナーガーデン購入履歴(https://floracheck.meiji.co.jp/purchase-history)にアクセスしていただき、最新の定期購入履歴の「定期購入マイぺージへ」ボタンをクリックしてください。',
          '定期購入マイページに進みましたら、「定期購買の解約」ボタンを押してください。',
          '定期購入の解約の期日は次回配送分の支払予定日の7日前までとなります。\n※初回定期購入日付が毎月の支払予定日となります。',
        ],
        id: 13,
        section: '10-13',
        title: '定期購入をキャンセル・停止するにはどうすればいいですか？',
      },
      // ! section 10-14
      {
        content: [
          'インナーガーデン購入履歴(https://floracheck.meiji.co.jp/purchase-history)にアクセスしていただき、最新の定期購入履歴の「定期購入マイぺージへ」ボタンをクリックしてください。',
          '定期購入マイページに進みましたら、「スキップ」ボタンを押してください。',
          '定期購入のスキップの期日は次回配送分の支払予定日の7日前までとなります。\n※初回定期購入日付が毎月の支払予定日となります。',
        ],
        id: 14,
        section: '10-14',
        title: '定期購入をスキップするにはどうすればいいですか？',
      },
      // ! section 10-15
      {
        content: [
          'インナーガーデンは、本サービスの腸内フローラ検査結果に基づいて商品をご提供します。\n他社の腸内フローラ検査結果をもとに商品をご購入できません。',
        ],
        id: 15,
        section: '10-15',
        title:
          '他社の腸内フローラ検査の結果をもとに商品(飲料)を購入できますか？',
      },
      // ! section 10-16
      {
        content: [
          '定期購入中に検査を受けて腸内タイプが変わった場合、商品(飲料)の定期購入を一度解約していただき、再度購入していただく必要があります。',
          '定期購入の解約は下記ページをご参照ください。\nhttps://floracheck.meiji.co.jp/purchase-history',
        ],
        id: 16,
        section: '10-16',
        title:
          '定期購入中に検査を受けて腸内タイプが変わった場合、商品(飲料)も変わりますか？',
      },
      // ! section 10-17
      {
        content: [
          '過去に検査を受けてタイプが判明している方は、過去のタイプに最適な素材が入った商品をお届けします。',
        ],
        id: 17,
        section: '10-17',
        title:
          '定期購入中に検査を受けて測定不可だった場合は、どの商品(飲料)が届きますか？',
      },
      // ! section 10-18
      {
        content: [
          'ログイン後、下記手順に沿って画面を操作し変更を行ってください。',
        ],
        id: 18,
        section: '10-18',
        subContent: [
          {
            applyNumber: 1,
            content: [
              'インナーガーデンTOPページの画面右上のユーザーアイコンをタップ',
              '表示されたリストより「購入履歴」リンクを押下',
              '購入履歴画面にて定期購入お申込み中の商品下部にある「定期購入マイページへ」を押下',
              '定期購入マイページ画面右側にある配送住所エリア内にある「編集」ボタンを押下',
              'その後、変更希望のお届け先を各項目に従って入力し、画面下部にある「更新」ボタンを押下',
            ],
          },
        ],
        title:
          '定期購入時に登録している送付先住所が変更になった場合、どうすればよいですか？',
      },
    ],
    id: 10,
  },
  // * section 11
  {
    categoryTitle: '商品(飲料)について',
    children: [
      // ! section 11-1
      {
        content: [
          '大変申し訳ございません。\n明治会員サポートセンターまでお問合せください。\n商品を再送させていただきます。',
          '【明治会員サポートセンター】\n  https://id.meiji.co.jp/contact/input',
        ],
        id: 1,
        section: '11-1',
        title: ' 届いた商品(飲料)の数が不足しています。',
      },
      // ! section 11-2
      {
        content: [
          '常温でもお飲みいただけますが、冷やしてお飲みいただくことをお勧めします。',
        ],
        id: 2,
        section: '11-2',
        title: '商品(飲料)をどのように飲めばよいですか？',
      },
      // ! section 11-3
      {
        content: [
          '容器が破損する場合があるため、紙パックでの加温はお止めください。\n別の容器であたためてからお飲みください。',
        ],
        id: 3,
        section: '11-3',
        title: 'あたためて飲んでもいいですか？',
      },
      // ! section 11-4
      {
        content: [
          '開封前は、常温で保存いただけます。開封後は早めにお飲みください。',
        ],
        id: 4,
        section: '11-4',
        title: '商品(飲料)の保存方法を教えてください。',
      },
      // ! section 11-5
      {
        content: ['一日一本を目安にお飲みください。'],
        id: 5,
        section: '11-5',
        title: '商品(飲料)は1日何本まで飲んだらいいですか？',
      },
      // ! section 11-6
      {
        content: ['一日一本を目安にお飲みください。'],
        id: 6,
        section: '11-6',
        title: '商品(飲料)はどのくらいの頻度で飲めばいいですか？',
      },
      // ! section 11-7
      {
        content: [
          '商品の賞味期限は出荷時に90日以上の商品が届きます。\n詳しくは、段ボール、または商品に記載されている賞味期限をご確認ください。',
        ],
        id: 7,
        section: '11-7',
        title: '商品(飲料)の賞味期限はありますか？',
      },
      // ! section 11-8
      {
        content: ['余った場合は、破棄して頂きますようお願い致します。'],
        id: 8,
        section: '11-8',
        title: '商品(飲料)を飲みきれなかった時はどうすればよいですか？',
      },
      // ! section 11-9
      {
        content: [
          '一日一本を目安にお飲みいただくことをおすすめしますが、間隔が空いても問題ございません。',
        ],
        id: 9,
        section: '11-9',
        title: '商品(飲料)を飲み忘れた場合はどうしたらよいですか？',
      },
      // ! section 11-10
      {
        content: [
          '大変申し訳ございません。\n明治会員サポートセンターまでお問合せください。確認の上、ご連絡させていただきます。',
          '【明治会員サポートセンター】\n  https://id.meiji.co.jp/contact/input',
        ],
        id: 10,
        section: '11-10',
        title: '商品(飲料)に不良が見つかった場合はどうすればいいですか？',
      },
      // ! section 11-11
      {
        content: [
          '自分のタイプと異なる商品を飲んでも安全性には問題ございません。\nインナーガーデンはお客様の腸内タイプを特定して、短鎖脂肪酸を体内産生しやすい最適な素材を提供するサービスとなりますので、ぜひご自身のタイプに合った商品をお試しください。',
        ],
        id: 11,
        section: '11-11',
        title: '自分のタイプと違う商品(飲料)を飲んでも大丈夫ですか？',
      },
      // ! section 11-12
      {
        content: [
          '自分のタイプと異なる商品を飲んでも安全性には問題ございません。\nインナーガーデンはお客様の腸内タイプを特定して、短鎖脂肪酸を体内産生しやすい最適な素材を提供するサービスとなりますので、ぜひその方のタイプに合った商品をお試しください。',
        ],
        id: 12,
        section: '11-12',
        title: '自分以外の誰かが飲んでしまっても問題ないですか？',
      },
      // ! section 11-13
      {
        content: [
          'アレルギー物質として乳成分が含まれます。\n詳しくは、商品詳細情報をご確認ください。',
          '【商品詳細ページ】\n  https://floracheck.meiji.co.jp/products/allergy-ingredient',
        ],
        id: 13,
        section: '11-13',
        title: '商品(飲料)にアレルギー物質は含まれていますか？',
      },
      // ! section 11-14
      {
        content: [
          '一般的なココア飲料となりますので問題はございませんが、気になる場合はかかりつけのお医者様にご相談ください。',
        ],
        id: 14,
        section: '11-14',
        title: '持病がある場合でも飲んでもいいですか？',
      },
      // ! section 11-15
      {
        content: [
          '摂取して数日間は腸内環境が変わりやすくなるため、おなかの調子が変化する可能性がございます。',
          '量や頻度をご調整いただきながら続けることをおすすめします。 気になる場合は、かかりつけのお医者様にご相談ください。',
          'ご不明な点がございましたら、明治会員サポートセンターまでお問合せください。',
          '【明治会員サポートセンター】\n  https://id.meiji.co.jp/contact/input',
        ],
        id: 15,
        section: '11-15',
        title: '商品(飲料)を飲んで数日でおなかの調子が悪くなりました。',
      },
      // ! section 11-16
      {
        content: [
          'いつ飲んでも構いません。\n一日一本の飲用をおすすめしております。',
        ],
        id: 16,
        section: '11-16',
        title: '1日の間でいつ飲めばいいですか？',
      },
      // ! section 11-17
      {
        content: [
          '一般的なココア飲料となりますので問題はございませんが、気になる場合はかかりつけのお医者様にご相談ください。',
        ],
        id: 17,
        section: '11-17',
        title: '子供に飲ませても大丈夫ですか？',
      },
      // ! section 11-18
      {
        content: [
          '詳しくは商品詳細情報をご確認ください。',
          '【商品詳細ページ】\n  https://floracheck.meiji.co.jp/products/allergy-ingredient',
        ],
        id: 18,
        section: '11-18',
        title: '入っている成分・原料の詳細が知りたいです。',
      },
      // ! section 11-19
      {
        content: [
          '本商品は腸内タイプ別に合わせて、短鎖脂肪酸が産生しやすい素材を配合した飲料であり、効果を保証したものではありません。',
        ],
        id: 19,
        section: '11-19',
        title: '商品(飲料)を摂取してどの程度の効果があるでしょうか？',
      },
      // ! section 11-20
      {
        content: [
          '腸内環境の変化に関しては個人差があるため明確な期間はお答えできませんが、腸内環境は生活習慣によって変化するため、継続して摂取し続けることを推奨いたします。',
        ],
        id: 20,
        section: '11-20',
        title: '商品(飲料)をどのくらいの期間飲み続ければよいですか？',
      },
      // ! section 11-21
      {
        content: [
          '本商品は科学的根拠に基づき、短鎖脂肪酸が産生しやすい素材を配合した飲料です。\n効果を保証したものではありません。',
        ],
        id: 21,
        section: '11-21',
        title:
          '商品(飲料)の機能に関する論文や医学的根拠などのエビデンスはありますか？',
      },
      // ! section 11-22
      {
        content: [
          '本商品は一般的なココア飲料をベースに、腸内タイプ別に合わせて短鎖脂肪酸が産生しやすい素材を配合しています。',
          '風味は一般的なココア飲料と大きな違いはありません。一方で、商品は効果を保証しているものではありません。',
        ],
        id: 22,
        section: '11-22',
        title: '商品(飲料)が体質に合わないことはありますか？',
      },
      // ! section 11-23
      {
        content: ['本商品は乳酸菌を使用しておりません。'],
        id: 23,
        section: '11-23',
        title: '商品(飲料)に乳酸菌は使用されていますか？',
      },
    ],
    id: 11,
  },
  // * section 12
  {
    categoryTitle: 'その他',
    children: [
      // ! section 12-1
      {
        content: [
          '腸内には、数十兆個の腸内細菌が菌種毎に塊となって腸の壁に隙間なくびっしりと張り付いてます。その状態が、お花畑（＝flora)に見えることから腸内フローラと呼ばれています。その腸内フローラのバランスや働きが、私たちの健康や病気に関わっていることが近年の研究でわかってきています。',
        ],
        id: 1,
        section: '12-1',
        title: '腸内フローラとは何ですか？',
      },
      // ! section 12-2
      {
        content: [
          '下記ブラウザにてご利用お願いします。',
          'iOS: Mobile Safari（最新バージョン）\nAndroid: Chrome（最新バージョン）',
          'また、スマートフォンのOSは最新バージョンにてご利用お願いいたします。',
        ],
        id: 2,
        section: '12-2',
        title: '推奨環境を教えてください。',
      },
      // ! section 12-3
      {
        content: [
          'お薬を服用中でも、検査は可能です。普段通りお薬は服用してください。',
        ],
        id: 3,
        section: '12-3',
        title: '薬を服用していますが、検査は可能でしょうか？',
      },
      // ! section 12-4
      {
        content: ['持病をお持ちの方でも、検査は可能です。'],
        id: 4,
        section: '12-4',
        title: '持病があるのですが、検査は可能でしょうか？',
      },
      // ! section 12-5
      {
        content: ['妊娠中、授乳中でも、検査は可能です。'],
        id: 5,
        section: '12-5',
        title: '妊娠中、授乳中でも検査は可能でしょうか？',
      },
      // ! section 12-6
      {
        content: [
          '検査自体は可能です。\n下痢などの症状が無いときの腸内フローラの状態を知りたい場合は、症状がおさまってからの検査をお勧めいたします。',
        ],
        id: 6,
        section: '12-6',
        title: '下痢をしていますが、検査をしても問題ありませんか？',
      },
      // ! section 12-7
      {
        content: [
          '抗生物質は腸内フローラに影響を与えますので、取っていない状態と異なる結果が出ることをご了承ください。',
        ],
        id: 7,
        section: '12-7',
        title: '抗生物質を取っていても問題ないですか？',
      },
      // ! section 12-8
      {
        content: ['18歳以上の方が検査を受けられます。'],
        id: 8,
        section: '12-8',
        title: '子どもでも腸内フローラチェックサービスを受けられますか？ ',
      },
      //  ! section 12-9
      {
        content: [
          '原則として、申し込みから6か月を過ぎて便を郵送された場合は検査結果が出ません。あらかじめご了承ください。',
        ],
        id: 9,
        section: '12-9',
        title:
          '申し込みから6ヵ月過ぎてから便を郵送してしまった場合、どうなりますか？',
      },
    ],
    id: 12,
  },
];
