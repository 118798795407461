import { Grid } from '@mui/material';

import { TOP_PAGE } from '@/constants';
import { SectionTitle } from '@/view/components/TopPage/SectionTitle';
import { STYLES, LINE_HEIGHTS } from '@/view/styling/theme';

const CONSTANTS = TOP_PAGE.CAUTION;

export function Caution() {
  return (
    <Grid container sx={STYLES.topPage.caution.grid}>
      <SectionTitle title={'Caution'} subtitle={CONSTANTS.HEADER} />
      <ol style={{ lineHeight: LINE_HEIGHTS.xxl }}>
        {CONSTANTS.TEXT.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ol>
    </Grid>
  );
}
