export function isOnlyNumber(text: string) {
  const regex = /^\d*$/;
  return regex.test(text);
}

export function isEmailAddress(text: string) {
  const regex =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return regex.test(text);
}

export function isPostCode(text: string) {
  const regex = /^\d{7}$/;
  return regex.test(text);
}

export function isPhoneNumber(text: string) {
  const regex = /^0[\d]{9,10}$/;
  return regex.test(text);
}

export function isValidLength(
  text: string,
  options: number | { max?: number; min?: number }
) {
  const isNumber = typeof options === 'number';
  const min = isNumber ? options : options?.min || 0;
  const max = isNumber ? options : options?.max || '';
  // We can assume this regex is safe because max and min are defined by us.
  // eslint-disable-next-line security-node/non-literal-reg-expr
  const regex = new RegExp(`^.{${min},${max}}$`);
  return regex.test(text);
}

/**
 * Calculate check digit for 13 digit barcode
 *
 * @remarks - https://www.gs1jp.org/code/jan/check_digit.html
 * @param text - 13 digit number in string
 * @returns - check digit
 */
export function getBarcodeCheckDigit(text: string): number {
  const regex = /^\d{13}$/;
  if (!regex.test(text)) {
    throw new Error('value must match to /^\\d{13}$/');
  }
  let factor = 3;
  let sum = 0;
  for (let i = text.length; i > 0; --i) {
    sum = sum + Number(text.substring(i - 1, i)) * factor;
    factor = 4 - factor;
  }
  return (1000 - sum) % 10;
}

/**
 * Check if valid test kit id
 *
 * @param text - 14 digit number in string
 * @returns - boolean value
 */
export function isMeijiTestBarcode(text: string): boolean {
  const regex = /^\d{14}$/;
  if (!regex.test(text)) {
    return false;
  }
  const beforeCheckDigit = text.slice(0, 13);
  const checkDigit = getBarcodeCheckDigit(beforeCheckDigit);
  return text === `${beforeCheckDigit}${checkDigit}`;
}
