import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  IconButton,
  Box,
  Divider,
} from '@mui/material';
import React, { useState } from 'react';

import { FONT_SIZES, LINE_HEIGHTS } from '@/view/styling/theme';

interface Challenge {
  description: string;
  id: number;
  image: string;
  title: string;
}

interface ChallengeAccordionProps {
  categoryTitle: string;
  challenges: Challenge[];
}

const expandCloseIconProps = {
  border: 1,
  borderColor: 'grey.100',
  borderRadius: '3px',
  color: 'black',
  fontSize: FONT_SIZES.xl,
};

export const ChallengeAccordion: React.FC<ChallengeAccordionProps> = ({
  challenges,
  categoryTitle,
}) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box sx={{ backgroundColor: '#F3F5F7', padding: 0 }}>
      {challenges.map((challenge) => (
        <Accordion
          key={challenge.id}
          expanded={expanded === `panel${challenge.id}`}
          onChange={handleChange(`panel${challenge.id}`)}
          sx={{
            '&:before': { display: 'none' },
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: 'none',
            mb: 2,
            overflow: 'hidden',
          }}
        >
          <AccordionSummary
            aria-controls={`panel${challenge.id}bh-content`}
            id={`panel${challenge.id}bh-header`}
            sx={{
              alignItems: 'center',
              backgroundColor: 'white',
              borderRadius: '8px',
              m: 0,
              p: 0,
            }}
          >
            <Grid container alignItems="center" sx={{ m: 0 }}>
              {challenge.image && (
                <img
                  src={`/images/challenges/${challenge.image}`}
                  alt={challenge.title}
                  style={{
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    marginTop: '-22px',
                    width: '100%',
                  }}
                />
              )}
              <Divider sx={{ m: 0, width: '100%' }} />
              <Grid item xs={10} sx={{ pl: '16px' }}>
                <Typography
                  sx={{
                    color: 'grey.300',
                    display: 'block',
                    fontSize: FONT_SIZES.xs,
                    fontWeight: '600',
                    mt: '12px',
                  }}
                >
                  {`${categoryTitle} チャレンジ No.${challenge.id}`}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: FONT_SIZES.sm,
                    fontWeight: '600',
                    lineHeight: LINE_HEIGHTS.lg,
                    mt: '4px',
                  }}
                >
                  {challenge.title}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{ pr: '16px', pt: '12px', textAlign: 'right' }}
              >
                <IconButton disableRipple>
                  {expanded === `panel${challenge.id}` ? (
                    <RemoveIcon sx={expandCloseIconProps} />
                  ) : (
                    <AddIcon sx={expandCloseIconProps} />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px',
              p: '16px',
              pt: 0,
            }}
          >
            <Typography
              sx={{ fontSize: FONT_SIZES.sm, whiteSpace: 'pre-wrap' }}
            >
              {challenge.description}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
