import { Grid, Typography, Box, Card } from '@mui/material';

import { FONT_SIZES, STYLES } from '@/view/styling/theme';

type Props = {
  body1: string;
  body2: string;
  body3: string;
  imagePerson: string;
  title: string;
};

export function CustomerFeedbackCard({
  body1,
  body2,
  body3,
  imagePerson,
  title,
}: Props) {
  return (
    <Card
      sx={{
        borderRadius: 3,
        boxShadow: 'none',
        marginX: 2,
        marginY: 3,
        padding: '24px',
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        gap={2}
      >
        <Grid item xs={12} width="100%">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gap={2}
          >
            <Grid item xs={2} md={'auto'}>
              <Box>
                <img width="56px" height="56px" src={imagePerson} />
              </Box>
            </Grid>
            <Grid item xs={8} md={'auto'}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                gap={'4px'}
              >
                <Grid item xs={12} width="100%">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    gap={1}
                  >
                    <Grid item>
                      <Typography
                        component="h5"
                        sx={STYLES.topPage.voicesSectionTitle}
                      >
                        {title}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} width="100%">
                  <Typography
                    component="h3"
                    sx={{
                      ...STYLES.topPage.voicesSectionSubtitle,
                      fontSize: FONT_SIZES.md,
                    }}
                  >
                    {body1}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} width="100%">
          <Typography component="p" sx={STYLES.topPage.voicesSectionPageBody}>
            {body2}
          </Typography>
        </Grid>
        <Grid item xs={12} width="100%">
          <Typography component="p" sx={STYLES.topPage.voicesSectionPageBody}>
            {body3}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
