import { Box, Grid, Typography } from '@mui/material';

import { TOP_PAGE } from '@/constants';
import { SectionTitle } from '@/view/components/TopPage/SectionTitle';
import { STYLES } from '@/view/styling/theme';

const CONSTANTS = TOP_PAGE.REPORT;

export function Report() {
  return (
    <Box>
      <Grid container sx={STYLES.topPage.report.grid}>
        <Grid item>
          <SectionTitle title={'Report'} subtitle={CONSTANTS.TITLE} />
        </Grid>
        <Grid item container direction="column" rowSpacing={4}>
          <Grid container item rowSpacing={2}>
            <Grid item width="100%">
              <img
                style={{
                  width: '100%',
                }}
                src={CONSTANTS.BODY_IMAGE_1}
              />
            </Grid>
            <Grid item>
              <Typography sx={STYLES.topPage.report.description}>
                {CONSTANTS.CAPTION_1}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item rowSpacing={2}>
            <Grid item width="100%">
              <img width="60%" src={CONSTANTS.BODY_IMAGE_2} />
            </Grid>
            <Grid item>
              <Typography sx={STYLES.topPage.report.description}>
                {CONSTANTS.CAPTION_2}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container rowSpacing={2}>
            <Grid item width="100%">
              <img width="60%" src={CONSTANTS.BODY_IMAGE_3} />
            </Grid>
            <Grid item>
              <Typography sx={STYLES.topPage.report.description}>
                {CONSTANTS.CAPTION_3}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
