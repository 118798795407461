/* eslint-disable @typescript-eslint/naming-convention */
import { NormalizedAssessment } from '@/types/network';

/**
 * @returns the redirect url and if the user is new
 *
 * @param assessments - an array of assessments objects
 * @remarks
 * checks the users assessments to find the correct page to redirect to
 */
export function getUserAssessmentRoute(assessments: NormalizedAssessment[]) {
  return {
    isNew: false,
    url: '/my-page',
  };
}
