import { Grid, Typography, Divider } from '@mui/material';

import { defaultThemeColor, STYLES } from '@/view/styling/theme';

type Props = {
  children: React.ReactNode;
  subtitle: string;
  title: string;
};

export function StepItemCard({ children, title, subtitle }: Props) {
  return (
    <Grid
      container
      direction="column"
      gap={2}
      sx={{ ...STYLES.topPage.stepWhiteBoxWrapper }}
    >
      <Grid item xs={12} sx={{ pt: 2 }}>
        <Typography
          component="p"
          sx={{
            ...STYLES.topPage.stepSubSectionTitle,
            color: defaultThemeColor,
          }}
        >
          {title}
        </Typography>
        <Typography component="p" sx={STYLES.topPage.stepSubSectionTitle}>
          {subtitle}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ width: '100%' }}>
        <Divider />
      </Grid>
      {children}
    </Grid>
  );
}
