import {
  Container,
  Button,
  FormHelperText,
  FormLabel,
  Grid,
  useMediaQuery,
  OutlinedInput,
  Theme,
  Typography,
  Box,
} from '@mui/material';
import _ from 'lodash';

import {
  COLLECTION_PHASE_STEPPER,
  TEST_KIT_ADD_BARCODE_PAGE,
  TOP_PAGE,
} from '@/constants';
import * as user from '@/domain/middlewares/user';
import { store } from '@/domain/store';
import { State } from '@/types';
import { QRModal } from '@/view/components/QRModal';
import { SpeechBubble } from '@/view/components/SpeechBubble';
import { FormStepper } from '@/view/components/Stepper';
import { TextHighlight } from '@/view/components/TextHighlight';
import { FONT_SIZES, LINE_HEIGHTS } from '@/view/styling/theme';

const CONSTANTS = TOP_PAGE.INFORMATION;

export function AssessmentAddByScan({ state }: { state: State }) {
  const onSubmitAddAssessmentButton = _.debounce(
    _.partial(user.onSubmitAddAssessmentForm, store),
    500
  );
  const onScanOfBarcodeAssessment = _.partial(
    user.onScanOfBarcodeAssessment,
    store
  );
  const onAssessmentTestInput = _.partial(
    user.onChangeAddAssessmentInput,
    store
  );

  const isInputInvalid = !state.addAssessmentInput.isValid;
  const isInputEmpty = state.addAssessmentInput.value === '';

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <div>
      <FormStepper activeStep={0} steps={COLLECTION_PHASE_STEPPER} />
      <Container>
        {/* TODO will replace all blocks like this at once */}
        <Typography
          sx={{
            display: 'block',
            fontSize: FONT_SIZES.lg,
            fontWeight: 600,
            lineHeight: LINE_HEIGHTS.xl,
            textAlign: 'center',
            width: '100%',
          }}
          mb={4}
        >
          {state.currentRouteMeta?.title}
        </Typography>

        <QRModal
          onReadComplete={onScanOfBarcodeAssessment}
          triggerButton={
            <Button
              component="label"
              size="large"
              variant="contained"
              fullWidth
              tabIndex={-1}
              sx={{
                lineHeight: '21.44px',
                textAlign: 'center',
                whiteSpace: 'pre-wrap',
              }}
            >
              {isMobile ? (
                <span>
                  QRコードを撮影して
                  <br />
                  検査受付番号を読み込む
                </span>
              ) : (
                'QRコードを撮影して検査受付番号を読み込む'
              )}
            </Button>
          }
        />

        <Grid container gap={3} direction="column">
          <Grid item>
            <img
              src={TEST_KIT_ADD_BARCODE_PAGE.IMAGE}
              width="343px"
              height="124px"
              style={{ borderRadius: '12px', marginTop: '24px' }}
            />
          </Grid>
          <Grid item>
            <FormHelperText error={true}>
              {TEST_KIT_ADD_BARCODE_PAGE.SCAN_CAPTION}
            </FormHelperText>
          </Grid>
          <Grid item>
            <Grid container id="email" direction="column">
              <FormLabel>{TEST_KIT_ADD_BARCODE_PAGE.FORM_TITLE}</FormLabel>
              <OutlinedInput
                error={!state.addAssessmentInput.isValid}
                value={state.addAssessmentInput.value}
                onChange={onAssessmentTestInput}
                placeholder={TEST_KIT_ADD_BARCODE_PAGE.PLACEHOLDER}
                inputProps={{
                  'data-e2e': 'register-testkit__input',
                  inputMode: 'numeric',
                }}
              />
              {!state.addAssessmentInput.isValid && (
                <FormHelperText error={true}>
                  {state.addAssessmentInput.errorMessage}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Box sx={{ margin: '16px' }}>
              <SpeechBubble
                style={{
                  backgroundColor: '#F3F5F7',
                  borderRadius: '10px',
                  padding: '16px',
                  width: '100%',
                }}
              >
                <TextHighlight
                  commonTextStyle={{
                    fontSize: FONT_SIZES.sm,
                    fontWeight: 400,
                    lineHeight: LINE_HEIGHTS.md,
                  }}
                  text={TEST_KIT_ADD_BARCODE_PAGE.SPEECH_BUBBLE}
                />
              </SpeechBubble>
              <img
                style={{
                  height: 'auto',
                  marginTop: '-20px',
                  maxWidth: '290px',
                }}
                src={CONSTANTS.BODY_CAPTION_1_FOOTER.BODY_IMAGE_1}
              />
            </Box>
          </Grid>
          <Grid item>
            <Button
              onClick={onSubmitAddAssessmentButton}
              size="large"
              variant="contained"
              fullWidth
              disabled={isInputInvalid || isInputEmpty}
              data-e2e="register-testkit__submit-btn"
              data-gtm-link="input_testkitid"
            >
              {TEST_KIT_ADD_BARCODE_PAGE.SUBMIT_BUTTON}
            </Button>
          </Grid>
          <Grid item>
            <FormHelperText sx={{ color: 'grey.300' }}>
              {TEST_KIT_ADD_BARCODE_PAGE.HELPER_TEXT}
            </FormHelperText>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
