import { Button, Grid, Typography } from '@mui/material';

import { Mascot } from '@/view/components/Mascot';
import { OutlinedCard } from '@/view/components/OutlinedCard';
import { FONT_SIZES, LINE_HEIGHTS } from '@/view/styling/theme';

const titleStyle = {
  color: 'grey.300',
  display: 'block',
  fontSize: FONT_SIZES.lg,
  fontWeight: 600,
  textAlign: 'center',
};

const cardTextStyle = {
  color: 'grey.300',
};

type Props = {
  code: string;
  description: string;
  detailDescription: string;
  detailTitle: string;
  linkLabel: string;
  onClick: () => void;
  title: string;
};

export function ErrorCard({
  code,
  description,
  detailDescription,
  detailTitle,
  title,
  onClick,
  linkLabel,
}: Props) {
  return (
    <Grid container mt={1} spacing={4}>
      <Grid item xs={12}>
        <Typography sx={{ ...titleStyle, fontSize: 32 }}>{code}</Typography>
        <Typography sx={titleStyle}>{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          className="text-wrap"
          sx={{
            ...titleStyle,
            fontSize: FONT_SIZES.md,
            lineHeight: LINE_HEIGHTS.xxl,
          }}
        >
          {description}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Mascot variant="sorry" size={148} />
      </Grid>
      <Grid item xs={12}>
        <OutlinedCard
          type="info"
          color="grey.light"
          padding={3}
          borderRadius={3}
          coloredTitle={
            <Typography sx={cardTextStyle}>
              <strong>{detailTitle}</strong>
            </Typography>
          }
          content={
            <Typography sx={cardTextStyle}>{detailDescription}</Typography>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ display: 'block', textAlign: 'center' }}>
          <Button
            sx={{
              fontSize: FONT_SIZES.md,
              fontWeight: 400,
            }}
            onClick={onClick}
          >
            {linkLabel}
          </Button>
        </Typography>
      </Grid>
    </Grid>
  );
}
