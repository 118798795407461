import ScienceIcon from '@mui/icons-material/Science';
import { Box, Button, Typography, Grid, Divider } from '@mui/material';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
  ComponentType,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { REPORTS_LIST, BACTERIA_TYPE } from '@/constants';
import * as userActions from '@/domain/middlewares/user';
import { store } from '@/domain/store';
import { State } from '@/types';
import { AssessmentStatus, NormalizedAssessment } from '@/types/network';
import { AssessmentReportCard } from '@/view/components/AssessmentReportCard';
import { PageTitle } from '@/view/components/PageTitle';
import { SpiderGraph } from '@/view/components/Report/ReportSpiderGraph';
import { SpeechBubble } from '@/view/components/SpeechBubble';
import { STYLES, FONT_SIZES, LINE_HEIGHTS } from '@/view/styling/theme';

const NoAssessment: ComponentType = () => {
  return (
    <Stack my={20} gap={4}>
      <Box>
        <img
          src={REPORTS_LIST.NO_REPORTS.IMAGE_PATH}
          style={{ height: 'auto', maxWidth: '300px' }}
        />
      </Box>
      <Typography sx={{ color: 'grey.300' }} align="center">
        {REPORTS_LIST.NO_REPORTS.TEXT}
      </Typography>
    </Stack>
  );
};

export const MyPageReportList: ComponentType<{
  state: State;
}> = ({ state: { assessments, ongoingAssessment } }) => {
  const onClickGoToKitConfirmation = _.partial(
    userActions.onClickGoToKitConfirmation,
    store
  );

  const sortedAssessments = assessments.sort(
    (a, b) =>
      new Date(b.testCompletedAt).getTime() -
      new Date(a.testCompletedAt).getTime()
  );

  const onClickGoToAssessmentReport = _.partial(
    userActions.onClickGoToAssessmentReport
  );

  const bacteriaTypes = Object.values(BACTERIA_TYPE);

  const validAssessments = sortedAssessments.filter(
    (assessment) =>
      assessment.report?.reportSummary.tScore &&
      assessment.report.reportSummary.primaryBacteria !== 'UNMEASURABLE'
  );

  const mostRecentTScore = validAssessments[0]?.report?.reportSummary.tScore;

  const secondMostRecentTScore =
    validAssessments[1]?.report?.reportSummary.tScore;

  return (
    <Box sx={{ bgcolor: 'grey.50', overflow: 'hidden' }}>
      <PageTitle
        text={REPORTS_LIST.PAGE_TITLE}
        rightSection={
          _.isNull(ongoingAssessment) ||
          ongoingAssessment.status === AssessmentStatus.START_APPLICATION ? (
            <Button
              onClick={onClickGoToKitConfirmation}
              startIcon={
                <ScienceIcon
                  sx={{
                    color: 'primary.main',
                  }}
                />
              }
            >
              <Typography sx={STYLES.reportList.applyForTestText}>
                {REPORTS_LIST.TEST_APPLICATION}
              </Typography>
            </Button>
          ) : null
        }
      />
      {sortedAssessments.length === 0 && <NoAssessment />}
      {sortedAssessments.length !== 0 && (
        <>
          <Stack gap={0} my={4}>
            {sortedAssessments.map((assessment, i) => (
              <Box
                key={assessment._id}
                py={1}
                px={2}
                sx={{
                  bgcolor: 'common.white',
                  borderColor: 'grey.100',
                  borderStyle: 'solid',
                  borderWidth: i === 0 ? '0px' : '1px 0px 0px 0px',
                  cursor: 'pointer',
                  py: 1.5,
                }}
                onClick={() => onClickGoToAssessmentReport(assessment._id)}
              >
                <AssessmentReportCard
                  report={assessment.report}
                  testCompletedAt={assessment.testCompletedAt}
                />
              </Box>
            ))}
          </Stack>
          <Box sx={{ bgcolor: 'common.white' }} py={2} px={2} my={4}>
            <Typography sx={STYLES.reportList.yourGardenScore}>
              {REPORTS_LIST.YOUR_GARDEN_SCORE}
            </Typography>
            <GardenScoreGraph
              assessments={assessments.filter((assessment, i) => i < 4)}
            />
            <SpeechBubble style={STYLES.reportList.speechBubble}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    display: 'block',
                    fontSize: FONT_SIZES.sm,
                    lineHeight: LINE_HEIGHTS.mmd,
                  }}
                >
                  {REPORTS_LIST.GARDEN_SCORE_DEFINITION}
                </Typography>
              </Box>
            </SpeechBubble>
            <Box sx={{ mb: '10px', mt: '-20px' }}>
              <img
                src={REPORTS_LIST.IMAGE_PATH}
                style={{ height: 'auto', maxWidth: '300px' }}
              />
            </Box>
          </Box>
          <Box sx={{ bgcolor: 'common.white' }} py={2} px={2} my={4}>
            <Typography fontWeight={600} fontSize={14}>
              菌の偏差値*推移
            </Typography>
            <Box sx={{ mt: 3 }}>
              <SpiderGraph
                tScore={mostRecentTScore}
                previousTScore={secondMostRecentTScore}
              />
              <Typography
                component="p"
                sx={{ fontSize: '11px', lineHeight: '16.5px' }}
              >
                *菌の偏差値とは、あなたの菌数が平均（偏差値50）からどれだけ離れているかをしめした数値です。
                <br />
                あなたの菌数が多いと偏差値が高くなり、あなたの菌数が少ないと偏差値が低くなります。
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: 'common.white',
            }}
            p={2}
          >
            <Grid container sx={{ pb: 2 }}>
              <Grid
                item
                xs={12}
                sx={{
                  mb: 5,
                }}
              >
                <Typography
                  component="h3"
                  sx={STYLES.reportList.bacteriaDescription.title}
                >
                  {REPORTS_LIST.BACTERIA_DESCRIPTION}
                </Typography>
              </Grid>

              {bacteriaTypes.map((bacteria, index) => (
                <Fragment key={bacteria.KEY}>
                  <Grid item xs={12}>
                    <Grid container gap={1}>
                      <Grid
                        item
                        xs={2}
                        sx={STYLES.reportList.bacteriaDescription.image}
                      >
                        <Box>
                          <img src={bacteria.IMAGE_PATH} />
                        </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          gap={1}
                        >
                          <Grid item xs={12}>
                            <Typography
                              component="h3"
                              sx={
                                STYLES.reportList.bacteriaDescription.item.title
                              }
                            >
                              {bacteria.TITLE}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              component="h4"
                              sx={
                                STYLES.reportList.bacteriaDescription.item
                                  .description
                              }
                            >
                              {bacteria.DESCRIPTION}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {index < bacteriaTypes.length - 1 && (
                    <Grid item xs={12} sx={{ m: 2 }}>
                      <Divider />
                    </Grid>
                  )}
                </Fragment>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
};

/**
 * get graph position from score and average
 *
 * @returns graph position
 */

function getGraphPosition(gardenScore: number): number {
  return (100 - gardenScore) / 100;
}

const GardenScoreGraph: ComponentType<{
  assessments: NormalizedAssessment[];
}> = ({ assessments }) => {
  const h = 220;
  const grids = 5;
  const gridHeight = h / grids;

  // [1, 2, 3, 4] => [[1, 2], [2, 3], [3, 4]]
  const coupledAssessments = useMemo<
    [
      { assessment: NormalizedAssessment; assessmentIndex: number },
      { assessment: NormalizedAssessment; assessmentIndex: number }
    ][]
  >(() => {
    return assessments
      .map<{ assessment: NormalizedAssessment; assessmentIndex: number }>(
        (assessment, assessmentIndex) => ({ assessment, assessmentIndex })
      )
      .filter(({ assessment }) => assessment.report?.reportSummary.gardenScore)
      .map<
        [
          { assessment: NormalizedAssessment; assessmentIndex: number },
          { assessment: NormalizedAssessment; assessmentIndex: number }
        ]
      >(({ assessment, assessmentIndex }, ind, arr) => [
        { assessment, assessmentIndex },
        {
          assessment: arr[ind + 1]?.assessment,
          assessmentIndex: arr[ind + 1]?.assessmentIndex,
        },
      ])
      .filter(([, nxt]) => !!nxt.assessment);
  }, [assessments]);

  // TRY NOT TO USE THIS
  const [graphWidth, setGraphWidth] = useState(200);
  const graphRef = useRef<HTMLElement>();
  useEffect(() => {
    const graphElm = graphRef.current;
    if (!graphElm) return;

    const observer = new ResizeObserver(([entry]) => {
      if (!entry) return;
      setGraphWidth(entry.contentRect.width);
    });
    observer.observe(graphElm);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Box sx={{ position: 'relative' }} mt={3}>
      <Box sx={{ height: `${h + 28}px`, position: 'relative' }}>
        {/* deactivated assessments */}
        <Box
          sx={{
            bottom: 0,
            left: '20px',
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          {assessments.map((assessment, i, a) => {
            const gardenScore = assessment.report?.reportSummary.gardenScore;
            return (
              <Fragment key={i}>
                {typeof gardenScore !== 'number' && (
                  <>
                    <Box
                      sx={{
                        bgcolor: 'grey.50',
                        bottom: `0px`,
                        height: `${gridHeight * 5}px`,
                        left: `${i * 25}%`,
                        position: 'absolute',
                        textAlign: 'center',
                        top: `0px`,
                        width: '23%',
                      }}
                    />
                  </>
                )}
              </Fragment>
            );
          })}
        </Box>
        {/* Vertical Axis */}
        <Box
          sx={{
            backgroundColor: 'grey.300',
            height: `${h}px`,
            left: 20,
            position: 'absolute',
            top: 0,
            width: '2px',
          }}
        />
        {/* Horizontal Axis */}
        <Box
          sx={{
            backgroundColor: 'grey.300',
            height: '2px',
            left: 20,
            position: 'absolute',
            right: 10,
            top: `${h - 2}px`,
          }}
        />
        {/* Dashed Horizontal Lines */}
        {Array.from({ length: grids }, (unused, i) => (
          <Box
            sx={{
              borderColor: 'grey.600',
              borderStyle: 'dashed',
              borderWidth: '0 0 1px 0',
              height: '1px',
              left: 20,
              position: 'absolute',
              right: 10,
              top: `${gridHeight * i}px`,
            }}
            key={i}
          />
        ))}
        {/* Offset Box */}
        <Box
          sx={{
            bottom: 0,
            left: '20px',
            position: 'absolute',
            right: 10,
            top: 0,
          }}
          ref={graphRef}
        >
          {/* Dots Plotting */}
          {assessments.map((assessment, i, a) => {
            const gardenScore = assessment.report?.reportSummary.gardenScore;
            return (
              <Fragment key={i}>
                {typeof gardenScore === 'number' && (
                  <Box
                    sx={{
                      left: `${i * 25}%`,
                      position: 'absolute',
                      top: `${getGraphPosition(gardenScore) * h}px`,
                      width: '25%',
                    }}
                  >
                    <Box
                      sx={{
                        left: 0,
                        position: 'absolute',
                        right: 0,
                        top: '-12px',
                      }}
                    >
                      <Box
                        sx={{
                          bgcolor: 'primary.main',
                          borderRadius: '100%',
                          height: '24px',
                          opacity: i === 0 ? '30%' : '0%',
                          width: '24px',
                        }}
                        mx="auto"
                      />
                    </Box>
                    <Box
                      sx={{
                        left: 0,
                        position: 'absolute',
                        right: 0,
                        top: '-6px',
                      }}
                    >
                      <Box
                        sx={{
                          bgcolor: 'primary.main',
                          borderRadius: '100%',
                          height: '12px',
                          width: '12px',
                        }}
                        mx="auto"
                      />
                    </Box>
                    <Box mt="10px" sx={{ textAlign: 'center' }}>
                      <Typography
                        fontSize={20}
                        color="primary.main"
                        fontWeight={600}
                      >
                        {gardenScore}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {typeof gardenScore !== 'number' && (
                  <>
                    <Box
                      sx={{
                        bottom: `0px`,
                        height: `${h}px`,
                        left: `${i * 25}%`,
                        position: 'absolute',
                        textAlign: 'center',
                        top: `0px`,
                        width: '25%',
                      }}
                    >
                      <Box
                        sx={{
                          bottom: '0px',
                          color: 'grey.300',
                          fontSize: '12px',
                          fontWeight: 600,
                          left: '0px',
                          pb: '8px',
                          position: 'absolute',
                          right: '0px',
                          textAlign: 'center',
                        }}
                      >
                        ー
                        <br />
                        測定不能
                      </Box>
                    </Box>
                  </>
                )}
                <Box
                  sx={{
                    left: `${i * 25}%`,
                    position: 'absolute',
                    textAlign: 'center',
                    top: `${h}px`,
                    width: '25%',
                  }}
                >
                  <Typography fontSize={12} fontWeight={600}>
                    {dayjs(assessment.testCompletedAt).format('YY/M/D')}
                  </Typography>
                </Box>
              </Fragment>
            );
          })}
          {/* Lines Plotting */}
          {coupledAssessments.map(
            (
              [
                { assessment: from, assessmentIndex: fromIndex },
                { assessment: to, assessmentIndex: toIndex },
              ],
              i
            ) => {
              if (
                !from.report?.reportSummary.gardenScore ||
                !to.report?.reportSummary.gardenScore
              )
                return null;

              const fromPosition =
                getGraphPosition(from.report?.reportSummary.gardenScore ?? 0) *
                h;
              const toPosition =
                getGraphPosition(to.report?.reportSummary.gardenScore ?? 0) * h;

              const deltaX = (graphWidth / 4) * (toIndex - fromIndex);
              const deltaY = toPosition - fromPosition;
              const angle = Math.atan2(deltaY, deltaX);
              const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);

              return (
                <Box
                  sx={{
                    bgcolor: 'primary.main',
                    height: '2px',
                    left: `${12.5 + 25 * fromIndex}%`,
                    position: 'absolute',
                    top: `${fromPosition}px`,
                    transform: `rotate(${angle}rad)`,
                    transformOrigin: '0 0',
                    width: `${distance}px`,
                  }}
                  key={from._id}
                />
              );
            }
          )}
        </Box>
      </Box>
    </Box>
  );
};
