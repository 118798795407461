import { Container, Button, Grid, Typography } from '@mui/material';
import _ from 'lodash';

import { REGISTER_PHASE_STEPPER, REGISTER_COMPLETE_PAGE } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { store } from '@/domain/store';
import { State } from '@/types';
import { Mascot } from '@/view/components/Mascot';
import { FormStepper } from '@/view/components/Stepper';
import { FONT_SIZES, STYLES } from '@/view/styling/theme';

export function RegisterComplete({ state }: { state: State }) {
  const onClickGoToKitConfirmation = _.partial(
    user.onClickGoToKitConfirmation,
    store
  );
  return (
    <div>
      <FormStepper activeStep={2} steps={REGISTER_PHASE_STEPPER} />
      <Container>
        <Grid container direction="column" gap={4}>
          <Grid item>
            <Typography mb={3} mt={2} sx={STYLES.mainHeading}>
              {REGISTER_COMPLETE_PAGE.PAGE_TITLE}
            </Typography>
            <Typography
              sx={{
                display: 'block',
                fontSize: FONT_SIZES.md,
                textAlign: 'center',
                whiteSpace: 'pre-wrap',
                width: '100%',
              }}
            >
              {REGISTER_COMPLETE_PAGE.BODY_LINE_1}
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              item
              sx={{
                marginBottom: 3,
                marginTop: 2,
                textAlign: 'center',
              }}
            >
              <Mascot variant="register-complete" />
            </Grid>
            <Grid item>
              <Button
                onClick={onClickGoToKitConfirmation}
                sx={{ marginTop: 4 }}
                size="large"
                variant="contained"
                fullWidth
              >
                {REGISTER_COMPLETE_PAGE.SUBMIT_BUTTON}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
