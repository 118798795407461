import { Backdrop, CircularProgress } from '@mui/material';

type Props = {
  isLoading: boolean;
};
export function Loading({ isLoading }: Props) {
  return (
    <Backdrop
      sx={(theme) => ({
        backgroundColor: 'rgba(256,256,256,0.4)',
        top: '54px',
        zIndex: 999999,
      })}
      open={isLoading}
    >
      <CircularProgress />
    </Backdrop>
  );
}
