import { Grid, Typography } from '@mui/material';

import { TextWithLinks } from '@/view/components/Accordion/TextWithLinks';

interface ListGridProps {
  bulletList?: boolean;
  content: string[];
  section: string;
  subContent?: {
    applyNumber: number;
    content: string[];
  }[];
}

export function ListGrid({
  content,
  subContent,
  section,
  bulletList,
}: ListGridProps) {
  return (
    <>
      {content.map((item, index) => (
        <Grid container key={`${ListGrid}-${section}-${index}`}>
          {bulletList ? (
            <>
              <Grid item sx={{ pt: 2 }} xs={1} md={0.5}>
                <Typography
                  sx={{ fontSize: 30, fontWeight: 'bold', lineHeight: 0.5 }}
                >
                  •
                </Typography>
              </Grid>
              <Grid item sx={{ pt: 2 }} xs={11}>
                <TextWithLinks text={item} />
              </Grid>
            </>
          ) : (
            <Grid item sx={{ pt: 2 }} xs={12}>
              <TextWithLinks text={item} />
            </Grid>
          )}
          <Grid item sx={{ mt: 3 }} xs={12}>
            {subContent &&
              subContent.map((subItem, subIndex) =>
                subItem.applyNumber === index + 1
                  ? subItem.content.map((content, contentIndex) => (
                      <Grid
                        container
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                        key={`${ListGrid}-${section}-${subIndex}-${contentIndex}`}
                        gap={0.5}
                      >
                        <Grid item xs={0.5}>
                          <Typography>{contentIndex + 1}.</Typography>
                        </Grid>
                        <Grid item xs={11}>
                          <Typography> {content} </Typography>
                        </Grid>
                      </Grid>
                    ))
                  : null
              )}
          </Grid>
        </Grid>
      ))}
    </>
  );
}
