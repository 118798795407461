import { useLottie } from 'lottie-react';

type Props = {
  animation: unknown;
};

export function Animation({ animation }: Props) {
  const options = {
    animationData: animation,
    autoplay: true,
    loop: true,
  };

  const { View } = useLottie(options, {});

  return View;
}
