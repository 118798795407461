import {
  CategoryToColumnsMap,
  ColumnCategoryJA,
  type ColumnCategory,
  ColumnCategoryToURLSlug,
} from '@/constants';
type CategoryData = {
  image: string;
  key: string;
  link: string;
  subtitle: string;
  title: string;
};

function transport(
  id: number,
  adviceKey: string,
  columnCategory: ColumnCategory
): CategoryData {
  const base = CategoryToColumnsMap[columnCategory];

  return {
    image: `${base[id - 1].headerImage}.png`,
    key: adviceKey,
    link: `${
      ColumnCategoryToURLSlug.find((c) => c.category === columnCategory)
        ?.slug ?? ''
    }-${id}`,
    subtitle: `${ColumnCategoryJA[columnCategory]} No. ${id}`,
    title: base[id - 1].title,
  };
}

export const categoryData: CategoryData[] = [
  transport(2, 'STRESS', 'LIFESTYLE_MENTAL'),
  transport(3, 'FOOD_FOR_FLORA', 'FOOD_AND_INTESTINE'),
  transport(1, 'EXERCISE', 'EXERCISE_AND_INTESTINE'),
  transport(2, 'EXERCISE', 'EXERCISE_AND_INTESTINE'),
  transport(3, 'YOGA', 'LIFESTYLE_MENTAL'),
  transport(4, 'YOGA', 'LIFESTYLE_MENTAL'),
  transport(1, 'REGULAR_LIFE', 'LIFESTYLE_MENTAL'),
  transport(1, 'GUT_HEALTHY_DIET', 'FOOD_AND_INTESTINE'),
  transport(6, 'GUT_HEALTHY_DIET', 'FOOD_AND_INTESTINE'),
  transport(2, 'SUPPLEMENTS', 'FOOD_AND_INTESTINE'),
];
