import { ColumnCategory } from '@/constants';
import { Store } from '@/domain/store';
import {
  AppRoute,
  State,
  Route,
  SurveyPage,
  Answer,
  Question,
  BacteriaType,
} from '@/types';
import {
  NormalizedAssessment,
  NormalizedReport,
  NetworkPurchase,
} from '@/types/network';

export function updateName(store: Store, name: string) {
  store.swap((state) => ({ ...state, name }));
}

export function setUser(
  store: Store,
  user: {
    buildingName: string;
    city: string;
    dayOfBirth: string;
    email: string;
    firstName: string;
    houseNumber: string;
    isNew: boolean;
    lastName: string;
    meijiId: string;
    monthOfBirth: string;
    phoneNumber: string;
    postalCode: string;
    prefecture: string;
    sex: string;
    sexJA: string;
    token: string;
    yearOfBirth: string;
  }
) {
  store.swap((state) => ({ ...state, user }));
}

/**
 * A reducer to reset state.registerForm and state.registerFormErrors
 *
 * @param store - js-atom store
 */
export function resetRegisterForm(store: Store) {
  store.swap((state) => ({
    ...state,
    registerForm: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      addressLine4: '',
      addressLine5: '',
      birthday: '',
      name: '',
      phone: '',
      postcode1: '',
      postcode2: '',
      sex: '',
    },
    registerFormErrors: {
      addressLine1: { isValid: true, message: '' },
      addressLine2: { isValid: true, message: '' },
      addressLine3: { isValid: true, message: '' },
      addressLine4: { isValid: true, message: '' },
      addressLine5: { isValid: true, message: '' },
      phone: { isValid: true, message: '' },
      postcode: { isValid: true, message: '' },
    },
  }));
}

export function setUserAssessments(
  store: Store,
  assessments: NormalizedAssessment[]
) {
  store.swap((state) => ({ ...state, assessments }));
}

export function setLatestReport(
  store: Store,
  report?: NormalizedReport & { assessmentId: string; reportDate: string }
) {
  store.swap((state) => ({ ...state, latestReport: report }));
}

export function setOngoingAssessment(
  store: Store,
  ongoingAssessment: NormalizedAssessment | null
) {
  store.swap((state) => ({ ...state, ongoingAssessment }));
}

export function setSelectedAssessment(
  store: Store,
  selectedAssessment: NormalizedAssessment | null
) {
  store.swap((state) => ({ ...state, selectedAssessment }));
}

export function setLatestBacteriaType(
  store: Store,
  latestBacteriaType: BacteriaType
) {
  store.swap((state) => ({ ...state, latestBacteriaType }));
}

export function setPurchases(store: Store, purchases: NetworkPurchase[]) {
  store.swap((state) => ({ ...state, purchases }));
}

export function setCurrentRoute(store: Store, route: AppRoute) {
  store.swap((state) => ({ ...state, currentRoute: route }));
}
export function setCustomRouteMeta(store: Store, route: Route) {
  store.swap((state) => ({ ...state, currentRouteMeta: route }));
}

export function updateAgreedTerms(store: Store, value: boolean) {
  store.swap((state) => ({
    ...state,
    agreedTerms: value,
  }));
}

export function updateRegisterFormPostcode1(store: Store, value: string) {
  store.swap((state) => ({
    ...state,
    registerForm: {
      ...state.registerForm,
      postcode1: value,
    },
  }));
}
export function updateRegisterFormPostcode2(store: Store, value: string) {
  store.swap((state) => ({
    ...state,
    registerForm: {
      ...state.registerForm,
      postcode2: value,
    },
  }));
}
export function updateRegisterFormName(store: Store, value: string) {
  store.swap((state) => ({
    ...state,
    registerForm: {
      ...state.registerForm,
      name: value,
    },
  }));
}
export function updateRegisterFormPhone(store: Store, value: string) {
  store.swap((state) => ({
    ...state,
    registerForm: {
      ...state.registerForm,
      phone: value,
    },
  }));
}
export function updateRegisterFormAddressLine1(store: Store, value: string) {
  store.swap((state) => ({
    ...state,
    registerForm: {
      ...state.registerForm,
      addressLine1: value,
    },
  }));
}
export function updateRegisterFormAddressLine2(store: Store, value: string) {
  store.swap((state) => ({
    ...state,
    registerForm: {
      ...state.registerForm,
      addressLine2: value,
    },
  }));
}
export function updateRegisterFormSex(store: Store, value: string) {
  store.swap((state) => ({
    ...state,
    registerForm: {
      ...state.registerForm,
      sex: value,
    },
  }));
}
export function updateRegisterFormBirthday(store: Store, value: string) {
  store.swap((state) => ({
    ...state,
    registerForm: {
      ...state.registerForm,
      birthday: value,
    },
  }));
}
export function updateRegisterFormAddressLine3(store: Store, value: string) {
  store.swap((state) => ({
    ...state,
    registerForm: {
      ...state.registerForm,
      addressLine3: value,
    },
  }));
}
export function updateRegisterFormAddressLine4(store: Store, value: string) {
  store.swap((state) => ({
    ...state,
    registerForm: {
      ...state.registerForm,
      addressLine4: value,
    },
  }));
}
export function updateRegisterFormAddressLine5(store: Store, value: string) {
  store.swap((state) => ({
    ...state,
    registerForm: {
      ...state.registerForm,
      addressLine5: value,
    },
  }));
}

export function updateRegisterFormErrors(
  store: Store,
  key: string,
  value: { isValid: boolean; message: string }
) {
  store.swap((state) => ({
    ...state,
    registerFormErrors: {
      ...state.registerFormErrors,
      [key]: value,
    },
  }));
}

export function updateLoading(
  store: Store,
  key: keyof State['loading'],
  value: boolean
) {
  store.swap((state) => ({
    ...state,
    loading: { ...state.loading, [key]: value },
  }));
}

export function updateAddAssessmentInput(store: Store, value: string) {
  store.swap((state) => ({
    ...state,
    addAssessmentInput: {
      ...state.addAssessmentInput,
      value,
    },
  }));
}

export function addBarcodeScanPhoto(store: Store, value: string) {
  store.swap((state) => ({
    ...state,
    barcodeScanPhoto: value,
  }));
}

export function updateAddAssessmentInputErrors(
  store: Store,
  values: {
    errorMessage: string;
    isValid: boolean;
  }
) {
  store.swap((state) => ({
    ...state,
    addAssessmentInput: {
      ...state.addAssessmentInput,
      ...values,
    },
  }));
}
export function updateSnackbar(
  store: Store,
  values: {
    message: string;
    type: 'error' | 'info' | 'success' | 'warning';
    visible: boolean;
  }
) {
  store.swap((state) => ({
    ...state,
    snackbar: {
      ...values,
    },
  }));
}

export function setCurrentSurveyPage(store: Store, value: SurveyPage) {
  store.swap((state) => ({
    ...state,
    surveyPage: {
      ...state.surveyPage,
      ...value,
    },
  }));
}

export function updateSurveyAnswers(store: Store, value: Answer[]) {
  store.swap((state) => ({
    ...state,
    surveyAnswers: value,
  }));
}
export function updateQuestionErrors(store: Store, value: Question[]) {
  store.swap((state) => ({
    ...state,
    surveyPage: {
      ...state.surveyPage,
      questionErrors: value,
    },
  }));
}

export function setTermsUrl(store: Store, value: string) {
  store.swap((state) => ({
    ...state,
    termsUrl: value,
  }));
}

/**
 * Set column
 *
 * @remarks
 * A reducer to update the column id
 *
 * @param store - js-atom instance
 */
export function setColumn(store: Store, category: ColumnCategory, num: number) {
  store.swap((state) => ({ ...state, column: { category, num } }));
}

/**
 * Set column category for list
 *
 * @remarks
 * A reducer to update the column category for column list page
 *
 * @param store - js-atom instance
 */
export function setColumnListCategory(
  store: Store,
  category: ColumnCategory | null
) {
  store.swap((state) => ({ ...state, columnListCategory: category }));
}

/**
 * updates first-set purchase status of a user
 * @param store - js-atom instance
 * @param value - a boolean value to update the first-set purchase state
 */
export function updateIsFirstSetPurchase(store: Store, value: boolean) {
  store.swap((state) => ({
    ...state,
    isFirstSetPurchase: value,
  }));
}
