import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { useRef, useEffect, useMemo } from 'react';

import { ListGrid } from '@/view/components/Accordion/ListGrid';
import { STYLES } from '@/view/styling/theme';

interface FaqAccordionProps {
  bulletList?: boolean;
  content: string[];
  defaultExpanded: boolean;
  section: string;
  subContent?: {
    applyNumber: number;
    content: string[];
  }[];
  title: string;
}
[];

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ChevronRightIcon sx={{ color: 'grey.600' }} />}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: '#F3F5F7',
  padding: theme.spacing(2),
}));
/**
 * The FAQ Accordion component will look like this:
 * ----------------------------------------------
 * If bulletList = true:
 * ・ content 1
 * 1. sub content one
 * 2. sub content two
 * ・ content 2
 * 1. sub content one
 * 2. sub content two
 * ----------------------------------------------
 * If bulletList = false (default):
 * content 1
 * 1. sub content one
 * 2. sub content two
 * content 2
 * 1. sub content one
 * 2. sub content two
 */
export function FaqAccordion({
  title,
  subContent,
  content,
  bulletList = false,
  section,
  defaultExpanded,
}: FaqAccordionProps) {
  const accordionRef = useRef<HTMLDivElement>(null);
  const initialExpanded = useMemo(() => defaultExpanded, []);

  useEffect(() => {
    if (defaultExpanded && accordionRef.current) {
      accordionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [defaultExpanded]);

  return (
    <Box sx={{ pb: 2 }} ref={accordionRef}>
      <Accordion sx={STYLES.accordionFaq} defaultExpanded={initialExpanded}>
        <AccordionSummary
          aria-controls={`subPanel${section}b-content`}
          id={`subPanel${section}b-header`}
        >
          <Box sx={{ alignItems: 'center', display: 'flex' }}>
            <Typography sx={STYLES.accordionTitle_Q}>Q</Typography>
            <Typography sx={{ fontWeight: 300 }}>{title}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <ListGrid
            content={content}
            subContent={subContent}
            section={section}
            bulletList={bulletList}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
