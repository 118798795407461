import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';

import { MY_PAGE } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { store } from '@/domain/store';
import { State } from '@/types';
import {
  AssessmentStatus,
  NormalizedAssessment,
  NormalizedReport,
} from '@/types/network';
import { LatestReportCard } from '@/view/components/LatestReportCard';
import { NoOngoing } from '@/view/components/MyPage/NoOngoing';
import { PaymentComplete } from '@/view/components/MyPage/PaymentComplete';
import { ReadyForPost } from '@/view/components/MyPage/ReadyForPost';
import { ResumeAssessment } from '@/view/components/MyPage/ResumeAssessment';
import { PageTitle } from '@/view/components/PageTitle';

export function MyPage({ state }: { state: State }) {
  const onClickOpenBeforeSampleCollections = _.partial(
    user.onClickOpenBeforeSampleCollections,
    store
  );
  const onClickOpenCollectionInstructions = _.partial(
    user.onClickOpenCollectionInstructions,
    store
  );
  const onClickOpenPostageInstructions = _.partial(
    user.onClickOpenPostageInstructions,
    store
  );
  const onClickGoToRegisterTestKitId = _.debounce(
    _.partial(user.onClickOpenScanInstructions, store),
    500
  );
  const onClickGoToPurchase = _.debounce(
    _.partial(user.onClickGoToTestKitPurchase, store),
    500
  );
  const onClickOpenSurvey = _.partial(user.onClickOpenSurvey, store);

  const onClickGoToAssessmentReport = _.partial(
    user.onClickGoToAssessmentReport
  );

  const getOngoingAssessmentContent = (
    assessment: NormalizedAssessment | null,
    report?: NormalizedReport & { assessmentId: string; reportDate: string }
  ) => {
    if (
      assessment?.status === AssessmentStatus.START_APPLICATION &&
      !_.isEmpty(assessment?.testKitId)
    ) {
      return (
        <ResumeAssessment
          afterTesting={false}
          onClickSubmit={onClickGoToPurchase}
        />
      );
    }

    if (
      assessment === null ||
      assessment?.status === AssessmentStatus.START_APPLICATION
    ) {
      return (
        <NoOngoing report={report} onClick={onClickGoToAssessmentReport} />
      );
    }

    if (assessment?.status === AssessmentStatus.PAYMENT_COMPLETE) {
      const onClickSubmitHandler = _.isEmpty(assessment.testKitId)
        ? onClickGoToRegisterTestKitId
        : onClickOpenBeforeSampleCollections;

      return <PaymentComplete onClickSubmit={onClickSubmitHandler} />;
    }

    if (assessment?.status === AssessmentStatus.PREPARING_SURVEY) {
      return (
        <ResumeAssessment
          afterTesting={false}
          onClickSubmit={
            assessment.survey === null
              ? onClickOpenBeforeSampleCollections
              : onClickOpenSurvey
          }
        />
      );
    }

    if (assessment?.status === AssessmentStatus.PREPARING_POST) {
      return (
        <ResumeAssessment
          afterTesting={true}
          onClickSubmit={onClickOpenPostageInstructions}
        />
      );
    }

    if (assessment?.status === AssessmentStatus.READY_FOR_POST) {
      return <ReadyForPost />;
    }

    if (assessment?.status === AssessmentStatus.TESTING) {
      return (
        <ResumeAssessment
          afterTesting={false}
          onClickSubmit={onClickOpenCollectionInstructions}
        />
      );
    }
  };

  return (
    <Box>
      <PageTitle
        text={MY_PAGE.PAGE_TITLE}
        rightSection={
          <>
            <AccountCircleIcon
              sx={{
                color: '#8AC1D8',
                fontSize: '24px',
                position: 'relative',
                right: '4px',
                top: '6px',
              }}
            />
            <Typography>
              {`${state.user.lastName}${state.user.firstName} さま`}
            </Typography>
          </>
        }
      />
      <Container>
        <Grid container direction="column">
          {/* Ongoing Assessment Content */}

          <Grid item>
            {getOngoingAssessmentContent(
              state.ongoingAssessment,
              state.latestReport
            )}
          </Grid>

          {/* Latest Report */}
          {!_.isNil(state.ongoingAssessment) &&
            state.ongoingAssessment.status !== 'START_APPLICATION' && (
              <Grid item>
                <LatestReportCard
                  hasOngoing={state.ongoingAssessment !== null}
                  report={state.latestReport}
                  hideIfEmpty={true}
                  onClickGoToAssessmentReport={onClickGoToAssessmentReport}
                />
              </Grid>
            )}
        </Grid>
      </Container>
    </Box>
  );
}
