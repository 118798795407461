import { Grid } from '@mui/material';

import { SectionTitle } from '@/view/components/TopPage/SectionTitle';
import { StepDetail } from '@/view/components/TopPage/StepDetail';
import { StepItemArrow } from '@/view/components/TopPage/StepItemArrow';
import { StepItemCard } from '@/view/components/TopPage/StepItemCard';

interface NormalStepProps {
  children?: React.ReactNode;
  image?: string;
  imageTitle?: string;
  imageWidth?: string;
  subTitle: string;
  text: string;
  title: string;
  type: string;
}

interface StepsProps {
  data: NormalStepProps[];
}
export function Steps({ data }: StepsProps) {
  return (
    <Grid container sx={{ display: 'flex', justifyContent: 'center', p: 2.5 }}>
      <SectionTitle title={'Steps'} subtitle={'ご利用までの流れ'} />
      <Grid item container sx={{ pt: 2 }} xs={12} md={8}>
        {data.map((item, index) => {
          return (
            <Grid item xs={12} key={index}>
              <StepItemCard title={item.title} subtitle={item.subTitle}>
                <StepDetail
                  type={item.type}
                  text={item.text}
                  image={item.image}
                  imageTitle={item.imageTitle}
                  imageWidth={item.imageWidth}
                />
              </StepItemCard>
              {index >= 0 && index < data.length - 1 && (
                <Grid item xs={12}>
                  <StepItemArrow />
                </Grid>
              )}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
