import { Box, Typography } from '@mui/material';

import { TOP_PAGE, ColumnCategoryJA, ColumnCategory } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { Carousel } from '@/view/components/Carousel';
import { TopPageItem } from '@/view/components/TopPageItem';

export function Column() {
  return (
    <TopPageItem
      title={TOP_PAGE.SECTION_BODY_8.BODY_TITLE_1}
      subtitle={TOP_PAGE.SECTION_BODY_8.BODY_TITLE_2}
      backgroundColor={'grey.50'}
      marginTop={3}
      buttonTitle={TOP_PAGE.SECTION_BODY_8.BUTTON_TITLE}
      onClick={() => user.navigateTo('/columns')}
    >
      <Box sx={{ marginBottom: 3, marginTop: 4 }}>
        <Carousel>
          {TOP_PAGE.SECTION_BODY_8.BODY.map((column, i) => (
            <Box
              key={i}
              sx={{
                bgcolor: 'common.white',
                borderRadius: '12px',
                overflow: 'hidden',
              }}
            >
              <img src={column.IMAGE_PATH} width="100%" />
              <Box
                p={2}
                onClick={() =>
                  user.onClickGoToColumn(
                    column.CATEGORY as ColumnCategory,
                    column.NUMBER
                  )
                }
              >
                <Typography
                  sx={{
                    color: 'grey.300',
                    display: 'block',
                    mb: '4px',
                  }}
                  fontSize={12}
                  fontWeight="bold"
                >
                  {ColumnCategoryJA[column.CATEGORY as ColumnCategory]}No.
                  {column.NUMBER}
                </Typography>
                <Typography
                  sx={{
                    display: 'block',
                    mb: '8px',
                  }}
                  fontSize={16}
                  fontWeight="bold"
                >
                  {column.TITLE}
                </Typography>
                <Typography
                  sx={{
                    display: 'block',
                  }}
                  fontSize={14}
                >
                  {column.SECTION_TITLE.length > 30
                    ? column.SECTION_TITLE.substring(0, 30) + '・・・'
                    : column.SECTION_TITLE}
                </Typography>
              </Box>
            </Box>
          ))}
        </Carousel>
      </Box>
    </TopPageItem>
  );
}
