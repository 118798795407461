import {
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';

import { OnQuestionAnswerChanged, Question } from '@/types';
import { LINE_HEIGHTS } from '@/view/styling/theme';

type Props = Question & {
  isAutoSaving: boolean;
  onChange: OnQuestionAnswerChanged;
  questionNumber: number;
  showBorderTop?: boolean;
};

export function SurveyRadioItem({
  title,
  description,
  selectedValue,
  showBorderTop,
  questionNumber,
  onChange,
  id,
  children,
  isAutoSaving,
}: Props) {
  const subQuestionStyles = showBorderTop
    ? {
        border: 0,
        borderColor: 'grey.100',
        borderTopWidth: 1,
        pb: 1,
        pt: 2,
        px: 1,
      }
    : {};
  const [activeOptionId, setActiveOptionId] = useState('');
  return (
    <Grid container key={id} id={id} direction="column" spacing={1}>
      <Grid item>
        <Box sx={subQuestionStyles}>
          <Typography variant="h6">
            {questionNumber ? `Q${questionNumber}.` : ''} {title}
          </Typography>
        </Box>
      </Grid>
      {description.length > 0 ? (
        <Grid item>
          <Typography
            variant="caption"
            sx={{
              color: 'grey.300',
              display: 'block',
              lineHeight: LINE_HEIGHTS.md,
              mt: 1.5,
            }}
          >
            {description}
          </Typography>
        </Grid>
      ) : null}
      {children.length > 0 ? (
        <Grid item>
          <RadioGroup
            data-testid={id}
            sx={{
              pointerEvents: isAutoSaving ? 'none' : 'auto',
              width: '100%',
            }}
            value={selectedValue}
            onChange={async (e) => {
              setActiveOptionId(e.target.value);
              await onChange({ key: id, value: e.target.value });
              setActiveOptionId('');
            }}
          >
            {children.map((item) => {
              return (
                <FormControlLabel
                  className={
                    selectedValue === _.get(item, 'value') ? 'checked' : ''
                  }
                  key={_.get(item, 'label') + '_' + _.get(item, 'value')}
                  value={item.value}
                  data-testid={id + '_' + _.get(item, 'value')}
                  control={<Radio sx={{ paddingLeft: 0, paddingRight: 1 }} />}
                  label={
                    <Box>
                      {item.label}
                      {item.value === activeOptionId && (
                        <CircularProgress
                          size={20}
                          sx={{ ml: '16px', position: 'relative', top: '2px' }}
                        />
                      )}
                    </Box>
                  }
                />
              );
            })}
          </RadioGroup>
        </Grid>
      ) : null}
    </Grid>
  );
}
