import { Grid, Typography, Button, Box } from '@mui/material';
import { useState } from 'react';

import { EXPLAINING_FLORA_CHECK } from '@/constants';
import { Modal } from '@/view/components/Modal';
import { FONT_SIZES, LINE_HEIGHTS, STYLES } from '@/view/styling/theme';

export function ExplainingFloraCheck({
  triggerButton,
}: {
  triggerButton: JSX.Element;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <span onClick={() => setIsModalOpen(true)}>{triggerButton}</span>
      <Modal
        title=""
        open={isModalOpen}
        hasStickyTitle={true}
        backgroundColor="#F8F6F4"
        onClose={() => {
          setIsModalOpen(false);
        }}
        actions={
          <Button
            variant="outlined"
            size="large"
            sx={{ ...STYLES.secondaryButtonModal, flex: 1, marginX: 1 }}
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            {EXPLAINING_FLORA_CHECK.CLOSE_BUTTON_TEXT}
          </Button>
        }
      >
        <Grid
          mb={4}
          sx={{
            textWrap: 'nowrap',
          }}
        >
          <Typography
            sx={(theme) => ({
              color: 'text.primary',
              fontSize: FONT_SIZES.xxl,
              [theme.breakpoints.down('md')]: {
                fontSize: FONT_SIZES.xl,
              },
              fontWeight: 600,
              lineHeight: LINE_HEIGHTS.xxxxl,
              textAlign: 'center',
              width: '312px',
            })}
          >
            {EXPLAINING_FLORA_CHECK.TITLE}
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          rowSpacing={5}
          wrap="nowrap"
          sx={{ mb: 5 }}
        >
          <Grid item>
            <Box>
              <img width="295.29px" src={EXPLAINING_FLORA_CHECK.IMAGE_1} />
            </Box>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: 'text.primary',
                fontSize: FONT_SIZES.md,
                fontWeight: 300,
                lineHeight: LINE_HEIGHTS.xl,
              }}
            >
              {EXPLAINING_FLORA_CHECK.BODY_1}
            </Typography>
          </Grid>
          <Grid item>
            <Box>
              <img width="165.29px" src={EXPLAINING_FLORA_CHECK.IMAGE_2} />
            </Box>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: 'text.primary',
                fontSize: FONT_SIZES.md,
                fontWeight: 300,
                lineHeight: LINE_HEIGHTS.xl,
              }}
            >
              {EXPLAINING_FLORA_CHECK.BODY_2}
            </Typography>
          </Grid>
          <Grid item>
            <Box>
              <img width="165.29px" src={EXPLAINING_FLORA_CHECK.IMAGE_3} />
            </Box>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: 'text.primary',
                fontSize: FONT_SIZES.md,
                fontWeight: 300,
                lineHeight: LINE_HEIGHTS.xl,
              }}
            >
              {EXPLAINING_FLORA_CHECK.BODY_3}
            </Typography>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}
