import { ArrowDropDown } from '@mui/icons-material';
import {
  Grid,
  MenuItem,
  Select,
  Typography,
  CircularProgress,
} from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';

import { OnQuestionAnswerChanged, Question } from '@/types';

type Props = Question & {
  isAutoSaving: boolean;
  onChange: OnQuestionAnswerChanged;
  questionNumber: number;
};

export function SurveySelectItem({
  title,
  description,
  selectedValue,
  onChange,
  questionNumber,
  placeholder,
  id,
  children,
  suffix,
  isAutoSaving,
}: Props) {
  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false);
  return (
    <Grid container id={id} key={id} direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h6">
          {questionNumber ? `Q${questionNumber}.` : ''} {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption" sx={{ color: 'grey.300' }}>
          {description}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={11}>
            <Select
              data-testid={id}
              sx={{
                pointerEvents: isAutoSaving ? 'none' : 'auto',
                width: '100%',
              }}
              displayEmpty
              value={selectedValue}
              onChange={async (e) => {
                setIsSubmittingAnswer(true);
                await onChange({ key: id, value: e.target.value });
                setIsSubmittingAnswer(false);
              }}
              IconComponent={
                isSubmittingAnswer
                  ? () => <CircularProgress size={25} sx={{ mr: '16px' }} />
                  : ArrowDropDown
              }
            >
              <MenuItem disabled value="">
                {placeholder}
              </MenuItem>
              {children.map((item) => {
                return (
                  <MenuItem
                    data-testid={
                      id +
                      '_' +
                      _.get(item, 'label') +
                      '_' +
                      _.get(item, 'value')
                    }
                    key={_.get(item, 'label') + '_' + _.get(item, 'value')}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={1}>
            <Typography sx={{ display: 'block', marginLeft: 1, marginTop: 4 }}>
              {suffix}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
