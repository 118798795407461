import page from 'page';

import { createLogger } from '@/domain/utils/logger';
import * as network from '@/domain/utils/network';

const {
  MEIJI_IDPF_ENDPOINT,
  MEIJI_IDPF_FIRST_LOGIN_URL,
  MEIJI_FRONTEND_SYSTEM_ID,
  MEIJI_FRONTEND_URL,
} = import.meta.env;

const logger = createLogger('@domain/middlewares/auth/utils');

/**
 * Verify access token
 *
 * @param token - access token to verify
 *
 * @returns boolean value
 */
export async function verifyAccessToken(token: string): Promise<boolean> {
  logger.debug(`Verify token:${token} ...`);
  try {
    if (!token) {
      throw new Error(`Invalid token: ${token}`);
    }
    const { user } = await network.getSessionUser(token);
    if (!user) {
      throw new Error(`Invalid session user: ${user}`);
    }
    return true;
  } catch (error) {
    logger.debug(`Token:${token} is invalid:`, error);
    return false;
  }
}

/**
 * Get access token and session user info
 *
 * @remarks
 * If the user has not agreed on the latest terms, redirect to IDPF first login page
 *
 * @returns - accessToken and user info
 */
export async function getAccessTokenAndSessionUser() {
  logger.debug('Refreshing token...');
  try {
    const { accessToken, isFirstLogin, disagreeFrontsystemIds } =
      await network.refreshAccessTokenAndSetUserState();
    const hasNotAgreedOnLatestTerms =
      isFirstLogin ||
      disagreeFrontsystemIds.includes(Number(MEIJI_FRONTEND_SYSTEM_ID));
    if (hasNotAgreedOnLatestTerms) {
      logger.debug('Redirect to IDPF first login page');
      location.href = `${MEIJI_IDPF_FIRST_LOGIN_URL}?frontsystemId=${MEIJI_FRONTEND_SYSTEM_ID}&redirectUrl=${MEIJI_FRONTEND_URL}/auth/callback`;
      return null;
    }
    const { user } = await network.getSessionUser(accessToken);
    if (!user) {
      throw new Error(`Invalid session user: ${user}`);
    }
    return { accessToken, user };
  } catch (error) {
    logger.debug('Refreshing token is failed:', error);
    return null;
  }
}

/**
 * Get term url from IDPF and normalize it
 *
 * @returns - termsUrl string
 */
export async function getTermsUrl() {
  logger.debug('Refreshing token...');
  try {
    const { accessToken } = await network.refreshAccessTokenAndSetUserState();
    const { term } = await network.getTerms(accessToken);
    if (!term) {
      throw new Error(`Cant get terms: ${term}`);
    }
    return term?.termsUrl;
  } catch (error) {
    logger.debug('Refreshing token is failed:', error);
    return null;
  }
}

/**
 * Redirect to login page
 *
 * @remarks
 * If the user is already on login page, redirect to IDPF login page
 */
export function redirectToLogin() {
  logger.debug('current path:', location.pathname);
  const isOnLoginPage = /^\/login/.test(location.pathname);
  if (isOnLoginPage) {
    logger.debug('Redirect to IDPF login page');
    location.href = `${MEIJI_IDPF_ENDPOINT}/login?frontsystemId=${MEIJI_FRONTEND_SYSTEM_ID}&redirectUrl=${MEIJI_FRONTEND_URL}/auth/callback`;
  } else {
    logger.debug('Redirect to login page');
    page.redirect('/login');
  }
}
