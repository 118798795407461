import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from '@/App';
import { startRouters } from '@/domain/middlewares/router';
import { store } from '@/domain/store';
import { startAnalytics } from '@/domain/utils/analytics';
import { ErrorBoundary } from '@/view/components/ErrorBoundary';
import { CSSThemeVariables } from '@/view/styling/cssThemeVariables';
import { theme } from '@/view/styling/theme';

import '@/view/styling/index.css';

const gaId = import.meta.env.MEIJI_GA_ID;

const root = createRoot(document.getElementById('root') as HTMLElement);

function onCatchError(error: Error, errorInfo?: unknown) {
  Sentry.withScope(function (scope) {
    scope.setLevel('fatal');
    Sentry.captureException(error);
  });
}

function render() {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CSSThemeVariables theme={theme} />
        <CssBaseline />
        <ErrorBoundary onCatchError={onCatchError}>
          <App />
        </ErrorBoundary>
      </ThemeProvider>
    </React.StrictMode>
  );
}

if (
  import.meta.env.MEIJI_SENTRY_DSN &&
  import.meta.env.MEIJI_APP_ENVIRONMENT !== 'local'
) {
  const integrations =
    import.meta.env.MEIJI_APP_ENVIRONMENT === 'development'
      ? [Sentry.browserTracingIntegration(), Sentry.replayIntegration()]
      : [Sentry.browserTracingIntegration()];
  Sentry.init({
    beforeSend(event, hint) {
      if (event.request && event.request?.url?.includes('meiji.co.jp/user')) {
        return null;
      }
      return event;
    },
    dsn: import.meta.env.MEIJI_SENTRY_DSN,
    environment: import.meta.env.MEIJI_APP_ENVIRONMENT,
    integrations,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    tracesSampleRate: 1.0,
  });
}
store.addWatch('render', render);
if (
  import.meta.env.MEIJI_APP_ENVIRONMENT === 'production' ||
  import.meta.env.MEIJI_APP_ENVIRONMENT === 'staging'
) {
  startAnalytics(gaId);
}
startRouters();
