import { Box, Grid, Typography } from '@mui/material';

import { SectionTitle } from '@/view/components/TopPage/SectionTitle';
import {
  defaultThemeColor,
  FONT_SIZES,
  LINE_HEIGHTS,
  STYLES,
} from '@/view/styling/theme';

interface ServiceProps {
  serviceData: {
    content: string;
    image: string;
    title: string;
  }[];
}

export function Service({ serviceData }: ServiceProps) {
  return (
    <Grid container sx={{ p: 2.5 }}>
      {/* service title  */}
      <SectionTitle title={'Service'} subtitle={'サービスの流れ'} />

      {serviceData.map((service, index) => {
        return (
          <Grid key={index} item xs={12} sx={{ position: 'relative', pt: 4 }}>
            <Typography
              sx={{
                color: defaultThemeColor,
                fontFamily: 'Roboto',
                fontSize: '48px',
                fontWeight: 800,
                left: 20,
                position: 'absolute',
                top: 0,
              }}
            >{`0${index + 1}`}</Typography>

            <Box sx={{}}>
              <img src={service.image} alt="" width={'100%'} />
            </Box>
            <Box sx={STYLES.topPage.serviceContent}>
              <Typography sx={{ fontSize: FONT_SIZES.lg, fontWeight: 800 }}>
                {service.title}
              </Typography>
              <Typography
                sx={{ fontSize: '17px', lineHeight: LINE_HEIGHTS.xxl, pt: 3 }}
              >
                {service.content}
              </Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}
