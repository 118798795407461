import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ScienceIcon from '@mui/icons-material/Science';
import { Grid, Typography, Button, Paper, Box } from '@mui/material';

import { MY_PAGE } from '@/constants';
import { ExplainingFloraCheck } from '@/view/components/ExplainingFloraCheck';
import { FONT_SIZES, LINE_HEIGHTS, STYLES } from '@/view/styling/theme';

type Props = {
  onClickSubmit: () => void;
};

export function PaymentComplete({ onClickSubmit }: Props) {
  return (
    <Grid container direction="column" gap={2}>
      <Grid item>
        <Typography sx={STYLES.myPageHeading}>申込み中の検査</Typography>
      </Grid>
      <Grid item>
        <Paper elevation={0} sx={{ borderRadius: '12px', padding: '24px' }}>
          <Grid container direction="column" gap={2}>
            <Grid item>
              <Box
                mt={2}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                }}
              >
                <img src={MY_PAGE.PAYMENT_COMPLETE.IMAGE_1} />
                <img width="248.46px" src={MY_PAGE.PAYMENT_COMPLETE.IMAGE_2} />
              </Box>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                <Typography
                  component={'div'}
                  sx={{
                    color: 'grey.300',
                    fontSize: FONT_SIZES.md,
                    fontWeight: 600,
                    lineHeight: LINE_HEIGHTS.lg,
                    wordBreak: 'keep-all',
                  }}
                >
                  {MY_PAGE.PAYMENT_COMPLETE.BODY_LINE}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Button
                size="large"
                variant="contained"
                fullWidth
                onClick={onClickSubmit}
                data-e2e="my-page__start-btn"
                startIcon={<ScienceIcon />}
              >
                検査をはじめる
              </Button>
            </Grid>
            <Grid item>
              <ExplainingFloraCheck
                triggerButton={
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{
                      '&:hover': {
                        backgroundColor: 'common.white',
                        boxShadow: 'none',
                      },
                      alignItems: 'end',
                      backgroundColor: 'common.white',
                      color: 'grey.300',
                      fontSize: FONT_SIZES.sm,
                      fontWeight: 300,
                      height: '37px',
                      justifyContent: 'flex-start',
                      paddingLeft: 0,
                      textTransform: 'none',
                    }}
                    startIcon={
                      <HelpOutlineIcon
                        sx={{
                          color: 'primary.main',
                        }}
                      />
                    }
                  >
                    腸内フローラ検査について
                  </Button>
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
