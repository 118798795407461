import { Grid, Container, Button, Typography } from '@mui/material';
import _ from 'lodash';

import { LOGIN_PAGE } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { store } from '@/domain/store';
import { State } from '@/types';
import { defaultThemeColor, STYLES } from '@/view/styling/theme';

export function Login({ state }: { state: State }) {
  const onClickSignUpOrLogin = _.partial(user.onClickSignUpOrLogin, store);
  return (
    <Container>
      <Grid container direction="column" sx={{ marginTop: 4 }}>
        <Grid item className="text-center">
          <Typography mb={1.5} sx={STYLES.mainHeading}>
            {LOGIN_PAGE.PAGE_TITLE}
          </Typography>
          <Typography variant="body2" className="text-wrap">
            {LOGIN_PAGE.BODY_LINE_1}
          </Typography>
          <Button
            sx={{ backgroundColor: defaultThemeColor, marginTop: 4 }}
            size="large"
            variant="contained"
            data-e2e="login__login-btn"
            fullWidth
            onClick={onClickSignUpOrLogin}
          >
            {LOGIN_PAGE.SUBMIT_BUTTON}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
