import { Box, Grid, Typography } from '@mui/material';

import { TOP_PAGE } from '@/constants';
import { SpeechBubble } from '@/view/components/SpeechBubble';
import { SectionTitle } from '@/view/components/TopPage/SectionTitle';
import { FONT_SIZES } from '@/view/styling/theme';

const CONSTANTS = TOP_PAGE.REPORT;

export function Fact() {
  return (
    <Grid container p={2.5}>
      <SectionTitle title={'Fact'} subtitle={CONSTANTS.FACT_HEADER} />
      <Grid item xs={12} alignItems="center">
        <SpeechBubble
          style={{ bgcolor: '#F8F6F4' }}
          text={CONSTANTS.BUBBLE_TEXT}
          subText={CONSTANTS.BUBBLE_SUB_TEXT}
        />
      </Grid>
      <Grid item xs={12} sx={{ mb: 7 }}>
        <img width="80%" src={CONSTANTS.FACT_IMAGE_1} />
        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}>
          <Typography sx={{ fontSize: FONT_SIZES.xs }}>
            {CONSTANTS.REMARK}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
