import { Box, Grid, Typography } from '@mui/material';

import { TOP_PAGE } from '@/constants';
import { STYLES } from '@/view/styling/theme';

export function HeroTitle() {
  return (
    <Box sx={{ width: 1 }}>
      <Grid container>
        <Box sx={{ pl: 3, pt: 3 }}>
          <img src={TOP_PAGE.HERO.MEIJI_LOGO} />
        </Box>
      </Grid>
      <Box width={'100%'} sx={{ pt: 2, textAlign: 'center' }}>
        <Typography component="h3" sx={STYLES.hero_title}>
          {TOP_PAGE.HERO.TITLE}
        </Typography>
      </Box>
      <Box sx={{ pt: 1 }}>
        <img src="/images/top-page/hero_line.svg" />
      </Box>
      <Box width={'100%'} sx={{ pt: 2, textAlign: 'center' }}>
        <Typography sx={STYLES.hero_title2}>
          あなたの
          <span style={{ color: '#F7871A' }}>腸内タイプ</span>に
        </Typography>
        <br />
        <Typography sx={STYLES.hero_title2}>適した商品をお届け</Typography>
      </Box>
    </Box>
  );
}
