import { State } from '@/types';

export const initialState: State = {
  addAssessmentInput: {
    errorMessage: '',
    isValid: true,
    value: '',
  },
  agreedTerms: false,
  assessments: [],
  barcodeScanPhoto: '',
  currentRoute: null,
  currentRouteMeta: null,
  isFirstSetPurchase: false,
  latestBacteriaType: 'None',
  loading: {
    applicationIsLoading: false,
    assessmentIsLoading: false,
    scanningIsLoading: false,
    surveyAutoSaveIsLoading: false,
  },
  ongoingAssessment: null,
  registerForm: {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    addressLine5: '',
    birthday: '',
    name: '',
    phone: '',
    postcode1: '',
    postcode2: '',
    sex: '',
  },
  registerFormErrors: {
    addressLine1: { isValid: true, message: '' },
    addressLine2: { isValid: true, message: '' },
    addressLine3: { isValid: true, message: '' },
    addressLine4: { isValid: true, message: '' },
    addressLine5: { isValid: true, message: '' },
    phone: { isValid: true, message: '' },
    postcode: { isValid: true, message: '' },
  },
  selectedAssessment: null,
  snackbar: {
    message: '',
    type: 'error',
    visible: false,
  },
  surveyAnswers: [],
  surveyPage: {
    index: 0,
    questionErrors: [],
    questionOffset: 0,
    questions: [],
  },
  termsUrl: '',
  //TODO: this will be replaced once we have auth/user
  user: {
    buildingName: '',
    city: '',
    dayOfBirth: '',
    email: '',
    firstName: '',
    houseNumber: '',
    isNew: false,
    lastName: '',
    meijiId: '',
    monthOfBirth: '',
    phoneNumber: '',
    postalCode: '',
    prefecture: '',
    sex: '',
    sexJA: '',
    token: '',
    yearOfBirth: '',
  },
};
