import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import ScienceIcon from '@mui/icons-material/Science';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import {
  Button,
  Typography,
  Alert,
  Grid,
  Snackbar,
  Box,
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';

import mascotAdviceGrey from '@/assets/mascot-advice-grey.svg';
import { CONTACT_FORM_ELEMENT_ID, TOP_BAR } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { store } from '@/domain/store';
import { isTeaserRoute } from '@/domain/utils';
import { AppRoute, State } from '@/types';
import { FONT_SIZES, LINE_HEIGHTS, STYLES } from '@/view/styling/theme';
const { MEIJI_IDPF_URL } = import.meta.env;

type Props = {
  img: string;
  isLoggedIn: boolean;
  showMenu: boolean;
  state: State;
};

const CustomIcon = ({ src }: { src: string }) => (
  <Icon
    sx={{
      display: 'flex',
      ml: '-2px',
    }}
  >
    <img src={src} alt="Custom Icon" width="100%" height="18px" />
  </Icon>
);

export function TopBar({ state, img, isLoggedIn, showMenu }: Props) {
  const onClickCloseSnackbar = _.partial(user.onClickCloseSnackbar, store);
  const onClickLogin = _.partial(user.onClickLogin, store);
  const onClickMyPage = _.partial(user.onClickMyPage, store);
  const onClickLogout = _.partial(user.onClickLogout, store);

  const isTopPage = state.currentRoute === AppRoute.TOP;
  const [isGeneralDrawerOpen, setIsGeneralDrawerOpen] = useState(false);
  const [isAccountDrawerOpen, setIsAccountDrawerOpen] = useState(false);
  // FIXME: This menu items should just be declared directly in the JSX.
  const generalDrawerMenuItems = [
    {
      icon: <HomeIcon />,
      isClickable: true,
      onClick: () => user.navigateTo('/'),
      text: TOP_BAR.HOME,
    },
    {
      icon: <ScienceIcon />,
      isClickable: true,
      onClick: () => user.navigateTo('/products'),
      text: TOP_BAR.PRODUCT_LIST,
    },
    {
      icon: <DescriptionIcon />,
      isClickable: true,
      onClick: () => user.navigateTo('/concept'),
      text: TOP_BAR.CONCEPT,
    },
    {
      icon: <EmojiEmotionsIcon />,
      isClickable: true,
      onClick: () => user.navigateTo('/user-feedback'),
      text: TOP_BAR.CUSTOMER_FEEDBACK,
    },
    {
      icon: <CustomIcon src={mascotAdviceGrey} />,
      isClickable: true,
      onClick: () => user.navigateTo('/columns'),
      text: TOP_BAR.INTESTINE_COLUMN,
    },
    {
      icon: <HelpIcon />,
      isClickable: true,
      onClick: () => user.navigateTo('/faq'),
      text: TOP_BAR.FAQ,
    },
  ];

  const accountDrawerMenuItems = [
    {
      icon: <HomeIcon />,
      isClickable: true,
      onClick: onClickMyPage,
      text: TOP_BAR.MY_PAGE,
    },
    {
      icon: <FactCheckIcon />,
      isClickable: true,
      onClick: () => user.navigateTo('/my-page/reports'),
      text: TOP_BAR.TEST_RESULT,
    },
    {
      icon: <ShoppingBagIcon />,
      isClickable: true,
      onClick: () => user.navigateTo('/purchase-history'),
      text: TOP_BAR.PURCHASE_HISTORY,
    },
    {
      isClickable: false,
      onClick: () => {},
      text: TOP_BAR.ACCOUNT,
    },
    {
      icon: <SettingsIcon />,
      isClickable: true,
      onClick: () => user.navigateToExternal(`${MEIJI_IDPF_URL}/user/edit`),
      text: TOP_BAR.MEIJI_ID,
    },
    {
      icon: <LogoutIcon />,
      isClickable: true,
      onClick: onClickLogout,
      text: TOP_BAR.LOGOUT,
    },
    {
      isClickable: false,
      onClick: () => {},
      text: '',
    },
    {
      icon: <HelpIcon />,
      isClickable: true,
      onClick: () => {
        // FIXME: Dirty workaround to make scroll to work when the user is already on the FAQ page.
        if (state.currentRoute === AppRoute.FAQ) {
          const contactFormElement = document.getElementById(
            CONTACT_FORM_ELEMENT_ID
          );
          if (!_.isNil(contactFormElement)) {
            contactFormElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
        } else {
          user.navigateTo('/faq#contact');
        }
      },
      text: TOP_BAR.CONTACT_US,
    },
  ];

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent={showMenu ? 'space-between' : 'center'}
        alignItems="center"
        className={isTopPage ? '' : 'border-bottom'}
        sx={{ bgcolor: 'common.white', height: '59px' }}
      >
        {showMenu && (
          <Grid item sx={{ marginLeft: '6px' }}>
            <IconButton
              aria-label="open general drawer"
              onClick={() => setIsGeneralDrawerOpen(true)}
            >
              <img src={'/images/top-page/burger_nav.svg'} alt="menu icon" />
            </IconButton>
          </Grid>
        )}
        <Grid item>
          <IconButton
            disableRipple
            onClick={() => {
              if (!isTeaserRoute(window.location.pathname)) {
                user.navigateTo('/');
              }
            }}
            sx={{
              '&:active': {
                backgroundColor: 'transparent',
              },
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <img src={'/images/top-page/logo.svg'} style={{ width: '192px' }} />
          </IconButton>
        </Grid>
        {showMenu && isLoggedIn && (
          <Grid item sx={{ marginRight: '6px' }}>
            <IconButton
              aria-label="open account drawer"
              onClick={() => setIsAccountDrawerOpen(true)}
            >
              <img
                src={'/images/top-page/person_outline.svg'}
                alt="person outline icon"
              />
            </IconButton>
          </Grid>
        )}
        {showMenu && !isLoggedIn && (
          <Grid item sx={{ marginRight: '10px' }}>
            <Button
              variant="outlined"
              size="small"
              data-e2e="topbar__login-btn"
              onClick={onClickLogin}
              sx={STYLES.secondaryButton}
            >
              <Typography
                sx={{
                  color: 'text.primary',
                  fontSize: FONT_SIZES.xxs,
                  fontWeight: 300,
                }}
              >
                {TOP_BAR.LOGIN}
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
      <Snackbar
        open={state.snackbar.visible}
        autoHideDuration={6000}
        onClose={onClickCloseSnackbar}
      >
        <Alert
          onClose={onClickCloseSnackbar}
          severity={state.snackbar.type}
          sx={{ width: '100%' }}
        >
          {state.snackbar.message}
        </Alert>
      </Snackbar>
      <Drawer
        anchor="left"
        open={isGeneralDrawerOpen}
        onClose={() => setIsGeneralDrawerOpen(false)}
      >
        <Box
          sx={STYLES.topBarDrawer}
          role="presentation"
          onClick={() => setIsGeneralDrawerOpen(false)}
        >
          <List>
            {generalDrawerMenuItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem
                  button
                  sx={STYLES.topBarGeneralDrawerListItem}
                  disabled={!item.isClickable}
                  onClick={() => {
                    // FIXME:  temporary - remove once pages have been implemented
                    if (item.isClickable) {
                      item.onClick();
                    }
                    setIsGeneralDrawerOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <span
                      style={{
                        lineHeight: 0,
                      }}
                    >
                      {React.cloneElement(item.icon, {
                        style: STYLES.topBarDrawerListItemIcon,
                      })}
                    </span>
                  </ListItemIcon>
                  <ListItemText primary={item.text}></ListItemText>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Box>
        <Typography
          variant="caption"
          sx={{
            bottom: '10px',
            color: 'grey.300',
            fontSize: FONT_SIZES.xxs,
            left: '16px',
            position: 'absolute',
            right: 0,
          }}
        >
          v{import.meta.env.MEIJI_APP_VERSION}
        </Typography>
      </Drawer>
      <Drawer
        anchor="right"
        open={isAccountDrawerOpen}
        onClose={() => setIsAccountDrawerOpen(false)}
        sx={STYLES.topBarAccountDrawer}
      >
        <Box sx={STYLES.topBarDrawer} role="presentation">
          <List>
            <ListItem sx={STYLES.topBarAccountDrawerListItem}>
              <ListItemIcon sx={STYLES.topBarAccountDrawerListItemIcon}>
                <AccountCircleIcon
                  sx={STYLES.topBarAccountDrawerAccountCircleIcon}
                />
              </ListItemIcon>
              <ListItemText
                primary={`${state.user.lastName}${state.user.firstName} さま`}
                primaryTypographyProps={{
                  sx: {
                    fontSize: FONT_SIZES.md,
                    fontWeight: 300,
                    lineHeight: LINE_HEIGHTS.sm,
                  },
                }}
              />
            </ListItem>
            <Divider />
            {accountDrawerMenuItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItemButton
                  disabled={!item.isClickable}
                  onClick={() => {
                    if (item.isClickable) {
                      item.onClick();
                    }
                    setIsAccountDrawerOpen(false);
                  }}
                  sx={{
                    '& .MuiListItemIcon-root': {
                      minWidth: '36px',
                    },
                    '& .MuiListItemText-root': {
                      alignSelf: item.icon ? 'center' : 'flex-end',
                      marginBottom: item.icon ? '' : '0px',
                    },
                    '&.Mui-disabled': {
                      opacity: 0.5,
                    },
                    backgroundColor: item.icon ? 'common.white' : 'grey.50',
                    height: item.text
                      ? item.isClickable
                        ? '56px'
                        : '46px'
                      : 'auto',
                  }}
                >
                  {item.icon && (
                    <ListItemIcon>
                      <span
                        style={{
                          lineHeight: 0,
                        }}
                      >
                        {React.cloneElement(item.icon, {
                          style: STYLES.topBarDrawerListItemIcon,
                        })}
                      </span>
                    </ListItemIcon>
                  )}
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{
                      sx: {
                        fontSize: item.icon ? FONT_SIZES.md : FONT_SIZES.sm,
                        fontWeight: 300,
                        lineHeight: item.icon ? LINE_HEIGHTS.sm : '14px',
                      },
                    }}
                  />
                </ListItemButton>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Drawer>
      {isGeneralDrawerOpen && (
        <IconButton
          onClick={() => setIsGeneralDrawerOpen(false)}
          sx={STYLES.closeIconGeneralDrawer}
        >
          <CloseIcon />
        </IconButton>
      )}
      {isAccountDrawerOpen && (
        <IconButton
          onClick={() => setIsAccountDrawerOpen(false)}
          sx={STYLES.closeIconAccountDrawer}
        >
          <CloseIcon />
        </IconButton>
      )}
    </>
  );
}
