import { Box, Grid } from '@mui/material';

import { CUSTOMER_FEEDBACK_PAGE, TOP_PAGE } from '@/constants';
import { State } from '@/types';
import { CustomerFeedbackCard } from '@/view/components/CustomerFeedbackCard';
import { PageTitle } from '@/view/components/PageTitle';
import { SpeechBubble } from '@/view/components/SpeechBubble';
import { TextHighlight } from '@/view/components/TextHighlight';
import { FONT_SIZES, LINE_HEIGHTS } from '@/view/styling/theme';

export function CustomerFeedback({ state }: { state: State }) {
  return (
    <Grid container sx={{ mb: 5 }}>
      <Grid item xs={12}>
        <PageTitle text={CUSTOMER_FEEDBACK_PAGE.PAGE_TITLE} />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}
      >
        <Box sx={{ maxWidth: '500px', px: '16px' }}>
          <SpeechBubble
            style={{
              backgroundColor: '#ffffff',
              borderRadius: '10px',
              padding: '16px',
              width: '100%',
            }}
          >
            <TextHighlight
              commonTextStyle={{
                fontSize: FONT_SIZES.sm,
                fontWeight: 400,
                lineHeight: LINE_HEIGHTS.md,
              }}
              text={CUSTOMER_FEEDBACK_PAGE.DESCRIPTION}
            />
          </SpeechBubble>
          <img
            style={{
              height: 'auto',
              marginTop: '-20px',
              maxWidth: '290px',
            }}
            src={CUSTOMER_FEEDBACK_PAGE.IMAGE}
          />
        </Box>
      </Grid>
      <Grid item>
        {TOP_PAGE.SECTION_BODY_7.BODY.map((item, index) => (
          <CustomerFeedbackCard
            key={index}
            body1={item.BODY_1}
            body2={item.BODY_2}
            body3={item.BODY_3}
            imagePerson={item.IMAGE_PATH}
            title={item.TITLE}
          />
        ))}
      </Grid>
    </Grid>
  );
}
