import { Grid, Typography, Box } from '@mui/material';

import { TOP_PAGE } from '@/constants';
import { SectionTitle } from '@/view/components/TopPage/SectionTitle';
import { STYLES, LINE_HEIGHTS } from '@/view/styling/theme';

const CONSTANTS = TOP_PAGE.PARTNER;

export function Partner() {
  return (
    <Grid container sx={STYLES.topPage.partner.grid}>
      <SectionTitle title={'Partner'} subtitle={CONSTANTS.HEADER} />
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: { sm: 'flex-start', xs: 'center' },
          paddingY: 2,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
          }}
        >
          <Box>
            <img src={CONSTANTS.AVATAR_IMAGE_PATH} height={130} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box>
              <img src={CONSTANTS.LOGO_IMAGE_PATH} style={{ margin: 0 }} />
            </Box>
            <Typography
              variant="body1"
              fontWeight={300}
              sx={{ lineHeight: LINE_HEIGHTS.xxl }}
            >
              株式会社メタジェン代表
            </Typography>
            <Typography
              variant="body1"
              fontWeight={600}
              sx={{ lineHeight: LINE_HEIGHTS.xxl }}
            >
              福田真嗣
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ paddingY: 2 }}>
        <Typography
          variant="body1"
          fontWeight={300}
          sx={{ lineHeight: LINE_HEIGHTS.xxl }}
        >
          {CONSTANTS.TEXT}
        </Typography>
      </Grid>
    </Grid>
  );
}
