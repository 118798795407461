import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

import * as _ from 'lodash';
import page from 'page';

import {
  ALL_SURVEY,
  ColumnCategory,
  ColumnCategoryToURLSlug,
  ERRORS,
} from '@/constants';
import { refreshAccessTokenAndSetUserState } from '@/domain/middlewares/auth';
import { getState, Store } from '@/domain/store';
import * as reducers from '@/domain/store/reducers';
import {
  isValidLength,
  isPhoneNumber,
  isPostCode,
  isMeijiTestBarcode,
  isOnlyNumber,
} from '@/domain/utils/forms';
import { createLogger } from '@/domain/utils/logger';
import * as network from '@/domain/utils/network';
import * as normalizers from '@/domain/utils/normalizers';
import {
  calculateNextQuestionsFromCurrentAnswers,
  getUnAnsweredQuestions,
} from '@/domain/utils/survey';
import { Answer } from '@/types';
import { AssessmentStatus, NormalizedAssessment } from '@/types/network';
const logger = createLogger('@domain/middleware/user');

export async function onClickLogout() {
  await refreshAccessTokenAndSetUserState();
  const currentState = getState();
  const token = currentState.user.token;
  await network.logout(token);
  location.href = '/';
}

export function onChangeRegisterFormPostcode1(
  store: Store,
  errorMsg: string,
  value: string
) {
  const postcode2 = _.get(getState(), 'registerForm.postcode2') as string;
  const postcode = `${value}${postcode2}`;
  const isBelowMax = isValidLength(value, { max: 3 });
  const isValid = isPostCode(postcode);
  const message = !isValid ? errorMsg : '';
  if (isOnlyNumber(value) && isBelowMax) {
    reducers.updateRegisterFormErrors(store, 'postcode', { isValid, message });
    reducers.updateRegisterFormPostcode1(store, value);
  }
}

export function onChangeRegisterFormPostcode2(
  store: Store,
  errorMsg: string,
  value: string
) {
  const postcode1 = _.get(getState(), 'registerForm.postcode1') as string;
  const postcode = `${postcode1}${value}`;
  const isBelowMax = isValidLength(value, { max: 4 });
  const isValid = isPostCode(postcode);
  const message = !isValid ? errorMsg : '';
  if (isOnlyNumber(value) && isBelowMax) {
    reducers.updateRegisterFormErrors(store, 'postcode', { isValid, message });
    reducers.updateRegisterFormPostcode2(store, value);
  }
}

export function onChangeRegisterFormPhone(
  store: Store,
  errorMsg: string,
  value: string
) {
  const transformed = value.replace(/-/g, '');
  const isBelowMax = isValidLength(transformed, { max: 11 });
  const isValid = isPhoneNumber(transformed);
  const message = !isValid ? errorMsg : '';
  if (isOnlyNumber(transformed) && isBelowMax) {
    reducers.updateRegisterFormErrors(store, 'phone', { isValid, message });
    reducers.updateRegisterFormPhone(store, value);
  }
}

export function onChangeRegisterFormAddressLine1(
  store: Store,
  errorMsg: string,
  value: string
) {
  const isBelowMax = isValidLength(value, { max: 128 });
  const isValid = !_.isEmpty(value) && isBelowMax;
  const message = !isValid ? errorMsg : '';
  if (isBelowMax) {
    reducers.updateRegisterFormErrors(store, 'addressLine1', {
      isValid,
      message,
    });
    reducers.updateRegisterFormAddressLine1(store, value);
  }
}

export function onChangeRegisterFormAddressLine2(
  store: Store,
  errorMsg: string,
  value: string
) {
  const isBelowMax = isValidLength(value, { max: 128 });
  const isValid = !_.isEmpty(value) && isBelowMax;
  const message = !isValid ? errorMsg : '';
  if (isBelowMax) {
    reducers.updateRegisterFormErrors(store, 'addressLine2', {
      isValid,
      message,
    });
    reducers.updateRegisterFormAddressLine2(store, value);
  }
}

export function onChangeRegisterFormAddressLine3(
  store: Store,
  errorMsg: string,
  value: string
) {
  const isBelowMax = isValidLength(value, { max: 128 });
  const isValid = !_.isEmpty(value) && isBelowMax;
  const message = !isValid ? errorMsg : '';
  if (isBelowMax) {
    reducers.updateRegisterFormErrors(store, 'addressLine3', {
      isValid,
      message,
    });
    reducers.updateRegisterFormAddressLine3(store, value);
  }
}

export function onChangeRegisterFormAddressLine4(
  store: Store,
  errorMsg: string,
  value: string
) {
  const isValid = isValidLength(value, { max: 128 });
  const message = !isValid ? errorMsg : '';
  if (isValid) {
    reducers.updateRegisterFormErrors(store, 'addressLine4', {
      isValid,
      message,
    });
    reducers.updateRegisterFormAddressLine4(store, value);
  }
}

export function onChangeRegisterFormAddressLine5(
  store: Store,
  errorMsg: string,
  value: string
) {
  const isValid = isValidLength(value, { max: 128 });
  const message = !isValid ? errorMsg : '';
  if (isValid) {
    reducers.updateRegisterFormErrors(store, 'addressLine5', {
      isValid,
      message,
    });
    reducers.updateRegisterFormAddressLine5(store, value);
  }
}

export function onChangeAddAssessmentInput(
  store: Store,
  event: React.ChangeEvent<HTMLInputElement>
) {
  const safeVal = _.get(event, 'target.value');
  reducers.updateAddAssessmentInputErrors(store, {
    errorMessage: ERRORS.FAILED_TO_BARCODE_INPUT,
    isValid: safeVal === '' || isMeijiTestBarcode(safeVal),
  });
  reducers.updateAddAssessmentInput(store, safeVal);
}

export async function onScanOfBarcodeAssessment(
  store: Store,
  base64Image: string,
  qrCode?: string
) {
  reducers.addBarcodeScanPhoto(store, '');
  reducers.updateAddAssessmentInput(store, '');
  reducers.updateAddAssessmentInputErrors(store, {
    errorMessage: '',
    isValid: true,
  });

  if (_.isString(base64Image)) {
    reducers.addBarcodeScanPhoto(store, base64Image);
  }

  if (_.isString(qrCode)) {
    reducers.updateAddAssessmentInputErrors(store, {
      errorMessage: '',
      isValid: true,
    });
    reducers.updateAddAssessmentInput(store, qrCode);
  } else {
    reducers.updateAddAssessmentInput(store, '');
    reducers.updateAddAssessmentInputErrors(store, {
      errorMessage: ERRORS.FAILED_TO_BARCODE_INPUT,
      isValid: false,
    });
  }
  page('/test-kit/barcode-scan-attempt');
}

export function onCancelBarcodeScan(store: Store) {
  page('/test-kit/barcode-scan');
  reducers.updateAddAssessmentInputErrors(store, {
    errorMessage: '',
    isValid: false,
  });
  reducers.updateAddAssessmentInput(store, '');
  reducers.addBarcodeScanPhoto(store, '');
}

export function onTestKitCodeRetry(store: Store) {
  reducers.updateAddAssessmentInputErrors(store, {
    errorMessage: '',
    isValid: false,
  });
  reducers.updateAddAssessmentInput(store, '');
  reducers.addBarcodeScanPhoto(store, '');
  page('/test-kit/barcode-scan');
}

export async function onSubmitAddAssessmentForm(store: Store) {
  try {
    await refreshAccessTokenAndSetUserState();
    const currentState = getState();
    const token = currentState.user.token;

    const testKitId = currentState.addAssessmentInput?.value;
    const ongoingAssessmentId = currentState.ongoingAssessment?._id;
    if (!ongoingAssessmentId)
      throw new Error('selectedAssessment._id not found');

    await network.updateAssessment(ongoingAssessmentId, { testKitId }, token);
    if (
      currentState.ongoingAssessment?.status ===
      AssessmentStatus.PAYMENT_COMPLETE
    ) {
      return page('/test-kit/before-sample-collection');
    }
    page('/test-kit/purchase');
  } catch (error) {
    logger.error(error);
    reducers.updateSnackbar(store, {
      message: ERRORS.TEST_KIT_ID_REGISTRATION_ERROR,
      type: 'error',
      visible: true,
    });
  }
}

export function onSubmitRegisterForm(store: Store) {
  page('/register/confirmation');
}

/**
 * onClickOpenScanInstructions
 *
 * @remarks
 * A click event handler on open scan introductions page.
 *
 * @param store - js-atom instance
 */
export async function onClickOpenScanInstructions(store: Store) {
  logger.debug('onClickOpenScanInstructions');
  try {
    const currentState = getState();
    if (!currentState.ongoingAssessment) {
      await network.createAssessment(
        {
          meijiId: currentState.user.meijiId,
        },
        currentState.user.token
      );
    }
    page('/test-kit/barcode-scan');
  } catch (error) {
    reducers.updateSnackbar(store, {
      message: 'Error creating assessment',
      type: 'error',
      visible: true,
    });
  }
}

/**
 * onClickGoToPayment
 *
 * @remarks
 * A click event handler to -
 * [1] create assessment if none exists
 * [2] open the shopify checkout
 *
 * @param store - js-atom instance
 * @param productVariantId - the shopify product variantId
 */
export async function onClickGoToPayment(
  store: Store,
  productVariantId: string
) {
  logger.debug(
    '[onClickGoToPayment]',
    'onClickOpenScanInstructions',
    'Redirect...'
  );
  // Redirect
  try {
    reducers.updateLoading(store, 'applicationIsLoading', true);
    await refreshAccessTokenAndSetUserState();
    const state = store.deref();
    const token = state.user.token;

    // create assessment if none exists
    if (!state.ongoingAssessment) {
      await network.createAssessment(
        {
          meijiId: state.user.meijiId,
        },
        token
      );
    }

    // Request to get unique shopify item checkout url
    const res = await network.getCheckoutUrl(token, productVariantId);

    // Push history so browser back button redirects to my-page
    window.history.pushState(state, '', '/my-page');

    // open shopify checkout for item payment
    location.href = res.checkoutUrl;
  } catch (error) {
    logger.error(`onClickGoToPayment`, error);
  }
  reducers.updateLoading(store, 'applicationIsLoading', false);
}

/**
 * onClickGoToSubscriptionDetails
 *
 * @remarks
 * A click event handler to -
 * open the subscription details page for a shopify purchase
 *
 * @param store - js-atom instance
 * @param shopifyOrderId - the shopify orderId
 *
 * MEIJI-7053
 */
export async function onClickGoToSubscriptionDetails(
  store: Store,
  shopifyOrderId: string
) {
  logger.debug('[onClickGoToSubscriptionDetails]', 'Redirect...');

  try {
    reducers.updateLoading(store, 'applicationIsLoading', true);
    await refreshAccessTokenAndSetUserState();
    const state = store.deref();
    const token = state.user.token;

    const res = await network.getSubscriptionDetailsUrl(token, shopifyOrderId);

    location.href = res.subscriptionUrl;
  } catch (error) {
    logger.error(`onClickGoToSubscription`, error);
  }

  reducers.updateLoading(store, 'applicationIsLoading', false);
}

/**
 * handle Subscription ProductDrink Purchase
 *
 * @remarks
 * handler to -
 * open the shopify checkout page for Subscription payment
 * of the product
 *
 * @param store - js-atom instance
 * @param productVariantId - the shopify product variantId
 * @param sellingPlan - the shopify selling plan id for subscriptions
 */
export async function handleSubscriptionProductPurchase(
  store: Store,
  productVariantId: string,
  sellingPlanId: string
) {
  logger.debug('[handleSubscriptionProductPurchase]', 'Redirect...');
  // Redirect
  try {
    reducers.updateLoading(store, 'applicationIsLoading', true);
    await refreshAccessTokenAndSetUserState();
    const state = store.deref();
    const token = state.user.token;

    // Request to get unique shopify item checkout url
    const res = await network.getCheckoutUrl(
      token,
      productVariantId,
      sellingPlanId
    );

    // Push history so browser back button redirects to my-page
    window.history.pushState(state, '', '/my-page');

    // open shopify checkout for item payment
    location.href = res.checkoutUrl;
  } catch (error) {
    logger.error(`handleSubscriptionProductPurchase`, error);
  }
  reducers.updateLoading(store, 'applicationIsLoading', false);
}

/**
 * handle OneTime ProductDrink Purchase
 *
 * @remarks
 * handler to -
 * open the shopify checkout page for One Time payment
 * of the product
 *
 * @param store - js-atom instance
 * @param productVariantId - the shopify product variantId
 */
export async function handleOneTimeProductPurchase(
  store: Store,
  productVariantId: string
) {
  logger.debug('[handleOneTimeProductPurchase]', 'Redirect...');
  // Redirect
  try {
    reducers.updateLoading(store, 'applicationIsLoading', true);
    await refreshAccessTokenAndSetUserState();
    const state = store.deref();
    const token = state.user.token;

    // Request to get unique shopify item checkout url
    const res = await network.getCheckoutUrl(token, productVariantId);

    // Push history so browser back button redirects to my-page
    window.history.pushState(state, '', '/my-page');

    // open shopify checkout for item payment
    location.href = res.checkoutUrl;
  } catch (error) {
    logger.error(`handleOneTimeProductPurchase`, error);
  }
  reducers.updateLoading(store, 'applicationIsLoading', false);
}

/**
 * onClickGoToTestKitPurchase
 *
 * @remarks
 * A click event handler sent you to the data service purchase information page
 *
 * @param store - js-atom instance
 */
export async function onClickGoToTestKitPurchase(store: Store) {
  // Redirect
  try {
    //FIXME check for non closed assessments
    await refreshAccessTokenAndSetUserState();
    const currentState = store.deref();
    const token = currentState.user.token;
    if (!currentState.ongoingAssessment) {
      await network.createAssessment(
        {
          meijiId: currentState.user.meijiId,
        },
        token
      );
    }
    page('/test-kit/purchase');
  } catch (error) {
    logger.error(`onClickGoToTestKitPurchase`, error);
    // FIXME: Japanese translation
    reducers.updateSnackbar(store, {
      message: 'Error creating assessment',
      type: 'error',
      visible: true,
    });
  }
}
/**
 * onClickGoToPurchaseBoth
 *
 * @remarks
 * A click event handler sent you to the purchase kit and data service purchase
 *
 * @param store - js-atom instance
 */
export async function onClickGoToPurchaseBoth(store: Store) {
  // Redirect
  try {
    //FIXME check for non closed assessments
    await refreshAccessTokenAndSetUserState();
    const currentState = store.deref();
    const token = currentState.user.token;
    if (!currentState.ongoingAssessment) {
      await network.createAssessment(
        {
          meijiId: currentState.user.meijiId,
        },
        token
      );
    }

    page('/test-kit/purchase/both');
  } catch (error) {
    logger.error(`onClickGoToPurchaseBoth`, error);
  }
}

/**
 * onClickOpenScanInstructions
 *
 * @remarks
 * A click event handler on open scan introductions page.
 *
 * @param store - js-atom instance
 */
export function onClickGoToKitConfirmation(store: Store) {
  logger.debug('onClickOpenScanInstructions');
  page('/products');
}

/**
 * onClickOpenBeforeSampleCollections
 *
 * @remarks
 * A click event handler on open before sample collections page.
 * Redirect to /test-kit/collection-instructions
 *
 * @param store - js-atom instance
 */
export function onClickOpenBeforeSampleCollections(store: Store) {
  logger.debug('onClickOpenBeforeSampleCollections');
  page('/test-kit/before-sample-collection');
}

export function onClickCloseSnackbar(store: Store) {
  reducers.updateSnackbar(store, {
    message: '',
    type: 'error',
    visible: false,
  });
}

export function onClickOpenScanner() {
  page('/test-kit/add-by-scan');
}

/**
 * onClickMyPage
 *
 * @remarks
 * Redirect to /my-page
 *
 * @param store - js-atom instance
 */
export function onClickMyPage(store: Store) {
  page('/my-page');
}

/**
 * onClickOpenBeforeSampleCollections
 *
 * @remarks
 * A click event handler redirecting to reports page
 */
export function onClickMyPageReports() {
  const currentState = getState();
  const selectedAssessment = currentState.selectedAssessment;
  page(`/my-page/reports/${selectedAssessment?._id}`);
}

/**
 * onClickMyPageChallenges
 *
 * @remarks
 * A click event handler redirecting to challenges page
 */
export function onClickMyPageChallenges() {
  const currentState = getState();
  const selectedAssessment = currentState.selectedAssessment;
  page(`/my-page/reports/${selectedAssessment?._id}/challenges`);
}

/**
 * onClickOpenSurvey
 *
 * @remarks
 * A click event handler to go to survey from before-sample-collections
 * - update assessment status
 *
 * @param store - js-atom instance
 */
export async function onClickOpenSurvey(store: Store) {
  logger.debug('onClickOpenSurvey');

  try {
    const currentState = getState();
    if (
      currentState.ongoingAssessment?.status !==
      AssessmentStatus.PREPARING_SURVEY
    ) {
      await refreshAccessTokenAndSetUserState();
      const token = currentState.user.token;
      const status = AssessmentStatus.PREPARING_SURVEY;
      const ongoingAssessmentId = currentState.ongoingAssessment?._id;

      if (!ongoingAssessmentId)
        throw new Error('ongoingAssessment?._id not found');

      await network.updateAssessment(ongoingAssessmentId, { status }, token);
    }

    page('/survey');
  } catch (error) {
    logger.error(error);
    reducers.updateSnackbar(store, {
      message: ERRORS.UPDATE_ASSESSMENT_STATUS_ERROR,
      type: 'error',
      visible: true,
    });
  }
}

/**
 * onSelectAnswer
 *
 * @remarks
 * store the selected answer
 * add or remove questions based on that answer
 * send that answer to the network
 *
 * @param store - js-atom instance
 * @param answer - the selected answer
 */
export async function onSelectAnswer(
  store: Store,
  saveIncrementally: boolean,
  answer: Omit<Answer, 'label'>
) {
  logger.info(answer);
  reducers.updateLoading(store, 'surveyAutoSaveIsLoading', true);
  try {
    const conditionalActions = calculateNextQuestionsFromCurrentAnswers(
      getState().surveyPage.questions,
      getState().surveyAnswers,
      `${answer.key}_${answer.value}`
    );

    if (_.get(conditionalActions, 'questions')) {
      reducers.setCurrentSurveyPage(store, {
        ...getState().surveyPage,
        questions: _.get(
          conditionalActions,
          'questions',
          getState().surveyPage.questions
        ),
      });
    }
    if (_.get(conditionalActions, 'answers')) {
      // Remove conditional questions & answer if a new option is selected.
      reducers.updateSurveyAnswers(
        store,
        _.get(conditionalActions, 'answers', getState().surveyAnswers)
      );
    }
    //Update answer
    const nextSurveyAnswers = _.filter([...getState().surveyAnswers], (o) => {
      return answer.key !== o.key;
    });
    reducers.updateSurveyAnswers(store, [...nextSurveyAnswers, answer]);
    if (saveIncrementally) {
      await onAutoSaveSurvey(store);
    }
    reducers.updateLoading(store, 'surveyAutoSaveIsLoading', false);
    // Update Error list
    if (getState().surveyPage.questionErrors.length > 0) {
      const currentSurveyQuestionsUnanswered = getUnAnsweredQuestions(
        getState().surveyAnswers,
        getState().surveyPage.questions
      );
      reducers.setCurrentSurveyPage(store, {
        ...getState().surveyPage,
        questionErrors: currentSurveyQuestionsUnanswered,
      });
    }
  } catch (error) {
    logger.error('[onSelectAnswer]', error);
    reducers.updateLoading(store, 'surveyAutoSaveIsLoading', false);
  }
}

/**
 * onNextSurveyPage
 *
 * @remarks
 * check all the questions have been answered
 * go to the next page
 *
 * @param store - js-atom instance
 */
export function onNextSurveyPage(store: Store) {
  const currentState = getState();
  const currentSurveyQuestionsUnanswered = getUnAnsweredQuestions(
    currentState.surveyAnswers,
    currentState.surveyPage.questions
  );
  reducers.setCurrentSurveyPage(store, {
    ...currentState.surveyPage,
    questionErrors: currentSurveyQuestionsUnanswered,
  });
  if (currentSurveyQuestionsUnanswered.length > 0) return;
  page(`/survey/${currentState.surveyPage.index + 1}`);
}

/**
 * onPreviousSurveyPage
 *
 * @remarks
 * go to the previous page
 *
 * @param store - js-atom instance
 */
export function onPreviousSurveyPage(store: Store) {
  const currentState = store.deref();
  const previousIndex =
    currentState.surveyPage.index - 1
      ? `/${currentState.surveyPage.index - 1}`
      : '';
  page(`/survey${previousIndex}`);
}

/**
 * onAutoSaveSurvey
 *
 * @remarks
 * - post the survey on answer change
 *
 * @param store - js-atom instance
 */
export async function onAutoSaveSurvey(store: Store) {
  try {
    await refreshAccessTokenAndSetUserState();
    const currentState = getState();
    const token = currentState.user.token;
    const ongoingAssessmentId = currentState.ongoingAssessment?._id;
    const survey = normalizers.normalizeSurvey(
      ALL_SURVEY,
      _.get(currentState, 'surveyAnswers')
    );
    if (!ongoingAssessmentId)
      throw new Error('ongoingAssessment._id not found');
    const apiSurvey = normalizers.normalizedSurveyToSurvey(survey);
    await network.updateAssessment(
      ongoingAssessmentId,
      { survey: apiSurvey },
      token
    );

    // FIXME: @alex can we remove this?
    reducers.setSelectedAssessment(store, {
      ...(_.get(currentState, 'selectedAssessment') as NormalizedAssessment),
      survey,
    });

    reducers.setOngoingAssessment(store, {
      ...(_.get(currentState, 'ongoingAssessment') as NormalizedAssessment),
      survey,
    });
  } catch (error) {
    logger.error('[onAutoSaveSurvey]', error);
    reducers.updateSnackbar(store, {
      message: ERRORS.SURVEY_AUTO_SAVE_ERROR,
      type: 'error',
      visible: true,
    });
    // Caller needs to know if error occurred
    // Consider refactoring to move all error handling to the caller: onSelectAnswer
    throw error;
  }
}

/**
 * onSubmitSurvey
 *
 * @remarks
 * - check there is no unanswered questions
 * - refresh access token
 * - update the status
 * - update the Selected Assessment
 *
 * @param store - js-atom instance
 */

export async function onSubmitSurvey(store: Store) {
  const currentState = getState();
  const currentSurveyQuestionsUnanswered = getUnAnsweredQuestions(
    currentState.surveyAnswers,
    currentState.surveyPage.questions
  );
  reducers.setCurrentSurveyPage(store, {
    ...currentState.surveyPage,
    questionErrors: currentSurveyQuestionsUnanswered,
  });
  if (currentSurveyQuestionsUnanswered.length > 0) {
    logger.error(
      'Must answer to the unanswered questions:',
      _.map(currentSurveyQuestionsUnanswered, 'id').join()
    );
    reducers.updateSnackbar(store, {
      message: ERRORS.SURVEY_UNANSWERED_ERROR,
      type: 'error',
      visible: true,
    });
    return;
  }
  try {
    await refreshAccessTokenAndSetUserState();
    const currentState = getState();
    const token = currentState.user.token;
    const status = AssessmentStatus.TESTING;
    const ongoingAssessmentId = currentState.ongoingAssessment?._id;
    const survey = normalizers.normalizeSurvey(
      ALL_SURVEY,
      _.get(currentState, 'surveyAnswers')
    );
    if (!ongoingAssessmentId)
      throw new Error('ongoingAssessment._id not found');
    const apiSurvey = normalizers.normalizedSurveyToSurvey(survey);
    await network.updateAssessment(
      ongoingAssessmentId,
      { status, survey: apiSurvey },
      token
    );
    reducers.setOngoingAssessment(store, {
      ...(currentState.ongoingAssessment as NormalizedAssessment),
      status,
      survey,
    });
    page('/test-kit/collection-instructions');
  } catch (error) {
    logger.error(error);
    reducers.updateSnackbar(store, {
      message: ERRORS.SUBMIT_SURVEY_ERROR,
      type: 'error',
      visible: true,
    });
  }
}

/**
 * onClickLogin
 *
 * @remarks
 * A click event handler on login button on top bar.
 * - Redirect to /login
 *
 * @param store - js-atom instance
 */
export function onClickLogin(store: Store) {
  logger.debug('onClickLogin');
  page('/login');
}

/**
 * onClickSignUpOrLogin
 *
 * @remarks
 * A click event handler on sign up or login button on login page.
 * - Refresh access token and get meijiId of the session user
 * - Update user state with access token and meijiId
 * - Redirect to /my-page
 * - When any of requests to IDPF get failed, redirect to login page of IDPF
 *
 * @param store - js-atom instance
 */
export async function onClickSignUpOrLogin(store: Store) {
  logger.debug('onClickSignUpOrLogin');
  try {
    await refreshAccessTokenAndSetUserState(true);
    page('/products');
  } catch (error) {
    logger.debug('onClickSignUpOrLogin: Invalid access token');
  }
}

/**
 * onClickStartApplying
 *
 * @remarks
 * A click event handler on start applying button in top page.
 * Redirect to /agreement
 */
export async function onClickStartApplying(store: Store) {
  logger.debug('onClickStartApplying');

  try {
    page('/products');
  } catch (error) {
    logger.error(error);
    reducers.updateSnackbar(store, {
      message: ERRORS.SUBMIT_DELIVERY_REQUEST_ERROR,
      type: 'error',
      visible: true,
    });
  }
}

/**
 * onClickOpenCollectionInstructions
 *
 * @remarks
 * A click event handler on open collection instructions page.
 * Redirect to /test-kit/collection-instructions
 *
 * @param store - js-atom instance
 */
export function onClickOpenCollectionInstructions(store: Store) {
  logger.debug('onClickOpenCollectionInstructions');
  page('/test-kit/collection-instructions');
}

/**
 * onClickBackPage
 *
 * @remarks
 * A click event handler to go back to the previous page
 *
 * @param store - js-atom instance
 */
export function onClickBackPage(store: Store) {
  const routeOrder: string[] = [
    '/test-kit/before-sample-collection',
    '/test-kit/collection-instructions',
    '/test-kit/postage-instructions',
  ];
  const index = routeOrder.indexOf(location.pathname);
  const prevRoute = routeOrder[index - 1];
  if (!prevRoute) {
    logger.debug(`prev route is not defined for route:${location.pathname}`);
    return;
  }
  page(prevRoute);
}

/**
 * onClickOpenPostageInstructions
 *
 * @remarks
 * A click event handler to go to postage-instruction from collection-instructions
 * - update assessment status
 *
 * @param store - js-atom instance
 */
export async function onClickOpenPostageInstructions(store: Store) {
  try {
    await refreshAccessTokenAndSetUserState();
    const currentState = getState();
    if (currentState.ongoingAssessment?.status === AssessmentStatus.TESTING) {
      const token = currentState.user.token;
      const status = AssessmentStatus.PREPARING_POST;
      const ongoingAssessmentId = currentState.ongoingAssessment?._id;
      if (!ongoingAssessmentId)
        throw new Error('ongoingAssessment._id not found');

      await network.updateAssessment(ongoingAssessmentId, { status }, token);
      // FIXME: Can we remove???
      reducers.setSelectedAssessment(store, {
        ...(currentState.selectedAssessment as NormalizedAssessment),
        status,
      });

      reducers.setOngoingAssessment(store, {
        ...(currentState.ongoingAssessment as NormalizedAssessment),
        status,
      });
    }

    page('/test-kit/postage-instructions');
  } catch (error) {
    logger.error(error);
    reducers.updateSnackbar(store, {
      message: ERRORS.UPDATE_ASSESSMENT_STATUS_ERROR,
      type: 'error',
      visible: true,
    });
  }
}

/**
 * onClickPostageInstructions
 *
 * @remarks
 * A click event handler to go to postage-instruction from collection-instructions
 * - update assessment status
 *
 * @param store - js-atom instance
 */
export async function onClickPostageInstructions(store: Store) {
  try {
    await refreshAccessTokenAndSetUserState();
    const currentState = getState();
    const token = currentState.user.token;
    const status = AssessmentStatus.READY_FOR_POST;
    const ongoingAssessmentId = currentState.ongoingAssessment?._id;
    if (!ongoingAssessmentId)
      throw new Error('ongoingAssessment._id not found');
    await network.updateAssessment(ongoingAssessmentId, { status }, token);
    // FIXME:  Can we remove this??
    reducers.setSelectedAssessment(store, {
      ...(currentState.selectedAssessment as NormalizedAssessment),
      status,
    });

    reducers.setOngoingAssessment(store, {
      ...(currentState.ongoingAssessment as NormalizedAssessment),
      status,
    });

    page('/my-page');
  } catch (error) {
    logger.error(error);
    reducers.updateSnackbar(store, {
      message: ERRORS.UPDATE_ASSESSMENT_STATUS_ERROR,
      type: 'error',
      visible: true,
    });
  }
}

/**
 * onClickErrorButton
 *
 * @remarks
 * - to reload the page when the error button is clicked
 *
 * @param link - a href link etc "/my-page" or "/"
 */
export function onClickErrorButton(link: string) {
  window.location.href = link;
}

/**
 * navigateTo -  internal
 *
 * redirects to internal page
 * @param path
 *
 */
export function navigateTo(path: string) {
  logger.debug('navigateTo');
  page(path);
}

/**
 * navigateToExternal
 *
 * redirects to external page
 * @param path
 *
 */
export function navigateToExternal(path: string) {
  logger.debug('navigateToExternal');
  location.assign(path);
}

export function onChangeColumnListCategory(category: ColumnCategory | null) {
  if (category) page(`/columns?category=${category}`);
  else page(`/columns`);
}

export function onClickGoToColumn(category: ColumnCategory, num: number) {
  page(
    `/columns/${
      ColumnCategoryToURLSlug.find((item) => item.category === category)
        ?.slug ?? ''
    }-${num}`
  );
}

export function onClickToColumn(link?: string) {
  page(`/columns/${link}`);
}

/**
 * onClickGoToAssessmentReport
 *
 * @remarks
 * - tracks the first time a user views a report
 * - navigates to report detail page
 *
 * @param assessmentId
 */
export async function onClickGoToAssessmentReport(assessmentId: string) {
  logger.debug('onClickGoToAssessmentReport');

  // check if the user has not viewed the report
  const hasViewedCurrentReport = localStorage.getItem(
    `assessment_${assessmentId}_report_viewed`
  );

  if (!hasViewedCurrentReport) {
    // if the user has not viewed the report, push this event to GTM dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'view_report_first',
      report_provided_date: dayjs().tz('Asia/Tokyo').format('YYYY年M月D日'),
    });

    //storing the report as viewed
    localStorage.setItem(`assessment_${assessmentId}_report_viewed`, 'true');
  }

  // Navigate to report detail page
  page(`/my-page/reports/${assessmentId}`);
}
