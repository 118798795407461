import { Box, Grid, Typography } from '@mui/material';

import { TeaserSectionTitle } from '@/view/components/Teaser/TeaserSectionTitle';
import { defaultThemeColor, STYLES } from '@/view/styling/theme';

interface ServiceProps {
  serviceData: {
    alert?: string;
    content: string;
    image: string;
    title: string;
  }[];
}

export function TeaserService({ serviceData }: ServiceProps) {
  return (
    <Grid container sx={{ p: 2.5 }}>
      <TeaserSectionTitle title={'Service'} subtitle={'サービスの流れ'} />

      {serviceData.map((service, index) => {
        return (
          <Grid key={index} item xs={12} sx={{ position: 'relative', pt: 4 }}>
            <Typography
              sx={{
                color: defaultThemeColor,
                fontFamily: 'Roboto',
                fontSize: '40px',
                fontWeight: 600,
                left: 20,
                position: 'absolute',
                top: 6,
              }}
            >{`0${index + 1}`}</Typography>
            <Box>
              <img src={service.image} alt={service.title} width={'100%'} />
            </Box>
            {service.alert && (
              <Box sx={{ pt: 1 }}>
                <Typography
                  sx={(theme) => ({
                    color: theme.typography.caption.color,
                    fontSize: '14px',
                    fontWeight: 300,
                    lineHeight: '25.2px',
                  })}
                >
                  {service.alert}
                </Typography>
              </Box>
            )}
            <Box sx={STYLES.topPage.serviceContent}>
              <Typography
                sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '27px' }}
              >
                {service.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 300,
                  lineHeight: '28.8px',
                  pt: 3,
                }}
              >
                {service.content}
              </Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}
