import { Box, Grid, Typography } from '@mui/material';

import { FONT_SIZES, LINE_HEIGHTS } from '@/view/styling/theme';

type GraphColorBox = {
  color: string;
  label: string;
};

export function GraphColoringLabels({ list }: { list: GraphColorBox[] }) {
  return (
    <Grid container direction="row" gap={2}>
      {list.map((l) => {
        return (
          <Grid item key={l.color}>
            <Grid container direction="row" gap={1}>
              <Grid item>
                <Box
                  display="flex"
                  marginTop="3px"
                  bgcolor={l.color}
                  width="24px"
                  height="16px"
                />
              </Grid>
              <Grid item justifySelf="center">
                <Typography
                  sx={{
                    fontSize: FONT_SIZES.sm,
                    lineHeight: LINE_HEIGHTS.sm,
                  }}
                >
                  {l.label}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
