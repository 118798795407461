import { Box } from '@mui/material';

import { TOP_PAGE } from '@/constants';
import { HeroTitle } from '@/view/components/TopPage/HeroTitle';
import { STYLES } from '@/view/styling/theme';

import { AutoplayImageCarousel } from '../Carousel';

export function Hero() {
  return (
    <Box sx={STYLES.hero}>
      <Box
        sx={(theme) => ({
          backgroundColor: '#F8F6F4',
          pb: 4,
          [theme.breakpoints.up('lg')]: { pb: 6 },
          [theme.breakpoints.up('md')]: { pb: 4 },
        })}
      >
        <HeroTitle />
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: '#F8F6F4',
          display: 'flex',
          height: '55%',
          maxWidth: '100vw',
          [theme.breakpoints.down('sm')]: { height: '47%' },
        })}
      >
        <AutoplayImageCarousel images={[...TOP_PAGE.HERO.IMAGE_LIST]} />
      </Box>
    </Box>
  );
}
