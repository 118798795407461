import { Box, Grid, Button, Typography } from '@mui/material';
import { useState, Fragment } from 'react';

import { EXPLAINING_COCOA } from '@/constants';
import { Modal } from '@/view/components/Modal';
import { BORDER_RADIUS, STYLES } from '@/view/styling/theme';

export function ExplainingCocoa({
  triggerButton,
}: {
  triggerButton: JSX.Element;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <span onClick={() => setIsModalOpen(true)}>{triggerButton}</span>
      <Modal
        title=""
        open={isModalOpen}
        hasStickyTitle={true}
        actions={
          <Button
            variant="outlined"
            size="large"
            sx={{ ...STYLES.secondaryButtonModal, flex: 1, marginX: 1 }}
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            {EXPLAINING_COCOA.CLOSE_BUTTON_TEXT}
          </Button>
        }
      >
        <Grid container direction="column" wrap="nowrap">
          <Grid item sx={{ mb: 5 }}>
            <Typography variant="h6" fontSize={20} fontWeight={600}>
              {EXPLAINING_COCOA.TITLE}
            </Typography>
          </Grid>

          <Grid item sx={{ mb: 2 }}>
            <Typography variant="h6" fontSize={16} fontWeight={600}>
              {EXPLAINING_COCOA.SUBTITLE_1}
            </Typography>
          </Grid>
          <Grid item sx={{ mb: 2 }}>
            <Box
              sx={{
                border: 1,
                borderColor: 'grey.100',
                borderRadius: BORDER_RADIUS.xl,
              }}
            >
              <img
                width="100%"
                src={EXPLAINING_COCOA.IMAGE_1}
                style={{ borderRadius: '12px' }}
                alt="EXPLAINING_COCOA.SUBTITLE_1"
              />
            </Box>
          </Grid>
          <Grid item sx={{ mb: 5 }}>
            <Typography fontWeight={300} lineHeight={1.75}>
              {EXPLAINING_COCOA.BODY_1.map((item, index) => (
                <Fragment key={index}>
                  {item} <br />
                </Fragment>
              ))}
            </Typography>
          </Grid>

          <Grid item sx={{ mb: 2 }}>
            <Typography variant="h6" fontSize={16} fontWeight={600}>
              {EXPLAINING_COCOA.SUBTITLE_2}
            </Typography>
          </Grid>
          <Grid item sx={{ mb: 2 }}>
            <Box
              sx={{
                border: 1,
                borderColor: 'grey.100',
                borderRadius: BORDER_RADIUS.xl,
              }}
            >
              <img
                width="100%"
                src={EXPLAINING_COCOA.IMAGE_2}
                style={{ borderRadius: '12px' }}
                alt="EXPLAINING_COCOA.SUBTITLE_2"
              />
            </Box>
          </Grid>
          <Grid item sx={{ mb: 5 }}>
            <Typography fontWeight={300} lineHeight={1.75}>
              {EXPLAINING_COCOA.BODY_2.map((item, index) => (
                <Fragment key={index}>
                  {item} <br />
                </Fragment>
              ))}
            </Typography>
          </Grid>

          <Grid item sx={{ mb: 2 }}>
            <Typography variant="h6" fontSize={16} fontWeight={600}>
              {EXPLAINING_COCOA.SUBTITLE_3}
            </Typography>
          </Grid>
          <Grid item sx={{ mb: 2 }}>
            <Box
              sx={{
                border: 1,
                borderColor: 'grey.100',
                borderRadius: BORDER_RADIUS.xl,
              }}
            >
              <img
                width="100%"
                src={EXPLAINING_COCOA.IMAGE_3}
                style={{ borderRadius: '12px' }}
                alt="EXPLAINING_COCOA.SUBTITLE_3"
              />
            </Box>
          </Grid>
          <Grid item sx={{ mb: 5 }}>
            <Typography fontWeight={300} lineHeight={1.75}>
              {EXPLAINING_COCOA.BODY_3.map((item, index) => (
                <Fragment key={index}>
                  {item} <br />
                </Fragment>
              ))}
            </Typography>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}
