import { Box, Grid, Typography } from '@mui/material';

import { TEASER_PAGE } from '@/constants';
import { SpeechBubble } from '@/view/components/SpeechBubble';
import { TeaserSectionTitle } from '@/view/components/Teaser/TeaserSectionTitle';
import { TextHighlight } from '@/view/components/TextHighlight';
import { FONT_SIZES } from '@/view/styling/theme';

const report = TEASER_PAGE.REPORT;
const info = TEASER_PAGE.INFORMATION;

export function TeaserFact() {
  return (
    <Grid
      container
      sx={{
        background: '#F8F6F4',
        justifyContent: 'center',
        p: 2.5,
        textAlign: 'center',
      }}
    >
      <Grid
        item
        container
        sx={{
          justifyContent: 'center',
          marginTop: '8px',
          textAlign: 'center',
        }}
      >
        <TeaserSectionTitle title={'Fact'} subtitle={info.BODY_CAPTION_3} />

        <Grid item sx={{ marginTop: '16px', width: '100%' }}>
          <SpeechBubble
            style={{ padding: '16px', width: '90%' }}
            tipSize="12px"
          >
            <TextHighlight
              text={info.BODY_LINE_4_HIGHLIGHTED}
              commonTextStyle={{
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '32.4px',
                textAlign: 'center',
              }}
            />
          </SpeechBubble>
          <img style={{ width: '70%' }} src={info.BODY_IMAGE_4} />
          <Box sx={{ pt: 3 }}>
            <Typography sx={{ fontSize: FONT_SIZES.xs }}>
              {info.REMARK}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} alignItems="center" sx={{ marginTop: '40px' }}>
        <SpeechBubble style={{ padding: '16px', width: '90%' }} tipSize="12px">
          <TextHighlight
            text={report.BUBBLE_TEXT_HIGHLIGHTED}
            commonTextStyle={{
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '32.4px',
              textAlign: 'center',
            }}
          />
        </SpeechBubble>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: '20px' }}>
        <img width="75%" src={report.FACT_IMAGE_1} />
        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}>
          <Typography sx={{ fontSize: FONT_SIZES.xs }}>
            {report.REMARK}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
