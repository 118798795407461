import { Container } from '@mui/material';

import { NOT_FOUND_PAGE } from '@/constants';
import * as user from '@/domain/middlewares/user';
import { ErrorCard } from '@/view/components/ErrorCard';

export function NotFound() {
  return (
    <div>
      <Container>
        <ErrorCard
          code={NOT_FOUND_PAGE.ERROR_CODE}
          title={NOT_FOUND_PAGE.PAGE_TITLE}
          description={NOT_FOUND_PAGE.BODY_LINE_1}
          detailTitle={NOT_FOUND_PAGE.BODY_LINE_2}
          detailDescription={NOT_FOUND_PAGE.BODY_LINE_3}
          onClick={() => user.onClickErrorButton('/')}
          linkLabel={NOT_FOUND_PAGE.LINK_LABEL_1}
        />
      </Container>
    </div>
  );
}
