import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Grid, Typography } from '@mui/material';

import { MY_PAGE_REPORT } from '@/constants';
import { FONT_SIZES, STYLES } from '@/view/styling/theme';

export type RightActionType = 'good' | 'kaizen-needed';

type Props = {
  isOpen: boolean;
  onClick?: () => void;
  rightActionType: RightActionType;
  title: string;
};

export function ReportTitleWithAdvice({
  isOpen,
  rightActionType,
  title,
  onClick,
}: Props) {
  const shouldBeDownArrow = isOpen ? { transform: 'rotate(90deg)' } : {};
  const icon = (
    <ArrowForwardIosIcon
      sx={{
        color: 'grey.600',
        height: 14,
        ml: -0.5,
        opacity: onClick ? 1 : 0,
        ...shouldBeDownArrow,
      }}
    />
  );

  const goodButton = (
    <Button
      variant="outlined"
      onClick={onClick}
      disabled={!onClick}
      size="small"
      sx={STYLES.smallOutlinedIconButton}
      startIcon={<img src="/images/reports/sentiment_satisfied.svg" />}
      endIcon={icon}
    >
      <Grid container alignItems="center" gap={1}>
        <Typography
          sx={{
            color: 'text.primary',
            fontSize: FONT_SIZES.xs,
            fontWeight: 600,
          }}
        >
          {MY_PAGE_REPORT.THUMB_UP_TEXT}
        </Typography>
      </Grid>
    </Button>
  );

  const improvementButton = (
    <Button
      variant="outlined"
      size="small"
      onClick={onClick}
      disabled={!onClick}
      sx={STYLES.smallOutlinedIconButton}
      startIcon={<img src="/images/reports/sentiment_dissatisfied.svg" />}
      endIcon={icon}
    >
      <Typography
        sx={{
          color: 'text.primary',
          fontSize: FONT_SIZES.xs,
          fontWeight: 600,
        }}
      >
        {MY_PAGE_REPORT.GRAPH_ACTION_TEXT}
      </Typography>
    </Button>
  );

  const rightActions: Record<RightActionType, React.ReactElement> = {
    good: goodButton,
    'kaizen-needed': improvementButton,
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      sx={{ mt: 2 }}
    >
      <Grid item>
        <Typography
          sx={{
            display: 'block',
            fontSize: FONT_SIZES.sm,
            fontWeight: 600,
            paddingLeft: 1,
            whiteSpace: 'pre-wrap',
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item sx={{ flex: '0 0 auto' }}>
        {rightActions[rightActionType]}
      </Grid>
    </Grid>
  );
}
