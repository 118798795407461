import { Grid, Typography } from '@mui/material';

export function Inquiry() {
  return (
    <Grid
      container
      sx={{ backgroundColor: 'white', padding: '10px', width: '100%' }}
    >
      <Grid container item>
        <Grid
          container
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '40px 16px 32px 16px',
            textAlign: 'center',
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '22px',
                fontWeight: 600,
                lineHeight: '33px',
                textAlign: 'center',
                whiteSpace: 'pre-wrap',
              }}
            >
              {`インナーガーデンについての\nお問い合わせはコチラ`}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: '32px' }}>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 300,
                lineHeight: '32.4px',
                textAlign: 'center',
              }}
            >
              {`明治会員サポートセンター`}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '0px' }}>
            <a
              href="https://id.meiji.co.jp/contact/input"
              style={{ color: '#2F2F2F', textDecoration: 'underline' }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 300,
                  lineHeight: '32.4px',
                  textAlign: 'center',
                }}
              >
                {`https://id.meiji.co.jp/contact/input`}
              </Typography>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
