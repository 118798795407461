import { SxProps, Theme } from '@mui/system';
import styled from '@mui/system/styled';
import _ from 'lodash';

import MascotAdvice from '@/assets/mascot-advice.svg';
import MascotHeadDefault from '@/assets/mascot-head-default.svg';
import MascotPoint from '@/assets/mascot-point.svg';
import MascotPost from '@/assets/mascot-post.svg';
import MascotRegisterComplete from '@/assets/mascot-register-complete.svg';
import MascotRestartPost from '@/assets/mascot-restart-post.svg';
import MascotRestartTest from '@/assets/mascot-restart-test.svg';
import MascotSorry from '@/assets/mascot-sorry.svg';
import MascotWaitingForTest from '@/assets/mascot-waiting-on-test.svg';

export type Variant =
  | 'default'
  | 'advice'
  | 'point'
  | 'post'
  | 'register-complete'
  | 'restart-post'
  | 'restart-test'
  | 'sorry'
  | 'waiting-for-test';

export type Props = {
  isFlippedHorizontally?: boolean;
  size?: number;
  sx?: SxProps<Theme>;
  variant: Variant;
};

type StyledImgProps = {
  isFlippedHorizontally?: boolean;
  size?: number;
};

const StyledImg = styled('img')<StyledImgProps>(
  ({ size, isFlippedHorizontally }) => ({
    height: size ? `${size}px` : undefined,
    transform: isFlippedHorizontally ? 'scaleX(-1)' : 'scaleX(1)',
  })
);

export function Mascot({ variant, size, isFlippedHorizontally, sx }: Props) {
  const images: Record<Variant, string> = {
    advice: MascotAdvice,
    default: MascotHeadDefault,
    point: MascotPoint,
    post: MascotPost,
    'register-complete': MascotRegisterComplete,
    'restart-post': MascotRestartPost,
    'restart-test': MascotRestartTest,
    sorry: MascotSorry,
    'waiting-for-test': MascotWaitingForTest,
  };

  return (
    <StyledImg
      size={size}
      isFlippedHorizontally={isFlippedHorizontally}
      sx={sx}
      src={_.get(images, variant, 'default')}
      alt={variant}
    />
  );
}
