import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, Typography, Button, Paper, Box } from '@mui/material';

import { MY_PAGE_RE_START } from '@/constants';
import { ExplainingFloraCheck } from '@/view/components/ExplainingFloraCheck';
import { FONT_SIZES, LINE_HEIGHTS, STYLES } from '@/view/styling/theme';

type Props = {
  afterTesting?: boolean;
  onClickSubmit: () => void;
};

export function ResumeAssessment({ afterTesting, onClickSubmit }: Props) {
  return (
    <Grid container direction="column" gap={2}>
      <Grid item>
        <Typography sx={STYLES.myPageHeading}>申込み中の検査</Typography>
      </Grid>
      <Grid item>
        <Paper elevation={0} sx={{ borderRadius: '12px', padding: '24px' }}>
          <Grid container direction="column" gap={2}>
            <Grid item>
              <Box
                mt={1}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                }}
              >
                <img
                  src={
                    afterTesting
                      ? MY_PAGE_RE_START.IMAGE_2
                      : MY_PAGE_RE_START.IMAGE_1
                  }
                />
                <img width="248.46px" src={MY_PAGE_RE_START.IMAGE_3} />
              </Box>
            </Grid>
            <Grid item>
              <Typography sx={STYLES.mainHeading}>
                {afterTesting
                  ? MY_PAGE_RE_START.BODY_LINE_2
                  : MY_PAGE_RE_START.BODY_LINE_1}
              </Typography>
            </Grid>
            <Grid item>
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  fullWidth
                  onClick={onClickSubmit}
                  data-e2e="my-page__resume-btn"
                >
                  {MY_PAGE_RE_START.SUBMIT_BUTTON}
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item>
                <Typography
                  sx={{
                    color: 'grey.300',
                    display: 'block',
                    fontSize: FONT_SIZES.md,
                    lineHeight: LINE_HEIGHTS.lg,
                    textAlign: 'center',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                  }}
                >
                  {MY_PAGE_RE_START.BODY_CAPTION}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <ExplainingFloraCheck
                triggerButton={
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{
                      '&:hover': {
                        backgroundColor: 'common.white',
                        boxShadow: 'none',
                      },
                      alignItems: 'end',
                      backgroundColor: 'common.white',
                      color: 'grey.300',
                      fontSize: FONT_SIZES.sm,
                      fontWeight: 300,
                      height: '37px',
                      justifyContent: 'flex-start',
                      paddingLeft: 0,
                      textTransform: 'none',
                    }}
                    startIcon={
                      <HelpOutlineIcon
                        sx={{
                          color: 'primary.main',
                        }}
                      />
                    }
                  >
                    腸内フローラ検査について
                  </Button>
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
