import { Box } from '@mui/material';

import arrowDown from '@/assets/arrow-down.svg';

export function StepItemArrow() {
  return (
    <Box sx={{ marginBottom: 1, marginTop: '2px' }}>
      <img src={arrowDown} width="28px" height="23px" />
    </Box>
  );
}
